import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const VciEmt = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "Rust-X"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "Rust-X"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "Rust-X"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "Rust-X"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "Rust-X"&&cid==='8'){
            setShow("vcp8")
          }
       
       
        
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "19000 Desiccants"  : show === "vcp2" ? "4200 Emitters Sachets"  : 
                               show === "vcp3" ? "4231 Tablets"  : show === "vcp4" ? "4500 Dual Action Pouch"  :
                               show === "vcp5" ? " 777 Chips"  : show === "vcp6" ? "Foam 798"  :
                               show === "vcp7" ? "Emitter 5 and 11"  : show === "vcp8" ? "VCI Powder 4201"  
                            
                               :""}

                               

                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/emi1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/emit2.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/emit3.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/emit4.webp' alt='dummy'/> 
                                </>:
                               show === "vcp5" ?
                                <>
                                    <img src='/images/emit5.webp' alt='dummy'/> 
                                </>:
                               show === "vcp6" ?
                                <>
                                    <img src='/images/emit6.webp' alt='dummy'/> 
                                </>:
                               show === "vcp7" ?
                                <>
                                    <img src='/images/emit7.webp' alt='dummy'/> 
                                </>:
                               show === "vcp8" ?
                                <>
                                    <img src='/images/emit8.webp' alt='dummy'/> 
                                </>:
                              
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>VCI Desiccants 19000 are DMF free, ROHS and REACH approved desiccants that help reduce the humidity inside the package. The desiccants adsorb excess moisture and drop the dew point inside the package and maintain an environment that passivates corrosion.VCI Desiccants 19000 provide a reliable solution for moisture control and corrosion prevention, ensuring that valuable metal items are protected during storage, shipping, and handling. Their compliance with industry standards and regulations underscores their safety and effectiveness in various environments.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>VCI Emitters 4200 are a mixture of high and low speed concentrates of Vapor Corrosion Inhibitors powders that volatilize due to vapor pressure difference in the packaging and react with humidity. They form a nano molecular layer on the metallic surface that inhibits corrosion. It is packed in various sizes of Tysorb bags and can be placed in vicinity or direct contact with metal.
                                VCI Emitters 4200 offer an advanced and effective solution for protecting metal items by utilizing vapor corrosion inhibition technology. Their ability to form a protective layer and their versatility in placement make them valuable assets in preserving the integrity of metal components.</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>Rust-X VCI Tablets Thanks to the small sizes, they can be easily positioned in the most hidden and difficult to protect points. They provide the mono-ionic protective layer to enclosed materials in containers, boxes, Plastic bags, Cartons etc which protects them from the corrosion and safe them from Rust. They are ideal solution for heat transfer equipment tubes as well as equipment which is hollow and needs corrosion protection
                                ust-X VCI Tablets offer a convenient and effective solution for protecting metal items from corrosion.</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>VCI 4500 is the most advanced version which is the dual action pouch is made to avoid confusions that are commonly caused related to dosage levels of desiccant and emitters to the end customers in the packaging. The dual action formula adsorbs moisture and emits VCI at the same time.
                                VCI 4500 Dual Action Pouch offers a convenient and efficient way to protect metal items during packaging, simplifying the process for end customers while actively preventing corrosion.</p> : 
                                null}
                                {show === "vcp5"? <p className='text-muted firstparavalue'>RustX VCI Chips are impregnated with the SMP VCI quality of rust preventives and give excellent cushion and scratch protection for metallic components and surfaces. RUSTX VCI chips provide ultimate corrosion prevention and also act as an abrasion barrier. It is ideal to pack the chips in a closed environment close to metal for longevity of protection.
                                RustX VCI Chips offer a reliable solution for protecting metal components from corrosion, scratches, and abrasions. Their impregnation with VCI technology enhances their effectiveness, making them valuable assets for industries where metal preservation is crucial.</p> :
                                null}
                                {show === "vcp6"? <p className='text-muted firstparavalue'>VCI Foams are flat PU foams containing VCI chemicals. These chemicals sublimate and deposit on metal surfaces forming a protective mono-molecular layer. It conforms to most shapes without adding extra weight, helping to reduce the risk of damage to parts in transit.
                                VCI Foams, such as Foam 798, offer a practical and effective solution for corrosion prevention while providing cushioning and protection for metal components during transit. Their ability to conform to various shapes and their lightweight nature make them valuable assets in preserving the integrity of metal parts.</p> : 
                                null}
                                {show === "vcp7"? <p className='text-muted firstparavalue'>Emitter 5 and 11 are in the form of plastic boxes that can installed in electrical cabinets and form a leak proof system. These work for a larger surface area and lasts longer and are designed to easy installation inside the junction boxes, electrical cabinets and enclosures.
                                Emitter 5 and Emitter 11 provide a reliable solution for protecting metal components inside electrical cabinets and enclosures. Their leak-proof design, large surface area coverage, and long-lasting effectiveness make them valuable assets in preserving the integrity of electrical systems and preventing corrosion-related issues.</p> : 
                                null}
                                {show === "vcp8"? <p className='text-muted firstparavalue'>Corrosion Protection for boilers, pressure vessels, heat exchangers and other process plant equipment is a difficult task as dry removable protection is required without impacting the heat or thermal transfer properties of the equipment and yet need to protect hollow spaces from the wrath of corrosion. Also the VCI Powder should be easily removable when this equipment is brought in operation without any mechanical cleaning. Preserving these hollow components is achieved by spraying or fogging with VCI powder using air spray or compressed air or by pouring and rolling the powder. This can also be used for hydro testing and aqueous coating by dissolving at 2-5% in water.</p> : 
                                null}
                              
                               

                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp4" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp5" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp6" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp7" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp8" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>VCI Desiccants 19000 do not contain Dimethyl Fumarate (DMF), ensuring that they are free from this substance, which is often used in desiccants but can be harmful in certain applications.</p>: null}
                                    {show === "vcp2"?<p>VCI Emitters 4200 are specialized products that contain a blend of high and low-speed concentrates of Vapor Corrosion Inhibitors (VCIs) in powder form. These emitters are designed to volatilize within the packaging environment.</p>: null}
                                    {show === "vcp3"?<p> Rust-X VCI Tablets are specialized tablets designed for corrosion protection. "VCI" stands for Vapor Corrosion Inhibitors, indicating that these tablets release protective vapors that form a thin, mono-ionic layer on enclosed materials.</p>: null}
                                    {show === "vcp4"?<p>VCI 4500 Dual Action Pouch is an advanced version designed to simplify the process of corrosion protection in packaging. It combines desiccant and VCI (Volatile Corrosion Inhibitors) functionalities in a single pouch.</p>: null}
                                    {show === "vcp5"?<p>RustX VCI Chips are impregnated with SMP VCI (Volatile Corrosion Inhibitors) quality rust preventives. These inhibitors actively work to prevent rust and corrosion on metallic components and surfaces.</p>: null}
                                    {show === "vcp6"?<p>VCI Foams are flat Polyurethane (PU) foams infused with VCI (Volatile Corrosion Inhibitors) chemicals. These foams are designed to actively prevent corrosion on metal surfaces.</p>: null}
                                    {show === "vcp7"?<p> Emitter 5 and Emitter 11 come in the form of plastic boxes. This design ensures durability and provides a protective casing for the components inside, making them suitable for various environments, including electrical cabinets.</p>: null}
                                    {show === "vcp8"?<p>VCI Powder 4201 actively prevents rust and corrosion on metal surfaces. It forms a protective molecular layer on the metal, inhibiting the corrosive reactions that lead to rust formation.</p>: null}
                                    
                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These desiccants are compliant with ROHS (Restriction of Hazardous Substances) and REACH (Registration, Evaluation, Authorization, and Restriction of Chemicals) regulations, meeting stringent environmental and safety standards.</p>: null}
                                    {show === "vcp2"?<p>When these emitters volatilize, they react with humidity in the air. This reaction results in the formation of a nano-molecular layer on the surface of metal items. This layer acts as a barrier, inhibiting corrosion and protecting the metal surfaces from rust and other forms of degradation.</p>: null}
                                    {show === "vcp3"?<p>These tablets are small in size, allowing for easy placement in hidden and hard-to-reach areas within containers, boxes, plastic bags, cartons, and other enclosures.</p>: null}
                                    {show === "vcp4"?<p>The dual action pouch is created to prevent confusion regarding dosage levels. By integrating both moisture adsorption and VCI emission capabilities, it streamlines the packaging process for end customers.</p>: null}
                                    {show === "vcp5"?<p>These chips provide excellent cushioning and scratch protection for metal components. They act as a protective barrier, ensuring that delicate or sensitive surfaces are shielded from abrasions during storage or transportation.</p>: null}
                                    {show === "vcp6"?<p> VCI chemicals in the foam sublimate (transition from solid to gas) and deposit on metal surfaces. This process forms a protective mono-molecular layer on the metal, acting as a barrier against corrosion.</p>: null}
                                    {show === "vcp7"?<p>These emitters are designed to create a leak-proof system, preventing the entry of moisture or contaminants. The sealed plastic boxes offer protection against external elements, ensuring the integrity of the components within.</p>: null}
                                    {show === "vcp8"?<p>The powder contains VCI chemicals that volatilize and create a protective layer on metal components. This layer acts as a barrier, even in enclosed or hard-to-reach areas, preventing corrosion.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Desiccants 19000 are designed to adsorb excess moisture present inside packages. By doing so, they help maintain a low humidity environment, which is essential for preventing corrosion on metal surfaces.</p>: null}
                                    {show === "vcp2"?<p>The nano-molecular layer formed on the metallic surface is extremely thin and effective in preventing corrosion. It provides a protective shield against environmental factors that can cause metal corrosion, ensuring the longevity and quality of the metal components.</p>: null}
                                    {show === "vcp3"?<p>When placed in an enclosed space, the tablets release vapor that forms a protective mono-ionic layer on the surfaces of materials inside the container. This layer acts as a barrier, preventing corrosion and safeguarding the enclosed items from rust formation.</p>: null}
                                    {show === "vcp4"?<p>The pouch actively adsorbs moisture, helping to maintain a dry environment within the packaging. This is crucial for preventing moisture-related corrosion on metal items during storage and transportation.</p>: null}
                                    {show === "vcp5"?<p>RustX VCI Chips offer ultimate corrosion prevention. The VCI impregnation creates a protective layer on metal surfaces, preventing the formation of rust even in humid or corrosive environments.</p>: null}
                                    {show === "vcp6"?<p>VCI Foams are flexible and can conform to various shapes without adding significant weight. This conformability makes them ideal for wrapping or packaging irregularly shaped metal parts. It ensures that the foam closely adheres to the metal surface, providing effective corrosion protection.</p>: null}
                                    {show === "vcp7"?<p>Emitter 5 and Emitter 11 are engineered to cover a larger surface area. This expanded coverage allows for effective distribution of protective agents, enhancing their efficiency in preventing corrosion on metal surfaces within electrical cabinets.</p>: null}
                                    {show === "vcp8"?<p>VCI Powder 4201 is available in a powder form, allowing for versatile application methods. It can be dusted, sprinkled, or incorporated into various packaging materials to provide corrosion protection.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These desiccants lower the dew point inside the package. By reducing the dew point, they minimize the risk of condensation, which can lead to corrosion on metal components.</p>: null}
                                    {show === "vcp2"?<p>VCI Emitters 4200 are packaged in various sizes of Tysorb bags. These bags are designed to contain the emitters securely while allowing the vaporized VCIs to disperse within the enclosed space, effectively protecting metal items stored within the packaging.</p>: null}
                                    {show === "vcp3"?<p>Rust-X VCI Tablets are versatile and can be used in various applications. They are particularly useful for protecting heat transfer equipment tubes and other hollow equipment that require corrosion protection.</p>: null}
                                    {show === "vcp4"?<p>Simultaneously, the pouch emits Volatile Corrosion Inhibitors (VCI). These inhibitors form a protective layer on metal surfaces, preventing corrosion even in the presence of humidity or moisture.</p>: null}
                                    {show === "vcp5"?<p> In addition to corrosion prevention, these chips serve as an abrasion barrier. They safeguard metal components from scratches, dents, and other damages that can occur during handling or movement.</p>: null}
                                    {show === "vcp6"?<p>By conforming to the shape of the metal components, VCI Foams help reduce the risk of damage to parts during transit. The foam acts as a cushion, protecting the metal from physical impacts and scratches.</p>: null}
                                    {show === "vcp7"?<p>These emitters are formulated to have a longer lifespan, providing extended protection against corrosion. Their longevity ensures continuous and reliable corrosion prevention for the components installed inside electrical cabinets and enclosures.</p>: null}
                                    {show === "vcp8"?<p> Due to its powder form, VCI Powder 4201 is easy to apply to metal surfaces. It can be applied manually or integrated into automated systems for efficient and consistent coverage.</p>: null}
                                   
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>By creating a dry and controlled environment, VCI Desiccants 19000 passivate corrosion, ensuring that metal items remain protected and corrosion-free even during extended periods of storage or transportation.</p>: null}
                                    {show === "vcp2"?<p>These emitters can be placed in the vicinity or in direct contact with metal items. This flexibility allows for strategic placement based on the specific requirements of the items being protected, ensuring maximum effectiveness.</p>: null}
                                    {show === "vcp3"?<p>The protective layer created by these tablets actively prevents corrosion, ensuring that metal components, machinery, and equipment remain corrosion-free even during prolonged storage or transportation.</p>: null}
                                    {show === "vcp4"?<p> By adsorbing moisture and emitting VCIs, the dual action pouch actively prevents corrosion on metal components, ensuring they remain rust-free and in optimal condition.</p>: null}
                                    {show === "vcp5"?<p>It is recommended to pack the chips in a closed environment close to the metal components. Placing the chips in proximity to the metal ensures a longer duration of protection, enhancing the effectiveness of corrosion prevention.</p>: null}
                                    {show === "vcp6"?<p>The mono-molecular layer formed by the VCI chemicals actively inhibits corrosion. Even in challenging environments or during extended periods of storage, the foam provides continuous protection against rust and other forms of corrosion.</p>: null}
                                    {show === "vcp7"?<p>Emitter 5 and Emitter 11 are designed for easy installation. They can be conveniently placed inside junction boxes, electrical cabinets, and enclosures without complex procedures. Their user-friendly design simplifies the installation process.</p>: null}
                                    {show === "vcp8"?<p>This VCI powder is versatile and can be used in various industries, including manufacturing, automotive, aerospace, and electronics. It is suitable for protecting metal parts, machinery, tools, and equipment during storage and transportation.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These desiccants are commonly used in packaging applications where metal components, machinery, electronics, or sensitive equipment need to be protected from moisture-related damage and corrosion.</p>: null}
                                    {show === "vcp2"?<p>The emitters react with humidity levels in the surrounding air. By leveraging the vapor pressure difference, they effectively disperse the VCIs, creating a controlled environment that actively prevents corrosion.</p>: null}
                                    {show === "vcp3"?<p>These tablets can be placed in a range of containers, including metal or plastic boxes, plastic bags, cartons, and other enclosed spaces where metal items are stored or transported.</p>: null}
                                    {show === "vcp4"?<p>The integration of desiccant and VCI functionalities simplifies the packaging process for customers, eliminating the need to separately manage desiccants and corrosion inhibitors.</p>: null}
                                    {show === "vcp5"?<p>RustX VCI Chips are versatile and can be used in various industries, including manufacturing, automotive, aerospace, and electronics. They are suitable for protecting metal parts, machinery, tools, and equipment during storage and transportation.</p>: null}
                                    {show === "vcp6"?<p>VCI Foams like Foam 798 find applications in various industries, including manufacturing, automotive, aerospace, and electronics. They are used to protect metal parts, components, and machinery during transportation and storage.</p>: null}
                                    {show === "vcp7"?<p>The emitters inside these plastic boxes actively work to prevent corrosion on metal components within electrical systems. They release protective agents that form a barrier against rust and other forms of corrosion, ensuring the longevity of electrical equipment.</p>: null}
                                    {show === "vcp8"?<p>Once applied, VCI Powder 4201 provides long-lasting protection. Its corrosion inhibiting properties remain active over extended periods, ensuring that metal components remain corrosion-free</p>: null}
                                   
                                    
                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Desiccants 19000 are effective in enclosed spaces, ensuring that the entire package is kept dry and corrosion-free.</p>: null}
                                    {show === "vcp2"?<p> VCI Emitters 4200 find applications in various industries, including manufacturing, automotive, aerospace, and electronics, where metal components need reliable corrosion protection during storage and transportation.</p>: null}
                                    {show === "vcp3"?<p>Rust-X VCI Tablets provide long-lasting corrosion protection, making them ideal for applications where items need to be stored for extended periods without the risk of corrosion-related damage.</p>: null}
                                    {show === "vcp4"?<p>VCI 4500 Dual Action Pouch can be used in various packaging scenarios, including the protection of metal parts, equipment, machinery, and sensitive electronics, where corrosion prevention is essential.</p>: null}
                                    {show === "vcp5"?<p>By being placed close to the metal items, these chips provide long-lasting protection against corrosion. Their continuous action ensures that metal components remain rust-free over extended periods.</p>: null}
                                    {show === "vcp6"?<p>VCI Foams are lightweight, ensuring that they do not add significant weight to the packaged items. This is particularly important in applications where weight considerations are critical, such as in aerospace or automotive industries.</p>: null}
                                    {show === "vcp7"?<p>Emitter 5 and Emitter 11 are versatile and suitable for various applications, including electrical cabinets, control panels, and enclosures used in industrial and commercial settings. They are particularly beneficial for protecting electrical connections and sensitive components.</p>: null}
                                    {show === "vcp8"?<p>VCI Powder 4201 is effective in enclosed spaces such as shipping containers, storage boxes, and packaging materials. It is particularly useful for protecting metal items in transit, especially when exposed to humid or corrosive environments.</p>: null}
                                   
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>: null}
                                    {show === "vcp2"?<p>The nano-molecular layer created by these emitters provides long-lasting corrosion protection, making them ideal for extended storage periods and shipments, even in challenging environmental conditions.</p>: null}
                                    {show === "vcp3"?<p>Due to their small size, these tablets are easy to handle and install. Their flexibility in placement ensures that critical components within complex equipment can be effectively protected.</p>: null}
                                    {show === "vcp4"?<p>By providing dual functionality in a single pouch, it offers a cost-effective solution, reducing the complexity of packaging processes and ensuring effective corrosion protection.</p>: null}
                                    {show === "vcp5"?<p>RustX VCI Chips are easy to use. They can be strategically placed within packaging or storage containers, providing comprehensive protection to the enclosed metal items without the need for complex application processes.</p>: null}
                                    {show === "vcp6"?<p> Due to their flexibility and lightweight nature, VCI Foams are easy to handle and apply. They can be cut and shaped as needed to fit specific components, offering convenience in the packaging process.</p>: null}
                                    {show === "vcp7"?<p>Once installed, these emitters operate efficiently without the need for regular maintenance. Their hassle-free operation makes them a practical choice for long-term corrosion prevention in electrical systems.</p>: null}
                                    {show === "vcp8"?<p>Using VCI Powder 4201 as a corrosion prevention method is cost-effective. It helps avoid the expenses associated with rust damage, ensuring the longevity and quality of metal components without the need for complex or costly treatments.</p>: null}
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default VciEmt