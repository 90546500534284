import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Rc = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }


    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "Rust Converters"  : show === "vcp2" ? "Rust Remover"   
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/rustre1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/rustre2.webp' alt='dummy'/> 
                                </>:
                               
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Neutracoat Rust Converter is a powerful oxidation converter designed for use on various steels, iron, cast iron and copper, metals & aluminum.Neutracoat Rust Converter is a aqueous solution designed to convert rust and oxidation safely and economically. The biggest advantage is that the components do not get rusty again after removing from solution which is case in Acidic Rust removers and forms black inactive layer on the metal surface which can then be painted for longer life.
                                Rust converter is a coating that will convert heavy rusted areas into a protective polymeric coating with little prep.Neutracoat Rust Converter easily works in the nooks and crannies and all the other wetted areas eliminating the need for mechanical removal.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Rusting is the biggest evil for the steel or automotive component industry. Hi-Tech Internationals proprietary rust converters, help in the recovery of metal by converting the rust back to steel. Rusted components can be retained back by the process of rust removers, then neutralization and finally applying rust preventive oils.RUST REMOVER 500 is a liquid acid-based product primarily designed to simultaneously clean and remove rust from steel and iron parts. It is single component modified acid with different additive base rust removal. It has uses for removal of rust and prepares the substrate for painting.
                                </p> : 
                                null}
                                
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Rust converters contain active ingredients, such as tannic acid or phosphoric acid, that chemically react with rust, turning it into a stable compound called iron tannate or iron phosphate. This reaction stops the rusting process and prevents further corrosion.</p>: null}
                                    {show === "vcp2"?<p> Rust removers typically contain acids, such as phosphoric acid, oxalic acid, or hydrochloric acid, that dissolve rust. Some commercial rust removers also contain additives to enhance their effectiveness and prevent re-rusting.</p>: null}
                                    
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Rust converters often contain substances that act as primers, providing a surface suitable for painting. Once the rust is converted, the treated surface is ready for priming and painting, enhancing the overall protection against corrosion</p>: null}
                                    {show === "vcp2"?<p>Rust removers chemically react with rust, breaking down the iron oxide molecules and turning them into a soluble compound. This allows the rust to be easily washed or wiped away.</p>: null}
                                    
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Rust converters are available in various forms, including liquids, gels, and aerosols, making them easy to apply with brushes, sprayers, or rollers. They can be used on large surfaces or intricate details.</p>: null}
                                    {show === "vcp2"?<p>Rust removers are available in various forms, including liquid solutions, gels, sprays, and even soaking pads. The choice of form depends on the application and the size of the rusted object.</p>: null}
                                    
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Before applying a rust converter, it's important to remove loose rust, dirt, and grease from the metal surface. Wire brushing or sanding can help prepare the surface for better adhesion and rust conversion.</p>: null}
                                    {show === "vcp2"?<p>Rust removers are generally safe for use on ferrous metals like iron and steel. However, they should not be used on non-ferrous metals like aluminum, as the acids can cause damage</p>: null}
                                    
                                  
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Rust converters can be used on different types of metals, including iron and steel. They are commonly used in automotive restoration, metal fences, railings, machinery, and other metal structures.</p>: null}
                                    {show === "vcp2"?<p>Before applying a rust remover, it's important to clean the surface of loose rust, dirt, and grease. Wire brushing or sanding can help prepare the surface for better rust removal.</p>: null}
                                    
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>When using rust converters, it's essential to follow safety guidelines provided by the manufacturer. This may include wearing protective gear such as gloves and goggles and ensuring proper ventilation in the application area.</p>: null}
                                    {show === "vcp2"?<p>Users should follow safety instructions provided by the manufacturer, which often include wearing gloves and eye protection. Adequate ventilation is essential when using rust removers due to the acidic fumes they emit.</p>: null}
                                    

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>After application, rust converters require sufficient drying and curing time. This allows the chemical reaction to complete and ensures the converted surface is ready for painting or additional protective coatings.</p>: null}
                                    {show === "vcp2"?<p>Rust removers are potent chemicals, and excessive exposure can damage the metal surface. Users should follow the recommended application time provided by the manufacturer and not leave the remover on the surface for too long.</p>: null}
                                    
                                  
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Rust converters provide a long-lasting solution to prevent rust from spreading. However, it's advisable to apply a protective topcoat, such as paint or a sealant, to enhance the durability of the treated surface and provide additional protection against environmental factors.</p>: null}
                                    {show === "vcp2"?<p>After using a rust remover, it's crucial to thoroughly rinse the treated surface with water to neutralize the acid and prevent any residual chemical activity. Drying and applying a protective coating, such as paint or rust inhibitor, can prevent future rusting.</p>: null}
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Rc