import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../../css/Productdetails.css';

const Ff = ({id,cid,cat}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "firefighting"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "firefighting"&&cid==='2'){
            setShow("vcp2")
          }  
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "FIRE EXTINGUISHER"  : show === "vcp2" ? "FIRE HYDRANT SYSTEM"  : 
                               show === "vcp3" ? "LIGHTS"  : show === "vcp4" ? "TAPES"  
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/fire2.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/fire-hydrant.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>
Fire safety is significantly enhanced through the use of various types of fire extinguishers, each tailored to address specific fire hazards. The ABC fire extinguisher stands out for its versatility, providing effective suppression for ordinary combustibles, flammable liquids, and electrical equipment. CO2 type extinguishers prove invaluable for swiftly tackling electrical fires, utilizing a rapid discharge of carbon dioxide to suffocate the flames without leaving damaging residues.This diverse array of fire extinguishers ensures a comprehensive approach to fire safety, providing the means to combat various fire classes efficiently.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Integral to effective firefighting systems, the components such as branch pipes, hose reel drums, valves, sprinkler systems, and couplings, along with fire hoses, work in synergy to ensure a swift and precise response to fire emergencies. The branch pipe stands out for its ability to provide firefighters with a focused and controlled stream of water, enabling them to target the source of the fire accurately. Hose reel drums play a pivotal role in the quick and organized deployment of firefighting hoses, strategically placed for immediate access during emergencies.</p> : 
                                null}

                               

                            </div>
                        </div>

                        < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>Water-type fire extinguishers excel in controlling Class A fires by cooling and reducing temperatures, particularly useful for materials like wood and paper. Mechanical foam fire extinguishers find their niche in suppressing flammable liquid fires, creating a foam blanket to smother the fire and eliminate the oxygen supply. Additionally, wheeled fire extinguishers offer increased capacity and enhanced mobility, making them indispensable in environments where larger areas need coverage.


                            </p> : null}
                            { show === "vcp2" ? <p className='text-muted firstparavalue'>Valves serve as critical regulators, allowing firefighters to control water flow and adjust pressure, optimizing the performance of hoses and other firefighting equipment. The inclusion of sprinkler systems introduces automation to fire suppression within buildings, responding promptly to heat triggers and limiting the spread of flames. Couplings and fire hoses, functioning in tandem, ensure flexible and efficient water delivery, essential for reaching and extinguishing fires effectively.
                            </p> : null}
                            
                            </div> 

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div>
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>ABC fire extinguishers are versatile, capable of suppressing fires involving ordinary combustibles (Class A), flammable liquids (Class B), and electrical equipment (Class C).</p>: null}
                                    {show === "vcp2"?<p>The branch pipe provides firefighters with a tool for directing a focused and controlled stream of water onto the fire, enabling effective firefighting efforts.</p>: null}
                                   
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>CO2 extinguishers are effective for electrical fires (Class C) as they rapidly discharge carbon dioxide, suffocating the fire without leaving residue..</p>: null}
                                    {show === "vcp2"?<p>Hose reel drums facilitate the rapid and organized deployment of firefighting hoses. They are strategically positioned for quick access during fire emergencies, ensuring a prompt response.</p>: null}
                                   
                                    
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Water-type extinguishers are designed for Class A fires, cooling and reducing the temperature to extinguish flames in materials like wood and paper.</p>: null}
                                    {show === "vcp2"?<p>Valves play a crucial role in regulating water flow within the firefighting system. They allow firefighters to control water pressure, ensuring efficient operation of hoses and firefighting equipment.</p>: null}
                                  
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Mechanical foam extinguishers are suitable for flammable liquid fires (Class B), forming a foam blanket to smother the fire and cut off the oxygen supply.</p>: null}
                                    {show === "vcp2"?<p>Sprinkler systems are essential for automated fire suppression within buildings. Activated by heat, they release water to extinguish or control the spread of the fire, providing an immediate and localized response.</p>: null}
                                  
                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Wheeled extinguishers offer larger capacity and enhanced mobility, making them suitable for environments where more suppressant is required, covering larger areas effectively.</p>: null}
                                    {show === "vcp2"?<p> Couplings connect and secure fire hoses, enabling firefighters to deliver water effectively to the fire source. Fire hoses, supported by couplings, are crucial tools for reaching and extinguishing flames efficiently.</p>: null}
                                       
                                    </span>
                            </div>
                            {/*  6 points  */}
                           
                        </div>
    </div>
  )
}

export default Ff