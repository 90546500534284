import React, { useEffect, useState } from 'react'
    import Navbar from '../Home/Navbar'
    import Welcome from '../Common/Welcome'
    import SnHeader from '../Common/SnHeader'
    import Nav from '../Common/Nav'
    import Footer from '../Home/Footer'
    import CnBanner from '../Common/CnBanner'
    import SubCategory from "./SubCategory";
import { useParams } from 'react-router-dom'
import Four from '../Pagenotfound'

    
    const SubCategories = ({windowSize,color,setColor}) => {
      const {id}=useParams();
      
        const [show, setShow] = useState(false)
        const showed = () => {
            if(window.scrollY >= 150){
              setShow(true)
            }else{
              setShow(false)
            }
        }
      
        useEffect(() => {
            window.addEventListener('scroll', showed)
      
            return () => window.removeEventListener('scroll',showed)
        })
        const image = '/images/productbanner.webp';

        const ids = ["vcipackaging","welding",'cleaners','marinesafety','firefighting'
                    ,'cuttingoil','desiccants','rustremovers','coatings','excul-prod',
                    'rustpreventiveoils','vciemitters','propre','road&parking','steelcoil',
                    "vcisprays","firefighting"]
      return (
        <>
        {ids.includes(id) ? <>
          {windowSize[0] > 1200 && show &&
                <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
            }
            {windowSize[0] > 1200 && 
              <Welcome />
            }
         
          {windowSize[0] > 1390 && 
           <SnHeader /> 
          }
         {windowSize[0] < 1390 && 
          <Nav />
         }    
        <CnBanner windowSize={windowSize} title="SubCategories" image={image}/>
         <SubCategory/>
         <Footer color={color}/>
        </>: 
         <> <Four /></>}   
        </>
      )
    }
export default SubCategories;
