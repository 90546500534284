import React, { useEffect, useState } from "react";
import "../css/Productdetails.css";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import CommonBtns from "./CommonBtns";
import LoadPage from "../Pages/LoadPage";

function Gp({ setLoad }) {
  const [openCategory, setOpenCategory] = useState(null);

  const { id, pid } = useParams();
  const [dp, setDp] = useState(false);
  const [comp, setComp] = useState(false);
  const [lotp, setLotp] = useState(false);
  const [mlp, setMlp] = useState(false);
  const [gfc, setGfc] = useState(false);

  useEffect(() => {
    if (pid === "GasPipeline") {
      setOpenCategory(2);
    }
    <LoadPage />
  }, []);

  useEffect(() => {
    if (id === "1") {
      setDp(true);
      setComp(false);
      setLotp(false);
      setMlp(false);
      setGfc(false);
    }
    if (id === "2") {
      setDp(false);
      setComp(true);
      setLotp(false);
      setMlp(false);
      setGfc(false);
    }
    if (id === "3") {
      setDp(false);
      setComp(false);
      setLotp(true);
      setMlp(false);
      setGfc(false);
    }
    if (id === "4") {
      setDp(false);
      setComp(false);
      setLotp(false);
      setMlp(true);
      setGfc(false);
    }
    if (id === "5") {
      setDp(false);
      setComp(false);
      setLotp(false);
      setMlp(false);
      setGfc(true);
    }
  }, [id]);



  const handleLoad = () => {
    setLoad(true);

    // setTimeout(() => {
    //     setShowLoader(false);
    //   }, 1000);
  };

  const [isLoading, setIsLoading] = useState(false);


  return (
    <>
      <div className="container">
        <div className={isLoading ? "opaque" : "opaque1"}>
          <div className="row overall">
            <div className="col-md-4">
              <CommonBtns id={id} pid={pid} setLoad={setLoad} />
            </div>
            <div className="col-md-8">
              <div className="firstlineflex">
                <div className="yellowbreak"></div>
                <div className="firstheading">
                  <h1>
                    {dp
                      ? "Domestic Pipeline​"
                      : comp
                      ? "Commercial Pipeline"
                      : lotp
                      ? "LOT Pipeline Systems"
                      : mlp
                      ? "Medical/Laboratory Pipeline"
                      : gfc
                      ? "Gas Fuel Conversion Services"
                      : ""}
                  </h1>
                </div>
              </div>
              <div className="row paraoutdoor">
                <div className="col-md-4 col-sm-12">
                  {dp ? (
                    <>
                      <img src="/images/dp.webp" alt="dummy" />
                      {/* <button className='prdtBtn'>Buy now</button> */}
                    </>
                  ) : comp ? (
                    <img src="/images/cp.webp" alt="dummy" />
                  ) : lotp ? (
                    <img src="/images/lot.webp" alt="dummy" />
                  ) : mlp ? (
                    <img src="/images/mlp.webp" alt="dummy" />
                  ) : gfc ? (
                    <img src="/images/gfp.webp" alt="dummy" />
                  ) : null}
                </div>
                <div className="col-md-8 col-sm-12">
                  {dp ? (
                    <p className="text-muted firstparavalue">
                      Installing an LPG pipeline system for domestic use
                      involves connecting a central LPG storage cylinder to the
                      kitchen in the residential building, enabling a continuous
                      supply of LPG gas to appliances like stoves, ovens, water
                      heaters, etc. Keeping the LPG cylinders away from the
                      kitchen serves as good protection. Our technicians provide
                      your residences with the best quality LPG gas pipeline
                      services. Our gas pipelines are made using high-quality
                      materials per the rules and regulations that comply with
                      the residential norms. Our experts execute the projects
                      per the agreed time schedule and within the budget.
                    </p>
                  ) : null}
                  {comp ? (
                    <p className="text-muted firstparavalue">
                      Commercial LPG cylinder pipeline installation specialized
                      in designing, installing, and maintaining LPG pipeline
                      systems for commercial and industrial establishments. We
                      cater to various industries such as hotels, restaurants,
                      hospitals, factories, and other large-scale facilities
                      where a constant and reliable supply of LPG is required.
                      We are the leading service provider for Commercial Gas
                      Pipeline Installation works and services. We are one of
                      the most trusted and reliable customer-focused
                      organizations concerned with offering efficient pipeline
                      installation, fabrication, fitting &amp; repairing
                      services all over the world.
                    </p>
                  ) : null}
                  {lotp ? (
                    <p className="text-muted firstparavalue">
                      LOT System is an advance concept in Multi-cylinder
                      Installation which offers the strength of bulk LPG
                      installation &amp; easy functionality. This System can
                      cater to volumes up to 250 Kg/hour Installation which
                      overcomes lots of problems &amp; demerits of bulk LPG
                      setup installation &amp; conventional manifold (VOT)
                      system. LOT systems are compact, safe, and highly
                      cost-effective as liquid is completely drawn from the
                      cylinder and there is no residual loss.       In a LOT gas
                      pipeline system, the gas is initially transported through
                      a high- pressure transmission pipeline from the production
                      site or gas field to a gas processing plant.
                    </p>
                  ) : null}
                  {mlp ? (
                    <p className="text-muted firstparavalue">
                      We provides top-quality Medical gas (Oxygen. Helium,
                      Argon, Nitrogen) pipelines to all kinds of medical
                      platforms such as Hospitals, Laboratories, and other
                      sectors. Over the years, we have honed our expertise and
                      refined our techniques, setting industry standards for
                      safety, efficiency, and innovation. Installing an Medical
                      gas pipeline involves creating a system that safely and
                      efficiently delivers Medical grade gas to various points
                      in a facility, such as hospitals, clinics, or medical
                      centers. This process should only be carried out by
                      qualified professionals with experience in medical gas
                      system installations.
                    </p>
                  ) : null}
                  {gfc ? (
                    <p className="text-muted firstparavalue">
                      Gas Fuel Conversion System refers to the process of
                      converting machinery that initially runs on fuels like
                      coal, or gasoline (petrol) to operate on an alternative
                      fuel, such as compressed Liquefied petroleum gas (LPG).
                      The primary objective of such conversions is often to
                      reduce emissions, decrease dependence on fossil fuels, and
                      potentially lower operating costs. We provides the best
                      fuel conversion system that helps your
                      industries/factories to run with zero emissions and can
                      improve top-level efficiencies. Our conversion system is
                      reputed with Safecon tech products to maintain high-class
                      accuracy in your system.
                    </p>
                  ) : null}
                </div>
              </div>


              <div className="downsession">
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                        Domestic pipelines transport essential resources such as water, natural gas, oil, and even sewage within a country's borders.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                       Commercial pipelines are used for bulk transportation of liquids and gases such as crude oil, natural gas, chemicals, and water over long distances.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                       LOT Pipeline Systems introduce an advanced concept in multi-cylinder installations, combining the strength of bulk LPG installation with easy functionality.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                       These systems supply essential medical gases such as oxygen, nitrous oxide, and medical air directly to patient care areas, ensuring a safe and reliable source of gases for various medical procedures.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                        Gas fuel conversion reduces greenhouse gas emissions, air pollutants, and overall carbon footprint, contributing to a cleaner environment and improved air quality.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/* 2 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                        They play a crucial role in the distribution of energy resources, ensuring a stable supply of natural gas and oil to homes, industries, and power plants.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                        Industries rely on commercial pipelines to transport raw materials, fuels, and chemicals essential for manufacturing processes, ensuring a continuous supply for production.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                        SLOT Systems can handle volumes up to 250 Kg/hour, addressing challenges and drawbacks associated with bulk LPG setup installations and conventional manifold (VOT) systems.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                        Laboratory pipelines provide gases like helium, nitrogen, and hydrogen to research and analytical equipment in laboratories. These pipelines are essential for experiments, sample analyses, and other scientific processes.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                       Natural gas and propane are often more cost-effective than gasoline or diesel, providing substantial savings on fuel expenses in the long run.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/* 3 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                       Domestic pipelines are fundamental for supplying clean and treated water to households, businesses, and agricultural areas, supporting daily life and agriculture.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                        Commercial pipelines are vital for the economy, supporting industries like petrochemicals, energy, and manufacturing, contributing significantly to GDP and employment.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                        LOT systems are designed to be compact, safe, and highly cost-effective. Liquid is drawn completely from the cylinder, minimizing residual loss and enhancing efficiency.


                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                      Medical and laboratory pipelines enhance safety by reducing the need for gas cylinders in patient areas and labs, minimizing the risks associated with handling and storing compressed gases.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                       Using domestically sourced natural gas or propane promotes energy independence, reducing reliance on imported oil and enhancing national energy security.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/*  4 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                        The construction and maintenance of domestic pipelines contribute to infrastructure development, improving connectivity and facilitating economic growth.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                        Pipelines facilitate international trade by transporting commodities between countries, connecting producers and consumers on a global scale.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                        In a LOT gas pipeline system, gas is initially transported through a high-pressure transmission pipeline from the production site or gas field to a processing plant. Various treatments are applied, ensuring the gas meets required pipeline quality standards.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                       Medical and laboratory pipelines enhance safety by reducing the need for gas cylinders in patient areas and labs, minimizing the risks associated with handling and storing compressed gases.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                        Many governments offer tax incentives and subsidies to encourage the conversion to alternative fuels, making it financially attractive for businesses and individuals.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/* 5 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                       Properly maintained pipelines are environmentally friendly, reducing the need for transportation via trucks or ships, which can lead to pollution and greenhouse gas emissions.
                      </p>
                    ) : null}
                    {comp ? (
                      <p>
                        Commercial pipelines offer a safer and more efficient mode of transportation compared to road or rail, reducing the risk of accidents and environmental incidents.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                         LOT pipelines maintain gas pressure at a constant level, ensuring optimal utilization of gas fuel resources.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                        Medical pipelines are essential for life support systems in hospitals, providing oxygen and other medical gases to patients in critical conditions, such as in operating rooms and intensive care units.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                       Alternative fuels often burn cleaner, leading to reduced engine wear and extended lifespan for converted vehicles or machinery.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/*  6 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                      Pipeline construction, maintenance, and operation create job opportunities, stimulating local economies and providing employment in various sectors.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                        RWell-maintained pipelines are environmentally friendly, minimizing carbon emissions and reducing the ecological footprint associated with other forms of transportation.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                     These systems offer minimal maintenance costs and address space constraint issues, enhancing operational efficiency and reducing overall operational hassles.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                       Laboratory pipelines contribute to the control of laboratory environments, supplying gases necessary for maintaining specific temperature, humidity, and other conditions required for experiments.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                       Some gas fuels, such as biomethane, can be derived from organic waste, supporting the development of renewable energy sources and waste-to-energy initiatives.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/* 7 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                        Reliable domestic pipelines contribute to a stable economy by ensuring the steady supply of resources, supporting industries, and reducing energy costs for consumers.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                        Commercial pipelines are subject to strict regulations to ensure safety, environmental protection, and compliance with legal standards, guaranteeing the integrity of the infrastructure and the safety of the communities it serves.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                       LOT Pipeline Systems do not require statutory approvals for installations below 1000 Kg, simplifying the regulatory process for businesses.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                      Over time, investing in a well-designed medical/laboratory pipeline system can be cost-effective compared to the recurring expenses of purchasing and replacing gas cylinders, especially in facilities with high gas consumption.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                         Gas fuel conversion services are applicable to a wide range of vehicles, including cars, buses, trucks, and industrial equipment, as well as residential and commercial appliances like stoves and water heaters.
                      </p>
                    ) : null}
                  </span>
                </div>
                {/* 8 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {dp ? (
                      <p>
                        Domestic pipelines are regulated to ensure safety standards are met, minimizing the risk of leaks, spills, and accidents, thereby safeguarding public health and the environment.
                      </p>
                    ) : null}

                    {comp ? (
                      <p>
                        he construction and expansion of commercial pipelines require substantial investment, leading to the development of infrastructure and creating job opportunities.
                      </p>
                    ) : null}

                    {lotp ? (
                      <p>
                        Comparatively, LOT pipelines offer a rapid return on investment, with a period of fewer than 4 years if daily consumption exceeds 40 cylinders, making it a financially viable option for businesses.
                      </p>
                    ) : null}

                    {mlp ? (
                      <p>
                      Pipelines ensure a precise and constant flow of gases, critical for medical procedures requiring accurate gas concentrations or laboratory experiments demanding consistency.
                      </p>
                    ) : null}

                    {gfc ? (
                      <p>
                       Gas fuel conversion services are typically provided by specialized technicians and companies with expertise in engine modifications, ensuring safe and compliant conversions that meet regulatory standards.
                      </p>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gp;
