import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Coating = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }


    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "VCI Coatings"  : show === "vcp2" ? "VCI Jelly Wax"  
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/costing1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/coating2.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Unrivaled Protection, Endless Possibilities! Our VCI (Vapor Corrosion Inhibitor) coatings redefine the standard for corrosion protection. Engineered with advanced technology, our coatings create a protective shield that safeguards your valuable assets from rust and corrosion, ensuring their longevity and reliability. From industrial equipment to metal components, our VCI coatings provide unmatched defense against the elements. With a commitment to innovation and excellence, we offer a diverse range of coatings tailored to meet your specific needs</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Discover the Power of Preservation with VCI Jelly Wax: Unmatched Protection, Uninterrupted Performance! Our VCI (Vapor Corrosion Inhibitor) Jelly Wax is a breakthrough solution designed to provide unparalleled protection for your valuable metal assets. With its advanced formula, this jelly wax forms a durable barrier, shielding metal surfaces from corrosion, rust, and environmental damage. Easy to apply and long-lasting, our VCI Jelly Wax ensures your equipment stays in pristine condition, even in challenging environments. Trust in the excellence of VCI technology and experience worry-free storage and transportation. Preserve the integrity of your metal investments with VCI Jelly Wax – where protection meets peace of mind.</p> : 
                                null}
                               
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>VCI coatings offer effective protection against corrosion, safeguarding metal surfaces during storage, transportation, and in challenging environmental conditions.</p>: null}
                                    {show === "vcp2"?<p>VCI Jelly Wax provides excellent corrosion prevention for various metals, including steel, aluminum, copper, and brass. It forms a protective layer on the metal surface, preventing rust and corrosion.</p>: null}
                                   
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings contain volatile corrosion inhibitors that release protective vapors, creating an invisible layer on metal surfaces. These inhibitors interact with metal surfaces, preventing corrosion by interrupting the electrochemical corrosion process.</p>: null}
                                    {show === "vcp2"?<p>VCI Jelly Wax contains volatile corrosion inhibitors that vaporize and form a protective atmosphere around metal surfaces. This inhibits the electrochemical reactions responsible for corrosion, even in hard-to-reach areas.</p>: null}
                                  
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings are versatile and provide protection for various metals, including steel, aluminum, copper, and brass. They are used in industries where different metals are processed or stored together.</p>: null}
                                    {show === "vcp2"?<p>The jelly-like consistency of VCI Jelly Wax allows it to adhere well to vertical and overhead surfaces, providing uniform coverage. It is especially useful for complex shapes and intricate metal parts.</p>: null}
                                    
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings are easy to apply, whether through spraying, brushing, or dipping. They adhere well to metal surfaces, forming a uniform and long-lasting protective layer.</p>: null}
                                    {show === "vcp2"?<p>VCI Jelly Wax is easy to apply by brushing or spraying onto metal surfaces. Its jelly-like texture ensures even coverage, making it suitable for both large and small components.

</p>: null}
                                    
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings provide temporary protection and are ideal for short-term storage or during transportation. They are commonly used in the automotive, aerospace, and manufacturing industries.

</p>: null}
                                    {show === "vcp2"?<p>Once applied, VCI Jelly Wax provides long-lasting protection, making it ideal for both short-term and long-term storage of metal parts. It can withstand harsh environmental conditions, including humidity and temperature fluctuations.</p>: null}
                                  
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings are environmentally friendly as they do not contain harmful chemicals. They provide an eco-conscious solution for corrosion prevention compared to traditional methods involving oils or greases.</p>: null}
                                    {show === "vcp2"?<p>Despite its durable protection, VCI Jelly Wax is removable. It can be easily cleaned off metal surfaces when the parts are ready for use, leaving no residue behind.</p>: null}
                                    

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings come in both reactive and non-reactive formulations. Reactive coatings are activated by moisture, providing protection in humid conditions, while non-reactive coatings offer protection in dry environments.</p>: null}
                                    {show === "vcp2"?<p>VCI Jelly Wax does not dry out, ensuring continuous protection even during extended storage periods. It remains effective as long as the protective layer is intact.</p>: null} 
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI coatings are designed to be easily removable. The protective layer can be removed by cleaning or washing the metal surface, leaving it clean and ready for use without residue.</p>: null}
                                    {show === "vcp2"?<p> VCI Jelly Wax is used in various industries, including automotive, aerospace, manufacturing, and marine, to protect metal components, equipment, and machinery during transit, storage, or long-term preservation.</p>: null}
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Coating