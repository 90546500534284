import React, { useEffect, useState } from "react";
import "../css/Productdetails.css";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import CommonBtns from "./CommonBtns";
import LoadPage from "../Pages/LoadPage";

function OfficeEst({ setLoad }) {
  const [openCategory, setOpenCategory] = useState(null);

  const { id, pid } = useParams();
  const [indlpg, setIndLpg] = useState("");

  useEffect(() => {
    if (id === "1") {
        setIndLpg("cc")
    }
    if (id === "2") {
        setIndLpg("bmcg")
    }
    if (id === "3") {
        setIndLpg("hippo")
    }
    if (id === "4") {
        setIndLpg("ftl")
    }
    if (id === "5") {
        setIndLpg("cpr")
    }
    if (id === "6") {
        setIndLpg("a4")
    }
  }, [id]);



  const handleLoad = () => {
    setLoad(true);

    // setTimeout(() => {
    //     setShowLoader(false);
    //   }, 1000);
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="container">
        <div className={isLoading ? "opaque" : "opaque1"}>
          <div className="row overall">
            <div className="col-md-4">
              <CommonBtns id={id} pid={pid} setLoad={setLoad} />
            </div>
            <div className="col-md-8">
              <div className="firstlineflex">
                <div className="yellowbreak"></div>
                <div className="firstheading">
                  <h1>
                    {indlpg === "cc"
                      ? "Thermal Paper Roll​"
                      : indlpg === "bmcg"
                      ? "Nornal Paper Roll"
                      : indlpg === "hippo"
                      ? "Carrugated Paper Roll"
                      : indlpg === "ftl"
                      ? "Carrugated Ply Roll"
                      : indlpg === "cpr"
                      ? `Office Stationery's`
                      : indlpg === "a4"
                      ? "A4 Bundles"
                      : ""}
                  </h1>
                </div>
              </div>
              <div className="row paraoutdoor">
                <div className="col-md-4 col-sm-12">
                 

                  {indlpg === "cc" ? (
                    <>
                      <img src="/images/thermalpaper.webp" alt="dummy" />
                      {/* <button className='prdtBtn'>Buy now</button> */}
                    </>
                  ) : indlpg === "bmcg" ? (
                    <img src="/images/normal paper.webp" alt="dummy" />
                  ) : indlpg === "hippo" ? (
                    <img className="lpgimg3" src="/images/corrugated-paper-roll.webp" alt="dummy" />
                  ) : indlpg === "ftl" ? (
                    <img src="/images/ply-corrugated-roll.webp" alt="dummy" />
                  ) 
                  : indlpg === "cpr" ? (
                    <img src="/images/office.webp" alt="dummy" />
                  ) 
                  : indlpg === "a4" ? (
                    <img src="/images/blank-white-paper.webp" alt="dummy" />
                  ) 
                   : null}

                  
                </div>
                <div className="col-md-8 col-sm-12">
                  {indlpg === "cc" ? (
                    <p className="text-muted firstparavalue">
                    Thermal paper rolls are essential in various industries for printing receipts, labels, and other thermal images. These rolls are coated with a special heat-sensitive layer that reacts to heat, eliminating the need for ink or ribbons in printing. Available in various sizes and lengths, they cater to different devices like point-of-sale systems, ATMs, and handheld printers. With a smooth surface ensuring high-quality prints and excellent durability, thermal paper rolls streamline operations in retail, banking, hospitality, and logistics, providing reliable and convenient printing solutions.
                    </p>
                  ) : null}
                  {indlpg === "bmcg" ? (
                    <p className="text-muted firstparavalue">
                     Normal paper rolls, often used in receipt printers, calculators, and other similar devices, provide a reliable and cost-effective means of documentation. These rolls are made from standard paper and are suitable for printing receipts, invoices, notes, and other documents. Available in various sizes and lengths, they cater to different devices and printing needs. Their versatility and compatibility with a wide range of machines make them a ubiquitous choice across retail, accounting, and administrative sectors, ensuring smooth and efficient documentation processes.
                    </p>
                  ) : null}
                  {indlpg === "hippo" ? (
                    <p className="text-muted firstparavalue">
                    Corrugated paper rolls are a sturdy and versatile packaging material known for their strength and durability. Composed of multiple layers of fluted paper and linerboard, these rolls provide excellent cushioning and protection for shipping and packaging purposes. They come in various sizes, thicknesses, and grades, catering to different packaging needs. Widely used in industries for wrapping, cushioning, and securing items during transit, corrugated paper rolls safeguard goods against impact and external elements, ensuring they reach their destination safely. Additionally, they're also utilized for arts and crafts due to their versatility and texture.
                    </p>
                  ) : null}
                  {indlpg === "ftl" ? (
                    <p className="text-muted firstparavalue">
                    A Carrugated Ply Roll represents a unique amalgamation of corrugated and ply layers, offering exceptional strength and versatility. Combining the structural support of plywood with the cushioning and flexibility of corrugation, this specialized roll serves diverse purposes. Often utilized in heavy-duty packaging, it provides robust protection for sensitive items during shipping and storage. Additionally, its resilient construction finds applications in construction and industrial settings, offering a blend of durability and adaptability for various projects. 
                    </p>
                  ) : null}
                  {indlpg === "cpr" ? (
                    <p className="text-muted firstparavalue">           
Office stationery refers to a broad range of supplies essential for daily administrative tasks and operations within an office setting. These items encompass various tools and materials used for organization, documentation, communication, and general office functions. Common office stationery includes pens, pencils, notebooks, paper, staplers, paper clips, folders, binders, envelopes, tape, sticky notes, desk organizers, markers, and printer supplies like ink cartridges and toners. These items play a fundamental role in facilitating smooth workflow, maintaining records, and aiding in communication, contributing to the efficiency and functionality of any office environment.
                    </p>
                  ) : null}
                  {indlpg === "a4" ? (
                    <p className="text-muted firstparavalue">
                  A4 bundles typically refer to packs of A4-sized paper commonly used in offices and for printing documents. These bundles contain multiple reams of A4 paper, standardized at 210 by 297 millimeters or 8.27 by 11.69 inches. They are a staple supply for printing, copying, and everyday documentation needs in various businesses and institutions. A4 paper is versatile, fitting into most printers and copiers, and is the standard size for letters, reports, and other documentation in many countries. Bundles of A4 paper are available in different quantities, providing a convenient way to stock up on this essential office supply.
                    </p>
                  ) : null}
                  
                </div>
              </div>


              <div className="downsession">
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Coated with a heat-sensitive layer, reacting to heat for printing receipts, labels, and thermal images without requiring ink or ribbons, offering cost-effective and efficient printing solutions.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Used in receipt printers, calculators, and similar devices, offering a dependable and cost-effective method for documentation needs.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                    Composed of multiple layers of fluted paper and linerboard, offering exceptional strength and durability, ideal for packaging purposes.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                     Blends the structural support of plywood with the cushioning and flexibility of corrugation, offering a unique combination of strength and adaptability.
                      </p>
                    ) : null}

                  {indlpg === "cpr" ? (
                      <p>
                   Specialized notebooks or logs for recording machinery maintenance schedules, safety checks, inventory logs, and operational data crucial for industrial processes.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                  A4 paper measures 210 by 297 millimeters or 8.27 by 11.69 inches, offering a standardized size for printing, copying, and general documentation purposes.
                      </p>
                    ) : null}

                   
                  </span>
                </div>
                {/* 2 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Available in diverse sizes and lengths, catering to different devices such as point-of-sale systems, ATMs, and handheld printers, ensuring compatibility across a range of equipment.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                        Made from standard paper material, suitable for printing various documents including receipts, invoices, notes, and other essential paperwork.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                       Provides effective cushioning and protection for items during shipping and packaging, safeguarding goods against impact and external elements. 
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Primarily used in heavy-duty packaging due to its exceptional strength, providing robust protection for sensitive items during shipping and storage.
                      </p>
                    ) : null}


                  {indlpg === "cpr" ? (
                      <p>
                      Industrial-grade pens, markers, and pencils suitable for marking on various surfaces, including metal, plastic, or oily surfaces, aiding in labeling, noting, and technical documentation.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                     Integral for everyday office operations, used extensively for printing documents, reports, memos, letters, presentations, and other paperwork.
                      </p>
                    ) : null}

                  </span>
                </div>
                {/* 3 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Essential across various industries including retail, banking, hospitality, and logistics, serving multiple purposes like transaction receipts, labels, and image printing.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       Available in diverse sizes and lengths, catering to different devices and accommodating various document printing requirements.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                  Available in various sizes, thicknesses, and grades, catering to diverse packaging needs and accommodating different types of items for shipping and protection.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                 Offers superior cushioning and protection, ensuring the safe transportation of delicate or valuable goods, safeguarding them against impact and damage.
                      </p>
                    ) : null}


                    {indlpg === "cpr" ? (
                      <p>
                    Heavy-duty paper, weather-resistant notebooks, and documentation supplies designed to withstand industrial environments, ensuring durability in harsh conditions.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                    Designed to fit most printers, copiers, and fax machines, ensuring compatibility across a wide range of office equipment.
                      </p>
                    ) : null}
                  
                  </span>
                </div>
                {/*  4 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Ensures a smooth printing surface, facilitating high-quality prints, contributing to the clarity and readability of printed content.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Versatile and compatible with a wide range of machines and devices, ensuring suitability across multiple platforms and equipment types.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                        Widely utilized across industries for wrapping, cushioning, and securing items during transit, ensuring goods reach their destination safely without damage.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Its resilient structure finds applications beyond packaging in construction and industrial settings, providing durability and adaptability for various projects.
                      </p>
                    ) : null}

                    {indlpg === "cpr" ? (
                      <p>
                    Safety manuals, incident report forms, and signage materials ensuring compliance with safety protocols and regulatory requirements in industrial settings.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                     Suitable for various applications, including official correspondence, academic assignments, business reports, and personal printing at home.
                      </p>
                    ) : null}

                  </span>
                </div>
                {/* 5 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Withstands handling and environmental conditions, offering durability to printed content, ensuring longevity and legibility of receipts and labels over time.
                      </p>
                    ) : null}
                    {indlpg === "bmcg" ? (
                      <p>
                       Widely used across retail, accounting, administrative sectors, and various businesses requiring documentation, making them a common and accessible choice.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                        Offers reliable protection against external elements, ensuring the integrity of packaged items, especially during transportation and storage.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                        Used in construction for protection, reinforcement, or temporary support due to its sturdy build and ability to withstand challenging conditions.
                      </p>
                    ) : null}

                  {indlpg === "cpr" ? (
                      <p>
                   Industrial-grade labels, barcode stickers, and printers for inventory management, asset tracking, and labeling components in warehouses or manufacturing units.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                  Bundles contain multiple reams of A4 paper, providing a convenient way to purchase and stock up on this essential office supply in different quantities.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/*  6 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Facilitates streamlined operations by providing reliable and convenient printing solutions, optimizing workflow efficiency in businesses relying on continuous printing needs.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       Contributes to smooth and efficient documentation processes, aiding in the seamless operation of daily tasks and transactions.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                    Beyond packaging, corrugated paper rolls are also used in arts and crafts due to their versatility, texture, and ease of manipulation, allowing for creative applications.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p> 
                       Suitable for various industrial applications requiring resilient and strong materials for packaging, shipping, or temporary structural support.
                      </p>
                    ) : null}

                {indlpg === "cpr" ? (
                      <p>
                   Heavy-duty folders, binders, and filing systems designed to store technical drawings, schematics, blueprints, and technical documents related to machinery and equipment.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                    Manufactured to meet quality standards, ensuring smooth printing, good ink absorption, and consistent performance in printing and copying tasks.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/* 7 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                      Elimination of ink or ribbons reduces ongoing operational costs associated with printing, contributing to cost-effectiveness in businesses.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Offers a cost-effective means of documentation without compromising on quality, making them an economical choice for businesses with regular documentation needs.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                     Can be easily cut, folded, or manipulated to suit specific packaging needs or creative artistic endeavors, offering adaptability and customization.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      This unique amalgamation combines the best qualities of both materials, ensuring a balance of strength and flexibility for versatile usage.
                      </p>
                    ) : null}

                  {indlpg === "cpr" ? (
                      <p>
                   Industrial rulers, specialized measuring tools, and calibration logs essential for precise measurements and equipment calibration in industrial processes.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                    Recognized as the standard size for documentation in many countries, promoting consistency and uniformity in printed materials.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/* 8 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                      Provides a reliable printing method with minimal maintenance, offering convenience and consistency in output for various printing needs.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                     Suitable for printing various types of documents, providing flexibility in use across different sectors and departments within an organization.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                     Often made from recycled materials and recyclable themselves, providing an environmentally conscious packaging solution.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                     Depending on the sourcing of materials, it can be an eco-friendly choice, especially if made from sustainable or recycled materials.
                      </p>
                    ) : null}
                    {indlpg === "cpr" ? (
                      <p>
                   Inventory tags, asset tracking systems, and maintenance logbooks facilitating effective inventory management and maintenance scheduling for machinery and equipment.
                      </p>
                    ) : null}

                    {indlpg === "a4" ? (
                      <p>
                    Offers a cost-effective solution for routine printing and documentation needs, facilitating economical procurement for various organizations and individuals.
                      </p>
                    ) : null}

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfficeEst;
