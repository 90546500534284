import React, { useEffect, useRef, useState } from "react";
import "../css/Biodegrable.css";
import { Link, useParams } from "react-router-dom";

function Biodegradable() {
  const loca = useRef(null);
  const locals = useRef(null);
  const { id } = useParams();
  const Scrolling = () => {
    loca.current.scrollIntoView({ behaviour: "smooth" });
  };
  const Scrolling2 = () => {
    locals.current.scrollIntoView({ behaviour: "smooth" });
  };
  return (
    <>
      {id == "bio-degradable" && (
        <>
          <div className="container prdtname">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Biodegradable</h4>
            </div>

            <div className="colspin">
              <div className="biod">
                <h3 className="pill"  data-aos="fade-right" data-aos-duration="1500">
                  Dr Bio is India’s first approved bio polymer manufacturer,
                  focusing on compostable polymers, packaging, cutlery, and
                  straws, contributing to sustainable and environmentally
                  friendly solutions.
                </h3>
                <div className="text-muted pol"  data-aos="fade-left" data-aos-duration="1500">
                  <p>
                    Dr. Bio Polymers are the new eco-friendly resins that are
                    made up of Compostable polymers & starch blends. The Dr.BIO
                    Resin is a film grade resin and can be processed on standard
                    blown film lines or blow moulding lines. The manufactured
                    products using this resin are degradable in normal land fill
                    environment and get disintegrated within 3-6 months after
                    being disposed off in a land-fill.
                  </p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <img src="/images/bio1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Food packaging</h3>
                    <Link
                      to="/products/Bio-Degradable/1"
                      target="_blank"
                    >
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio2.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Lamination</h3>
                    <Link to="/products/Bio-Degradable/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio4.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Agriculture</h3>
                    <Link to="/products/Bio-Degradable/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio3.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Shopping Carry Bags</h3>
                    <Link to="/products/Bio-Degradable/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio5.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Shrink, Strech Films </h3>
                    <Link to="/products/Bio-Degradable/5" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio6.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Paper Like Films</h3>
                    <Link to="/products/Bio-Degradable/6" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio7.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Bottles, Containers</h3>
                    <Link to="/products/Bio-Degradable/7" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio8.webp" className="iml image-fluid" />

                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Cutlery</h3>
                    <Link to="/products/Bio-Degradable/8" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/bio9.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Garbage Bags</h3>
                    <Link to="/products/Bio-Degradable/9" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {id == "gas-pipeline" && (
        <>
          <div className="container prdtname">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Gas Pipeline</h4>
            </div>

            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                  We are specialised in gas pipeline services. We are engaged in
                  LPG (Liquefied Petroleum Gas) Gas Pipeline Installation,
                  Fabrication and Testing Services in all Tamil Nadu Especially
                  in Coimbatore, Erode, Tirupur, Salem, Pollachi, Udmalpet and
                  Mettupalayam.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  {" "}
                  We undertake the planning, engineering, and execution of gas
                  pipeline projects, including the laying of pipelines,
                  installation, and construction of related facilities as We
                  always recommend superiority and excellence for Commercial Gas
                  Pipeline Installation, LPG Gas Pipeline Installation along
                  with Hospitals, Malls, Hotels, Restaurants, Laboratory,
                  Industries, Schools and Colleges etc.We provide gas pipeline
                  connections to various sectors and also have expertise team
                  for smooth execution of the Project. <p></p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <img src="/images/dp.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Domestic Pipeline</h3>
                    <Link to="/products/GasPipeline/1" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/cp.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Commercial Pipeline</h3>
                    <Link to="/products/GasPipeline/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/lot.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">LOT Pipeline Systems</h3>
                    <Link to="/products/GasPipeline/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/mlp.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Medical/Laboratory </h3>
                    <Link to="/products/GasPipeline/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/gfp.webp " className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Gas Fuel Conversion </h3>
                    <Link to="/products/GasPipeline/5" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {id == "lubricants" && (
        <>
          <div className="container lubricants-logo">
            <div className="row l-logo">
              <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1500">
                <div className="about1">
                  <div className="smallsss1 mt-4"></div>
                  <h4 className="aboutline_head1 mt-4">Rustx</h4>
                </div>
                <p className="text-muted pol mt-2">
                  Rust-X is the only vertically integrated VCI manufacturer in
                  the industry with inhouse manufacturing of chemicals,
                  masterbatch, films, papers, desiccants, oils and foils.
                </p>
                <div className="row ">
                  <div className="col bioti">
                    <img
                      src="/images/lubricant.webp"
                      className="iml6 image-fluid"
                    />
                    <div className="plow">
                      <h3 className="biops">Rust-X</h3>
                      <button onClick={Scrolling} className="butts btn1">
                        +
                      </button>
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1500">
                <div className="about1">
                  <div className="smallsss1 mt-4"></div>
                  <h4 className="aboutline_head1 mt-4">Fillezy</h4>
                </div>
                <p className="text-muted pol mt-2">
                  Fillezy is a leading manufacturer of a complete range of
                  paper,hexapaper,void fill, and cushioning solutions that have
                  proven to prevent damages up to 100%.
                </p>
                <div className="row ">
                  <div className="col bioti">
                    <img
                      src="/images/Fillezy.webp"
                      className="iml6 image-fluid"
                    />
                    <div className="plow">
                      <h3 className="biops">Fillezy</h3>
                      <button onClick={Scrolling2} className="butts btn1">
                        +
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-lg-6 bioti">
            <img src="/images/fillezy2.webp" className='iml6 image-fluid' />
                <div className='plow'>
                    <h3 className='biops'>View More</h3>
                    <button onClick={Scrolling2} className='butts btn1'>+</button>
                </div>
            </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5" ref={loca}>
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Rustx</h4>
            </div>
            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                  Rust-X is the only vertically integrated VCI manufacturer in
                  the industry with inhouse manufacturing of chemicals,
                  masterbatch, films, papers, desiccants, oils and foils.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  <p>
                    Rust-X is a Global Market Leader for Corrosion Prevention
                    Products & Solutions and manufactures over 500 products in
                    45 countries. Rust-X caters to over 10,000 customers in
                    Automotive, Aersospace, Oil&Gas, Infrastructure etc. with
                    its presence in all continents.
                  </p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <img
                    src="/images/rustflex1.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Cleaners</h3>
                    <Link to="/SubCategories/cleaners">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex2.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Cutting Oils</h3>
                    <Link to="/SubCategories/cuttingoil">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex3.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Coatings</h3>
                    <Link to="/SubCategories/coatings">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex4.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">VCI Packaging</h3>
                    <Link to="/SubCategories/vcipackaging">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex5.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Rust Preventive Oils</h3>
                    <Link to="/SubCategories/rustpreventiveoils">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex6.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">VCI Emitters</h3>
                    <Link to="/SubCategories/vciemitters">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex7.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Steel Coil Packaging</h3>
                    <Link to="/SubCategories/steelcoil">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex8.webp"
                    className="iml image-fluid"
                  />

                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">VCI Sprays</h3>
                    <Link to="/SubCategories/vcisprays">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/rustflex9.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Desiccants</h3>
                    <Link to="/SubCategories/desiccants">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>

                <div className="biots">
                  <img
                    src="/images/rustflex10.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">VCI Masterbatch</h3>
                    <Link to="/products/LubricantsPacking/Rust-X/VCI Masterbatch/1">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>

                <div className="biots">
                  <img
                    src="/images/rustflex11.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Remover & Converter</h3>
                    <Link to="/SubCategories/rustremovers">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>

                <div className="biots">
                  <img
                    src="/images/rustflex12.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Data Loggers</h3>
                    <Link to="/products/LubricantsPacking/Rust-X/Data Loggers/1">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5 " ref={locals}>
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Fillezy</h4>
            </div>

            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                  Fillezy is a leading manufacturer of a complete range of
                  paper,hexapaper,void fill, and cushioning solutions that have
                  proven to prevent damages up to 100%.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  <p>
                    Fillezy can guarantee the highest standard of heat seal with
                    unbelievably low power consumption. No matter which industry
                    you are in, we can provide you with integrated handling
                    systems and storage solutions.
                  </p>
                </div>
              </div>

              <div className="ghj">
                <div className="biots">
                  <img
                    src="/images/fillezy1.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Bio Aer</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/1">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy2.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillexy Air Bubbles</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/2">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy3.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillezy Air Cushion</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/3">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy4.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillezy Air Quilts</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/4">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy5.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillezy Air Tubes</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/5">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy6.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops"> Double Cushion</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/6">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy7.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillezy Handling</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/7">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy8.webp"
                    className="iml image-fluid"
                  />

                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillezy Hexa Paper</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/8">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img
                    src="/images/fillezy9.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Fillezy Rapid </h3>
                    <Link to="/products/LubricantsPacking/Fillezy/9">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>

                <div className="biots">
                  <img
                    src="/images/fillezy10.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Paper Aer</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/10">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>

                <div className="biots">
                  <img
                    src="/images/fillezy11.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Paper-Smart Machine</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/11">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>

                <div className="biots">
                  <img
                    src="/images/fillezy12.webp"
                    className="iml image-fluid"
                  />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Paper-Smart Pads</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/12">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {id == "IndustrialSafety" && (
        <>
          <div className="container prdtname">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Industrial Safety </h4>
            </div>
            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                  Industrial safety products are essential tools and equipment
                  designed to protect workers, prevent accidents, and ensure a
                  secure working environment in various industries.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  <p>
                    These protect the head from falling objects, impact, and
                    electrical hazards, ensuring the safety of workers in
                    construction, manufacturing, and other industries. These
                    protect the head from falling objects, impact, and
                    electrical hazards, ensuring the safety of workers in
                    construction, manufacturing, and other industries.
                  </p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <img src="/images/is1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">PERSONAL PROTECTIVE </h3>
                    <Link to="/SubCategories/propre">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/is2.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">ROAD AND PARKING</h3>
                    <Link to="/SubCategories/road&parking" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/is3.jpg" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">FIRE FIGHTING</h3>
                    <Link to="/SubCategories/firefighting" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/is4.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">WELDING PRODUCTS</h3>
                    <Link to="/SubCategories/welding" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/is5.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">EXCLUSIVE PRODUCTS</h3>
                    <Link to="/SubCategories/excul-prod" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/is6.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">MARINE SAFETY</h3>
                    <Link to="/SubCategories/marinesafety" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* remain */}
      {id == "industrialcutting" && (
        <>
          <div className="container prdtname">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Industrial Cutting</h4>
            </div>

            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                  Industrial cutting involves various methods used in
                  manufacturing to divide materials into specific shapes or
                  sizes. Techniques like laser cutting, waterjet cutting, plasma
                  cutting, shearing, abrasive cutting, and ultrasonic cutting
                  are employed based on material type, precision needed, and
                  cost efficiency.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  We specialize in industrial cutting solutions, offering expert
                  planning, precise engineering, and seamless execution for a
                  spectrum of cutting needs. Our services cater to industries
                  such as manufacturing, construction, and engineering,
                  providing state-of-the-art techniques including laser cutting,
                  waterjet precision, plasma cutting, and more. We pride
                  ourselves on our ability to offer tailored industrial cutting
                  solutions, meeting the unique demands of diverse industries
                  and ensuring high-quality results for every client<p></p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <img src="/images/solid.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Solid Drill</h3>
                    <Link to="/products/IndustrialCutting/1" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/solidcut.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Solid Milling Cutters</h3>
                    <Link to="/products/IndustrialCutting/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/taps1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Taps</h3>
                    <Link to="/products/IndustrialCutting/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/turning1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Turning </h3>
                    <Link to="/products/IndustrialCutting/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/milling1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Milling</h3>
                    <Link to="/products/IndustrialCutting/5" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/therad1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Threading</h3>
                    <Link to="/products/IndustrialCutting/6" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/holmak.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Hole Making</h3>
                    <Link to="/products/IndustrialCutting/7" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/toolsys1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Tooling System</h3>
                    <Link to="/products/IndustrialCutting/8" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {id == "textilemachinery" && (
        <>
       <div className="container mt-5" ref={loca}>
       <div className="about1">
         <div className="smallsss1"></div>
         <h4 className="aboutline_head1">Imported Open End Spares</h4>
       </div>
       <div className="colspin">
         <div className="biod">
           <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
           Importing crucial machinery spares for diverse industrial machines, including BD, SN, RN, BT, and more, is our specialization. Our top-quality components, like PLC Rotor Assemblies, Resilient Mountings, Opening Roller Assemblies, Inserts, and Separators, ensure smooth operations.
           </h3>
           <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
             <p>
             We prioritize top-notch quality, ensuring each spare meets rigorous standards, enabling our clients to maintain efficiency and minimize downtime. Our commitment lies in delivering reliable, high-performance spares tailored to unique machinery requirements, aiming to keep industrial processes running smoothly.
             </p>
           </div>
         </div>
         <div className="ghj">
           <div className="biots">
             <img
               src="/images/tm1.png"
               className="iml image-fluid"
             />
           </div>
           <div className="biots">
             <img
               src="/images/tm2.png"
               className="iml image-fluid"
             />
             
           </div>
           <div className="biots">
             <img
               src="/images/tm3.png"
               className="iml image-fluid"
             />
           
           </div>
         </div>
       </div>
     </div>
     <div className="container mt-5" ref={loca}>
       <div className="about1">
         <div className="smallsss1"></div>
         <h4 className="aboutline_head1">Opening Roller Reclothing</h4>
       </div>
       <div className="colspin">
         <div className="biod">
           <h3 className="pill">
           Specializing in opening roller reclothing for BD, SN, RN, and BT machines, we use Truetzschler wire and cutting-edge technology. Our service is repeatable 1-3 times and includes a specialized coating process.
           </h3>
           <div className="text-muted pol">
             <p>
             Through our reclothing service, we ensure precise balancing, reduced maintenance needs, an extended service life for the rollers, and improved opening efficiency leading to superior trash and micro dust removal. These advantages elevate operational efficiency and machine performance significantly.
             </p>
           </div>
         </div>
         <div className="ghj1">
           <div className="biots">
             <img
               src="/images/tm4.png"
               className="iml image-fluid"
             />
            
           </div> 
         </div>
       </div>
     </div>
        </>
      )}
      {id == "OfficeEssential" && (
        <>
          <div className="container prdtname">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Office Essential</h4>
            </div>

            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                Office essentials in an industrial setting span specialized software for technical tasks, high-performance hardware, tailored communication platforms, sustainable infrastructure, workshop necessities, and ongoing training tools. These resources drive innovation, efficiency, and collaboration within the dynamic industrial landscape.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  {" "}
                  We focus on delivering essential office solutions for industrial environments, offering a specialized range of technical tools, communication platforms, and ergonomic equipment. Our goal is to optimize productivity by providing tailored software, high-performance hardware, and sustainable infrastructure, empowering industrial workspaces for continuous innovation and operational efficiency.
                  <p></p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <img src="/images/thermalpaper.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Thermal Paper Roll</h3>
                    <Link to="/products/OfficeEssential/1" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/normal paper.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Normal Paper Roll</h3>
                    <Link to="/products/OfficeEssential/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/corrugated-paper-roll.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Carrugated Paper Roll</h3>
                    <Link to="/products/OfficeEssential/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/ply-corrugated-roll.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Carrugated Ply Roll</h3>
                    <Link to="/products/OfficeEssential/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/office.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Office Stationery's</h3>
                    <Link to="/products/OfficeEssential/5" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/blank-white-paper.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">A4 Bundles</h3>
                    <Link to="/products/OfficeEssential/6" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {id == "industriallpg" && (
        <>
          <div className="container prdtname">
            <div className="about1">
              <div className="smallsss1"></div>
              <h4 className="aboutline_head1">Industrial LPG Solutions</h4>
            </div>

            <div className="colspin">
              <div className="biod">
                <h3 className="pill" data-aos="fade-right" data-aos-duration="1500">
                  Industrial LPG solutions excel in efficiency and
                  eco-friendliness. Its high energy content and clean combustion
                  produce minimal emissions, making it the cleanest fuel
                  available. LPG equipment requires minimal maintenance,
                  offering cost-effectiveness and environmental advantages
                  across diverse industrial applications.
                </h3>
                <div className="text-muted pol" data-aos="fade-left" data-aos-duration="1500">
                  {" "}
                  Industrial LPG stands out for its exceptional convenience,
                  safety, and accessibility. It's a remarkably safe fuel when
                  handled properly, catering to over 2000 known industrial
                  applications globally. Accessible in diverse portable cylinder
                  sizes, LPG offers flexibility in usage across industries.
                  Additionally, in terms of efficiency and maintenance costs,
                  LPG emerges as the most commercially viable fuel compared to
                  liquid and conventional options, underscoring its
                  cost-effectiveness and efficiency in industrial settings
                  <p></p>
                </div>
              </div>
              <div className="ghj">
                <div className="biots">
                  <div className="lpgimg">
                    <img src="/images/lpg1.webp" className="imllpg " />
                  </div>
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Commercial Cylinder​</h3>
                    <Link to="/products/Industriallpg/1" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <div className="lpgimg">
                    <img
                      src="/images/lpg2.webp"
                      className="imllpg  image-fluid"
                    />
                  </div>
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">BMCG</h3>
                    <Link to="/products/Industriallpg/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/lpg3.webp" className="iml  image-fluid" />

                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">HIPPO</h3>
                    <Link to="/products/Industriallpg/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <div className="lpgimg">
                    <img
                      src="/images/lpg4.webp"
                      className="imllpg  image-fluid"
                    />
                  </div>
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">5KG FTL</h3>
                    <Link to="/products/Industriallpg/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Biodegradable;
