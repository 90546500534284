import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Streetc = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "Rust-X"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "Rust-X"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "Rust-X"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "Rust-X"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "Rust-X"&&cid==='8'){
            setShow("vcp8")
          }
       
       
        
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "protectors & Protex"  : show === "vcp2" ? "Crepe Paper"  : 
                               show === "vcp3" ? "HDPE Papers"  : show === "vcp4" ? "Power Stretch"  :
                               show === "vcp5" ? " Yotex"  : show === "vcp6" ? "Scrim Paper"  :
                               show === "vcp7" ? "Scrim Tight Film"  : show === "vcp8" ? "Strength Fabric"  
                            
                               :""}

                               

                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/steel1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/steel2.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/steel3.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/steel4.webp' alt='dummy'/> 
                                </>:
                               show === "vcp5" ?
                                <>
                                    <img src='/images/steel5.webp' alt='dummy'/> 
                                </>:
                               show === "vcp6" ?
                                <>
                                    <img src='/images/steel6.webp' alt='dummy'/> 
                                </>:
                               show === "vcp7" ?
                                <>
                                    <img src='/images/steel7.webp' alt='dummy'/> 
                                </>:
                               show === "vcp8" ?
                                <>
                                    <img src='/images/steel8.webp' alt='dummy'/> 
                                </>:
                              
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Rust-X Manufactures OD protectors, ID rings and Protex rolls that are pseudo embossed from a 0.5 mm sheet. They can be die cut in the shape of rings and can be used as separators or protective covering for the steel coils and sheets.Rust-X's OD protectors, ID rings, and Protex rolls provide tailored protection for steel coils and sheets, ensuring their integrity and quality are maintained throughout the supply chain. Using OD protectors, ID rings, and Protex rolls is a cost-effective way to protect valuable steel coils and sheets, minimizing the risk of damage and ensuring the quality of the steel products remains intact.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>RUST-X manufactures VCI Crepe Papers which have exceptional strength, tear resistance. Volatile Chemicals coated on Natural Kraft Paper can provide protection for long time in packaged condition. Goods wrapped in or protected by RUST-X VCI crepe paper are not affected by corrosion. The reason for this is that the air surrounding the metals protected with RUST-X crepe paper. RUST-X VCI crepe paper is very heavily impregnated with the inhibitors released from the protective paper.Crepe paper is a type of paper that is coated with sizing (a substance that improves resistance to water, chemicals, and wear) and then crinkled to create a textured surface. </p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>VCI HDPE Papers, as described, combine the properties of VCI (Volatile Corrosion Inhibitor) papers with HDPE (High-Density Polyethylene) materials, resulting in a product that offers enhanced protection against moisture, corrosion, and environmental factors. VCI HDPE Papers are manufactured by combining various combinations of raffia and PE with VCI papers.VCI papers provide excellent protection against moisture and dew. RUST-X HDPE PAPERS are heavily impregnated with VCI’s ensuring longevity of protection.</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>VCI power stretch films are ideal to wrap large machinery, coils or parts that cannot be packed in bags. It has a great self-healing and arrests influx of moisture. Rust-x VCI Power Stretch films when stretched on metal products isolate the packed coils and sheets from humidity and the VCI neutralizes the environment inside the package. The elongate up to 600% and have great non-transfer adhesives that allow self-healing and binds the coil tight.Power Stretch, also known as stretch film or stretch wrap, is a highly stretchable plastic film that is wrapped around items to secure them, especially on a pallet, for transportation or storage.</p> : 
                                null}
                                {show === "vcp5"? <p className='text-muted firstparavalue'>Yotex is a product made from high-density polyethylene and combined with cross-link structure for high strength polymers.Yotex is a Pseudo embossed process where it gives a functional 3mm surface made from 500gsm base sheet. This product is designed to cover the objects to prevent the top layer of the product getting damaged. It has a great cushioning strength which ultimately protects the top layers of the wrapped coils or sheets.Yotex is a specialized protective material made from high-density polyethylene (HDPE) and features a cross-link structure for enhanced strength</p> : 
                                null}
                                {show === "vcp6"? <p className='text-muted firstparavalue'>Rust-X manufactures Super Laminated VCI Scrim paper that have exceptional bi directional strength, tear resistance as well as puncture resistance. Volatile Chemicals coated on Kraft Paper can provide protection of up to 10 YEARS in packaged condition when in synergy with RUST-X oils.Scrim paper is a type of paper that is reinforced with a mesh of continuous filament yarns. The term "scrim" refers to the thin, open-weave fabric that is embedded within the paper structure. This reinforcement provides additional strength and durability to the paper, making it suitable for various applications. </p> : 
                                null}
                                {show === "vcp7"? <p className='text-muted firstparavalue'>The Rust-X VCI Scrim Tight Films is a 7 layered film that contains reinforced fibers between the layers spaced at regular intervals to enhance the tensile strength and tear resistance.The reinforced fibers prevent tear propagation in the event of a puncture or shear damage. Which is 3 times higher than the non-reinforced film.Rust-X VCI Scrim Tight Films, as described, are advanced protective films designed with specific features to enhance their strength and durability.The reinforced fibers play a crucial role in preventing tear propagation in the event of punctures or shear damage.</p> : 
                                null}
                                {show === "vcp8"? <p className='text-muted firstparavalue'>Rust-X Strength Fabric is a woven fabric and as by its name is symbolic to not just the advantage of High Strength but also excellent moisture penetrations properties and oxygen transmissions.It is laminated on both sides with Poly Ethylene or Propylene and impregnated with VCI additives.Strength fabric, also known as high-strength fabric, is a type of textile material engineered to possess exceptional tensile strength, durability, and resistance to wear and tear. These fabrics are designed for applications where superior strength and resilience are crucial.</p> : 
                                null}
                              
                               

                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp4" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp5" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp6" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp7" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp8" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>OD protectors are designed to be placed on the outer circumference of steel coils. They act as a protective barrier, preventing damage and corrosion to the outer surface of the coil during storage, transportation, and handling.</p>: null}
                                    {show === "vcp2"?<p>Crepe paper is characterized by its crinkled or wrinkled texture. The creping process involves manipulating the paper fibers to create a series of fine wrinkles, giving the paper a distinctive appearance and texture.</p>: null}
                                    {show === "vcp3"?<p>VCI papers are impregnated with volatile corrosion inhibitors, which create a protective molecular layer on metal surfaces. This layer inhibits corrosion and rust formation, ensuring that metal objects, components, or equipment remain protected during storage and transit.</p>: null}
                                    {show === "vcp4"?<p>Power Stretch film is made from linear low-density polyethylene (LLDPE) and possesses excellent stretchability. It can be stretched up to 300-400% of its original length, allowing it to tightly secure and hold loads together.</p>: null}
                                    {show === "vcp5"?<p>Yotex is made from high-density polyethylene (HDPE), a durable and robust plastic known for its strength and resistance to wear and tear.</p>: null}
                                    {show === "vcp6"?<p>Scrim paper is reinforced with a network of filaments, usually made of materials like polyester or fiberglass. These filaments are embedded within the paper during the manufacturing process, enhancing the paper's mechanical properties.</p>: null}
                                    {show === "vcp7"?<p>Rust-X VCI Scrim Tight Films are composed of seven layers. The multiple layers contribute to the film's overall strength and performance.</p>: null}
                                    {show === "vcp8"?<p>Strength fabric is specifically woven or constructed to achieve high tensile strength. Tensile strength refers to the maximum amount of tensile (pulling) stress a material can withstand without breaking.</p>: null}
                                    
                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>ID rings are placed on the inner circumference of steel coils. They serve as separators and protective coverings for the inner layers of the coil. ID rings help maintain the structural integrity of the coil's inner diameter and prevent deformation or scratches during stacking and movement.</p>: null}
                                    {show === "vcp2"?<p> Crepe paper is highly flexible and can easily conform to irregular shapes. This property makes it ideal for decorative purposes, crafting, and packaging applications where flexibility is required.</p>: null}
                                    {show === "vcp3"?<p> The integration of HDPE, a durable and moisture-resistant plastic, adds strength and longevity to the paper. HDPE enhances the paper's resistance to tears, abrasions, and moisture ingress, making it suitable for a variety of industrial applications.</p>: null}
                                    {show === "vcp4"?<p>It is commonly used in warehouses, manufacturing facilities, and during shipping to secure pallet loads. The film sticks to itself when wrapped around items, providing a strong and secure hold without the need for adhesives.</p>: null}
                                    {show === "vcp5"?<p>The material features a cross-link structure, enhancing its strength and durability. Cross-linking involves chemically bonding the polymer chains, making the material more stable and resilient.</p>: null}
                                    {show === "vcp6"?<p> The embedded filaments provide tensile strength to the paper, making it resistant to tearing and damage. Scrim paper is stronger and more durable compared to regular paper due to the reinforcement.</p>: null}
                                    {show === "vcp7"?<p>The film contains reinforced fibers placed between the layers at regular intervals. These fibers significantly enhance the tensile strength and tear resistance of the film.</p>: null}
                                    {show === "vcp8"?<p>These fabrics are made from durable fibers, such as nylon, polyester, aramid, or high-performance synthetic fibers. The weaving or construction method enhances the fabric's durability, allowing it to withstand heavy loads and harsh conditions.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Protex rolls are manufactured from a 0.5 mm sheet and can be pseudo embossed. These rolls are versatile and can be die-cut into various shapes, including rings. They are utilized as separators and protective coverings for both the outer and inner surfaces of steel coils and sheets.</p>: null}
                                    {show === "vcp2"?<p>Crepe paper has moderate absorbency, making it suitable for certain art and craft projects, as well as applications where absorption of liquids is necessary.</p>: null}
                                    {show === "vcp3"?<p>VCI HDPE Papers are specifically designed to provide excellent protection against moisture and dew. The combination of VCI technology and HDPE material ensures that the papers effectively repel moisture, preventing corrosion and maintaining the integrity of metal products.</p>: null}
                                    {show === "vcp4"?<p> Power Stretch film is widely used for palletizing goods. It ensures that items on a pallet are held together firmly, preventing shifting or falling during transportation. This is crucial for safety and stability during transit.</p>: null}
                                    {show === "vcp5"?<p>Yotex undergoes a pseudo embossed process, creating a functional 3mm surface from a base sheet weighing 500gsm (grams per square meter). This process likely involves creating a textured or patterned surface on the material.</p>: null}
                                    {show === "vcp6"?<p>Scrim papers come in different weave patterns, ranging from fine to coarse. The choice of weave affects the paper's texture, strength, and flexibility, allowing for customization based on specific application requirements.</p>: null}
                                    {show === "vcp7"?<p>The reinforced fibers between the layers increase the film's tensile strength. Tensile strength refers to the maximum amount of tensile (pulling) stress that a material can withstand without breaking. </p>: null}
                                    {show === "vcp8"?<p> Strength fabrics are resistant to abrasion, making them suitable for applications where the material is exposed to friction or rubbing. This resistance ensures the fabric maintains its integrity over time, even in high-wear environments.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> The Protex rolls, when die-cut into specific shapes such as rings, offer customized solutions for different coil and sheet sizes. These shapes ensure proper coverage and protection for specific areas of the steel coils.</p>: null}
                                    {show === "vcp2"?<p> Crepe paper comes in a wide range of colors, allowing for diverse creative applications. It is often used in crafting, party decorations, and DIY projects where vibrant and varied colors are desired.</p>: null}
                                    {show === "vcp3"?<p>VCI HDPE Papers find applications in various industries, including metalworking, automotive, electronics, and aerospace. They are used to wrap, cover, or interleave metal parts, machinery, or components, safeguarding them from corrosion during storage and transportation.</p>: null}
                                    {show === "vcp4"?<p> Besides securing loads, Power Stretch film provides protection from dust, moisture, and other environmental factors. It helps in maintaining the integrity and cleanliness of the items being wrapped.</p>: null}
                                    {show === "vcp5"?<p>Yotex is designed to cover objects, providing a protective layer that prevents damage to the top layer of the wrapped coils or sheets. It acts as a cushioning material, offering protection against scratches, dents, and other forms of surface damage.</p>: null}
                                    {show === "vcp6"?<p>Scrim paper is commonly used in the construction industry. It is often impregnated with asphalt or other waterproofing materials to create moisture-resistant membranes for roofing and flooring applications. The reinforcement provided by the scrim enhances the material's tear resistance and stability.</p>: null}
                                    {show === "vcp7"?<p>In the event of a puncture or shear damage, the reinforced fibers prevent tear propagation. This means that if the film is punctured or damaged, the tear won't easily spread, maintaining the film's integrity.</p>: null}
                                    {show === "vcp8"?<p>The fabric's structure and material composition provide excellent tear resistance. It can withstand tearing forces, making it suitable for applications where the fabric may be subjected to sharp objects or punctures.</p>: null}
                                   
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>The products are made from a 0.5 mm sheet, providing a durable and robust protective layer for the steel coils and sheets.</p>: null}
                                    {show === "vcp2"?<p>Crepe paper is popular for decorative purposes, such as making paper flowers, party streamers, and piñatas. Its flexibility and vibrant colors enhance the visual appeal of decorations.</p>: null}
                                    {show === "vcp3"?<p>The papers are heavily impregnated with VCI compounds, ensuring a prolonged and reliable protection period. This longevity reduces the need for frequent replacement and maintenance, making it a cost-effective solution for corrosion prevention.</p>: null}
                                    {show === "vcp4"?<p>Power Stretch film is available in transparent or opaque versions. Transparent film allows for easy visibility of the wrapped items, making it convenient for inventory management and stock identification.</p>: null}
                                    {show === "vcp5"?<p>Yotex possesses excellent cushioning strength, making it effective in safeguarding the top layers of coils or sheets during storage, transportation, or handling. The cushioning properties help absorb shocks and impacts, minimizing the risk of surface damage.</p>: null}
                                    {show === "vcp6"?<p>Scrim paper is used by artists and crafters for various creative projects. Its unique texture and strength make it suitable for techniques like papier-mâché, collage, and mixed media art.</p>: null}
                                    {show === "vcp7"?<p>The tear resistance of the Scrim Tight Film is three times higher than non-reinforced films. This high tear resistance ensures that the film can withstand various stresses and impacts without tearing, providing reliable protection to the wrapped items.</p>: null}
                                    {show === "vcp8"?<p>Depending on the specific fibers used, strength fabrics can exhibit resistance to certain chemicals, oils, and solvents. This property makes them suitable for applications in industrial environments where exposure to chemicals is a concern.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Both ID rings and Protex rolls can be used as separators between individual coils or sheets when stacked together. They prevent direct contact between the surfaces, reducing the risk of scratches and abrasions.</p>: null}
                                    {show === "vcp2"?<p>Crafters use crepe paper for various projects, including scrapbooking, card making, and costume design. Its texture and versatility make it a favorite material among craft enthusiasts.</p>: null}
                                    {show === "vcp3"?<p>VCI HDPE Papers are safe for the environment and do not contain harmful substances. They provide a sustainable and eco-friendly solution for protecting metal items from corrosion, aligning with green initiatives.</p>: null}
                                    {show === "vcp4"?<p>Power Stretch film comes in various thicknesses, known as gauges. Thicker gauges provide higher strength and puncture resistance, suitable for heavier loads, while thinner gauges are used for lighter loads.</p>: null}
                                    {show === "vcp5"?<p>Yotex is likely used in industries where protecting the surface integrity of coils, sheets, or other sensitive objects is crucial. Its cushioning and protective qualities make it valuable for applications where items need to be safeguarded against potential damage.</p>: null}
                                    {show === "vcp6"?<p>Scrim paper is used in the insulation industry as a facing material for insulation products. It adds strength to the insulation material and helps maintain its integrity during installation and use.</p>: null}
                                    {show === "vcp7"?<p>Rust-X VCI Scrim Tight Films likely incorporate VCI technology, providing corrosion protection to the items wrapped inside the film. </p>: null}
                                    {show === "vcp8"?<p> Despite their high strength, these fabrics can be engineered to remain flexible. This flexibility allows them to conform to various shapes and structures, making them versatile for different applications.</p>: null}
                                   
                                    
                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>By acting as protective barriers, these products help prevent corrosion and rust formation on the steel coils and sheets, especially in humid or corrosive environments.</p>: null}
                                    {show === "vcp2"?<p>Crepe paper is sometimes used for wrapping delicate or fragile items. Its soft and pliable nature provides a cushioning effect, protecting the items during transportation.</p>: null}
                                    {show === "vcp3"?<p>VCI HDPE Papers can be customized in terms of size, thickness, and VCI concentration to meet specific industrial requirements. Tailored solutions ensure optimal protection for various types of metal products.</p>: null}
                                    {show === "vcp4"?<p>Power Stretch film can be applied manually using hand dispensers or automatically using machine wrappers. Machine wrapping allows for faster and more consistent wrapping, especially for high-volume operations.</p>: null}
                                    {show === "vcp5"?<p>Yotex's protective properties make it versatile for various industrial applications, especially in situations where maintaining the aesthetic and functional integrity of products is essential.</p>: null}
                                    {show === "vcp6"?<p>Scrim paper is used in the manufacturing of flexible ducts for HVAC (heating, ventilation, and air conditioning) systems. The reinforcement provided by the scrim ensures that the ducts remain durable and resistant to wear and tear.</p>: null}
                                    {show === "vcp7"?<p>Scrim Tight Films with these enhanced features are ideal for packaging applications where robust protection against tearing, punctures, and corrosion is essential. </p>: null}
                                    {show === "vcp8"?<p>Some strength fabrics are treated or inherently water-resistant, making them suitable for outdoor applications where protection against moisture is essential.</p>: null}
                                   
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> The ability to pseudo emboss the Protex rolls and die-cut them into specific shapes allows for customization based on the unique requirements of different steel coil and sheet applications.</p>: null}
                                    {show === "vcp2"?<p>Crepe paper is often used in themed decorations for parties, events, and holidays. Its ability to create visually appealing and cohesive decorative elements makes it a popular choice.</p>: null}
                                    {show === "vcp3"?<p>These papers are easy to handle, cut, and fold, allowing for convenient packaging and wrapping of metal components. Their versatility and ease of use make them popular choices in industrial settings.</p>: null}
                                    {show === "vcp4"?<p> After stretching and wrapping around items, Power Stretch film exhibits elastic recovery, meaning it tightens around the load, providing a snug fit and preventing items from shifting or coming loose.</p>: null}
                                    {show === "vcp5"?<p>Due to its composition and pseudo embossed surface, Yotex likely offers durability, ensuring that the protective layer remains intact and effective over time.</p>: null}
                                    {show === "vcp6"?<p> In some medical applications, scrim paper is used as a base material for surgical tapes and dressings. The reinforcement helps provide structural integrity to the tapes while allowing for flexibility.</p>: null}
                                    {show === "vcp7"?<p>The film might be available in various thicknesses and sizes, allowing for customization based on specific application requirements. Different industries and applications might require different film thicknesses for optimal protection.</p>: null}
                                    {show === "vcp8"?<p> Depending on the fibers and treatments used, strength fabrics can be engineered to be flame-resistant or self-extinguishing, ensuring safety in environments where fire risk is a concern.</p>: null}
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Streetc