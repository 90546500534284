import React, { useEffect, useState, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/Testimonial.css';
import { BsFillStarFill } from "react-icons/bs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Tilt } from 'react-tilt'
import CustomPrevArrow from './Iotprevarrow';
import CustomNextArrow from './iotCustomArrow';
import '../css/About.css';

function Testimonial() {
  const defaultOptions = {
    reverse:        false,  // reverse the tilt direction
    max:            35,     // max tilt rotation (degrees)
    perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
    scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
    speed:          1000,   // Speed of the enter/exit transition
    transition:     true,   // Set a transition on enter/exit.
    axis:           null,   // What axis should be disabled. Can be X or Y.
    reset:          true,    // If the tilt effect has to be reset on exit.
    easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
  }

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    AOS.init();
    AOS.refresh();

  }, []);
  const settings = {
    prevArrow: <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    nextArrow: <CustomNextArrow onClick={() => sliderRef.current.slickNext()} />,
    afterChange: (current) => setCurrentSlide(current),
  };
  return (
    <div className='container' >
      <div className='row fulline'>
        <div className='col-lg-6 fullleftcompany'>
          <div className='line_with_testimonial' >
          <div className='smallsss'></div>
            <p>Testimonials</p>
          </div>
          <div className='header'>
          <h1> Some feedbacks <span className='tm-span'>from <br></br>{" "}
            honarble Clients</span> </h1>
          {" "}
          </div>
          <div className='movingslick'>
            <div className="slide">
            <Slider ref={sliderRef} {...settings} >
                <div>
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <p className='text-muted mummypara'>"Best service and Low price customer friendly,
                   He explained every technical details and timely completion of work with 100% perfection.
                    so I highly recommend
                   "Edgecut Tools".</p>
                  <div className='slideinside'>
                    <h4 className='bluecolor'>Sudharshan S</h4>
                    <span className='bluecolor1'>Hot Chips Restaurants</span> <br/>
                    <span className='bluecolor1'>Managing Director</span>
                  </div>
                </div>
                <div>
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <p className='text-muted mummypara'>"Edgecut has a team of engineering 
                   professionals who constantly work hard to serve the better quality 
                   products to the major manufacturing industries. 
                ".</p>
                  <div className='slideinside'>
                  <h4 className='bluecolor'>Sandeep</h4>
                  <span className='bluecolor1'>Covai Anganan Briyani</span> <br/>
                    <span className='bluecolor1'>Managing Director</span>
                  </div>
                </div>
                <div>
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <p className='text-muted mummypara'>"Edgecut Tools India Private Limited
                   is an authorized distributor of ITL and Cutting Tools for Coimbatore, 
                   Manchester of South India".</p>
                  <div className='slideinside'>
                 
                  <h4 className='bluecolor'>Arasu</h4>
                  <span className='bluecolor1'>Microfoam Automation</span> <br/>
                    <span className='bluecolor1'>Production Head</span>
                  </div>
                </div>
                <div>
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <p className='text-muted mummypara'>"Edgecut was started in the year 2017 and engaged in the complete supply of tools and technologies for all kinds of metal machining.
                   We also deals with engineering design services and importer of open end textile machinery spares".</p>
                  <div className='slideinside'>
            
                  <h4 className='bluecolor'>Name</h4>
                  <span className='bluecolor1'>Hot Chips</span> <br/>
                    <span className='bluecolor1'>Degination</span>
                  </div>
                </div>
                <div>
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <BsFillStarFill className='rating' />{" "}
                  <p className='text-muted mummypara'>"Edgecut provides training to the 
                  customers to make their work force to
                   update about the technology. .</p>
                  <div className='slideinside'>
                  <h4 className='bluecolor'>Name</h4>
                    <span className='bluecolor1'>Magilam</span> <br/>
                    <span className='bluecolor1'>Degination</span>
                  </div>
                </div>
              </Slider>

            </div>
          </div>
        </div>

        <div className='col-lg-6 imagevloger'>
        <Tilt options={defaultOptions} >
          <img src="./images/testimonials-group.png"
            alt="value"
            data-aos="zoom-in-up" 
           className='reviewphoto'/>
            </Tilt>
        </div>
      </div>


    </div>
  )
}

export default Testimonial;