import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../../css/Productdetails.css';

const Rps = ({id,cid,cat}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "road&parking"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "road&parking"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "road&parking"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "road&parking"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "propre"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "propre"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "propre"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "propre"&&cid==='8'){
            setShow("vcp8")
          }
        if(id === "propre"&&cid==='9'){
            setShow("vcp9")
          }
        if(id === "propre"&&cid==='10'){
            setShow("vcp10")
          }
        if(id === "propre"&&cid==='11'){
            setShow("vcp11")
          }  
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "TRAFFIC SAFETY"  : show === "vcp2" ? "PARKING SAFETY"  : 
                               show === "vcp3" ? "LIGHTS"  : show === "vcp4" ? "TAPES"  
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/trafficsafetyprotect.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/parking-safety.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/lights.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/welding.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>

                                Traffic safety is a critical aspect of ensuring the safety and well-being of individuals on roads, encompassing a range of measures and practices to minimize the risk of accidents and enhance overall road user safety.
One of the foundational elements of traffic safety is the implementation of clear road signs and signals. These visual cues provide crucial information to drivers and pedestrians, facilitating orderly and safe traffic flow. Properly marked pedestrian crosswalks are equally vital, offering designated areas for pedestrians to safely traverse roads.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Implementing a comprehensive approach to parking safety involves the strategic deployment of various measures to ensure the well-being of both vehicles and pedestrians. Wheel stoppers play a crucial role in preventing vehicles from overshooting parking spaces, promoting proper alignment and reducing the risk of collisions within parking areas. Bollards act as guiding elements, directing traffic and creating safe zones for pedestrians, thereby enhancing overall safety within parking facilities.</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>These lights are not only efficient in traffic guidance but also contribute to overall road safety by improving visibility during low-light conditions. Whether in emergency situations, pedestrian crossings, or construction zones, the conspicuous presence of baton lights and solar blinker lights plays a pivotal role in preventing accidents and creating safer road environments for all road users. Their versatile applications underscore their importance in the broader context of road safety initiatives.</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>Tapes, encompassing barricade, reflective, floor marking, warning, and identification tapes, are integral to promoting safety through various functions. Barricade tapes act as visual guides, demarcating potential hazards and restricted areas for enhanced safety awareness. Reflective tapes, by improving nighttime visibility, contribute to heightened road safety. Floor marking tapes facilitate organization in industrial spaces, creating clear pathways and highlighting safety zones. Warning tapes effectively communicate potential dangers, prompting caution in specific areas. Identification tapes aid in easy recognition of elements, enhancing workplace safety and productivity.</p> : 
                                null}
                               
                               

                            </div>
                        </div>

                        < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>Comprehensive traffic safety measures integrate various tools to minimize accident risks and enhance overall road security. Convex mirrors enhance visibility at critical points, bollards guide traffic and prevent unauthorized access, while traffic cones and cone chains aid in hazard marking and lane delineation. Safety barriers offer physical separation between traffic lanes, reducing the risk of crossover accidents, and speed breakers promote safe driving, particularly in residential areas. These combined efforts create organized, secure road environments, prioritizing the safety of all road users.
                            </p> : null}
                            { show === "vcp2" ? <p className='text-muted firstparavalue'>Antiglare boards contribute to nighttime safety by mitigating headlight glare, improving visibility for both drivers and pedestrians. This is a significant measure to reduce the risk of accidents caused by impaired visibility in low-light conditions. Dock bumpers provide protection during loading and unloading activities, acting as a cushion to absorb impact and prevent damage to both vehicles and parking infrastructure.
                            </p> : null}
                            { show === "vcp3" ? <p className='text-muted firstparavalue'>
Baton lights and solar blinker lights are integral components of road safety, serving diverse purposes to enhance traffic management and visibility. Baton lights, often wielded by traffic police, play a crucial role in directing and managing traffic, especially during emergencies or at accident sites. Their high visibility assists in swift responses and ensures the smooth flow of vehicles.
                            </p> : null}
                            { show === "vcp4" ? <p className='text-muted firstparavalue'>Tapes, including barricade tapes, reflective tapes, and floor marking tapes, play pivotal roles in safety measures by demarcating restricted zones, enhancing visibility in low-light conditions, organizing spaces within industrial environments, communicating potential hazards, and facilitating easy identification of elements, collectively contributing to comprehensive safety protocols in diverse settings.
                            </p> : null}
                            </div>

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Enhance visibility at intersections and blind spots, reducing the risk of accidents by providing a broader view of oncoming traffic and pedestrians.</p>: null}
                                    {show === "vcp2"?<p>Wheel stoppers prevent vehicles from overshooting parking spaces, ensuring proper alignment and minimizing the risk of collisions within the parking area.</p>: null}
                                    {show === "vcp3"?<p>Baton lights and solar blinker lights serve as effective tools for directing traffic, providing clear visual cues to drivers and ensuring orderly traffic flow, particularly in scenarios requiring manual guidance.</p>: null}
                                    {show === "vcp4"?<p> Clear visual cues for individuals and vehicles to avoid restricted zones, enhancing overall safety.</p>: null}
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Serve as protective barriers to control traffic flow, guide pedestrians, and prevent unauthorized vehicle access, contributing to overall road safety.</p>: null}
                                    {show === "vcp2"?<p>Bollards guide traffic and create pedestrian-safe zones, serving as protective barriers to enhance overall safety within parking facilities.</p>: null}
                                    {show === "vcp3"?<p>In emergency situations or at accident sites, baton lights are utilized by traffic police to manage traffic effectively. Their high visibility aids in diverting traffic and facilitating a prompt response during critical incidents.</p>: null}
                                    {show === "vcp4"?<p>Reflect light to make objects or areas visible in low-light conditions, contributing to road safety and hazard awareness.</p>: null}
                                    


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Versatile tools for marking hazards, redirecting traffic, and delineating lanes. Their bright colors and reflective surfaces enhance visibility and provide clear guidance to drivers and pedestrians.</p>: null}
                                    {show === "vcp2"?<p>Antiglare boards reduce headlight glare, enhancing visibility for drivers and pedestrians, particularly during nighttime, to mitigate the risk of accidents caused by impaired visibility.</p>: null}
                                    {show === "vcp3"?<p>Solar blinker lights enhance pedestrian safety at crosswalks, alerting drivers to the presence of pedestrian crossings. This improves visibility, especially in low-light conditions, and reduces the risk of accidents involving pedestrians.</p>: null}
                                    {show === "vcp4"?<p>Create clear pathways, designate storage areas, and indicate safety hazards on the floor for an organized and safe working environment.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Provide physical separation between traffic lanes, minimizing the risk of crossover accidents and reducing the impact of collisions for enhanced highway and road safety.</p>: null}
                                    {show === "vcp2"?<p>Dock bumpers protect vehicles and structures during loading and unloading activities, absorbing impact and preventing damage to both the vehicle and the parking infrastructure.</p>: null}
                                    {show === "vcp3"?<p>Both baton lights and solar blinker lights are deployed in construction zones to warn drivers of ongoing activities. They signal potential hazards, encourage reduced speeds, and contribute to safer passage through work zones.</p>: null}
                                    {show === "vcp4"?<p>Alert individuals to exercise caution in areas with electrical wiring, slippery floors, or restricted access, reducing the risk of accidents.</p>: null}
                                   
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Effective in reducing vehicle speeds, especially in residential areas. Promote safer driving habits and prevent reckless driving for improved road safety.</p>: null}
                                    {show === "vcp2"?<p> Queue managers organize vehicle and pedestrian flow, reducing congestion and ensuring orderly movement within parking facilities. This contributes to a safer and more efficient parking environment, minimizing the risk of accidents or conflicts.</p>: null}
                                    {show === "vcp3"?<p>Baton lights and solar blinker lights play a crucial role in improving visibility during low-light conditions. Their bright and flashing features attract attention, reducing the likelihood of accidents, particularly in areas with reduced visibility.</p>: null}
                                    {show === "vcp4"?<p>Color-coded or labeled tapes help workers easily identify and understand different elements, contributing to workplace safety and efficiency.</p>: null}
                                  
                                    
                                    
                                    </span>
                            </div>
                           
                        </div>
    </div>
  )
}

export default Rps