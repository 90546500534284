import React, { useEffect, useState } from "react";
import "../css/Productdetails.css";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import CommonBtns from "./CommonBtns";
import LoadPage from "../Pages/LoadPage";

function IndCut({ setLoad }) {
  const [openCategory, setOpenCategory] = useState(null);

  const { id, pid } = useParams();
  const [indlpg, setIndLpg] = useState("");

  useEffect(() => {
    if (id === "1") {
        setIndLpg("cc")
    }
    if (id === "2") {
        setIndLpg("bmcg")
    }
    if (id === "3") {
        setIndLpg("hippo")
    }
    if (id === "4") {
        setIndLpg("ftl")
    }
    if (id === "5") {
        setIndLpg("mill")
    }
    if (id === "6") {
        setIndLpg("thd")
    }
    if (id === "7") {
        setIndLpg("hm")
    }
    if (id === "8") {
        setIndLpg("ts")
    }
  }, [id]);



  const handleLoad = () => {
    setLoad(true);

    // setTimeout(() => {
    //     setShowLoader(false);
    //   }, 1000);
  };

  const [isLoading, setIsLoading] = useState(false);


  return (
    <>
      <div className="container">
        <div className={isLoading ? "opaque" : "opaque1"}>
          <div className="row overall">
            <div className="col-md-4">
              <CommonBtns id={id} pid={pid} setLoad={setLoad} />
            </div>
            <div className="col-md-8">
              <div className="firstlineflex">
                <div className="yellowbreak"></div>
                <div className="firstheading">
                  <h1>
                    {indlpg === "cc"
                      ? "Solid Drill​"
                      : indlpg === "bmcg"
                      ? "Solid Milling Cutters"
                      : indlpg === "hippo"
                      ? "Taps"
                      : indlpg === "ftl"
                      ? "Turning"
                      : indlpg === "mill"
                      ? "Milling"
                      : indlpg === "thd"
                      ? "Threading"
                      : indlpg === "hm"
                      ? "Hole Making"
                      : indlpg === "ts"
                      ? "Tooling System"
                      : ""}
                  </h1>
                </div>
              </div>
              <div className="row paraoutdoor">
                <div className="col-md-4 col-sm-12">
                  {/* <div className="lpgdetialimg"> */}

                  {indlpg === "cc" ? (
                    <>
                      <img  src="/images/solid.webp" alt="dummy" />
                      {/* <button className='prdtBtn'>Buy now</button> */}
                    </>
                  ) : indlpg === "bmcg" ? (
                    <img src="/images/solidcut.webp" alt="dummy" />
                  ) : indlpg === "hippo" ? (
                    <img      src="/images/taps1 (1).webp" alt="dummy" />
                  ) : indlpg === "ftl" ? (
                    <img src="/images/turning1.webp" alt="dummy" />
                  ) 
                   : indlpg === "mill" ? (
                    <img src="/images/milling1.webp" alt="dummy" />
                  ) 
                   : indlpg === "thd" ? (
                    <img   src="/images/therad1.webp" alt="dummy" />
                  ) 
                   : indlpg === "hm" ? (
                    <img src="/images/holmak.webp" alt="dummy" />
                  ) 
                   : indlpg === "ts" ? (
                   
                         <img   src="/images/toolsys1.webp" alt="dummy" />
                   
                   
                  ) 
                   : 
                   null}

                  {/* </div> */}
                </div>
                <div className="col-md-8 col-sm-12">
                  {indlpg === "cc" ? (
                    <p className="text-muted firstparavalue">
                   Discover a comprehensive array of drilling tools tailored to meet your every need. From standard solid drills to specialized step drills for threaded holes that efficiently drill and chamfer simultaneously, our range is designed to enhance precision and versatility in every drilling task. Under the renowned Dormer brand, our collection spans High-Speed Steel (HSS), High-Speed Cobalt (M35/M42), and Solid Carbide (HM) drills, catering to varied drilling needs. With options for different lengths—stub, jobber, long series, and extra length—our tools accommodate drilling depths up to 20 times the diameter.Our diverse range ensures the right tool for every rotary hole-making need, offering versatile styles and solutions at your fingertips.
                    </p>
                  ) : null}
                  {indlpg === "bmcg" ? (
                    <p className="text-muted firstparavalue">
                     We provide a versatile program of rotary milling cutters under the Dormer brand covering almost every engineering material and application. Within the range we offer five basic types of shank cutters in High Speed Steel (HSS), High Speed Cobalt (HSS-E & HSS-E PM) and Solid Carbide (HM) to cover a variety of milling operations by Four-flute / multi-flute end mills, Ball nosed cutters and Roughing end mills.Additional cutter types available include T-slot cutters, woodruff cutters, dovetail cutters and corner rounding cutters. We also offer Carbide Thread Milling Cutters to support thread production with a milling operation.

                    </p>
                  ) : null}
                  {indlpg === "hippo" ? (
                    <p className="text-muted firstparavalue">
                      Dormer's threading range is a comprehensive suite supporting various tapping processes and primary tap geometries. Our selection includes an extensive array of taps such as Straight Flute, Spiral Point, Spiral Flute, and Forming Taps, each tailored to specific threading applications. Going beyond the conventional, our range extends to specialized taps including interrupted thread taps, serial taps, nut taps, combination drill-taps, and wire insert taps, ensuring we meet the diverse and intricate demands across threading processes.
                    </p>
                  ) : null}
                  {indlpg === "ftl" ? (
                    <p className="text-muted firstparavalue">
                    Our indexable turning tools provide versatility across a spectrum of operations, encompassing general turning for both external and internal applications, parting-off, grooving, threading, and heavy turning. Our range boasts a diverse selection, featuring MT-CVD and PVD coated inserts renowned for their durability and performance. Additionally, we offer inserts crafted from ceramic, cermet, CBN, and Diamond grades, ensuring a comprehensive suite of tools designed to meet varied machining needs with precision and efficiency.
                    </p>
                  ) : null}
                  {indlpg === "mill" ? (
                    <p className="text-muted firstparavalue">
                     Discover our comprehensive indexable milling range, offering a variety of grades including MT-CVD and PVD coated inserts. These inserts feature multiple geometries tailored for finishing, roughing, and universal applications, ensuring precision in diverse milling tasks. Additionally, our solid milling program presents an extensive array of designs, lengths, and coatings to support a wide spectrum of applications. From roughing to finishing operations, our range covers all machining needs, providing versatility and performance across the board
                    </p>
                  ) : null}
                  {indlpg === "thd" ? (
                    <p className="text-muted firstparavalue">
                     Pramet specializes in tools for turning both external and internal threads, offering a comprehensive selection of indexable cutting inserts tailored for M, W, RD, API, TR, UN, ACME threads with full profiles. Our range also includes inserts featuring partial profiles for 60° threads, ensuring versatility across various threading needs. Additionally, we provide customizable alternatives, offering specialized products that cater to specific requirements, whether it's thread profiles, insert designs, or grades, delivering tailored solutions for precise machining applications.
                    </p>
                  ) : null}
                  {indlpg === "hm" ? (
                    <p className="text-muted firstparavalue">
                      Our array of indexable drills encompasses a broad range of diameters catering to drilling depths from 2D to 5D. These drills offer versatility, functioning as rotary or stationary tools with single-edge capabilities, capable of operating outside the axis. Our innovative design allows for fine adjustments in hole dimensions within -0.2 to +0.5 mm using an off-centered tool, ensuring precision in machining. Even in unstable conditions or during interrupted cuts, our drills operate seamlessly, ensuring consistent performance without issues
                    </p>
                  ) : null}
                  {indlpg === "ts" ? (
                    <p className="text-muted firstparavalue">
                     Our precision-machined strong clamping base forms the foundation of our tooling systems, adapters, and essential accessories. Within our assortment, discover a wide array of options including shell mill holders, face and disc milling cutters, modular systems, and Weldon holders. Additionally, our range includes hydraulic chucks, collet chucks, shrink-fit holders, along with an extensive selection of HSK, DIN, and JIS holders. Each component is designed to ensure robust clamping and precise machining, providing a reliable foundation for diverse machining needs
                    </p>
                  ) : null}
                  
                </div>
              </div>


              <div className="downsession">
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Dormer's solid drilling tools encompass a comprehensive assortment, covering fundamental drilling needs and beyond.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Dormer's rotary milling cutters boast a versatile program catering to a wide spectrum of engineering materials and machining applications.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                      Dormer's threading range encompasses a comprehensive suite, supporting various tapping processes and primary tap geometries, ensuring versatility across threading requirements
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      General turning, parting-off, grooving, threading, and heavy turning, covering a wide range of machining needs with precision and versatility.
                      </p>
                    ) : null}
                    {indlpg === "mill" ? (
                      <p>
                       Offers MT-CVD and PVD coated grades catering to durability and high-performance needs in various milling applications.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                     Pramet tools support a wide array of thread types: full-profile threads (M, W, RD, API, TR, UN, ACME) and partial-profile inserts (60°), ensuring comprehensive compatibility and precision across industry standards.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                     Offers a wide selection of drill diameters, catering to various drilling needs, covering drilling depths ranging from 2D to 5D, ensuring adaptability across different projects.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                  Ensures stability and strength during machining processes, providing a reliable foundation for precision operations.
                      </p>
                    ) : null}

                   
                  </span>
                </div>
                {/* 2 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Unique step drills not only bore holes but also incorporate chamfering, streamlining the drilling process.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       Available in High-Speed Steel (HSS), High-Speed Cobalt (HSS-E & HSS-E PM), and Solid Carbide (HM), offering durability and precision for specific milling operations.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                         Offers an extensive array of taps including Straight Flute, Spiral Point, Spiral Flute, and Forming Taps, each specifically designed for different threading applications, ensuring precision and efficiency.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      At the first sale, cover the expenses for equipment (DPR plus cylinder), prevailing Non-domestic 5 Kg cylinder price, and administrative charges.
                      </p>
                    ) : null}

                    {indlpg === "mill" ? (
                      <p>
                        Finishing, roughing, and universal geometries tailored for precision, high material removal rates, and adaptability across milling tasks.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                      Provides indexable cutting inserts tailored for full profile threads, ensuring precision and accuracy in thread turning operations.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                     These versatile drills can be utilized as both rotary and stationary tools, providing flexibility in application and machining processes.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                      Pramet's tooling systems offer diverse solutions, shell mill holders for secure milling, versatile face/disc cutters, adaptable modular systems, and stable Weldon holders ensuring precision clamping.
                      </p>
                    ) : null}

                  </span>
                </div>
                {/* 3 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Providing chamfers with various point angles ensures precision in chamfering tasks, catering to diverse project requirements.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      The range comprises five fundamental shank cutter types, tailored to cover various milling operations and materials, ensuring adaptability and efficiency.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                   Specialized tap variants address specific threading challenges: interrupted threads, sequential operations, nut threading precision, combined drilling and tapping, and enhanced thread strength with wire inserts.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Ceramic for high-temperature resistance and wear, cermet for toughness and heat resistance, CBN for hard materials and high speeds, and diamond-grade inserts for exceptional hardness and precision machining.
                      </p>
                    ) : null}

                    {indlpg === "mill" ? (
                      <p>
                       Diverse milling designs cater comprehensively to various needs: roughing for efficient material removal, finishing for superior surface quality, and universal options for versatile application.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                     Covers a comprehensive range of thread profiles, accommodating different thread specifications and standards commonly used in various industries.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                     Featuring single-edge design, optimizing drilling efficiency while maintaining precision in hole creation.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                     Provides a wide range of holder types, including hydraulic chucks, collet chucks, shrinkfit holders, HSK (Hohl Schaft Konus), DIN (Deutsche Industrie Norm), and JIS (Japanese Industrial Standards) holders.
                      </p>
                    ) : null}

                  
                  </span>
                </div>
                {/*  4 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Available in High-Speed Steel (HSS), High-Speed Cobalt (M35/M42), and Solid Carbide (HM) variants, each tailored for specific drilling needs across different materials.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>                  
                        End mill varieties encompass precision (four-flute), contouring (ball-nosed), aggressive cutting (roughing), and specialized designs (single flute, corner radius, tapered, drill, aluminum-specific, variable helix, high-feed) for specific machining demands and materials.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                        Dormer's threading tools are engineered with precision, ensuring high performance, accuracy, and reliability in threading operations.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                       With inserts made from different materials, your suite of tools is designed to meet varied machining needs with precision and efficiency.
                      </p>
                    ) : null}

                    {indlpg === "mill" ? (
                      <p>
                        Offers varied lengths supporting different depths and sizes of cuts, ensuring flexibility in machining operations.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                       Offers flexibility with other alternatives, allowing customization in terms of thread profile, insert design, or grade, provided in the form of special products.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                     Ability to function outside the axis, allowing for off-centered drilling, facilitating adjustment of drilled hole dimensions within a range of -0.2 to +0.5 mm, ensuring precision in hole dimensions.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                     Offers necessary accessories to complement the tooling systems, ensuring optimal performance and efficiency in machining processes.
                      </p>
                    ) : null}

                  </span>
                </div>
                {/* 5 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                     Offering stub (short), jobber (standard), long series, and extra length options, accommodating drilling depths up to 20xD, ensuring adaptability for various project depths.
                      </p>
                    ) : null}
                    {indlpg === "bmcg" ? (
                      <p>
                        Includes T-slot cutters, woodruff cutters, dovetail cutters, and corner rounding cutters, catering to specific machining requirements, enhancing versatility.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                         Dormer's range addresses diverse and intricate threading demands across industries, offering specialized solutions for specific threading challenges.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                        MT-CVD and PVD coated inserts ensure durability and superior performance, enhancing tool life and machining efficiency.
                      </p>
                    ) : null}

                    {indlpg === "mill" ? (
                      <p>
                       Includes coatings to augment tool durability and performance, ensuring prolonged tool life and efficiency during milling operations.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                    Specialized inserts, tooling, and grades can be tailored to meet unique or specialized threading requirements, ensuring versatility in addressing specific customer needs.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                    Capable of performing without issues even under unstable conditions such as interrupted cuts, ensuring stability and reliability in drilling operations.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                      Ensures secure and powerful tool clamping, enhancing stability during machining operations.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/*  6 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Tailored to suit almost every rotary hole-making application, making these tools versatile and adaptable to a wide range of drilling tasks.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       Offer efficient and accurate threading solutions through milling operations, supporting thread production with precision.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                    Designed with quality materials and precise manufacturing, ensuring durability and efficiency in various threading processes.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p> 
                        The diversity in insert materials allows for precise and tailored machining solutions across different materials and machining requirements.
                      </p>
                    ) : null}

                    {indlpg === "mill" ? (
                      <p>
                        The program provides comprehensive tool support across diverse milling applications, ensuring a tool for every milling task.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                     Pramet's tools and inserts are engineered for precision and quality, delivering consistent and reliable results in thread turning operations.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                      The capability to adjust hole dimensions using off-centered tools adds a level of precision and flexibility, allowing for precise hole modifications within the specified range.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                     Provide precision clamping for smaller tools, ensuring accuracy and reliability in machining.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/* 7 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Dormer tools are designed and engineered to uphold precision, enhancing accuracy and reliability in drilling operations.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Dormer's milling cutters are engineered with precision to ensure high performance, accuracy, and reliability in machining operations.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                     The specialized taps optimize threading processes, enhancing productivity and delivering consistent, high-quality threads.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Your range caters to a broad range of machining needs, from standard turning to heavy-duty operations, providing adaptability and reliability.
                      </p>
                    ) : null}


                  {indlpg === "mill" ? (
                      <p>
                       Designed for efficiency and precision, enhancing productivity and achieving accurate milling results.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                     Designed to meet or exceed industry standards, ensuring compatibility and reliability across various threading applications and standards.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                      Suited for drilling depths from 2D to 5D, offering adaptability for various drilling depths without compromising performance.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                     Offer superior tool holding capabilities through thermal contraction, providing a strong and secure grip on tools.
                      </p>
                    ) : null}
                
                  </span>
                </div>
                {/* 8 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Being under the Dormer brand, these drilling tools are recognized for their quality, innovation, and performance, trusted by professionals worldwide.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                    Being part of the Dormer brand, these milling cutters are known for quality, innovation, and meeting the demands of professionals in various industries.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                       Being part of the Dormer brand, these threading tools are trusted by professionals, known for their innovation, quality, and ability to meet threading requirements across industries
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Renowned for their quality and performance, your indexable turning tools are trusted by professionals across diverse industries for their precision and efficiency in machining operations
                      </p>
                    ) : null}

                    {indlpg === "mill" ? (
                      <p>
                        Trusted by professionals across industries for their quality, versatility, and ability to meet diverse milling demands efficiently.
                      </p>
                    ) : null}

                    {indlpg === "thd" ? (
                      <p>
                     The availability of specialized products enables customization and adaptability, ensuring the tools meet the specific demands of different threading applications and industries.
                      </p>
                    ) : null}

                    {indlpg === "hm" ? (
                      <p>
                    Designed to deliver reliable performance across a range of drilling conditions, ensuring consistent results even in challenging or variable environments.
                      </p>
                    ) : null}

                    {indlpg === "ts" ? (
                      <p>
                     HSK, DIN, and JIS holders cater to industry standards, ensuring compatibility with a wide range of machining equipment and tooling.
                      </p>
                    ) : null}

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndCut;
