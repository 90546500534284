import React, { useEffect, useState } from 'react'
import Navbar from '../Home/Navbar'
import Welcome from '../Common/Welcome'
import SnHeader from '../Common/SnHeader'
import Nav from '../Common/Nav'
import Footer from '../Home/Footer'
import CnBanner from '../Common/CnBanner'
import ProductDetail from './ProductDetail'
import { useParams } from 'react-router-dom'
import ProductSlider from './ProductSlider'
import LoadPage from '../Pages/LoadPage'
import Gp from './Gp'
import ProductBtns from './ProductBtns'
import Lp from './Lp'
import Four from '../Pagenotfound'
import IndLpg from './IndLpg'
import OfficeEst from './OfficeEst'
import IndCut from './IndCut'

const Product = ({windowSize,color,setColor,load,setLoad}) => {
    const {id,pid,cat,cid} = useParams()
    const validPaths = ['Bio-Degradable',"GasPipeline",'LubricantsPacking','Industriallpg',"OfficeEssential","IndustrialCutting"]; 
    const BioIds =  ['1','2','3','4','5','6','7','8','9']
    const OEIds =  ['1','2','3','4','5','6']
    const ICoIds =  ['1','2','3','4','5','6','7','8',]
    const GpIds =  ['1','2','3','4','5']
    const IlIds =  ['1','2','3','4']
    const LpIds =  ['Rust-X','Fillezy']
    const cids = ['1',"2",'3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20']

    const [fp, setFp] = useState(false)
    const [lam, setLam] = useState(false)
    const [ag, setAg] = useState(false) 
    const [shp, setshp] = useState(false)
    const [ss, setSs] = useState(false)
    const [plf, setPlf] = useState(false)
    const [bc, setBc] = useState(false)
    const [cut, setCut] = useState(false) 
    const [cgb, setCbg] = useState(false)
    const [show, setShow] = useState(false)

    const showed = () => {
        if(window.scrollY >= 150){
          setShow(true)
        }else{
          setShow(false)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', showed)
  
        return () => window.removeEventListener('scroll',showed)
    })
    let image = {
        image: pid==="Bio-Degradable"?"/images/biobanner.webp":pid==="GasPipeline"?"/images/gasbanner.webp":pid==="IndustrialCutting"?"/images/indcutbanner.webp":
        pid==="OfficeEssential"?"/images/officebanner.webp":pid==="Industriallpg"?"/images/indlpgbanner.webp":""
      };
   
  return (
    <div>
        {validPaths.includes(pid)  && ((pid === "IndustrialCutting" && ICoIds.includes(id)) || (pid === "OfficeEssential" && OEIds.includes(id))|| (pid === "Bio-Degradable" && BioIds.includes(id)) || (pid === "Industriallpg" && IlIds.includes(id)) || (pid === "GasPipeline" && GpIds.includes(id)) || (pid === "LubricantsPacking" && LpIds.includes(id)))  && !load ? 
            <>
                {windowSize[0] > 1200 && show &&
                    <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
                }
                {windowSize[0] > 1200 && 
                <Welcome />
                }
            
            {windowSize[0] > 1390 && 
            <SnHeader /> 
            }
            {windowSize[0] < 1390 && 
            <Nav />
            }    
            <CnBanner windowSize={windowSize} title={pid==="Bio-Degradable"?"Bio Degradable ":pid==="GasPipeline"?"Gas Pipeline ":
            pid==="Industriallpg"?"Industrial LPG":pid==="OfficeEssential"?"Office Essential":
            pid==="IndustrialCutting"?"Industrial Cutting":""}

            
            // title={fp ? "Food Packaging​" : lam ?  "Lamination" : shp ? "Shopping / Carry Bags" : ag ? "Agriculture" :
            //                                         ss ? "Shrink & Stretch Films" :plf ? "Paper Like Films" : bc ? "Bottles & Containers" : cut ? "Cutlery" : cgb? "Compostable Garbage Bags" : ""}
             image={image.image}/>

            {
                validPaths.includes(pid) && pid === "Bio-Degradable" &&
                <>
                    <ProductDetail load={load} setLoad={setLoad}/>
                       {/* <ProductBtns />         */}
                    <ProductSlider pid={pid}/>                                 
                </>
            }
            {
                validPaths.includes(pid) && pid === "GasPipeline" &&
                <>
                    <Gp load={load} setLoad={setLoad}/>
                    <ProductSlider pid={pid}/>                                      
                </>
            }
            {
                validPaths.includes(pid) && pid === "Industriallpg" &&
                <>
                    <IndLpg load={load} setLoad={setLoad}/>
                    <ProductSlider pid={pid}/>                                      
                </>
            }
            {
                validPaths.includes(pid) && pid === "OfficeEssential" &&
                <>
                    <OfficeEst load={load} setLoad={setLoad}/>
                    <ProductSlider pid={pid}/>                                      
                </>
            }
            {
                validPaths.includes(pid) && pid === "IndustrialCutting" &&
                <>
                    <IndCut load={load} setLoad={setLoad}/>
                    <ProductSlider pid={pid}/>                                      
                </>
            }
            {
                validPaths.includes(pid) && pid === "LubricantsPacking" && cat==="VCIPackaging" && cids.includes(cid) &&
                <>
                    <Lp load={load} setLoad={setLoad}/>
                    <ProductSlider pid={pid} cat={cat}/>                                      
                </>
            }
            {/* {
                validPaths.includes(pid) &&
                <>
                    Error
                </>

            } */}
            <Footer color={color}/>
            </> :
             !(pid === "Bio-Degradable" && BioIds.includes(id))  ? 
             <>
                <Four />
             </> :
              !(pid === "GasPipeline" && GpIds.includes(id))  ? 
              <>
                 <Four />
              </> :
            !validPaths.includes(pid)  && !load ?
            <>
            <Four />
            </> :   null
        }
    </div>
  )
}

export default Product