import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Cutting = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }

    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "Mineral"  : show === "vcp2" ? "Semi-Synthetic"  : 
                               show === "vcp3" ? "Synthetic"  
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/cutting1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/cutting2.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/cutting3.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Water Soluble Coolants -WS50’s main function is to remove heat from the cutting tool, chip and work piece. The cutting oils are manufactured from highly refined Group 2 base oils that are low in Sulphur content. Group II base oils are defined as being more than 90 percent saturates, less than 0.03 percent sulfur and with a viscosity index of 80 to 120. They are often manufactured by hydrocracking, which is a more complex process than what is used for Group I base oils.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>The Perfect Blend of Nature and Innovation! Our semi-synthetic products represent the ideal fusion of natural ingredients and cutting-edge technology. Engineered to provide superior performance, these formulations strike the perfect balance between organic elements and synthetic precision. Whether it's in automotive lubricants, skincare solutions, or industrial applications, our semi-synthetic products offer enhanced efficiency and reliability. Experience the best of both worlds with our semi-synthetic line, where nature's purity meets the power of innovation. Elevate your experience with products that redefine excellence and sustainability.</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>AmpliFi 40000, 50000 and 70000 premium are an ester based cutting fluid having no mineral oil content and a much higher ester concentration for extreme performance. The cutting fluid is manufactured using specialty esters that provide excellent tool life, excellent extreme pressure performance, superior wettability of the parts, improved cleanliness of the machines & parts, infinite sump life and longer corrosion prevention after processing</p> : 
                                null}
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Mineral-based cutting oils provide effective lubrication between the cutting tool and the workpiece, reducing friction and wear. This lubrication is crucial for extending the tool life and ensuring a smooth machining process.</p>: null}
                                    {show === "vcp2"?<p>Semi-synthetic cutting fluids combine mineral oil, which is derived from petroleum, with synthetic lubricants and additives. This blend optimizes both cost and performance.</p>: null}
                                    {show === "vcp3"?<p> Synthetic cutting fluids are chemically engineered lubricants, precisely formulated for specific machining applications. They are created through complex chemical processes to meet specific performance requirements.</p>: null}
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These oils have excellent heat dissipation properties, preventing overheating of the cutting tool and workpiece. By cooling the machining zone, they maintain dimensional accuracy and prevent thermal damage to the material being processed.</p>: null}
                                    {show === "vcp2"?<p>The synthetic component in semi-synthetic fluids provides improved lubrication compared to pure mineral oil-based fluids. This enhanced lubrication reduces friction between the cutting tool and the workpiece, extending tool life.</p>: null}
                                    {show === "vcp3"?<p>Synthetic fluids provide excellent lubrication between the cutting tool and the workpiece. This high lubrication reduces friction, heat, and tool wear, extending the life of cutting tools and enhancing machining precision.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Cutting oils help in the efficient removal of metal chips or swarf from the cutting area, preventing them from clogging the tool or interfering with the machining process.</p>: null}
                                    {show === "vcp2"?<p>Semi-synthetic fluids have effective cooling properties, dissipating heat generated during machining processes. Proper cooling is essential to prevent overheating and maintain dimensional accuracy of the workpiece.</p>: null}
                                    {show === "vcp3"?<p>They have superior heat dissipation properties, effectively cooling the cutting zone. This cooling capability prevents thermal damage to the workpiece and ensures dimensional accuracy, even during high-speed machining.</p>: null}
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Mineral-based cutting oils contribute to achieving a superior surface finish on the workpiece. The lubrication they provide ensures a clean, smooth cut, reducing the likelihood of surface imperfections.</p>: null}
                                    {show === "vcp2"?<p>They contain additives that offer protection against rust and corrosion, ensuring both the workpiece and the machinery remain in good condition, especially when working with ferrous metals.</p>: null}
                                    {show === "vcp3"?<p>Synthetic fluids offer exceptional stability under extreme temperatures and pressures. They maintain their properties during prolonged and demanding machining operations, ensuring consistent performance.</p>: null}
                                  
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These oils contain corrosion inhibitors that protect both the workpiece and the cutting tool from rust and corrosion. This is particularly important when machining ferrous metals.</p>: null}
                                    {show === "vcp2"?<p> Semi-synthetic fluids are more stable under high temperatures and pressures compared to pure mineral oil-based fluids. This stability ensures consistent performance during prolonged machining operations.</p>: null}
                                    {show === "vcp3"?<p>Synthetic fluids generally produce low foam levels, which is crucial in high-speed machining operations. Low foam minimizes interference with machining processes, providing uninterrupted cutting.</p>: null}
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Mineral-based cutting oils are compatible with a wide range of metals, including steel, aluminum, and copper alloys. They can be used in various machining operations, such as turning, milling, drilling, and grinding.</p>: null}
                                    {show === "vcp2"?<p>These fluids are versatile and can be used for a variety of machining operations, including turning, milling, drilling, and grinding. They are compatible with different metals and alloys.</p>: null}
                                    {show === "vcp3"?<p>Synthetic cutting fluids contain additives that offer excellent protection against rust and corrosion. This protection is vital, especially when machining ferrous metals, preventing damage to both the workpiece and the machinery.</p>: null}

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These oils offer good stability under high temperatures and pressures, maintaining their properties during prolonged machining operations. This stability ensures consistent performance throughout the process.</p>: null}
                                    {show === "vcp2"?<p>Semi-synthetic fluids often have lower environmental impact than purely synthetic fluids because they contain a blend of mineral and synthetic components, striking a balance between performance and environmental concerns.</p>: null}
                                    {show === "vcp3"?<p> Some synthetic cutting fluids are formulated to be biodegradable, reducing environmental impact. These fluids break down naturally over time, promoting eco-friendly machining practices.</p>: null}
                                  
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Mineral-based cutting oils are often more cost-effective than synthetic or semi-synthetic alternatives, making them a popular choice for many industrial applications, especially in high-volume machining operations.</p>: null}
                                    {show === "vcp2"?<p> Many semi-synthetic fluids are formulated to be operator-friendly, offering low-odor and low-foaming properties, creating a better working environment for machine operators.</p>: null}
                                    {show === "vcp3"?<p>Synthetic cutting fluids come in various specialized formulations, such as micro-emulsions, macro-emulsions, and neat oils. Each type is tailored to specific machining processes and materials, ensuring optimal performance.</p>: null}
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Cutting