import React, { useEffect, useState } from 'react';
import '../css/Productdetails.css';
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Spinner from 'react-bootstrap/Spinner';
import { Link, useParams } from 'react-router-dom';
import CommonBtns from './CommonBtns';


function Fillezy({setLoad}) {
    const [openCategory, setOpenCategory] = useState(null);

    
    

    const {id,pid,cid,cat} = useParams()
    const [show , setShow] = useState("")

    
    useEffect(() => {
        //VCIPackaging
        if(cid==='1'){
            setShow("fz1")
          }
        if(cid==='2'){
            setShow("fz2")
          }
        if(cid==='3'){
            setShow("fz3")
          }
        if(cid==='4'){
            setShow("fz4")
          }
        if(cid==='5'){
            setShow("fz5")
          }
        if(cid==='6'){
            setShow("fz6")
          }
        if(cid==='7'){
            setShow("fz7")
          }
        if(cid==='8'){
            setShow("fz8")
          }
        if(cid==='9'){
            setShow("fz9")
          }
        if(cid==='10'){
            setShow("fz10")
          }
        if(cid==='11'){
            setShow("fz11")
          }
        if(cid==='12'){
            setShow("fz12")
          }
   
    },[id,cid])

    const handleLoad = () => {
        setLoad(true); 

        // setTimeout(() => {
        //     setShowLoader(false);
        //   }, 1000);
    }

    const [isLoading, setIsLoading] = useState(false);
   

  
    return (
    <>
            
            <div className='container'>
                <div className={isLoading ? "opaque" :  "opaque1"}>
                <div className='row overall'>
                    <div className='col-md-4' >
                    <CommonBtns id={id} pid={pid} setLoad={setLoad}/>
                    </div> 
                    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "fz1" ? "Bio Aer"  : show === "fz2" ? "Fillexy Air Bubbles"  : 
                               show === "fz3" ? "Fillezy Air Cushion"  : show === "fz4" ? "Fillezy Air Quilts"  :
                               show === "fz5" ? "Fillezy Air Tubes  "  : show === "fz6" ? "Double Cushion"  :
                               show === "fz7" ? "Fillezy Handling"  : show === "fz8" ? "Fillezy Hexa Paper"  :
                               show === "fz9" ? "Fillezy Rapid"  : show === "fz10" ? "Paper Aer"  :
                               show === "fz11" ? "Paper-Smart Machine"  : show === "fz12" ? "Paper-Smart Pads"  
                               :""}


                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "fz1" ?
                                <>
                                    <img src='/images/fillezy1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "fz2" ?
                                <>
                                    <img src='/images/fillezy2.webp' alt='dummy'/> 
                                </>:
                               show === "fz3" ?
                                <>
                                    <img src='/images/fillezy3.webp' alt='dummy'/> 
                                </>:
                               show === "fz4" ?
                                <>
                                    <img src='/images/fillezy4.webp' alt='dummy'/> 
                                </>:
                               show === "fz5" ?
                                <>
                                    <img src='/images/fillezy5.webp' alt='dummy'/> 
                                </>:
                               show === "fz6" ?
                                <>
                                    <img src='/images/fillezy6.webp' alt='dummy'/> 
                                </>:
                               show === "fz7" ?
                                <>
                                    <img src='/images/fillezy7.webp' alt='dummy'/> 
                                </>:
                               show === "fz8" ?
                                <>
                                    <img src='/images/fillezy8.webp' alt='dummy'/> 
                                </>:
                               show === "fz9" ?
                                <>
                                    <img src='/images/fillezy9.webp' alt='dummy'/> 
                                </>:
                               show === "fz10" ?
                                <>
                                    <img src='/images/fillezy10.webp' alt='dummy'/> 
                                </>:
                               show === "fz11" ?
                                <>
                                    <img src='/images/fillezy11.webp' alt='dummy'/> 
                                </>:
                               show === "fz12" ?
                                <>
                                    <img src='/images/fillezy12.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "fz1"? <p className='text-muted firstparavalue'>The most revolutionary packaging that is sustainable made of 100% compostable materials that be used to fill void is now being manufactured at Fillezy’s production facilities. The product is made by extruding film made from corn and cassava starch and other compostable materials and is the only and right solutions to ensure we do not abuse the environment. Fillezy is the only manufacturer in the market which can supply the 100% biologically degradable Bio Film made from starch cheaply, even in small quantities. Our group company Hi Tech International manufacturer the BioDegradable Polymer which is further extruded and produced as inflatables in our Fillezy division.</p> : 
                                null}
                                {show === "fz2"? <p className='text-muted firstparavalue'>Air bubbles bag has been the best economical choice for transit packaging needs. Fillezy’s air bubble bag is a low-cost general purpose packaging material. It is flexible in its use for any small, medium, large, fragile or heavy products. Inserted between the palletized goods, they immobilize goods to prevent side-to-side and front-to-back shifting, tumbling, and abrasion so goods arrive safely and are intact. It can be ordered in large volumes in low prices. These bubble bags keep the product safe from environmental hazards, water, scratches, shocks, damage or breakage.</p> : 
                                null}
                                {show === "fz3"? <p className='text-muted firstparavalue'>Air cushions by Fillezy are created using an air-filled nature new packaging system. Airbags that we offer are provided with the fastest-filling valve and auto-shut-off that ensures the safety of both cargo and person using it. Our range of air cushions consists of a variety of film types and cushioning shapes, ensuring all your products are perfectly protected. All films are produced in our own production facilities following modern processes that require very little raw material, and offer exceptional mechanical characteristics. These mechanical characteristics offer no leakage of air, is water resistant and has great shock absorbing capacity. These air cushions are reusable, recyclable, and eco-friendly.</p> : 
                                null}
                                {show === "fz4"? <p className='text-muted firstparavalue'>Fillezy Air-Quilts is also the solution for business where ever a high quality air tubes system is needed. We made high quality of Air Quilts with high strength.Air Quilt that we offer are provided with fast-filling and auto-shut-off that ensures safety of both cargo and user. Inserted between the palletized goods, they immobilize loads to prevent side-to-side and front-to-back shifting, tumbling and abrasion so loads arrive safely and intact.Air Quilt are excellently suited for quickly and economically filling empty space in one simple step. Equipped with this protective packaging, the goods are fixed in the package preventing migration. Our void fill air cushion application is an ideal solution for pre-packaged products with minimal risk of breakages.</p> : 
                                null}
                                {show === "fz5"? <p className='text-muted firstparavalue'>Fillezy Air Tubes consist of two connected tubes that allows for maximum flexibility Contains air transfer technology that keeps products safe from shocks. Airbags that we offer are provided with the fastest-filling valve and auto-shut-off that ensures the safety of both cargo and user. Inserted between the palletized goods, they immobilize loads to prevent side-to-side and front-to-back shifting, tumbling, and abrasion so loads arrive safely and intact.</p> : 
                                null}
                                {show === "fz6"? <p className='text-muted firstparavalue'>Fillezy one is the best solution for business where ever a high quality air cushion system is needed. We made high quality of Air Pillow & air pouches with high strength.
Air bags that we offer are provided with fastest-filling valve and auto-shut-off that ensures safety of both cargo and user. Inserted between the palletized goods, they immobilize loads to prevent side-to-side and front-to-back shifting, tumbling and abrasion so loads arrive safely and intact.Air cushions are excellently suited for quickly and economically filling empty space in one simple step. Equipped with this protective packaging, the goods are fixed in the package preventing migration. Our void fill air cushion application is an ideal solution for pre-packaged products with minimal risk of breakages.</p> : 
                                null}
                                {show === "fz7"? <p className='text-muted firstparavalue'>No matter which industry you are in, we can provide you with integrated handling systems and storage solutions at Fillezy will supply the Fillezy One machines at the best price possible. We can then supply film through our specialist partners significantly cheaper than all our competitors. We work with local manufacturers in each region supplying the technology and expertise required to produce a quality product that adheres to the standards and specifications of the Fillezy brand.</p> : 
                                null}
                                {show === "fz8"? <p className='text-muted firstparavalue'>Protect your valuable items with Fillezy’s innovative wrapping solution. Our patented process transforms eco-friendly paper into a strong and resilient packaging material that shields against scratches, surface abrasions, and minor handling mishaps. With our 3D honeycomb structure, your items stay securely wrapped without the hassle of adhesive tapes or cutting. Keep your packages safe and the environment happy with Fillezy systems.With Fillezy, you not only safeguard your products but also contribute to a greener future. Choose the ultimate eco-conscious choice for reliable, sustainable, and cost-effective packaging. Wrap your valuable items securely and responsibly with Fillezy systems today.</p> : 
                                null}
                                {show === "fz9"? <p className='text-muted firstparavalue'>The Fillezy Rapid has been developed by our dedicated R&D team. We believe it is the complete machine as it inflates all films from 200mm x 120mm cushions up to 700mm wide quilt and bubble films. Air Bubble Sheet Machine is capable of running cushions at speeds of up to 37 meters per minute and still weighs less than 10KG. It is portable, practical and programmable with up to 10 different film type settings available to suit the customers requirements. The Rapid has few wear parts and is extremely easy to maintain.
Rapid is a Revolutionary Universal & versatile device capable of producing different variants of Air Cushioning products. It can produce seal and fill air in a range of films types and paper cushions.</p> : 
                                null}
                                {show === "fz10"? <p className='text-muted firstparavalue'>Go Environmentally Friendly without adding additional cost to your packaging. With PAPER AER you get a Bio-degradbale solution with added protection for your products. The Eco-Fills are made of flexible recycled paper to fill the voids and provide cushioning and ensuring you upto Zero Damages. They are made of unique FSC compliant papers that are compostable and fully degradable. The sealing layer on the inner is also made of bio plastics that degrade upon exposure and can be also be recycled as pulp and again put to use.

When it comes to protecting fragile or delicate items in shipping and storage environments, paper certainly does the trick. It’s also made to be non-abrasive, so you won’t have to worry about it scratching surfaces or leaving marks on your product.</p> : 
                                null}
                                {show === "fz11"? <p className='text-muted firstparavalue'>Fillezy paper void fill machine has two series cushioning wrapping and it is designed for packaging material that eliminates damage during transport. Paper Smart machine makes paper pads for high commercial production of cushioning applications. The machine is capable of manufacturing paper pads at your site without having to transport or store void filling materials. The production suffices all the needs from the cushioning, packing, positioning, and filling. It is ideal for packing applications where there is a need for heavy-duty cushioning combined with environmental protection.</p> : 
                                null}
                                {show === "fz12"? <p className='text-muted firstparavalue'>Goods protection has been a matter of concern for all packaging companies. Fillezy offers a resourceful solution of paper smart pads for product packaging and its protection. These paper smart pads are safe, reliable, durable and unharmed during transportation. The crumpled paper pads fill the maximum void in a packaging box, causing less or no movement of product inside the box used for packaging. The top-notch paper quality of padding has tremendous shock-absorbing capacity to protect products from any kind of damage during shipping. So even if the products are man-handled during shipping, these paper pads offer optimum protection to even fragile products.</p> : 
                                null}
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "fz1" ? <p className='text-muted firstparavalue'>Our biodegradable air cushions not only provide superior protection for your items during transit but also contribute to a greener planet. Made from eco-friendly materials, our cushions ensure your products reach their destination safely while reducing your environmental footprint. Choose Bio Air Cushions for secure, eco-conscious packaging solutions
                        </p> : null}
                        { show === "fz2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz4" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz5" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz6" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz7" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz8" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz9" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz10" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz11" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "fz12" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}

                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "fz1"?<p> Bio air cushions are made from biodegradable materials, reducing their environmental impact compared to traditional plastic packaging.</p>: null}
                                    {show === "fz2"?<p> Fillexy Air Bubbles provide excellent cushioning and protection for delicate and fragile items during shipping and handling, preventing damage.</p>: null}
                                    {show === "fz3"?<p> Fillexy Air Cushion provides superior cushioning and protection, ensuring fragile items remain intact during shipping and handling.</p>: null}
                                    {show === "fz4"?<p> FILLEZY AIR QUILTS offer advanced cushioning, protecting delicate items with their quilted design, ensuring safe transit and reducing breakage risks.</p>: null}
                                    {show === "fz5"?<p> Double Cushion packaging offers an extra layer of protection, ensuring products are safeguarded from shocks, bumps, and vibrations during transit.</p>: null}
                                    {show === "fz6"?<p>Fillezy Handling provides professional expertise in the efficient and safe handling of various goods and products, ensuring secure transit.</p>: null}
                                    {show === "fz7"?<p>Fillezy Handling provides professional expertise in the efficient and safe handling of various goods and products, ensuring secure transit.</p>: null}
                                    {show === "fz8"?<p>Fillezy Hexa Paper is an eco-friendly packaging solution made from sustainable materials, contributing to environmental conservation efforts.</p>: null}
                                    {show === "fz9"?<p>Fillezy Rapid offers quick and efficient solutions, ensuring fast and streamlined processes for various business needs.</p>: null}
                                    {show === "fz10"?<p>Paper air packaging utilizes recyclable and biodegradable materials, reducing the environmental impact compared to traditional plastic packaging.</p>: null}
                                    {show === "fz11"?<p>The Paper-Smart Machine boasts cutting-edge automation technology, streamlining various paper-related tasks and workflows for businesses.</p>: null}
                                    {show === "fz12"?<p>Paper-Smart Pads offer a digital note-taking experience, allowing users to write, draw, and annotate directly on the pad's surface with a stylus or digital pen.</p>: null}
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p>These cushions break down naturally over time, minimizing their presence in landfills and oceans, making them a sustainable choice.</p>: null}
                                    {show === "fz2"?<p>They are versatile and can be used for various applications, including packaging electronics, glassware, artwork, and other sensitive products.</p>: null}
                                    {show === "fz3"?<p> These cushions are highly versatile, fitting various product shapes and sizes, making them suitable for a wide range of items, from electronics to glassware.</p>: null}
                                    {show === "fz4"?<p>These quilts are adaptable, suitable for a wide array of products including electronics, ceramics, and artworks due to their customizable and flexible nature.</p>: null}
                                    {show === "fz5"?<p>These tubes are versatile and can be used for a wide range of products, including glassware, electronics, and artworks, due to their adaptable nature.</p>: null}
                                    {show === "fz6"?<p>It is versatile and suitable for various items, ranging from electronics to fragile glassware, providing adaptable cushioning for different products.</p>: null}
                                    {show === "fz7"?<p>They offer a wide range of handling services, including loading, unloading, packaging, and storage, catering to diverse business needs.</p>: null}
                                    {show === "fz8"?<p>The unique hexagonal design of the paper provides excellent cushioning and protection, ensuring products are secure during transportation.</p>: null}
                                    {show === "fz9"?<p>Fillezy Rapid specializes in rapid delivery services, ensuring prompt and on-time deliveries of goods and products.</p>: null}
                                    {show === "fz10"?<p>Paper air packaging provides excellent cushioning and protection for fragile items during shipping, ensuring products reach customers undamaged.</p>: null}
                                    {show === "fz11"?<p> This machine offers efficient document management solutions, enabling organizations to digitize, organize, and retrieve paper documents effortlessly.</p>: null}
                                    {show === "fz12"?<p>These pads are designed for multiple uses, allowing users to erase or clear the content easily, promoting a sustainable and eco-friendly alternative to traditional paper notebooks.</p>: null}
                                    


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p>Bio air cushions provide excellent cushioning, protecting fragile items during shipping and transportation, reducing the risk of damage.</p>: null}
                                    {show === "fz2"?<p>The air-filled bubbles absorb shocks and impacts during transit, ensuring items reach their destination in optimal condition.</p>: null}
                                    {show === "fz3"?<p>The air-filled cushions absorb shocks and impacts, safeguarding items from damage and reducing the risk of breakage.</p>: null}
                                    {show === "fz4"?<p>The quilted layers provide exceptional shock absorption, safeguarding items from impacts and vibrations during shipping, guaranteeing they arrive intact.</p>: null}
                                    {show === "fz5"?<p>FILLEZY AIR TUBES absorb shocks effectively, ensuring that items remain intact and undamaged during transit.</p>: null}
                                    {show === "fz6"?<p> The dual cushioning layers absorb impacts effectively, minimizing the risk of breakage or damage to delicate items during transportation.
</p>: null}
                                    {show === "fz7"?<p>Fillezy Handling employs skilled and trained personnel who are proficient in handling different types of items, from fragile objects to heavy machinery.</p>: null}
                                    {show === "fz8"?<p>Fillezy Hexa Paper is versatile and suitable for various products, offering adaptable cushioning for fragile items, electronics, and more.</p>: null}
                                    {show === "fz9"?<p>They provide express handling services, ensuring swift and careful management of items during loading, unloading, and transportation.</p>: null}
                                    {show === "fz10"?<p>It can be customized to fit various product shapes and sizes, offering a versatile solution for different items, from electronics to glassware.</p>: null}
                                    {show === "fz11"?<p>Equipped with advanced OCR capabilities, it can convert scanned documents into editable and searchable text, enhancing document accessibility and searchability.</p>: null}
                                    {show === "fz12"?<p>Paper-Smart Pads seamlessly integrate with cloud services, enabling users to automatically save and synchronize their notes and sketches, ensuring data accessibility across devices.</p>: null}
                                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p>They are adaptable and can be used to protect various products, including electronics, glassware, and delicate items.</p>: null}
                                    {show === "fz2"?<p>Being lightweight, Fillexy Air Bubbles reduce shipping costs and are an economical choice for protective packaging.</p>: null}
                                    {show === "fz3"?<p> Being lightweight, Fillexy Air Cushion reduces shipping costs and minimizes the overall weight of packages, making them easier to handle.</p>: null}
                                    {show === "fz4"?<p> FILLEZY AIR QUILTS are lightweight, cutting down shipping costs and making handling easier, without compromising on protective qualities.</p>: null}
                                    {show === "fz5"?<p> Available in various sizes, FILLEZY AIR TUBES can be customized to fit different products, providing tailored protection.</p>: null}
                                    {show === "fz6"?<p>Double Cushion packaging can be customized to fit specific product dimensions, providing a snug and secure fit, further enhancing the protection offered.</p>: null}
                                    {show === "fz7"?<p>They utilize specialized handling equipment and tools, ensuring the safe movement and transportation of goods, even in challenging environments.</p>: null}
                                    {show === "fz8"?<p>Being biodegradable, Fillezy Hexa Paper breaks down naturally, reducing its environmental impact and promoting a greener approach to packaging.</p>: null}
                                    {show === "fz9"?<p>Fillezy Rapid operates round the clock, providing services at any hour to meet urgent requirements and time-sensitive shipments.</p>: null}
                                    {show === "fz10"?<p>Being lightweight, paper air packaging reduces shipping costs and minimizes the overall weight of packages, making them easier to handle.</p>: null}
                                    {show === "fz11"?<p>Paper-Smart Machine ensures data security through encryption, user authentication, and secure cloud integration, safeguarding sensitive information.</p>: null}
                                    {show === "fz12"?<p> Ideal for students and educators, these pads facilitate interactive learning experiences, allowing teachers to illustrate concepts in real-time and students to engage actively during lessons.</p>: null}
                                    
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p>Bio air cushions are cost-effective as they are lightweight and easy to produce, saving on shipping costs and materials.</p>: null}
                                    {show === "fz2"?<p>These bubbles are compact and can be inflated as needed, saving storage space when not in use and inflating to required size when ready for use.</p>: null}
                                    {show === "fz3"?<p> Fillexy Air Cushion is space-efficient; they can be stored in a deflated state, saving storage space, and inflated as needed for immediate use.</p>: null}
                                    {show === "fz4"?<p>They can be stored in a compressed state, optimizing warehouse space, and inflated as needed, ensuring efficient use of storage areas.</p>: null}
                                    {show === "fz5"?<p> Being lightweight, these tubes reduce shipping costs and minimize the overall weight of packages, making them easier to handle.</p>: null}
                                    {show === "fz6"?<p>By providing an additional cushioning layer, it significantly reduces the likelihood of products being damaged during handling, ensuring they reach customers in pristine condition.</p>: null}
                                    {show === "fz7"?<p> Fillezy Handling tailors its services to meet specific client requirements, providing personalized solutions for unique handling needs.</p>: null}
                                    {show === "fz8"?<p>The thickness of Fillezy Hexa Paper can be customized to fit specific product requirements, offering tailored protection for different items.</p>: null}
                                    {show === "fz9"?<p>Tailored to specific needs, Fillezy Rapid offers customizable speed options, allowing businesses to choose the delivery pace that suits their demands.</p>: null}
                                    {show === "fz10"?<p>Paper air packaging is easy to dispose of and can be recycled, promoting responsible waste management practices.</p>: null}
                                    {show === "fz11"?<p>Designed with eco-conscious features, it encourages paperless operations, reducing paper consumption and promoting sustainable practices.</p>: null}
                                    {show === "fz12"?<p>Suited for professionals, Paper-Smart Pads provide a convenient platform for taking meeting notes, brainstorming ideas, and sketching diagrams, enhancing productivity and organization.</p>: null}
                                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p> Some bio air cushions are reusable, allowing customers to use them for multiple shipments, reducing overall packaging waste.</p>: null}
                                    {show === "fz2"?<p>Some Fillexy Air Bubbles are reusable, allowing for multiple uses. Additionally, they are often made from recyclable materials, promoting environmental sustainability.</p>: null}
                                    {show === "fz3"?<p>Many Fillexy Air Cushion products are eco-friendly, made from recyclable materials, and contribute to reducing the environmental impact of packaging waste.</p>: null}
                                    {show === "fz4"?<p>Many FILLEZY AIR QUILTS are eco-friendly, made from recyclable materials, aligning with sustainability goals and reducing the environmental footprint.</p>: null}
                                    {show === "fz5"?<p>: FILLEZY AIR TUBES can be deflated when not in use, saving storage space, and inflated as needed for immediate use, optimizing warehouse space.</p>: null}
                                    {show === "fz6"?<p> Despite the dual layers, Double Cushion packaging is designed to be lightweight, minimizing the overall weight of packages and reducing shipping costs.</p>: null}
                                    {show === "fz7"?<p> They optimize logistics processes, enhancing supply chain efficiency, and minimizing downtime, contributing to improved overall business operations.</p>: null}
                                    {show === "fz8"?<p> Fillezy Hexa Paper is lightweight, minimizing the overall weight of packages and reducing shipping costs while maintaining protective qualities.</p>: null}
                                    {show === "fz9"?<p>Fillezy Rapid optimizes logistics and supply chain processes, ensuring efficient movement of goods and minimizing transit times.</p>: null}
                                    {show === "fz10"?<p>It can be stored in a flat, deflated state, saving storage space, and inflated as needed for immediate use, optimizing warehouse space.</p>: null}
                                    {show === "fz11"?<p>Featuring an intuitive user interface, the machine is easy to operate, allowing users to scan, store, and manage documents with minimal training.</p>: null}
                                    {show === "fz12"?<p>Users can choose from a variety of digital templates, such as lined pages, grids, or planners, tailoring the pad to specific needs, whether for writing, designing, or planning tasks.</p>: null}
                                    
                                    
                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p> They come in various sizes and shapes, offering customization options to fit different packaging needs and product types.</p>: null}
                                    {show === "fz2"?<p>Fillexy Air Bubbles are user-friendly, easy to inflate, and can be quickly inserted into packages, streamlining the packing process.</p>: null}
                                    {show === "fz3"?<p> These cushions are designed for user convenience, easily inflated and inserted into packages, streamlining the packaging process and enhancing operational efficiency.</p>: null}
                                    {show === "fz4"?<p>Designed for ease of use, FILLEZY AIR QUILTS are quick to inflate and insert into packages, simplifying the packing process and enhancing productivity.</p>: null}
                                    {show === "fz5"?<p>Many FILLEZY AIR TUBES are made from recyclable materials, promoting eco-friendly practices and reducing environmental impact.</p>: null}
                                    {show === "fz6"?<p>Designed for easy handling, FILLEZY AIR TUBES are quick to inflate and insert into packages, simplifying the packaging process and increasing operational efficiency.</p>: null}
                                    {show === "fz7"?<p> Many Double Cushion products are eco-friendly, utilizing recyclable materials and promoting sustainable packaging practices</p>: null}
                                    {show === "fz8"?<p>Opting for Fillezy Hexa Paper demonstrates a commitment to sustainable packaging practices, aligning with eco-conscious business strategies and consumer preferences.</p>: null}
                                    {show === "fz9"?<p> They offer real-time tracking services, allowing clients to monitor their shipments in real-time, enhancing transparency and peace of mind.</p>: null}
                                    {show === "fz10"?<p>Designed for easy handling, paper air packaging is quick to inflate and insert into packages, simplifying the packing process and increasing operational efficiency.</p>: null}
                                    {show === "fz11"?<p>Seamlessly integrates with existing software systems, enabling smooth data flow between different departments and enhancing overall business efficiency</p>: null}
                                    {show === "fz12"?<p>Paper-Smart Pads support real-time collaboration, enabling multiple users to work on the same digital canvas simultaneously, fostering teamwork and creative collaboration.</p>: null}
                                    
                                    
                                   


                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "fz1"?<p>Choosing bio air cushions demonstrates a commitment to environmentally responsible packaging practices, aligning with eco-conscious business strategies.</p>: null}
                                    {show === "fz2"?<p>Choosing Fillexy Air Bubbles demonstrates a commitment to eco-friendly packaging solutions, aligning with sustainable business practices.</p>: null}
                                    {show === "fz3"?<p>By choosing Fillexy Air Cushion, businesses demonstrate a commitment to high-quality packaging, ensuring products reach customers in perfect condition, enhancing brand reputation and customer satisfaction.</p>: null}
                                    {show === "fz4"?<p>Designed for easy handling, FILLEZY AIR TUBES are quick to inflate and insert into packages, simplifying the packaging process and increasing operational efficiency.    </p>: null}
                                    {show === "fz5"?<p> Designed for easy use, Double Cushion packaging is quick to deploy, simplifying the packing process and increasing operational efficiency.</p>: null}
                                    {show === "fz6"?<p>Designed for easy use, Double Cushion packaging is quick to deploy, simplifying the packing process and increasing operational efficiency.</p>: null}
                                    {show === "fz7"?<p>Known for their reliability, Fillezy Handling delivers services on time, ensuring timely deliveries and meeting client deadlines consistently.</p>: null}
                                    {show === "fz8"?<p>Designed for user convenience, Fillezy Hexa Paper is easy to handle, quick to deploy, and simplifies the packaging process, enhancing operational efficiency.</p>: null}
                                    {show === "fz9"?<p> Fillezy Rapid is known for its reliability, guaranteeing dependable and timely services, fostering trust and long-term partnerships with clients.</p>: null}
                                    {show === "fz10"?<p> Paper air packaging can be customized with branding, logos, or promotional messages, enhancing the unboxing experience and reinforcing brand identity.</p>: null}
                                    {show === "fz11"?<p>By reducing paper usage, improving productivity, and minimizing manual errors, the Paper-Smart Machine offers a cost-effective solution for businesses seeking streamlined document management processes.</p>: null}
                                    {show === "fz12"?<p>Equipped with encryption and biometric authentication features, Paper-Smart Pads ensure the security of sensitive notes and sketches, providing peace of mind to users regarding their data privacy.</p>: null}
                                    
                                    </span>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </div>
    

    </>
      
    )
}

export default Fillezy;