import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Desiccants = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }


    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "Zorbit Clay"  : show === "vcp2" ? "Zorbit Gel"   
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/desiccants1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/desiccants2.webp' alt='dummy'/> 
                                </>:
                               
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Unleash Your Creativity with Limitless Possibilities! Dive into the world of endless imagination with Zorbit Clay, the ultimate medium for artists, crafters, and sculptors. Our premium, easy-to-mold clay opens the door to creativity, allowing you to bring your ideas to life with unmatched detail and precision. Whether you're crafting intricate sculptures, unique jewelry, or imaginative figurines, Zorbit Clay offers exceptional pliability and smooth texture, making it perfect for artists of all levels. Embrace the joy of sculpting and let your creativity soar with Zorbit Clay. Explore a universe of possibilities and transform your visions into tangible masterpieces</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Where Precision Meets Creativity! Experience the magic of Zorbit Gel, a revolutionary crafting companion that enhances precision and unleashes creativity. Our advanced gel formula provides unmatched control, allowing artists, designers, and DIY enthusiasts to achieve intricate details with ease. Whether you're working on delicate crafts, precise drawings, or intricate designs, Zorbit Gel ensures smooth application and vibrant results. Dive into a world of limitless possibilities, where your creativity knows no bounds. Elevate your projects with Zorbit Gel – the perfect blend of innovation and artistic freedom. Unleash your imagination and let your creations shine!</p> : 
                                null}
                                
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>ZORBIT Clay contains natural activated clay-based desiccants that work by evaporation and neutralization of moisture, making them effective in lowering the moisture content of the surrounding air</p>: null}
                                    {show === "vcp2"?<p>Zorbit Gel Desiccants are made from special starch that is highly hygroscopic, absorbing up to 350% of its own weight in moisture. This high absorption capacity makes it effective in preventing damage caused by moisture during transit.</p>: null}
                                    
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These desiccants are specifically used inside closed spaces, such as Moisture Barrier Bags, to maintain a dry environment for sensitive materials susceptible to moisture damage.</p>: null}
                                    {show === "vcp2"?<p> The desiccant eventually forms a biodegradable cake, making it environmentally friendly. It is ideal for use when not in direct contact with the commodity.</p>: null}
                                    
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Desiccants are packaged in fractional units, allowing flexibility in usage with a variety of bag sizes. This ensures efficient moisture absorption regardless of the bag's volume.</p>: null}
                                    {show === "vcp2"?<p> Zorbit Gel Desiccants offer 600% more absorption than Silica Gel, providing superior moisture control for goods in transit.</p>: null}
                                    
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>To create a complete moisture barrier, desiccants must be inserted into the bag before vacuum sealing. This process ensures that the desiccant can effectively absorb moisture inside the sealed environment.</p>: null}
                                    {show === "vcp2"?<p>Even after exposure to high temperatures (120 degrees C for 30 minutes in a desiccator), the desiccant exhibits minimal water leakage, ensuring the protection of goods even in challenging conditions.</p>: null}
                                    
                                  
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>The recommended amount of desiccant depends on various factors such as interior volume, weather conditions, required packaging lifespan, transportation mode, and ventilation. Proper calculation ensures optimal moisture control.</p>: null}
                                    {show === "vcp2"?<p>Approximately 6 kilograms of Zorbit Gel Desiccant is recommended for a 40 ft container during a haulage period of 30 days. This quantity can absorb up to 15 liters of water, safeguarding goods from moisture-related damage.</p>: null}
                                    
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> ZORBIT Clay desiccants are packed in special Tyvek paper, enhancing the strength of the packaging. Additionally, this packaging prevents reversible release of water to the surroundings, maintaining the desiccant's effectiveness.</p>: null}
                                    {show === "vcp2"?<p>Zorbit Gel Desiccants adhere to international standards such as DIN 55474 and MIL PRF 3464E, ensuring their effectiveness and reliability in moisture absorption.</p>: null}
                                    

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>It's important to avoid direct contact with the desiccant pouches, as they may sweat in certain conditions. Maintaining a barrier between the desiccant and the items being protected prevents inadvertent moisture exposure.</p>: null}
                                    {show === "vcp2"?<p> Zorbit Gel Desiccants reduce the likelihood of container rain, which occurs due to condensation of water on container surfaces and dripping onto packaged goods. The gel formulation prevents water from leaking out of the pouch.</p>: null}
                                    
                                  
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>ZORBIT Clay desiccants comply with international standards such as DIN 55473 and MIL 3464 E, ensuring their quality and effectiveness in maintaining a dry environment within packaging assemblies.</p>: null}
                                    {show === "vcp2"?<p>The desiccant absorbs moisture rapidly and continuously, effectively reducing dew-point within 4-5 minutes of placement. It can lower relative humidity to below 20-30% RH in a short span of time, making it highly efficient in preventing moisture-related issues.</p>: null}
                                    
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Desiccants