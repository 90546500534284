import FournotFour from './Fournotfour/four';
function Four() {

    return (
        <>
            <div className='ispmainservice'>
              
     <FournotFour />

            </div>
          
        </>
    );
}

export default Four;