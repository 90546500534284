import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Dl = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "Data Loggers"     
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/rustflex12.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Data loggers play a crucial role in various fields by providing valuable data for research, analysis, and decision-making processes. Reusable temperature data logger for use in warehouses or cold chain applications to monitor the transportation and storage conditions of pharmaceuticals, food and other temperature sensitive products. Recorded measured data can easily be transferred to a PC using the USB interface. When the data logger is stopped, a PDF report is automatically generated, which can be saved or printed for documentation purposes.</p> : 
                                null}
                                
                                
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                       
                        
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Data loggers collect and store data from sensors or other sources over a specific period. They can measure various parameters such as temperature, humidity, pressure, voltage, current, light intensity, and more, depending on their configuration.</p>: null}
                                   
                                    
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Data loggers operate autonomously, meaning they do not require continuous human intervention to collect data. They can be left unattended for extended periods, making them ideal for remote or inaccessible locations.</p>: null}
                                   
                                    
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Data loggers have built-in memory to store collected data. The storage capacity varies, allowing for the recording of large datasets. Some data loggers also support external storage devices or cloud-based storage for extended data storage.</p>: null}
                                   
                                    
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Data loggers are designed to provide accurate measurements. Regular calibration and maintenance are essential to ensuring their accuracy over time.</p>: null}
                                   
                                    
                                  
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Data loggers can be equipped with various sensors and input channels to measure different parameters. They can connect to external sensors or instruments through wired or wireless interfaces.</p>: null}
                                   
                                    
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Many data loggers are battery-powered, allowing them to operate in locations without a direct power source. The power efficiency of data loggers enables long-term data collection.</p>: null}
                                   
                                    

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Some advanced data loggers feature real-time monitoring capabilities. They can transmit data wirelessly to a central monitoring system, enabling real-time analysis and decision-making.</p>: null}
                                   
                                    
                                  
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Collected data can be downloaded to a computer for analysis. Software provided by data logger manufacturers allows users to visualize trends, generate reports, and make data-driven decisions.</p>: null}
                                   
                                    
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Dl