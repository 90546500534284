import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/Expert.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiPlus } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";
import {Link}  from 'react-router-dom';


function Expert() {
  

  useEffect(() => {
    AOS.init();
    AOS.refresh();

  }, []);
  var settings = {
    dots: true,
    infinite:true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 828,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 666,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 649,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      

    ]
  };
  return (
    <div>
      <div>
        <div className='value'>
          <div className='content'>
            <div className='clinicrow'>
            <div className='smallss'></div>
            <p>Our Products</p>
            </div>
            <div className='chief'>
            <h1>We delivery <span>Complete <br></br>{""} Industrial Solutions.</span></h1>
            </div>
          </div>
          <div className='sliding'>
            <Slider {...settings} className='slick'>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/indus.webp" alt="images" />
                        <div id='explos'  className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Industrial Cutting</h3>
                            <Link to="/Product_Category/industrialcutting" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/industrialsafety.webp" alt="images" />
                        <div id='explos'  className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Industrial Safety </h3>
                            <Link to="/Product_Category/IndustrialSafety" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/gas-pipeline.webp" alt="images" />
                        <div id='explos'  className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Gas Pipeline</h3>
                            <Link to="/Product_Category/gas" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/lubricant.webp" alt="images" />
                        <div id='explos'  className='plo'>
                          
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'> Lubricants Packing</h3>
                            <Link to="/Product_Category/lubricants" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/bio1.webp" alt="images" />
                        <div id='explos'  className='plo'>
                         
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Bio Degradable </h3>
                            <Link to="/Product_Category/bio" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/indlpg.webp" alt="images" />
                        <div id='explos'  className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'> Industrial LPG </h3>
                            <Link to="/Product_Category/industriallpg" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/lbg.webp" alt="images" />
                        <div id='explos'  className='plo'>
                           
                        </div>
                        <div className='testplo'>
                          <h3 className='biops'>Textile Machinery</h3>
                            <Link to="/Product_Category/textilemachinery" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>
            <div id='exbiots' className='biots'>
                        <img className="photocopy1" src="/images/textile machinery.webp" alt="images" />
                        <div id='explos'  className='plo'>
                          
                        </div>
                        <div className='testplo'>
                            <h3 className='biops'>Office Essential</h3>
                            <Link to="/products/GasPipeline/2" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
            </div>

            </Slider>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Expert;