import React,{useEffect} from 'react';
import '../css/About_us.css';
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function About_us(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();

    }, []);
    return(
        <div className='container'>
         <div className='edgibout'>
          {/* left side */}
          <div className='fullyleftonly'>
            <div className='aboutlinesegment animate__animated animate__bounce'>
            <div className='aboutunitline'></div>
            <p className='text-muted '> About us </p>
            </div>
            <div className='headquotes'>
                <h1 className='animate__animated animate__bounce'> We're Shaping the Future of Industrial Solutions Globally</h1>
            </div>
            <div className='paraskining'>
                <p className='text-muted paraburf1' data-aos="fade-up" data-aos-duration="900">Edgecut Tools India Private Limited formerly known as Edgecut Tools and Design
is a Coimbatore based Solutions provider for Industrial Cutting Tools, Safety
Products, Rust Preventive Lubricants, Bio Degradable Products, Gas Pipeline
Systems, Textile Machinery Spares and Office Essential Supplies. </p>
                     <p className='text-muted paraburf2' data-aos="fade-up" data-aos-duration="900">
                        
                     Edgecut was
started in the year 2017 and engaged in delivering Complete Solutions for
Manufacturing Industries, Hotels &amp; Restaurants, Institutions.</p>
            </div>
            <div>
                <div className='firstgriding' data-aos="fade-up" data-aos-duration="900">
                <div className='firstdir' >
                    <FaAngleDoubleRight className='lefi'/>
                    <p className='paraviewing'>Tailored Industrial Solutions</p>
                </div>
                <div className='seconddir'>
                <FaAngleDoubleRight className='lefi'/>
                    <p className='paraviewing'> Innovative Safety Solutions</p>
                </div>
                </div>
                <div className='firstgriding2' data-aos="fade-up" data-aos-duration="900">
                <div className='firstdir'>
                <FaAngleDoubleRight className='lefi'/>
                    <p className='paraviewing'>Eco-friendly Product Range</p>
                </div>
                <div className='firstdir'>
                <FaAngleDoubleRight className='lefi'/>
                    <p className='paraviewing'>Efficient Office Essentials</p>
                </div>
                </div>
            </div>
            {/* question */}
            <p className='paraquestion text-muted' data-aos="fade-up" data-aos-duration="900">Call to ask <a href="/contacts" className='linking'>any question </a> 9626437206</p>
            <hr data-aos="fade-up" data-aos-duration="900"/>
            <div>
                <div className='signaturepart' data-aos="fade-up" data-aos-duration="900">
                <img src='/sign.png'/>
                <div className='mdname'>
                <p>Sathish<br></br><span className='spanner'>(chairman and founder)</span></p>
                </div>
                </div>    
            </div>
          </div>
          {/* rightside */}
          <div className=' photogallery'>
            {/* <div className='photogallery'> */}
            {/* <img src="/images/Edgecut .jpg" data-aos="fade-left"/> */}
            <img src="/abt1.webp" />
            {/* </div> */}
          </div>


         </div>
        </div>
    )
}

export default About_us;