import React, { useEffect, useState } from 'react'
import Banner from '../Home/Banner'
import Activities from "../Home/Activities";
import About from '../Home/About'
import Service from '../Home/Service'
import Testimonial from '../Home/Testimonial'
import OurMission from '../Home/OurMission'
import Expert from '../Home/Expert'
import Contacts from '../Home/Contacts'
import Blogs from '../Home/Blogs'
import Footer from '../Home/Footer'
import FindWindowSize from '../Hooks/FindWIndowSize'
import AbtPricing from '../About/AbtPricing';

const Home = ({windowSize,color,setColor}) => {

  return (
    <>
        <Banner windowSize={windowSize} color={color} setColor={setColor}/>
        <About />
        <Service />
        {/* <Activities /> */}
        {/* <AbtPricing /> */}
        {/* <Projects /> */}
        <OurMission />
        <Expert />
        <Contacts windowSize={windowSize}/>
        <Testimonial />
        {/* <Pricing /> */}
        <Blogs />  
        <Footer color={color} setColor={setColor}/>
    </>
  )
}

export default Home