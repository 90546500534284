import React from 'react';
import '../css/BlogDetail.css'
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";



import {
  FaRegClock,
  FaLocationDot,
  FaCartPlus,
  FaHeadset
} from "react-icons/fa6";
import { IoMdArrowDropdown } from "react-icons/io";
import { LiaFacebookF, LiaInstagram, LiaLinkedinIn, LiaWhatsapp, LiaYoutube, LiaTwitter } from "react-icons/lia";
import { HiOutlineMail } from "react-icons/hi";

import { HiMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";

import { BsTelephoneFill } from "react-icons/bs";

import { MdMail } from "react-icons/md";



// sblog
function BlogDetail() {
  const { id } = useParams();
  const [blogs, setBlogs] = useState({
    id: "",
    date: "",
    title: "",
    category: "",
    quote: "",
    metades: "",
    metakey: "",
    intro: "",
    // content: "",
    img: "",
    status: "Active",
  });
  useEffect(() => {
    axios
    .get("https://infygain.in/api/editblog/" + id)
      .then((res) => {
        const blogData = res.data.result[0];
        setBlogs({
          ...blogs,
          id: blogData.id,
          date: blogData.date,
          title: blogData.title,
          category: blogData.category,
          quote: blogData.quote,
          intro: blogData.intro,
          content: blogData.content,
          metades: blogData.metades,
          metakey: blogData.metakey,
          img: blogData.img,
          status: blogData.status,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  const rawDate = blogs.date;
  const blogDate = rawDate.slice(0, 10);


  return (
    <>
      <div className=' container'>

        <div className='overallpique'>
          <div className='innerpiques'>
            <img
             src={"../../uploads/" + blogs.img} 
            // src='/images/abt.webp'
             className='image-fluid dataimg' />
            <div className='valuamount'>
              <p className='blog-inn-cons'>{blogDate}</p>

              <p className=' blog-inn-cons'>  {blogs.title} </p>
              <div dangerouslySetInnerHTML={{__html:blogs.content}} className="text-muted blog-content">
                
              </div>
              {/* <p className='text-muted details-blog-contents'>    {stripHTMLTags(blogs.content)}  </p> */}
            </div>
            {/* <div className='buttongroupin'>
                    <div className='buttonque'><img src="/images/facebook.png" className='blogmail image-fluid'></img>
                    <p className='lettering'>Facebook</p></div>
                    <div className='buttonque'><img src="/images/twitter.png" className='blogtwitter image-fluid'></img><p className='lettering'>Twitter</p></div>
                    <div className='buttonque'><img src="/images/mailing.png" className='blogmails image-fluid'></img><p className='lettering'>Email</p></div>
                    <div className='buttonque'><img src="/images/icons8-printer-26.png" className='blogicons image-fluid'></img><p className='lettering'>Print</p></div>
                    </div> */}
          </div>
        </div>


      </div>
      

    </>
  )
}

export default BlogDetail;