import React, { useEffect, useState } from 'react'
import Navbar from '../Home/Navbar'
import Welcome from '../Common/Welcome'
import SnHeader from '../Common/SnHeader'
import Nav from '../Common/Nav'
import Footer from '../Home/Footer'
import CnBanner from '../Common/CnBanner'
import ProductDetail from './ProductDetail'
import { useParams } from 'react-router-dom'
import ProductSlider from './ProductSlider'
import LoadPage from '../Pages/LoadPage'
import Gp from './Gp'
import ProductBtns from './ProductBtns'
import Lp from './Lp'
import Fillezy from './Fillezy'
import Ppe from './Safety/Ppe'
import Four from '../Pagenotfound'


const Product1 = ({windowSize,color,setColor,load,setLoad}) => {
    const {id,pid,cat,cid} = useParams()
    const validPaths = ['Bio-Degradable',"GasPipeline",'LubricantsPacking','IndustrialSafety']; 
    const BioIds =  ['foodPacking','2','3','4','5','6','7','8','9']
    const GpIds =  ['1','2','3','4','5']
    const LpIds =  ['Rust-X','Fillezy']
    const IsIds =  ['propre','road&parking','firefighting','welding','excul-prod','marinesafety']
    const cids = {
        // rust-x
        cleaner:[
            "1","2","3"
        ],
        cutting:[
            "1","2","3"
        ],
        coating:[
            "1","2"
        ],
        vm:[
            "1"
        ],
        DataLoggers:[
            "1"
        ],
        coating:[
            "1","2"
        ],
        RustPreventiveOils:[
            "1","2",'3','4'
        ],
        Steelcoil:[
            '1',"2",'3','4','5','6','7','8'
        ],
        vciemitters:[
            '1',"2",'3','4','5','6','7','8'
        ],
        VciSpray:[
            '1',"2",'3','4','5','6','7','8','9','10'
        ],
        Desiccants:[
            '1',"2"
        ],
        Rc:[
            '1',"2"
        ],
        vci:['1',"2",'3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
        // fillezy
        fillezy:[
            '1',"2",'3','4','5','6','7','8','9','10','11','12'
        ],
        //ind safety
        propre:['1',"2",'3','4','5','6','7','8','9'],
        roadsaf:['1',"2",'3','4'],
        ff:['1',"2"],
        weld:['1'],
        exprod:['1',"2",'3','4','5'],
        marine:['1',"2"],

    }


    const [fp, setFp] = useState(false)
    const [lam, setLam] = useState(false)
    const [ag, setAg] = useState(false) 
    const [shp, setshp] = useState(false)
    const [ss, setSs] = useState(false)
    const [plf, setPlf] = useState(false)
    const [bc, setBc] = useState(false)
    const [cut, setCut] = useState(false) 
    const [cgb, setCbg] = useState(false)
    const [show, setShow] = useState(false)

    const showed = () => {
        if(window.scrollY >= 150){
          setShow(true)
        }else{
          setShow(false)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', showed)
  
        return () => window.removeEventListener('scroll',showed)
    })
    let image = {
        image: id==="Rust-X"?"/images/rustxbanner.webp":id==="Fillezy"?"/images/fillezybanner.webp":pid==="IndustrialSafety"?"/images/indsafbanner.webp":pid==="IndustrialCutting"?"/images/indcutbanner.webp":
        pid==="Bio-Degradable"?"/images/biobanner.webp":pid==="Bio-Degradable"?"/images/biobanner.webp":pid==="Bio-Degradable"?"/images/biobanner.webp":""
      };

  return (
    <div>
        {validPaths.includes(pid)  &&  (pid === "LubricantsPacking" && LpIds.includes(id)) &&  ((pid === "LubricantsPacking" && id==="Fillezy" && cids.fillezy.includes(cid)) ||
        (pid === "LubricantsPacking" && id==="Rust-X" && cat === "VCI Emitters" && cids.vciemitters.includes(cid)) || (pid === "LubricantsPacking" && id==="Rust-X" && cat === "Data Loggers" && cids.DataLoggers.includes(cid))  ||(pid === "LubricantsPacking" && id==="Rust-X" && cat === "Remover & Converter" && cids.cutting.includes(cid)) ||(pid === "LubricantsPacking" && id==="Rust-X" && cat === "VCI Masterbatch" && cids.vm.includes(cid)) || (pid === "LubricantsPacking" && id==="Rust-X" && cat === "Desiccants" && cids.Desiccants.includes(cid)) ||  (pid === "LubricantsPacking" && id==="Rust-X" && cat === "VCI Sprays" && cids.VciSpray.includes(cid)) || (pid === "LubricantsPacking" && id==="Rust-X" && cat === "Steel Coil Packaging" && cids.Steelcoil.includes(cid)) ||(pid === "LubricantsPacking" && id==="Rust-X" && cat === "Rust Preventive Oils" && cids.RustPreventiveOils.includes(cid))|| (pid === "LubricantsPacking" && id==="Rust-X" && cat === "VCI Packaging" && cids.vci.includes(cid)) || (pid === "LubricantsPacking" && id==="Rust-X" && cat === "Cleaners" && cids.cleaner.includes(cid))) || (pid === "LubricantsPacking" && id==="Rust-X" && cat === "Coatings" && cids.coating.includes(cid)) || (pid === "LubricantsPacking" && id==="Rust-X" && cat === "Cutting Oils" && cids.cutting.includes(cid))
       || (pid === "IndustrialSafety" && id==="propre" && cids.propre.includes(cid)) || (pid === "IndustrialSafety" && id==="road&parking" && cids.roadsaf.includes(cid)) 
       || (pid === "IndustrialSafety" && id==="firefighting" && cids.ff.includes(cid)) || (pid === "IndustrialSafety" && id==="welding" && cids.weld.includes(cid)) 
       || (pid === "IndustrialSafety" && id==="excul-prod" && cids.exprod.includes(cid)) || (pid === "IndustrialSafety" && id==="marinesafety" && cids.marine.includes(cid)) 
       && !load ? 
            <>
                {windowSize[0] > 1200 && show &&
                    <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
                }
                {windowSize[0] > 1200 && 
                <Welcome />
                }
            
            {windowSize[0] > 1390 && 
            <SnHeader /> 
            }
            {windowSize[0] < 1390 &&    
            <Nav />
            }    
            <CnBanner windowSize={windowSize} title={pid==="Bio-Degradable"?"Bio Degradable":pid==="GasPipeline"?"Gas Pipeline":id==="Rust-X"?"Rust-X": id==="Fillezy"?"Fillezy":
                                                    id==="propre"?"PERSONAL PROTECTIVE": id==="road&parking"?"ROAD AND PARKING":id==="firefighting"?"FIRE FIGHTING":
                                                    id==="welding"?"WELDING PRODUCTS":id==="excul-prod"?"EXCLUSIVE PRODUCTS":id==="marinesafety"?"MARINE SAFETY":""}
            // title={fp ? "Food Packaging​" : lam ?  "Lamination" : shp ? "Shopping / Carry Bags" : ag ? "Agriculture" :
            //                                         ss ? "Shrink & Stretch Films" :plf ? "Paper Like Films" : bc ? "Bottles & Containers" : cut ? "Cutlery" : cgb? "Compostable Garbage Bags" : ""}
             image={image.image}/>
            {
                validPaths.includes(pid) && pid === "LubricantsPacking" && id==="Rust-X" &&
                <>
                    <Lp load={load} setLoad={setLoad}/>
                    <ProductSlider pid={pid} cat={cat}/>                                      
                </>
            }
            

            {/* fillezy */}
            {
                validPaths.includes(pid) && pid === "LubricantsPacking" && id==="Fillezy" &&cids.fillezy.includes(cid) &&
                <>
                  <Fillezy load={load} setLoad={setLoad}/>
                  <ProductSlider id={id}/>
                </>
            }
            {
                validPaths.includes(pid) && pid === "IndustrialSafety" &&
                <>
                  <Ppe load={load} setLoad={setLoad}/>
                  <ProductSlider id={id}/>
                </>
            }
            <Footer color={color}/>
            </> :
            !validPaths.includes(pid)  && !load ?
            <>
            <Four />
            </> :
        //    cat!=="VCIPackaging"?
        //     <>
        //      404 not found
        //     </> :

        //    !(pid === "Bio-Degradable" && BioIds.includes(id))  ? 
        //     <>
        //         404 not found
        //     </> :
        //      !(pid === "GasPipeline" && GpIds.includes(id))  ? 
        //      <>
        //          404 not found
        //      </> :
                <>
               <Four />
                </>
        }
    </div>
  )
}

export default Product1