import React from 'react'
import "../../css/SubCategory.css";


const SafetyProd = ({id,cid}) => {
    const BS = [
        {
            id:1,
            title:"Coveralls/Boilersuits",
            para:"Coveralls, or boilersuits, are one-piece protective garments offering full-body coverage and practicality with multiple pockets, now embraced as a fashionable choice for their utilitarian aesthetic.",
        },
        {
            id:2,
            title:"Electric Arc Protection",
            para:"Electric arc protection garments shield against intense heat from electrical arc flashes, prioritizing worker safety with flame-resistant materials in hazardous electrical environments.",
        },
        {
            id:3,
            title:"Chemical Protection",
            para:"Chemical protection gear shields against hazardous substances using impermeable materials, safeguarding workers from contact or absorption in high-risk environments.",
        },
        {
            id:4,
            title:"Fire Retardent Suite",
            para:"A fire retardant suit is protective gear designed with flame-resistant materials to minimize the risk of catching fire, commonly used in environments where exposure to flames or intense heat is a concern.",
        },
    ]
    const foot = [
        {
            id:1,
            title:"Safety Shoe",
            para:"Safety shoes are footwear designed with reinforced materials, like steel toes or composite caps, to protect feet from impact and puncture hazards in industrial settings, prioritizing safety and comfort for workers.",
        },
        {
            id:2,
            title:"Gumboot",
            para:"Gumboots, crafted from rubber, safeguard against water, mud, and chemical exposure, essential in industries to ensure safety and dryness in demanding environments.",
        },
        {
            id:3,
            title:"Formal Shoe",
            para:"Formal shoes are stylish footwear crafted from quality materials, designed with a refined appearance suitable for professional or dressy occasions, offering both sophistication and comfort.",
        },
    ]
    const hand = [
        {
            id:1,
            title:"Cut Resistant Gloves",
            para:"Cut-resistant gloves are protective hand coverings made from high-strength materials like Kevlar or Dyneema, shielding against cuts and abrasions in different industries without compromising dexterity or flexibility.",
        },
        {
            id:2,
            title:"Cotton Gloves",
            para:"Cotton gloves, crafted from lightweight fabric, offer basic hand protection and comfort, ideal for light tasks or as liners for added comfort under heavier gloves.",
        },
        {
            id:3,
            title:"Leather Gloves",
            para:"Leather gloves, crafted from durable animal hide, offer reliable hand protection and flexibility, suitable for diverse industries and stylish daily wear.",
        },
        {
            id:4,
            title:"Rubber Gloves",
            para:"Rubber gloves, made from latex or synthetic materials, provide protection against chemicals and germs, widely used in medical, cleaning, and industrial settings for safety and hygiene.",
        },
        {
            id:5,
            title:"Electrical Gloves",
            para:"Electrical gloves, made of insulating rubber, protect against electrical shock during work on live electrical systems, ensuring safety for workers in high-voltage environments.",
        },
    ]
    const head = [
        {
            id:1,
            title:"Safety Helmet",
            para:"A safety helmet is protective headgear designed to prevent head injuries in construction and industrial environments, made with durable materials and an impact-resistant shell for crucial protection.",
        },
        {
            id:2,
            title:"Helmet Refill",
            para:"Helmet refill might refer to replaceable components in helmets, like padding or visors, ensuring maintenance or customization of the headgear for optimal safety and comfort.",
        },
        {
            id:3,
            title:"Earmuff",
            para:"Earmuffs are protective devices that cover the ears, reducing exposure to loud noises in construction or industrial settings by using cushioned cups to block or diminish sound levels for hearing protection.",
        },
        {
            id:4,
            title:"Ear Plug",
            para:"Earplugs are small, foam or silicone devices inserted into the ear canal to reduce exposure to loud noises, offering hearing protection by blocking or minimizing sound levels for safety and comfort.",
        },
    ]
    const fall = [
        {
            id:1,
            title:"Safety Belt",
            para:"A safety belt, whether in vehicles or at heights in construction, is a crucial device designed to restrain and protect the wearer during sudden stops, collisions, or falls, reducing the risk of injury.",
        },
        {
            id:2,
            title:"Safety Net",
            para:"A safety net, often made of durable materials like nylon, serves as a protective barrier in construction or elevated work areas, preventing injuries by catching falling debris or individuals.",
        },
        {
            id:3,
            title:"Anchorage Line",
            para:"Anchorage lines are strong ropes or cables firmly attached to structures, serving as secure points for personal fall arrest systems in height-related work, ensuring safety by preventing falls.",
        },
        {
            id:4,
            title:"Fall Arrestor",
            para:"A fall arrestor is a safety device that halts a fall, reducing impact and preventing free falls, commonly used in harnesses or lifelines to protect against falls from heights in various industries.",
        },
        
    ]
    const safjack = [
        {
            id:1,
            title:"Mesh Reflective Jackets",
            para:"Mesh reflective jackets, with reflective strips and breathable mesh, ensure high visibility in low light for workers in construction or outdoor settings, prioritizing safety by enhancing wearers' visibility.",
        },
        {
            id:2,
            title:"Fabric Reflective Jackets",
            para:"Fabric reflective jackets feature embedded reflective material, ensuring high visibility in low-light conditions for workers in construction or outdoor settings, prioritizing safety by enhancing wearers' visibility.",
        },
        {
            id:3,
            title:"Reflective Vest Belt",
            para:"Reflective vest belts, worn around the waist, use reflective materials to enhance visibility in low-light conditions for pedestrians, runners, or workers, prioritizing safety by boosting visibility.",
        },
        {
            id:4,
            title:"High Visibility Jacket",
            para:"A high visibility jacket is a safety garment with bright colors and reflective strips, ensuring enhanced visibility in low-light or high-traffic areas, prioritizing safety by making wearers easily seen.",
        },
        
    ]
    const resp = [
        {
            id:1,
            title:"Disposible Respiratory",
            para:"Disposable respirators are masks designed for single-use, offering protection against inhaling airborne particles, contaminants, or hazardous substances in various industries and healthcare settings.",
        }, 
    ]
    const ms = [
        {
            id:1,
            title:"Raincoats Shirt & Pants",
            para:"Raincoat shirts and pants, made from waterproof materials like PVC, polyester, or nylon, protect against rain or wet conditions, ensuring comfort and dryness during outdoor activities or daily wear.",
        },
        {
            id:2,
            title:"Fullcoat",
            para:"A full coat is a longer outer garment that provides comprehensive protection from weather elements like rain or cold, available in various styles for different occasions and conditions.",
        },
        {
            id:3,
            title:"Gumboots",
            para:"Gumboots are waterproof boots made of rubber, shielding feet from water, mud, or chemicals in industries, agriculture, or outdoor environments, ensuring safety and dryness.",
        },
        
    ]
    const eye = [
        {
            id:1,
            title:"Safety Goggles",
            para:"Safety goggles shield eyes from hazards like chemicals and debris, providing essential protection in work environments like construction or laboratories.",
        },
        {
            id:2,
            title:"Face Shields",
            para:"Face shields are protective devices covering the face, guarding against splashes, sprays, or airborne particles in healthcare, laboratories, or industries, ensuring safety without obstructing visibility.",
        },
        
    ]

    const ts = [
        {
            id:1,
            title:"Convex Mirrors",
            para:"Convex mirrors widen visibility in areas like traffic intersections or warehouses, enhancing safety by reducing blind spots and improving surveillance.",
        },
        {
            id:2,
            title:"Bollards",
            para:"Bollards are sturdy posts used in areas like roadsides or walkways to control traffic and enhance safety, protecting pedestrians or buildings from vehicle impacts in urban settings.",
        },
        {
            id:3,
            title:"Traffic Cones",
            para:"Traffic cones are portable markers used to redirect traffic or signal hazards in construction sites or roadways, ensuring safety by creating work zones and guiding vehicles.",
        },
        {
            id:4,
            title:"Cone Chain",
            para:"A cone chain, also known as a cone bar, is a retractable barrier used with traffic cones to create temporary safety zones or restrict access in construction areas or roadways.",
        },
        {
            id:5,
            title:"Safety Barriers",
            para:"Safety barriers, made of materials like plastic, metal, or concrete, ensure safety in various settings by delineating spaces, guiding traffic, and protecting against hazards.",
        },
        {
            id:6,
            title:"Speed Breakers",
            para:"Speed breakers, commonly known as speed bumps, are raised road sections that slow down vehicle speed, enhancing safety in residential areas, parking lots, or school zones.",
        },
        {
            id:7,
            title:"Road Stud",
            para:"Road studs, also known as cat's eyes, are small markers embedded in road surfaces to reflect light and enhance road safety by marking lanes, curves, or hazards, especially in low-light",
        },
        {
            id:8,
            title:"Safety Fence",
            para:"Safety fences, made of materials like plastic, mesh, or metal, secure areas in construction sites or events, controlling access and delineating boundaries for safety and hazard prevention",
        },
        {
            id:9,
            title:"Dome Mirror",
            para:"Dome mirrors, or convex mirrors, widen the field of view in places like stores or road intersections, improving visibility, surveillance, and safety by minimizing blind spots.",
        },
        {
            id:10,
            title:"Median Markers",
            para:"Median markers, placed along road medians, improve visibility for drivers in low-light or adverse conditions, enhancing road safety by clearly delineating lanes and aiding lane positioning.",
        },
        {
            id:11,
            title:"Lane Divider",
            para:"Lane dividers, through lines, markers, or barriers, organize traffic flow on roads or highways, ensuring safety by guiding drivers and maintaining lane discipline for smooth traffic movement.",
        },
    ]
    const ps = [
        {
            id:1,
            title:"Wheel Stoppers",
            para:"Wheel stoppers, also called wheel chocks, prevent unintended vehicle movement in parking lots or storage areas, ensuring safety by securing parked vehicles.",
        },
        {
            id:2,
            title:"Corner Guards",
            para:"Corner guards shield building corners from damage caused by impacts, preserving surfaces and enhancing safety by cushioning collisions from carts or traffic.",
        },
        {
            id:3,
            title:"Bollards",
            para:"Bollards are sturdy posts used in areas like roadsides or walkways to control traffic and enhance safety, protecting pedestrians or buildings from vehicle impacts in urban settings.",
        },
        {
            id:4,
            title:"Antiglare Boards",
            para:"Antiglare boards reduce glare from headlights or sunlight along roads, improving driver visibility and safety, particularly during sunrise, sunset, or nighttime.",
        },
        {
            id:5,
            title:"Dock Bumbers",
            para:"Dock bumpers, commonly made of rubber or plastic, protect loading docks from damage during vehicle loading, ensuring safety and preserving structures from impact-related harm.",
        },
        {
            id:6,
            title:"Queue Managers",
            para:"Queue managers, like crowd control barriers, organize foot traffic and manage crowds efficiently in places such as stores, airports, or events, ensuring safety and orderly movement",
        },
        {
            id:7,
            title:"Cable Protector",
            para:"Cable protectors, made of rubber or plastic, prevent cable damage and tripping hazards from foot or vehicular traffic, ensuring safety in events or construction sites.",
        },
        {
            id:8,
            title:"Search Mirror",
            para:"A search mirror, also known as an inspection mirror, aids in examining hard-to-reach areas, enhancing safety during security checks or inspections.",
        },
        {
            id:9,
            title:"Plastic Floor Stand",
            para:"Plastic floor stands, durable and portable, display signage or information in indoor settings like retail stores or exhibitions, offering stability for communication purposes.",
        },
        {
            id:10,
            title:"Boom Barriers",
            para:"Boom barriers control vehicle access by lifting or lowering a horizontal bar, commonly seen at toll booths or parking lots for security and traffic management.",
        },
    ]
    const lig = [
        {
            id:1,
            title:"Batton Lights",
            para:"Baton lights, handheld by traffic police or emergency responders, aid in traffic direction and visibility in low-light conditions, ensuring safety and efficient traffic management.",
        },
        {
            id:2,
            title:"Solar Blinker Ligths",
            para:"Solar blinker lights, powered by solar energy, provide warning signals in road safety or construction zones, ensuring visibility and caution without external power sources.",
        },
        
    ]
    const tap = [
        {
            id:1,
            title:"Barricade Tapes",
            para:"Barricade tapes, brightly colored and highly visible, mark off restricted or hazardous areas, ensuring safety by warning individuals to avoid entry or approach.",
        },
        {
            id:2,
            title:"Reflective Tapes",
            para:"Reflective tapes, featuring high visibility and reflective properties, enhance safety by increasing visibility in low-light conditions, commonly used on vehicles",
        },
        {
            id:3,
            title:"Floor Marking Tapes",
            para:"Floor marking tapes, with vibrant colors and strong adhesion, guide movement and indicate zones in warehouses or workplaces, improving safety by delineating pathways or specific areas.",
        },
        
    ]
   
    const fe = [
        {
            id:1,
            title:"Abc Fire Extinguisher",
            para:"ABC fire extinguishers tackle fires involving ordinary combustibles, flammable liquids, and electrical equipment, providing versatile firefighting capabilities for comprehensive fire safety.",
        },
        {
            id:2,
            title:"Co2 Type",
            para:"CO2 fire extinguishers, effective for Class B and C fires, use carbon dioxide to smother fires involving flammable liquids or electrical equipment, leaving no residue, suitable for sensitive environments.",
        },
        {
            id:3,
            title:"Water Type ",
            para:"Water-based fire extinguishers effectively tackle Class A fires by cooling flames, commonly used in workplaces with organic materials, providing an efficient firefighting solution.",
        },
        {
            id:4,
            title:"Mechanical Foam Type ",
            para:"Mechanical foam fire extinguishers are effective against Class A and B fires by forming a foam blanket, used in environments with flammable liquids like gasoline or oils.",
        },
        {
            id:5,
            title:"Wheeled Fire",
            para:"Wheeled fire extinguishers are larger, mobile units for industrial or commercial settings, equipped to handle Class A, B, or C fires effectively over extended durations.",
        },
        
    ]
    const fh = [
        {
            id:1,
            title:"Branch Pipe",
            para:"A branch pipe is a firefighting tool connected to a hose, allowing firefighters to control water flow for effective fire suppression in various environments.",
        },
        {
            id:2,
            title:"Hose Reel Drum",
            para:"A hose reel drum stores and deploys hoses efficiently in firefighting or industrial settings, providing quick access to water for suppression or other purposes.",
        },
        {
            id:3,
            title:"Valves",
            para:"Valves regulate or control fluid flow in pipes or systems, essential in industries, plumbing, or engineering for managing processes.",
        },
        {
            id:4,
            title:"Sprinkle",
            para:"Sprinkler systems distribute water for irrigation or fire suppression, ensuring controlled distribution for specific areas or purposes, activated manually or automatically.",
        },
        {
            id:5,
            title:"Coupling",
            para:"A coupling connects two shafts or pipes, facilitating the transfer of power or fluids in machinery, plumbing, or industrial applications for efficient operations and transmission.",
        },
        {
            id:6,
            title:"Fire Hose",
            para:"A fire hose carries water or fire-retardant materials under pressure, used by firefighters for effective fire control and suppression in emergency situations.",
        },
        {
            id:7,
            title:"Inlet Fbc / Breeching",
            para:"An inlet breeching or FBC (Fire Brigade Connection) is a crucial firefighting component in buildings, allowing firefighters to connect hoses to the water supply for fire suppression and control.",
        },
        {
            id:8,
            title:"Water And Foam Monitor",
            para:"A water and foam monitor directs water or foam to extinguish fires, mounted on vehicles or fixed installations for efficient firefighting in industrial settings or large-scale fires.",
        },
    ]

    const ws = [
        {
            id:1,
            title:"Face Protection",
            para:"Face protection gear, such as shields or goggles, safeguards against impacts, particles, or hazardous substances, vital in industrial or medical settings to prevent injuries or exposure.",
        },
        {
            id:2,
            title:"Body Protection",
            para:"Body protection gear, including coveralls or vests, safeguards against chemicals, heat, or sharp objects, crucial in industrial or construction settings to prevent injuries or exposure.",
        },
        {
            id:3,
            title:"Hand Protection",
            para:"Hand protection involves gloves or barriers shielding against cuts, chemicals, or heat, vital in industries or construction to prevent injuries or exposure to hazards during work",
        },
        
    ]

    const mp = [
        {
            id:1,
            title:"First Aid",
            para:"First aid involves immediate care in emergencies, providing initial assistance and basic medical supplies to mitigate injuries or stabilize conditions until professional help arrives.",
        },
        {
            id:2,
            title:"Pulse Oximeter",
            para:"A pulse oximeter measures blood oxygen levels and heart rate, crucial for assessing respiratory health, commonly used in healthcare or at home for non-invasive monitoring.",
        },
        {
            id:3,
            title:"General",
            para:"Corrugations contain high level of humidity.VCI 3D Carton Liners are used to protect steel components from effects of moisture.",
        },
        
    ]
    const hw = [
        {
            id:1,
            title:"Tools",
            para:"Hardware tools like hammers, screwdrivers, and wrenches are used in construction or maintenance for specific tasks, offering versatility in various industries or domestic settings.",
        },
    ]
    const gen = [
        {
            id:1,
            title:"Body Protection",
            para:"Body protection gear, including coveralls or vests, safeguards against chemicals, heat, or sharp objects, crucial in industrial or construction settings to prevent injuries or exposure.",
        },
        {
            id:2,
            title:"Electrical Safety",
            para:"Electrical safety involves precautions and practices to prevent electric shock or fire hazards, encompassing proper equipment usage, insulation checks, and adherence to safety protocols",
        },
        {
            id:3,
            title:"Ropes & Lifting Belts",
            para:"Ropes and lifting belts are essential equipment for handling heavy loads, ensuring safety and stability in tasks like construction, shipping, or rescue operations.",
        },
        
    ]   
    const sss = [
        {
            id:1,
            title:"Notice Signs",
            para:"Notice signs use symbols or text to guide behavior or indicate hazards, crucial for safety and communication in workplaces, public areas, or roadways.",
        },
        {
            id:2,
            title:"Warning Signs",
            para:"Warning signs alert to potential hazards using symbols, colors, or text, ensuring safety by cautioning against specific risks in various environments.",
        },
    ]   
    const sn = [
        {
            id:1,
            title:"Bird Protection Net",
            para:"Bird protection nets are installed to prevent bird entry or nesting, commonly used in buildings, warehouses, or agricultural settings to avoid bird-related issues or damage.",
        },
    ]   

    const lj = [
        {
            id:1,
            title:"Vest Life Jacket",
            para:"A vest life jacket is a buoyancy aid worn like a vest, keeping a person afloat and safe during water activities, commonly used in boating or water sports.",
        },
    ]   
    const lb = [
        {
            id:1,
            title:"Life Buoyt",
            para:"A lifebuoy is a buoyant ring used in water rescue, aiding individuals in distress to stay afloat, crucial for swift water rescue operations in emergencies.",
        },
    ]   

  return (
    <div>
         { id === "propre" && cid ==="1" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>BodySuits Products</span>
                </div>
            <div className='service-slider'>
            {
                BS.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "bs-prd1" :val.id===2 ? "bs-prd2" :val.id===3 ? "bs-prd3" :val.id===4 ? "bs-prd4" :
                                                     ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="2" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>FOOT PROTECTION</span>
                </div>
            <div className='service-slider'>
            {
                foot.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "fp-prd1" :val.id===2 ? "fp-prd2" :val.id===3 ? "fp-prd3" : ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="3" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> HAND PROTECTION</span>
                </div>
            <div className='service-slider'>
            {
                hand.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "hp-prd1" :val.id===2 ? "hp-prd2" :val.id===3 ? "hp-prd3" :val.id===4 ? "hp-prd4" :
                                                val.id===5 ? "hp-prd5"  : ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="4" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> HEAD AND EAR PROTECTION</span>
                </div>
            <div className='service-slider'>
            {
                head.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "hep-prd1" :val.id===2 ? "hep-prd2" :val.id===3 ? "hep-prd3" :val.id===4 ? "hep-prd4" :
                                               ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="5" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>EYE AND FACE PROTECTION</span>
                </div>
            <div className='service-slider'>
            {
                eye.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "ep-prd1" :val.id===2 ? "ep-prd2" : ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="6" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>FALL PROTECTION</span>
                </div>
            <div className='service-slider'>
            {
                fall.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ffp-prd1" :val.id===2 ? "ffp-prd2" :val.id===3 ? "ffp-prd3" :val.id===4 ? "ffp-prd4" :
                                                           ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="7" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> SAFETY JACKETS</span>
                </div>
            <div className='service-slider'>
            {
                safjack.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "sj-prd1" :val.id===2 ? "sj-prd2" :val.id===3 ? "sj-prd3" :val.id===4 ? "sj-prd4" :
                                                 ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="8" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> RESPIRATORY PROTECTION</span>
                </div>
            <div className='service-slider'>
            {
                resp.map((val)=>(
                    <div className='subcategoryhead'>
                            
                    <div className={`subcategory2 ${val.id===1 ? "rp-prd1"  : ""}`}>

                    </div>
                    <div className='subcategory3'>
                        <h4>{val.title}</h4>
                        <div className='pilot'>
                            <p>{val.para}</p>
                        </div>
                    </div>
    </div>
                ))
            }
            </div>
         </>
        }
         { id === "propre" && cid ==="9" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>Mansoon Safetys</span>
                </div>
            <div className='service-slider'>
            {
                ms.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ms-prd1" :val.id===2 ? "ms-prd2" :val.id===3 ? "ms-prd3" : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }


         { id === "road&parking" && cid ==="1" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> TRAFFIC SAFETY</span>
                </div>
            <div className='service-slider'>
            {
                ts.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ts-prd1" :val.id===2 ? "ts-prd2" :val.id===3 ? "ts-prd3" :val.id===4 ? "ts-prd4" :
                                                            val.id===5 ? "ts-prd5" : val.id===6 ? "ts-prd6" : val.id===7 ? "ts-prd7" :val.id===8 ? "ts-prd8" :
                                                            val.id===9 ? "ts-prd9" :   val.id===10 ? "ts-prd10" :  val.id===11 ? "ts-prd11" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
         { id === "road&parking" && cid ==="2" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> PARKING SAFETY</span>
                </div>
            <div className='service-slider'>
            {
                ps.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ps-prd1" :val.id===2 ? "ps-prd2" :val.id===3 ? "ps-prd3" :val.id===4 ? "ps-prd4" :
                                                            val.id===5 ? "ps-prd5" : val.id===6 ? "ps-prd6" : val.id===7 ? "ps-prd7" :val.id===8 ? "ps-prd8" :
                                                            val.id===9 ? "ps-prd9" : val.id===10 ? "ps-prd10" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
         { id === "road&parking" && cid ==="3" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> LIGHTS</span>
                </div>
            <div className='service-slider'>
            {
                lig.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "lig-prd1" :val.id===2 ? "lig-prd2" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
         { id === "road&parking" && cid ==="4" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> TAPES</span>
                </div>
            <div className='service-slider'>
            {
                tap.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "tap-prd1" :val.id===2 ? "tap-prd2" :val.id===3 ? "tap-prd3"  : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
         { id === "firefighting" && cid ==="1" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> FIRE EXTINGUISHER</span>
                </div>
            <div className='service-slider'>
            {
                fe.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "fe-prd1" :val.id===2 ? "fe-prd2" :val.id===3 ? "fe-prd3" :val.id===4 ? "fe-prd4" :
                                                            val.id===5 ? "fe-prd5"  : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
         { id === "firefighting" && cid ==="2" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> FIRE HYDRANT SYSTEM</span>
                </div>
            <div className='service-slider'>
            {
                fh.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ff-prd1" :val.id===2 ? "ff-prd2" :val.id===3 ? "ff-prd3" :val.id===4 ? "ff-prd4" :
                                                            val.id===5 ? "ff-prd5" :  val.id===6 ? "ff-prd6" : val.id===7 ? "ff-prd7" : val.id===8 ? "ff-prd8" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }

        { id === "welding" && cid ==="1" &&
                <>
                    <div className='prdtline-heading'>
                            <div className='prdtunder-line'></div>
                            <span> WELDING SAFETY</span>
                        </div>
                    <div className='service-slider'>
                    {
                        ws.map((val)=>(
                            <div className='subcategoryhead'>
                                    
                                        <div className={`subcategory2 ${val.id===1 ? "ws-prd1" :val.id===2 ? "ws-prd2" :val.id===3 ? "ws-prd3"  : ""}`}>

                                        </div>
                                        <div className='subcategory3'>
                                            <h4>{val.title}</h4>
                                            <div className='pilot'>
                                                <p>{val.para}</p>
                                            </div>
                                        </div>
                        </div>
                        ))
                    }
                    </div>
                </>
                }

{ id === "excul-prod" && cid ==="1" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> MEDICAL PURPOSE</span>
                </div>
            <div className='service-slider'>
            {
                mp.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "mp-prd1" :val.id===2 ? "mp-prd2" :val.id===3 ? "mp-prd3" : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
{ id === "excul-prod" && cid ==="2" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> HARDWARE</span>
                </div>
            <div className='service-slider'>
            {
                hw.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "hl-prd1"  : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
{ id === "excul-prod" && cid ==="3" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> GENERAL</span>
                </div>
            <div className='service-slider'>
            {
                gen.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ge-prd1" :val.id===2 ? "ge-prd2" :val.id===3 ? "ge-prd3" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
{ id === "excul-prod" && cid ==="4" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> SAFETY SIGNS AND SIGNALS</span>
                </div>
            <div className='service-slider'>
            {
                sss.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "sss-prd1" :val.id===2 ? "sss-prd2"  : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
{ id === "excul-prod" && cid ==="5" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> SAFETY NET</span>
                </div>
            <div className='service-slider'>
            {
                sn.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "sn-prd1"  : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }

{ id === "marinesafety" && cid ==="1" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>LIFE JACKET</span>
                </div>
            <div className='service-slider'>
            {
                lj.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "lj-prd1" : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
{ id === "marinesafety" && cid ==="2" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>Life Buoy</span>
                </div>
            <div className='service-slider'>
            {
                lb.map((val)=>(
                    <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "lb-prd1" : ""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                    </div>
                                </div>
                </div>
                ))
            }
            </div>
         </>
        }
    </div>
  )
}

export default SafetyProd