import React from 'react'
import { FaRegPaperPlane} from 'react-icons/fa';
import { FaLocationDot} from 'react-icons/fa6';
import { TbArrowBigUpFilled} from 'react-icons/tb';
import {BsFillTelephoneFill, BsTwitter} from "react-icons/bs";
import { FaFacebookF} from "react-icons/fa";
import { AiFillInstagram} from "react-icons/ai";
import { IoLogoWhatsapp} from "react-icons/io";
import {BsArrowUp, BsArrowRight } from 'react-icons/bs'
import '../css/Footer.css'
import { Link } from 'react-router-dom';
import Aos from 'aos';
import { useEffect } from 'react';

const Footer = ({color,setColor}) => {
    const today = new Date();
    const year = today.getFullYear();

    
  useEffect(() => {
    Aos.init();
  })
  return (

    <div className='fot-header'>
        <div className='container footer1'>
            <span className='f1-span'>Get started to make easy!</span>
            <div className='footer2'>
              <button className="about-btns"  data-aos="fade-right" data-aos-duration="1000">
                    <span className="about-sc"> 
                    </span>
                    <Link to='/contacts'>
                      <span className="iot-about-text">Contact
                      <BsArrowRight className='about-btns-icons1'/>
                      </span>
                    </Link>
              </button>
                <Link to='/about-us' data-aos="fade-left" data-aos-duration="1500"><span className='f2-span1'>About us <span className='f2-span2'><BsArrowRight /></span></span></Link>
            </div>
        </div>

        <div className='footer3'>
        <div className=''>
            <div className='ec-footer'>
                <div className='ec-footer-col1'>
                    {/* <img src='/images/edge1.png' alt='image' width="200px"/> */}
                    <img src='/images/LOGO (1).png' alt='edgecut' width="170px"/>
                    <p>Edgecut Tools India Private Limited is a Coimbatore-based solution provider offering a diverse range of industrial tools, safety products, machinery spares, and essential supplies since 2017. Committed to customer support, cost reduction, and delivering high-quality solutions through a dedicated team of engineering professionals
                      </p>
                    {/* <button
                    className="test-btns"
                >
                    <span className="test-sc"></span>
                    <Link to='/about-us'>
                      <span className="iot-text">
                        About Us
                      <BsArrowRight className="btns-icons1" />
                      </span>
                    </Link>
                    </button> */}
                    <p className='foot-follow'>Follow Us</p>
                    <div>
                    <FaFacebookF className="fot-sicons1"/>
                    <BsTwitter className="fot-sicons"/>
                    <IoLogoWhatsapp className="fot-sicons"/>
                    <AiFillInstagram className="fot-sicons"/>
                    </div>
                </div>
                <div  className='ec-footer-col2'>
                    <h3>
                      Company
                      </h3>
                    <ul>
                     <Link to="/"> <li>Home</li></Link>
                      <Link to="/about-us"><li>About</li></Link>
                     <Link to="/services"> <li>Products</li></Link>
                     <Link to="/career"> <li>Career</li></Link>
                     <Link to="/contacts"> <li>Contacts</li></Link>
                     <Link to="/blogs"> <li>Blogs</li></Link>
                     <Link to="/Downloadcenter"> <li>Download Center</li></Link>
                    </ul>
                
                
                </div>
                <div  className='ec-footer-col2'>
                    <h3>Products</h3>
                    <ul>
                      <Link to="/Product_Category/industrialcutting"><li>Industrial Cutting</li></Link>
                       <Link to="/Product_Category/IndustrialSafety"><li>Industrial Safety Products</li></Link>
                       <Link to="/Product_Category/gas-pipeline"><li>Gas Pipeline projects</li></Link>
                       <Link to="/Product_Category/lubricants"><li>Lubricants Packing Products</li></Link>
                       <Link to="/Product_Category/bio-degradable"><li>Bio Degradable Products</li></Link>
                       <Link to="/Product_Category/industriallpg"><li>Industrial LPG Solutions</li></Link>
                       <Link to="/Product_Category/OfficeEssential"><li>Office Essential</li></Link>
                       <Link to="/Product_Category/textilemachinery"><li>Textile Machinery Spares</li></Link>
                    </ul>
                
                
                </div>
                <div  className='ec-footer-col3'>
                    <h3>Head Office</h3>
                    <span className='foot-office'>Edgecut Tools India Private Limited</span>
                    <div className='footer-col4'>
                        {/* <FaLocationDot className='fot-icons fote-icons1'/> */}
                        <p className='foot-address'>Hari complex opp.prozone mall,
                            Sathy Rd, Saravanampatti,
                            Coimbatore, Tamil Nadu 641035
                        </p>
                    </div>
                    <div className='footer-col4 '>
                        <span>Ph.No:</span>
                        {/* <BsFillTelephoneFill className='fot-icons'/> */}
                        <p>1-888-452-1505</p>
                    </div>
                    <div className='open-hrs'>
                        <h3>Open Hours</h3>
                        <p>Mon – Sat: 8.30 am – 6.30 pm, <br />
                            Sunday: CLOSED</p>
                    </div>
                </div>
            </div>
        </div>
        </div>

        {/* <div className="topmenu" onClick={scrollToTop}>
        <svg
          className="testIcon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 357.6 401.37"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-3"
                d="M357.6,282.36V119a37.33,37.33,0,0,0-18.66-32.33L197.47,5a37.32,37.32,0,0,0-37.33,0L18.67,86.68A37.33,37.33,0,0,0,0,119V282.36a37.32,37.32,0,0,0,18.67,32.33l141.47,81.68a37.32,37.32,0,0,0,37.33,0l141.47-81.68A37.31,37.31,0,0,0,357.6,282.36Z"
              />
            </g>
          </g>
        </svg>

        <TbArrowBigUpFilled className="topIcon" />
      </div> */}
       
        <div className='footer-cpy'>
            <span>{year} &copy; All rights reserved by Edgecut Tools</span>
        </div>
        {color &&
          <button className='fot-scroll' onClick={() => window.scrollTo({top:0, behavior:"smooth"})}><BsArrowUp /></button>
        }
    </div>
  )
}

export default Footer