import React, { useEffect, useState } from 'react'
    import Navbar from '../Home/Navbar'
    import Welcome from '../Common/Welcome'
    import SnHeader from '../Common/SnHeader'
    import Nav from '../Common/Nav'
    import Footer from '../Home/Footer'
    import CnBanner from '../Common/CnBanner'
    import Biodegradable from "./Biodegradable";
import { useParams } from 'react-router-dom'
import Four from '../Pagenotfound'
  
    
    const Product_Category = ({windowSize,color,setColor}) => {
      const paths = ["bio-degradable",'gas-pipeline','lubricants','IndustrialSafety',,"OfficeEssential",'Personalprotective',"industrialcutting",'textilemachinery','industriallpg']
      
      const {id} = useParams()
        const [show, setShow] = useState(false)
        const showed = () => {
            if(window.scrollY >= 150){
              setShow(true)
            }else{
              setShow(false)
            }
        }
      
        useEffect(() => {
            window.addEventListener('scroll', showed)
      
            return () => window.removeEventListener('scroll',showed)
        })

        let image = {
          image: id==="textilemachinery"?"/images/textilebanner.webp":"/images/productbanner.webp"
        };
        
      return (
        <>
        { paths.includes(id) ?
          <>
                          {windowSize[0] > 1200 && show &&
                <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
            }
            {windowSize[0] > 1200 && 
              <Welcome />
            }
         
          {windowSize[0] > 1390 && 
           <SnHeader /> 
          }
         {windowSize[0] < 1390 && 
          <Nav />
         }    
        <CnBanner windowSize={windowSize} title={id==="textilemachinery"?"Textile Machinery":"Categories"} image={image.image}/>
         <Biodegradable/>
         {/* <SubCategory/> */}
         <Footer color={color}/>
          </>:
          <><Four /></>
        }

        </>
      )
    }
    
export default Product_Category;