import React, { useEffect, useState } from 'react';
import '../css/Productdetails.css';
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Spinner from 'react-bootstrap/Spinner';
import { Link, useParams } from 'react-router-dom';
import CommonBtns from './CommonBtns';



function ProductDetail({setLoad}) {
    const [openCategory, setOpenCategory] = useState(null);



    

    const {id,pid} = useParams()
    const [fp, setFp] = useState(false)
    const [lam, setLam] = useState(false)
    const [ag, setAg] = useState(false) 
    const [shp, setshp] = useState(false)
    const [ss, setSs] = useState(false)
    const [plf, setPlf] = useState(false)
    const [bc, setBc] = useState(false)
    const [cut, setCut] = useState(false) 
    const [cgb, setCbg] = useState(false)

    useEffect(() => {
        if(pid === "Bio-Degradable"){
            setOpenCategory(1)
        }
    },[])
    useEffect(() => {
        // setLoad(true)
        if(id==='1'){
            setFp(true)
            setLam(false)
            setAg(false)
            setshp(false)
            setSs(false)
            setPlf(false)
            setBc(false)
            setCut(false)
            setCbg(false)
            // setLoad(true)
        }
        if(id==='2'){
            setFp(false)
            setLam(true)
            setAg(false)
            setshp(false)
            setSs(false)
            setPlf(false)
            setBc(false)
            setCut(false)
            setCbg(false)
        }
        if(id==='3'){
            setFp(false)
            setLam(false)
            setAg(true)
            setshp(false)
            setSs(false)
            setPlf(false)
            setBc(false)
            setCut(false)
            setCbg(false)
        }
        if(id==='4'){
            setFp(false)
            setLam(false)
            setAg(false)
            setshp(true)
            setSs(false)
            setPlf(false)
            setBc(false)
            setCut(false)
            setCbg(false)
        }if(id==='5'){
            setFp(false)
            setLam(false)
            setAg(false)
            setshp(false)
            setSs(true)
            setPlf(false)
            setBc(false)
            setCut(false)
            setCbg(false)
        }
        if(id==='6'){
            setFp(false)
            setLam(false)
            setAg(false)
            setshp(false)
            setSs(false)
            setPlf(true)
            setBc(false)
            setCut(false)
            setCbg(false)
        }
        if(id==='7'){
            setFp(false)
            setLam(false)
            setAg(false)
            setshp(false)
            setSs(false)
            setPlf(false)
            setBc(true)
            setCut(false)
            setCbg(false)
        }
        if(id==='8'){
            setFp(false)
            setLam(false)
            setAg(false)
            setshp(false)
            setSs(false)
            setPlf(false)
            setBc(false)
            setCut(true)
            setCbg(false)
        }
        if(id==='9'){
            setFp(false)
            setLam(false)
            setAg(false)
            setshp(false)
            setSs(false)
            setPlf(false)
            setBc(false)
            setCut(false)
            setCbg(true)
        }
         
    },[id])

    const handleLoad = () => {
        setLoad(true); 

        // setTimeout(() => {
        //     setShowLoader(false);
        //   }, 1000);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [box1, setBox1] = useState(true);
    const [box2, setBox2] = useState(false);
    const [box3, setBox3] = useState(false);
    const [box4, setBox4] = useState(false);
    const [box5, setBox5] = useState(false);
    const [box6, setBox6] = useState(false);
    const [box7, setBox7] = useState(false);
    const [box8, setBox8] = useState(false);

    const handleClick = () => {
        setIsLoading(true);
        setShowLoader(true); 
    
        setTimeout(() => {
          setIsLoading(false); 
          setShowLoader(false);
        }, 1000);
      };
      const Clickon1=()=>{
        handleClick();
        Value1();
      }
      const Clickon2=()=>{
        handleClick();
        Value2();
      }
      const Clickon3=()=>{
        handleClick();
        Value3();
      }
      const Clickon4=()=>{
        handleClick();
        Value4();
      }
      const Clickon5=()=>{
        handleClick();
        Value5();
      }

      const Clickon6=()=>{
        handleClick();
        Value6();
      }
      const Clickon7=()=>{
        handleClick();
        Value7();
      }
      const Clickon8=()=>{
        handleClick();
        Value8();
      }



    const Value1 = () => {
        setBox1(true);
        setBox2(false);
        setBox3(false);
        setBox4(false);
        setBox5(false);
        setBox6(false);
        setBox7(false);
        setBox8(false);

    }
    const Value2 = () => {
        setBox1(false);
        setBox2(true);
        setBox3(false);
        setBox4(false);
        setBox5(false);
        setBox6(false);
        setBox7(false);
        setBox8(false);
    }
    const Value3 = () => {
        setBox1(false);
        setBox2(false);
        setBox3(true);
        setBox4(false);
        setBox5(false);
        setBox6(false);
        setBox7(false);
        setBox8(false);
    }
    const Value4 = () => {
        setBox1(false);
        setBox2(false);
        setBox3(false);
        setBox4(true);
        setBox5(false);
        setBox6(false);
        setBox7(false);
        setBox8(false);
    }
    const Value5 = () => {
        setBox1(false);
        setBox2(false);
        setBox3(false);
        setBox4(false);
        setBox5(true);
        setBox6(false);
        setBox7(false);
        setBox8(false);
    }

    const Value6= () => {
        setBox1(false);
        setBox2(false);
        setBox3(false);
        setBox4(false);
        setBox5(false);
        setBox6(true);
        setBox7(false);
        setBox8(false);
    }
    const Value7 = () => {
        setBox1(false);
        setBox2(false);
        setBox3(false);
        setBox4(false);
        setBox5(false);
        setBox6(false);
        setBox7(true);
        setBox8(false);
    }
    const Value8 = () => {
        setBox1(false);
        setBox2(false);
        setBox3(false);
        setBox4(false);
        setBox5(false);
        setBox6(false);
        setBox7(false);
        setBox8(true);
    }

    
    return (
    <>
            
            <div className='container'>
                <div className={isLoading ? "opaque" :  "opaque1"}>
                <div className='row overall'>
                    <div className='col-md-4' >
                        <CommonBtns id={id} pid={pid}/>
                    </div> 
                    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { fp ? "Food Packaging​" : lam ?  "Lamination" : shp ? "Shopping / Carry Bags" : ag ? "Agriculture" :
                                                    ss ? "Shrink & Stretch Films" :plf ? "Paper Like Films" : bc ? "Bottles & Containers" : cut ? "Cutlery" : cgb? "Compostable Garbage Bags" : ""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                               {
                                fp ?
                                <>
                                    <img src='/images/bio1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                                lam ?
                                <img src='/images/bio2.webp' alt='dummy'/>:
                                shp ? 
                                <img src='/images/bio3.webp' alt='dummy'/> :
                                ag ?
                                <img src='/images/bio4.webp' alt='dummy'/>:
                                ss ?
                                <img src='/images/bio5.webp' alt='dummy'/>:
                                plf?
                                <img src='/images/bio6.webp' alt='dummy'/>:
                                bc ?
                                <img src='/images/bio7.webp' alt='dummy'/>:
                                cut ?
                                <img src='/images/bio8.webp' alt='dummy'/> :
                                cgb ?
                                <img src='/images/bio9.webp' alt='dummy'/> :
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                                {lam ? <p className='text-muted firstparavalue'>Lamination is the technique/process of manufacturing a material in multiple layers, so that the composite material achieves improved strength, stability, sound insulation, appearance, or other properties from the use of the differing materials, such as plastic which is now being replaced with Dr Bio Polymers. A laminate is a permanently assembled object created using heat, pressure, welding, or gluing. Dr Bio Laminated films are a special form of bio plastic matrix composite consisting of layers of reinforcing materials that have been impregnated with thermosetting resins, bonded together, and cured under heat and pressure. 
                                </p> : null}
                                {shp ? <p className='text-muted firstparavalue'>A plastic bag, poly bag, or pouch is a type of container made of thin, flexible, plastic film, nonwoven fabric, or plastic textile. Plastic bags are commonly used for containing and transporting goods such as foods, produce, powders, ice, magazines, chemicals, and waste. It is a common form of packaging. Dr Bio polymers allow to replace these bags, maintaining the same manufacturing processes and complying with the new specific to each country regulations in terms of bio-based content, compostability and biodegradation.
                                </p> : null}
                                {ag ? <p className='text-muted firstparavalue'>With the aid of agricultural plastics and bio plastics, growers can secure and significantly increase produced output per hectare while enhancing crop quality. The wide range of plasticulture applications includes greenhouse, mulch and silage stretch films, silage sheets, drip irrigation pipes, nettings, nonwovens and many more. Compared with conventional plastics, the biodegradation of Dr Bio products is a clear advantage. Conventional petroleum-based plastics are fragmented and remain in the ground for many years.
                                </p> : null}
                                {ss ? <p className='text-muted firstparavalue'>Shrink wrap, also shrink film, is a material made up normally of polymer film. When heat is applied, it shrinks tightly over whatever it is covering. Heat can be applied with a handheld heat gun (electric or gas), or the product and film can pass through a heat tunnel on a conveyor.Dr Bio delivers similar properties than polyolefins for shrink wrapping applications. When heat is applied, it shrinks tightly over whatever it is covering. It gives a sustainable solution to replace petroleum-based polymers with equivalent properties.
                                </p> : null}
                                {plf? <p className='text-muted firstparavalue'>Dr Bio Polymers manufactures an easy replacement to the  paper and cardboard packaging.

    Our paper like films can be thermoformed or heat sealed compared to traditional paper. It also gives the end products a good resistance to grease while maintaining its breathability. Dr Bio papers like films also have excellent sealing and tearing properties.

    Dr Bio paper like grade and can be processed in monolayer or multilayer machines
                                </p> : null}
                                {bc ? <p className='text-muted firstparavalue'>Injection moulding is a manufacturing process for producing parts by injecting molten material into a mould, or mold. Injection moulding can be performed with a host of materials mainly including metals (for which the process is called die-casting), glasses, elastomers, confections, and most commonly thermoplastic and thermosetting polymers. Material for the part is fed into a heated barrel, mixed (using a helical screw), and injected into a mould cavity, where it cools and hardens to the configuration of the cavity.
                                </p> : null}
                                {cut ? <p className='text-muted firstparavalue'>Plates, spoons, forks and glasses are made using this process. Dr Bio Polymers can help make the environment better by reducing the load of non recyclable polymers that are currently used . Dr Bio can help in making it fully compostable and safe for contact with food when produced. The single-use items have a negative image for the environment due to the large amount of waste generated. Dr Bio polymers catering materials, such us straws, single use plates or bowls, become environmentally friendly as they are produced from sustainable materials and renewable sources.
                                </p> : null}
                                {cgb ? <p className='text-muted firstparavalue'>Dr. Bio bio-degradable Disposable Garbage Bag is made from corn starch and known for its fine finish and long service life. Dr. Bio dustbin bags comes with a simple perforation technology for separating the bags from the roll. These trash bags offer convenience and high degree of hygiene. This Made in INDIA product is very useful in places like Offices, Hospitals, Restaurants, Home, Bathroom, Kitchen etc. 
                                Our garbage bags are leak & tear proof, durable & strong and perfect for wet and dry waste. This fits the trash cans conveniently without tearing or leaking.
                                </p> : null}

                            </div>
                        </div>
                        {/* <div className='paraoutdoor1 '>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {fp?<p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>: null}

                                    {lam?<p>Lamination adds a protective layer, ensuring documents withstand wear, tear, and maintain quality over time.</p>: null}

                                    {shp?<p>Offer bags made from biodegradable or recyclable materials, reducing plastic pollution and promoting eco-conscious consumer choices.</p>: null}

                                    {ag?<p>Emphasize eco-friendly techniques, like crop rotation and organic farming, ensuring long-term soil fertility and minimizing environmental impact.</p>: null}

                                    {ss?<p>Shrink films provide secure packaging for various products, while stretch films offer flexible wrapping, ensuring safe transport and storage for diverse items.</p>: null}

                                    {plf?<p>Paper-like films offer a sustainable option, resembling the look and feel of paper while being made from eco-friendly materials, reducing the reliance on traditional paper products.</p>: null}

                                    {bc?<p>Bottles and containers come in various shapes and sizes, catering to a wide range of products, from beverages and cosmetics to food and pharmaceuticals.</p>: null}

                                    {cut?<p>Cutlery includes a wide range of utensils, from knives, forks, and spoons to specialized tools, catering to different dining needs and cuisines.</p>: null}
                                    {cgb?<p>Compostable garbage bags are made from biodegradable materials, offering an environmentally friendly alternative for waste disposal, reducing plastic pollution and supporting composting initiatives.</p>: null}

                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {fp?<p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>: null}

                                    {lam? <p>Laminated materials resist water damage, making them suitable for various environments, including outdoor and high-moisture areas.</p> : null}

                                    {shp? <p>Provide sturdy, reusable bags that withstand wear, reducing the need for single-use plastic bags and encouraging sustainable shopping habits.</p> : null}

                                    {ag? <p>Encourage planting a variety of crops to enhance soil health, reduce pest pressure, and provide farmers with diversified income sources.</p> : null}

                                    {ss? <p>Both films can be tailored to fit specific products, reducing excess material usage and minimizing packaging waste, promoting efficient and sustainable packaging practices</p> : null}

                                    {plf? <p>These films can be used in various applications, such as packaging, labels, and promotional materials, providing a paper-like appearance without sacrificing durability and flexibility.</p> : null}

                                    {bc? <p>Available in glass, plastic, metal, and biodegradable materials, offering options for durability, eco-friendliness, and specific product requirements.</p> : null}

                                    {cut? <p>Available in stainless steel, bamboo, biodegradable plastics, and other materials, offering options for durability, eco-friendliness, and specific usage requirements.</p> : null}
                                    {cgb?<p>These bags break down naturally into organic matter, leaving no harmful residues. They are made from plant-based materials like cornstarch and sugarcane, ensuring they decompose without harming the environment.</p>: null}
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {fp?<p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>: null}
                                    
                                    {lam? <p>Lamination preserves colors and text clarity, preventing fading due to sunlight and environmental factors, ensuring long-lasting vibrancy.</p> : null}

                                    {shp? <p>Allow businesses to personalize bags with logos and designs, enhancing brand visibility while promoting eco-friendly practices.</p> : null}

                                    {ag? <p>Promote efficient irrigation methods and rainwater harvesting, optimizing water usage for crops and mitigating water scarcity issues.</p> : null}

                                    {ss? <p>Shrink films create a protective seal around products, safeguarding them from dust, moisture, and tampering, ensuring product integrity and customer satisfaction.</p> : null}

                                    {plf? <p>Despite their paper-like texture, these films are tear-resistant and durable, ensuring products remain protected during handling and transportation.</p> : null}

                                    {bc? <p>Sealable containers maintain product freshness, extend shelf life, and prevent contamination, ensuring quality and safety for consumers.</p> : null}

                                    {cut? <p>Well-designed cutlery ensures comfortable grip and ease of use, enhancing the dining experience for users and adding practicality to meal preparation.</p> : null}
                                    {cgb?<p>Compostable garbage bags can be disposed of along with organic waste, supporting composting systems and contributing to the creation of nutrient-rich soil for agriculture and landscaping.</p>: null}
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {fp ?<p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>: null}
                                    
                                    {lam? <p> Laminated surfaces are easy to clean, making them ideal for frequently handled items that may require occasional cleaning, ensuring consistent presentation.</p> : null}

                                    {shp? <p>Design bags with multiple compartments and features, catering to diverse needs such as grocery shopping, travel, and organizing belongings.</p> : null}

                                    {ag? <p>Utilize technology, such as GPS and sensors, to optimize inputs like fertilizers and pesticides, improving productivity while minimizing waste.</p> : null}

                                    {ss? <p>Stretch films tightly bind items together on pallets, preventing movement during transit, reducing the risk of damage, and improving overall stability in logistics.</p> : null}

                                    {plf? <p>Paper-like films are printable, allowing for vibrant graphics, logos, and product information, enhancing branding opportunities and attracting customer attention.</p> : null}

                                    {bc? <p>Customizable with labels, prints, and designs, bottles and containers serve as effective branding platforms, enhancing product visibility and recognition.</p> : null}

                                    {cut? <p>Cutlery comes in both disposable and reusable forms, allowing for single-use convenience or long-term use, balancing convenience and environmental concerns.</p> : null}
                                    {cgb?<p>Designed with features like odor control and leak-proof seals, compostable bags provide a hygienic and convenient way to manage kitchen and organic waste, maintaining cleanliness.</p>: null}</span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {fp ? <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>: null}
                                    {lam? <p>Lamination provides a polished, professional look, leaving a lasting impression on clients and customers, enhancing the overall aesthetic appeal.</p> : null}

                                    {shp? <p>Ensure bags are easy to clean and maintain, enhancing their longevity and encouraging customers to reuse them for various purposes.</p> : null}

                                    {ag? <p>Advocate for local food systems, connecting farmers directly with consumers, ensuring fresher produce and supporting local economies.</p> : null}

                                    {ss? <p>Shrink and stretch films are cost-effective packaging options, offering durability and security without the need for additional packaging materials, optimizing overall packaging expenses.</p> : null}

                                    {plf? <p>Many paper-like films are coated for water and moisture resistance, making them suitable for items that need protection against environmental factors, extending the lifespan of printed materials.</p> : null}

                                    {bc? <p>Many containers are recyclable, promoting sustainable practices, reducing environmental impact, and meeting the demands of eco-conscious consumers.</p> : null}

                                    {cut? <p>Specialty cutlery includes steak knives, dessert forks, and chopsticks, providing specialized tools for specific foods and dining occasions, enhancing dining etiquette.</p> : null}
                                    {cgb?<p>Available in various sizes and strengths, these bags are suitable for different waste types, including food scraps, yard waste, and biodegradable packaging, accommodating diverse composting needs.</p>: null}
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {fp ? <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>: null}
                                    
                                    {lam? <p>Various finishes, such as glossy, matte, or satin, allow for customization, enhancing the visual appeal and tactile experience of materials.</p> : null}

                                    {shp? <p>Create foldable bags that can be easily stored in pockets or purses, encouraging customers to carry them conveniently, reducing reliance on disposable options.</p> : null}

                                    {ag? <p>Integrate trees and diverse plant species into farming landscapes, enhancing biodiversity, improving soil health, and mitigating climate change effects.</p> : null}

                                    {ss? <p>Shrink films conform to product shapes when heat is applied, ensuring a snug fit, while stretch films are easily stretched and wrapped around items, simplifying the packaging process.</p> : null}

                                    {plf? <p>Offer recyclable and biodegradable paper-like films, catering to environmentally conscious consumers and businesses, reducing environmental impact and promoting sustainable practices.</p> : null}

                                    {bc? <p>Some containers come with features like tamper-evident seals, child-resistant caps, and dispensers, enhancing convenience and safety for end-users.</p> : null}

                                    {cut? <p>Cutlery is manufactured following hygiene standards, ensuring safe usage, and can include features like antimicrobial coatings or dishwasher-safe materials for easy cleaning.</p> : null}
                                    {cgb?<p>Many compostable garbage bags adhere to industry standards such as ASTM D6400 or EN 13432, ensuring they meet compostability requirements, giving consumers confidence in their eco-friendly choice.</p>: null}
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {fp ?<p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>: null}
                                    
                                    {lam? <p>Certain laminates enable writing and erasing, providing flexibility for items like calendars and educational materials, allowing for easy updates and changes.</p> : null}

                                    {shp? <p>Raise awareness about the environmental impact of plastic bags, advocating for responsible consumption and the importance of switching to reusable alternatives.</p> : null}

                                    {ag? <p>Empower farmers with modern agricultural techniques, educating them about sustainable practices and fostering knowledge exchange within communities.</p> : null}

                                    {ss? <p>Explore eco-friendly options, such as recyclable or biodegradable films, minimizing environmental impact and appealing to environmentally conscious clients.</p> : null}

                                    {plf? <p>These films are lightweight and flexible, making them easy to handle and suitable for various packaging needs, adapting to different shapes and sizes of products.</p> : null}

                                    {bc? <p>From single-serving bottles for beverages to storage containers for household items, they serve diverse purposes, meeting the needs of various industries and consumers.</p> : null}

                                    {cut? <p>Cutlery options cater to various dietary preferences, including child-friendly designs, ergonomic adaptations, and utensils for individuals with specific needs, ensuring inclusivity.</p> : null}
                                    {cgb?<p>By decomposing naturally, compostable bags reduce the volume of waste in landfills, lessening the environmental burden and promoting a more sustainable waste management approach.</p>: null}
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {fp ?<p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>: null}
                                    
                                    {lam? <p>Biodegradable or recyclable laminating options cater to eco-conscious clients, promoting sustainability and responsible business practices, reducing environmental impact.</p> : null}

                                    {shp? <p>Engage with local communities, distributing reusable bags and organizing awareness campaigns to encourage widespread adoption of eco-friendly shopping practices.</p> : null}

                                    {ag? <p>Implement climate-smart strategies, like drought-resistant crop varieties and soil conservation, making agriculture resilient to changing climate patterns.</p> : null}

                                    {ss? <p>Shrink films can be printed with branding and product information, enhancing visibility and brand recognition, making products more appealing to customers on store shelves.</p> : null}

                                    {plf? <p>Paper-like films provide a cost-effective solution, as they use fewer resources compared to traditional paper production, making them an economical and environmentally friendly choice.</p> : null}

                                    {bc? <p>Manufactured adhering to strict quality standards and regulations, ensuring compliance with industry requirements, safety, and consumer satisfaction.</p> : null}

                                    {cut? <p>Cutlery designs range from classic and elegant to modern and colorful, allowing users to express their style while adding visual appeal to table settings and dining experiences.</p> : null}
                                    {cgb?<p>Promote awareness about compostable options, encouraging consumers and businesses to adopt environmentally responsible practices, fostering a greener and more sustainable future.</p>: null}
                                    </span>

                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </div>
    

    </>
      
    )
}

export default ProductDetail;