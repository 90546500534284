import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../../css/Productdetails.css';

const Ms = ({id,cid,cat}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "marinesafety"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "marinesafety"&&cid==='2'){
            setShow("vcp2")
          }  
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "LIFE JACKET"  : show === "vcp2" ? "Life Buoy"  
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/marine jacket.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/safety buoy.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>A life jacket, also known as a personal flotation device (PFD), is a vital piece of equipment designed to provide buoyancy and ensure the safety of individuals in aquatic environments.  Life jackets are crafted to provide buoyancy, helping individuals stay afloat in water. The buoyant material ensures that even non-swimmers or exhausted individuals can remain on the water's surface, reducing the risk of drowning.Life jackets find applications across various water activities, including boating, kayaking, fishing, and water sports. Their versatility makes them a standard safety measure for individuals of all ages engaging in aquatic recreation.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>A life buoy stands as a vital element in marine safety, serving as a buoyant and easily deployable device designed to save lives in emergency situations at sea. With its primary function centered around buoyancy, a life buoy ensures that individuals in distress remain afloat, preventing potential drowning incidents.
                                The distinctive ring or horseshoe design of a life buoy plays a crucial role in its usability during water rescues. This configuration allows for easy handling and swift deployment, making it a practical tool for both professional rescuers and individuals on watercraft</p> : 
                                null}
                            </div>
                        </div>

                        < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>The vest life jacket stands as a pivotal element in ensuring water safety, offering a blend of functionality, comfort, and adaptability. Engineered with strategic buoyancy, these life jackets are designed to provide optimal floatation in water, contributing to the prevention of accidents and drownings. The vest-style design not only ensures effective buoyancy but also delivers a comfortable and ergonomic fit, allowing individuals to engage in water activities with ease and freedom of movement.
                            </p> : null}
                            { show === "vcp2" ? <p className='text-muted firstparavalue'>High visibility is a key feature of life buoys, typically crafted in bright colors with reflective strips. This design ensures that the buoy is easily discernible, even in challenging conditions or low-light situations. The enhanced visibility not only aids rescuers in locating individuals in the water but also serves as a visual beacon, guiding efforts during emergencies.
                            </p> : null}
                            
                            </div>

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Vest life jackets are designed to offer optimal buoyancy, ensuring effective floatation in water. The strategic placement of buoyant materials in the vest-style design provides stability and keeps the wearer afloat.</p>: null}
                                    {show === "vcp2"?<p>The primary function of a life buoy is to provide buoyancy, enabling it to float on water. This buoyancy is crucial for supporting individuals in distress and preventing drowning.</p>: null}
                                   
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Vest life jackets are known for their ergonomic fit, providing comfort and allowing for ease of movement. This ensures that individuals can engage in water activities without feeling restricted, promoting both safety and enjoyment.</p>: null}
                                    {show === "vcp2"?<p>Life buoys typically feature a ring or horseshoe design, making them easy to handle and throw to individuals in the water. This design enhances their usability in rescue situations, allowing for quick deployment.</p>: null}
                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> These life jackets typically feature adjustable straps and closures, allowing for a secure and customizable fit. The snug fit ensures that the life jacket stays in place during water activities, minimizing the risk of slipping off.</p>: null}
                                    {show === "vcp2"?<p>Life buoys are often designed in bright colors with reflective strips, ensuring high visibility in the water. This makes it easier for rescuers to spot individuals in need of assistance, especially during emergencies or low-light conditions.</p>: null}
                                   
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Vest life jackets are versatile and suitable for various water activities, including boating, kayaking, and paddleboarding. </p>: null}
                                    {show === "vcp2"?<p>Some life buoys come equipped with a safety line or strap that can be attached to the person in distress. This feature enhances the security of the rescue operation by preventing the buoy from drifting away.</p>: null}
                                   
                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Vest life jackets often come in bright colors and incorporate reflective elements. This enhances visibility, making wearers easily noticeable in the water, especially during low-light conditions.</p>: null}
                                    {show === "vcp2"?<p> Life buoys are indispensable components of marine safety, serving as immediate aids for water rescue operations. This ensures that vessels are well-equipped to handle emergencies and provide assistance to those in need.</p>: null}
                                    
                                    </span>
                            </div>

                        </div>
    </div>
  )
}

export default Ms