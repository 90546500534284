import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../../css/Productdetails.css';

const ExProd = ({id,cid,cat}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "excul-prod"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "excul-prod"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "excul-prod"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "excul-prod"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "excul-prod"&&cid==='5'){
            setShow("vcp5")
          }
       
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "MEDICAL PURPOSE"  : show === "vcp2" ? "HARDWARE"  : 
                               show === "vcp3" ? "GENERAL"  : show === "vcp4" ? "SAFETY SIGNS AND SIGNALS"  
                               :show === "vcp5" ? "SAFETY NET" : ""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/medical.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/hardware.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/general.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/safety.webp' alt='dummy'/> 
                                </>:
                               show === "vcp5" ?
                                <>
                                    <img src='/images/safetynet.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Promoting health and safety involves a combination of immediate response measures, continuous monitoring, and general well-being practices. First aid skills are fundamental, providing immediate assistance in emergencies through techniques like wound care and CPR. Concurrently, the use of a pulse oximeter becomes paramount, allowing for the real-time monitoring of oxygen saturation levels and pulse rates, particularly crucial during illnesses or recovery periods.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>
Efficient mechanical work relies on a comprehensive set of hardware tools and equipment. Power tools, such as drills and grinders, enhance productivity through versatility, while hand tools like wrenches provide manual precision. Precision measurement instruments ensure accurate results, and safety equipment minimizes the risk of injuries. The availability of fasteners and hardware supplies is crucial for seamless assembly. This curated selection of tools not only boosts efficiency and precision but also prioritizes safety in mechanical tasks</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>
Ensuring a secure work environment involves a multifaceted approach to body protection, electrical safety, and safe material handling. Body protection equipment, such as coveralls and safety vests, offers versatile coverage, safeguarding individuals from potential workplace hazards like chemicals, splashes, and debris. This adaptable attire ensures the overall safety and well-being of workers across diverse industries and environments.In the realm of electrical safety, measures such as insulating gloves and safety barriers are paramount for preventing electric shock and mitigating other electrical hazards. </p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>In any workplace, the effective use of notice signs and warning signs plays a crucial role in enhancing overall safety. Notice signs serve as communicative tools for disseminating non-urgent yet essential information. These signs provide general instructions, workplace policies, or relevant details that contribute to a well-informed environment. By guiding individuals on safe practices and reinforcing workplace rules, notice signs actively contribute to maintaining a secure working environment.The emphasis on preventive behavior fostered by warning signs contributes to the development of a safety-conscious culture within the workplace.</p> : 
                                null}
                                {show === "vcp5"? <p className='text-muted firstparavalue'>VA safety net is a fundamental component of fall protection measures in various work environmentsSafety nets act as crucial barriers to prevent falls from elevated work areas, providing a reliable means of protection for workers engaged in tasks such as construction, maintenance, or roof work.Safety nets are adaptable to different work environments, making them suitable for use in construction sites, industrial facilities, and otherIn the event of a fall, safety nets are designed to absorb the impact and reduce the force of the fall. This minimizes the risk of serious injuries to individuals working at heights. </p> : 
                                null}
                                
                            </div>
                        </div>

                        < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>Adopting general health measures further enhances overall well-being. This includes embracing preventive practices such as regular exercise, maintaining a balanced diet, and ensuring adequate sleep. These habits contribute to sustained health and resilience against potential health risks.Incorporating these elements into daily life fosters a holistic approach to health, emphasizing both immediate responses to emergencies and proactive measures for long-term well-being.
                            </p> : null}
                            { show === "vcp2" ? <p className='text-muted firstparavalue'>Hand tools such as wrenches, screwdrivers, and pliers provide manual precision, allowing for intricate adjustments and fine-tuning in various mechanical applications. Their manual operation ensures control and accuracy, particularly in tasks that require a delicate touch or meticulous handling
                            </p> : null}
                            { show === "vcp3" ? <p className='text-muted firstparavalue'>Ropes and lifting belts play a critical role in safe material handling, providing stability and security during lifting and transportation activities. These tools are indispensable for preventing accidents and ensuring the secure movement of heavy loads, contributing to overall workplace safety.Fall protection equipment, including harnesses and lanyards, addresses the heightened risks associated with tasks performed at elevated heights
                            </p> : null}
                            { show === "vcp4" ? <p className='text-muted firstparavalue'>Both notice signs and warning signs share a commitment to clarity in communication. Utilizing clear and universally understood symbols ensures that individuals, regardless of language barriers, can quickly comprehend the communicated information. This universal approach enhances overall safety awareness and understanding among diverse members of the workforce.
                            </p> : null}
                            { show === "vcp5" ? <p className='text-muted firstparavalue'>Bird protection nets play a crucial role in safeguarding environments from the challenges posed by avian intrusion. These nets serve as effective deterrents against birds seeking nesting or roosting spots in structures like buildings and balconies. By creating a barrier, these nets discourage birds from settling in undesirable areas, preserving the integrity of architectural spaces.
                            </p> : null}
                            </div>

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>First aid skills are essential for providing immediate care in emergencies, encompassing basic techniques such as wound care, bandaging, and CPR.</p>: null}
                                    {show === "vcp2"?<p>Power tools, such as drills, grinders, and saws, enhance mechanical tasks by providing efficiency, precision, and versatility. These tools are essential for cutting, shaping, and fastening materials in various applications.</p>: null}
                                    {show === "vcp3"?<p>Body protection equipment, such as coveralls and safety vests, provides versatile coverage, protecting against various workplace hazards like chemicals, splashes, and debris</p>: null}
                                    {show === "vcp4"?<p>Serve as tools for disseminating essential but non-urgent information within a workplace, providing general instructions, workplace policies, or relevant details.</p>: null}
                                    {show === "vcp5"?<p>Bird protection nets serve as an effective deterrent against birds seeking to nest or roost in buildings, balconies, or other structures. These nets create a barrier that discourages birds from settling in unwanted areas.</p>: null}
                                   
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> A pulse oximeter is a valuable device for monitoring oxygen saturation and pulse rate, especially useful during illnesses or recovery.</p>: null}
                                    {show === "vcp2"?<p>Hand tools, including wrenches, screwdrivers, and pliers, are indispensable for precise and controlled mechanical work. Their manual operation allows for intricate adjustments and fine-tuning in various tasks.</p>: null}
                                    {show === "vcp3"?<p>Electrical safety measures, including insulating gloves and safety barriers, are essential for preventing electric shock and other electrical hazards.</p>: null}
                                    {show === "vcp4"?<p>Encourage proactive behavior by alerting individuals to specific risks, prompting them to exercise caution and adhere to safety protocols.</p>: null}
                                    {show === "vcp5"?<p>By preventing birds from nesting in or around buildings, bird protection nets contribute to maintaining a clean and hygienic environment. This helps preserve the aesthetic appeal of structures and prevents potential health hazards associated with bird droppings.</p>: null}
                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Adopting preventive measures like regular exercise, a balanced diet, and sufficient sleep contributes to overall health and well-being.</p>: null}
                                    {show === "vcp2"?<p>Instruments like calipers, micrometers, and tape measures are crucial for accurate measurements in mechanical work. Precision in measurement is fundamental for ensuring the proper fit and function of components.</p>: null}
                                    {show === "vcp3"?<p> Ropes and lifting belts play a crucial role in safe material handling, offering stability and security during lifting and transportation activities. </p>: null}
                                    {show === "vcp4"?<p>Contribute to a well-informed environment, promoting awareness and understanding among individuals in the workplace regarding non-urgent but crucial information.</p>: null}
                                    {show === "vcp5"?<p> In agricultural settings, bird protection nets shield crops from bird damage. These nets act as a barrier, preventing birds from reaching and consuming fruits, vegetables, or seeds, thereby preserving crop yields.</p>: null}
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Having a well-equipped first aid kit and an emergency plan ensures a swift and effective response to unexpected health events.</p>: null}
                                    {show === "vcp2"?<p>Safety is paramount in mechanical tasks, and appropriate equipment such as gloves, safety glasses, and ear protection is essential. These ensure the well-being of individuals by minimizing the risk of injuries and exposure to potential hazards.</p>: null}
                                    {show === "vcp3"?<p>These tools prevent falls and minimize the impact in case of an accident, prioritizing the safety of individuals involved in construction, maintenance, or other elevated work.</p>: null}
                                    {show === "vcp4"?<p>Reinforce safety practices and contribute to accident prevention by providing guidance on safe behavior within the workplace.</p>: null}
                                    {show === "vcp5"?<p>Bird nests and droppings can cause structural damage over time. Bird protection nets serve as a proactive measure to minimize the potential for damage to buildings, equipment, and machinery caused by nesting activities.</p>: null}
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Basic hygiene practices, including regular handwashing and maintaining cleanliness, are crucial for preventing infections and promoting a healthy environment.</p>: null}
                                    {show === "vcp2"?<p> Nuts, bolts, screws, and other fasteners are the backbone of mechanical assembly. Ensuring a well-stocked supply of these components, along with various hardware items, is crucial for seamless construction and repair work.</p>: null}
                                    {show === "vcp3"?<p> In environments with potential chemical exposure, chemical-resistant clothing, including coveralls, gloves, and aprons, acts as a protective barrier.</p>: null}
                                    {show === "vcp4"?<p>Serve as proactive measures, encouraging individuals to adopt caution and preventive actions in areas with specific risks, ultimately contributing to a safety-conscious work environment.</p>: null}
                                    {show === "vcp5"?<p>In industrial and commercial settings, bird protection nets contribute to workplace safety by minimizing the presence of birds that could pose safety risks.</p>: null}
                                 
                                    </span>
                            </div>
                          
                        </div>
    </div>
  )
}

export default ExProd