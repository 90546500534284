import React from 'react'
import { BiSolidHome } from 'react-icons/bi'
import '../css/Contact.css'
import { Tilt } from 'react-tilt'
import { BsArrowRight, BsFillTelephoneFill } from 'react-icons/bs'
import { AiFillMail } from 'react-icons/ai'
import { useState } from 'react'
import axios from 'axios'
import { Alert } from 'react-bootstrap'

const Contacts = ({windowSize}) => {
    const defaultOptions = {
        reverse:        false,  // reverse the tilt direction
        max:            35,     // max tilt rotation (degrees)
        perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
        speed:          1000,   // Speed of the enter/exit transition
        transition:     true,   // Set a transition on enter/exit.
        axis:           null,   // What axis should be disabled. Can be X or Y.
        reset:          true,    // If the tilt effect has to be reset on exit.
        easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }

    const [errors, setErrors] = useState("");
      const [show, setShow] = useState(false);
      const [showMsg, setShowMsg] = useState(false);
    const [values, setValues] = useState({
        name: "",
        email: "",
        message: "",
      });

      const handleInput = (e) => {
        setValues((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      };

      const handleSubmit = (e) => {
        e.preventDefault();
    
          axios
            .post("https://infygain.in/api/contact", values)
            .then((res) => {
                if(res.data.info){
                    setValues({
                        name: "",
                        email: "",
                        message: ""
                      });
                      document.querySelector(".form").reset()
                }   else{
                  setErrors(res.data.err);
                  setShow(true);
                 
              }
            
          })
          .catch((err) => {
            console.log(err);
            setShow(true);
            setErrors("Internal Server Error");
          });
            
      };

      function alertBox() {
        if (show) {
          return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
              {errors}
            </Alert>
          );
        }
      }
      function msgBox() {
        if (showMsg) {
          return (
            <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
              {errors}
            </Alert>
          );
        }
      }
  return (
    <>
    <div className={`${windowSize[0] >900 ? "container": ""} service-contact`}>
        <div className='contact_col-1'>
            {/* contact info */}
            <div className='cont-group'>
                <div className='cont-details'>
                    <div className='cont-icon-parrent'>
                        <span className='cont-icon-hide'></span>
                        
                    <BsFillTelephoneFill className='cont-icon'/>
                    </div>
                   
                    <div className='cont-info'>
                        <span>+91 9876543210</span>
                        <span>+91 9876543210</span>
                    </div>
                </div>
                <div className='cont-details'>
                <div className='cont-icon-parrent'>
                        <span className='cont-icon-hide'></span>
                        
                    <AiFillMail className='cont-icon'/>
                    </div>
                    <div className='cont-info'>
                        <span>EdgecutTools@gmail.com,</span>
                        <span>EdgecutTools@gmail.com</span>
                    </div>
                </div>
                <div className='cont-details'>
                <div className='cont-icon-parrent'>
                        <span className='cont-icon-hide'></span>
                        
                    <BiSolidHome className='cont-icon'/>
                    </div>
                    <div className='cont-info'>
                        <span>Hari complex opp.prozone mall,</span>
                        <span>Saravanampatti, Coimbatore</span>
                    </div>
                </div>
            </div>

            <form className='form cont-formHead' onSubmit={handleSubmit}>
                <span className='cont-message'>Send message:</span> <br />
                {alertBox()}
                {msgBox()}
                <div className='cont-form'>
                    <div className='cont-input'>
                        <input type='text' placeholder='Name' name='name' onChange={handleInput} required/>
                        <input type='text' placeholder='E-mail' name='email' onChange={handleInput} required/>
                    </div>
                    <textarea placeholder='message' rows="6" name='message' onChange={handleInput} required/>
                </div>

                <button
                className="ctest-btns"
              >
                <span className="ctest-sc"></span>
                <span className="ciot-text">
                    Send Message
                  <BsArrowRight  className="cbtns-icons1" />
                </span>
              </button>
                {/* <button>Send Message</button> */}
            </form>
           
        </div>

        <div className='contact_col-2'>
            <Tilt options={defaultOptions}>
                <img src='/contactpic.jpg' alt='contact'/>
            </Tilt>
        </div>
    </div>
    </>
  )
}

export default Contacts