import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../../css/Productdetails.css';

const Cs = ({id,cid,cat}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "propre"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "propre"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "propre"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "propre"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "propre"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "propre"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "propre"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "propre"&&cid==='8'){
            setShow("vcp8")
          }
        if(id === "propre"&&cid==='9'){
            setShow("vcp9")
          }
        if(id === "propre"&&cid==='10'){
            setShow("vcp10")
          }
        if(id === "propre"&&cid==='11'){
            setShow("vcp11")
          }  
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "BODY SUITS"  : show === "vcp2" ? "FOOT PROTECTION"  : 
                               show === "vcp3" ? "HAND PROTECTION"  : show === "vcp4" ? "HEAD AND EAR PROTECTION"  :
                               show === "vcp5" ? "EYE AND FACE PROTECTION"  : show === "vcp6" ? "FALL PROTECTION"  :
                               show === "vcp7" ? "SAFETY JACKETS"  : show === "vcp8" ? "RESPIRATORY PROTECTION"  :
                               show === "vcp9" ? "Mansoon Safetys"
                              
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/bodysuit.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/foot protection.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/hand protect.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/ear protection.webp' alt='dummy'/> 
                                </>:
                               show === "vcp5" ?
                                <>
                                    <img src='/images/face.webp' alt='dummy'/> 
                                </>:
                               show === "vcp6" ?
                                <>
                                    <img src='/images/fallprotection.webp' alt='dummy'/> 
                                </>:
                               show === "vcp7" ?
                                <>
                                    <img src='/images/safetyjackets.webp' alt='dummy'/> 
                                </>:
                               show === "vcp8" ?
                                <>
                                    <img src='/images/respotoryprotection.webp' alt='dummy'/> 
                                </>:
                               show === "vcp9" ?
                                <>
                                    <img src='/images/monsoonsafety.webp' alt='dummy'/> 
                                </>:
                               
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Coveralls, boilersuits, and body suits are essential protective and versatile garments in various industries. Coveralls come in specialized forms like electric arc protection, chemical protection, and fire-retardant suits, providing comprehensive protection against specific workplace hazards. Body suits, on the other hand, offer a streamlined fit and are popular for fashion and functionality, serving as dancewear, undergarments, or standalone outfits. These garments play a vital role in ensuring safety, comfort, and style for individuals across different environments.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Foot protection is a critical aspect of workplace safety, with different types of footwear serving specific purposes. Safety shoes, designed with reinforced toes, offer protection against impact and compression in industries involving heavy objects. Gumboots are waterproof boots essential in agricultural and industrial settings, safeguarding against liquids and chemicals. Formal shoes, emphasizing style and comfort, are suitable for professional environments. These diverse footwear options ensure the safety and well-being of workers in various industries, enhancing workplace safety standards and overall productivity.</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>Hand protection is essential in ensuring workplace safety, and various types of gloves cater to specific hazards. Cut-resistant gloves provide robust protection against sharp objects, while cotton gloves offer lightweight coverage for general tasks. Leather gloves are durable, ideal for heavy-duty work, and rubber gloves safeguard against chemical exposure. Electrical gloves protect against electrical hazards, ensuring safety for workers handling live wires. Each type of glove serves a unique purpose, enhancing workplace safety and enabling workers to perform tasks securely and effectively.</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>Head and ear protection are essential for workplace safety. Safety helmets, including adjustable suspension systems and timely refills, guard against head injuries from falling objects and impacts. Meanwhile, earmuffs and earplugs provide effective hearing protection in noisy environments. Together, these measures offer a comprehensive approach, ensuring the well-being of workers by mitigating the risks associated with head injuries and hearing damage in diverse occupational settings.</p> : 
                                null}
                                {show === "vcp5"? <p className='text-muted firstparavalue'>Eye and face protection are fundamental elements of ensuring workplace safety, providing a robust defense against potential hazards that could compromise vision and facial safety. Safety goggles, designed to shield against airborne particles and projectiles, offer vital protection in industries where the risk of impact or exposure to debris is prevalent. The full seal around the eyes minimizes the chance of irritants entering, providing comprehensive protection. Many safety goggles are equipped with anti-fog coatings, ensuring clear vision even in conditions prone to fogging.</p> : 
                                null}
                                {show === "vcp6"? <p className='text-muted firstparavalue'>Fall protection is a crucial component of workplace safety, especially in industries where employees routinely work at elevated positions. The implementation of effective fall protection measures is paramount to prevent injuries resulting from falls. Personal Fall Arrest Systems (PFAS) are integral in this regard, incorporating a full-body harness, lanyard, and anchor point to arrest a worker's fall in case of an unexpected slip or trip. Guardrails and safety nets act as physical barriers and passive restraints, respectively, providing additional layers of protection.</p> : 
                                null}
                                {show === "vcp7"? <p className='text-muted firstparavalue'>Safety jackets are integral components of personal protective equipment, meticulously designed to prioritize visibility, protection, and overall safety in diverse work environments. These jackets are characterized by their high-visibility colors, often in fluorescent hues like orange, yellow, or lime green, complemented by reflective strips. This distinctive design ensures that workers remain easily discernible in low-light conditions or areas with heavy machinery, enhancing workplace safety.</p> : 
                                null}
                                {show === "vcp8"? <p className='text-muted firstparavalue'>
Respiratory protection is a crucial facet of personal protective equipment (PPE), dedicated to shielding individuals from inhaling hazardous airborne particles, gases, vapors, or contaminants in diverse occupational settings. This comprehensive approach involves various types of respirators, each tailored to address specific airborne threats.Particulate respirators, exemplified by N95 masks, are prevalent for their efficacy in filtering out at least 95% of airborne particles, making them crucial in industries ranging from healthcare to construction</p> : 
                                null}
                                {show === "vcp9"? <p className='text-muted firstparavalue'>Ensuring safety during the monsoon season is imperative to protect individuals from the unique challenges posed by heavy rainfall and associated risks. Proper footwear plays a pivotal role in preventing slips and falls on wet surfaces, emphasizing the need for non-slip shoes or rain boots with good traction, especially in bustling workplaces or high-traffic areas.Enhancing visibility is crucial in conditions of reduced sight during heavy rain. Wearing reflective clothing ensures that individuals remain noticeable to drivers and pedestrians, contributing to overall safety, particularly for those who commute or work outdoors.</p> : 
                                null}
                              
                               
                               

                            </div>
                        </div>

                        < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>
Coveralls, boilersuits, and body suits serve essential functions in diverse fields. Coveralls, available in specialized forms like electric arc protection and chemical protection, offer crucial safety in hazardous environments by shielding against specific risks. Meanwhile, body suits provide versatility, doubling as fashionable attire and functional sportswear. Together, these garments play a vital role in ensuring safety, comfort, and style for individuals across different sectors, from industrial settings to fashion and sports.
                            </p> : null}
                            { show === "vcp2" ? <p className='text-muted firstparavalue'>
Foot protection is vital in workplace safety, and different types of footwear are tailored for specific needs. Safety shoes offer protection against impact and compression, crucial in industries dealing with heavy objects. Gumboots shield against liquids and chemicals, essential in agricultural and industrial sectors. Formal shoes prioritize style and comfort, suitable for professional settings. These specialized footwear options enhance workplace safety, preventing injuries, and ensuring employees can perform effectively in diverse work environments.
                            </p> : null}
                            { show === "vcp3" ? <p className='text-muted firstparavalue'>Hand protection is paramount in ensuring workplace safety, and different types of gloves are designed to address specific risks. Cut-resistant gloves offer protection against sharp objects, cotton gloves provide comfort for general tasks, leather gloves are durable for industrial work, rubber gloves shield against chemicals, and electrical gloves protect from electrical hazards. Each type serves a unique purpose, enhancing safety, enabling workers to perform tasks securely and efficiently in various work environments.
                            </p> : null}
                            { show === "vcp4" ? <p className='text-muted firstparavalue'>Head and ear protection are crucial aspects of workplace safety. Safety helmets provide comprehensive protection against falling objects, impacts, and electrical hazards, with an adjustable suspension system ensuring a secure fit. Helmet refills maintain helmet integrity over time. Earmuffs and earplugs offer effective noise reduction, safeguarding against hearing damage in noisy environments. These measures, including versatile hearing protection options, contribute significantly to overall workplace safety, addressing the risks associated with head injuries and hearing-related issues.
                            </p> : null}
                            { show === "vcp5" ? <p className='text-muted firstparavalue'>Complementing this, face shields offer extensive coverage, safeguarding the entire face from splashes, sparks, and hazardous materials. This is particularly crucial in environments where facial exposure poses a risk. Moreover, these face shields are often designed to work seamlessly with other personal protective equipment (PPE), such as safety goggles or respirators, creating a layered approach to facial protection.
                            </p> : null}
                            { show === "vcp6" ? <p className='text-muted firstparavalue'>Safety harnesses, a key element of fall arrest systems, are worn by workers and connected to anchor points, ensuring a secure attachment that prevents falls from elevated positions. Warning lines and barricades serve as visible markers, delineating safe areas and preventing workers from approaching unprotected edges. These proactive measures contribute to accident prevention.
                            </p> : null}
                            { show === "vcp7" ? <p className='text-muted firstparavalue'>Notably, safety jackets extend their utility beyond daylight hours. Many variants feature reflective materials that significantly amplify visibility during nighttime or low-light situations, addressing the unique challenges posed by diminished visibility in such scenarios. In addition to enhancing visibility, safety jackets often incorporate weather-resistant features, providing protection against rain and cold weather, further fortifying workers against the elements.
                            </p> : null}
                            { show === "vcp8" ? <p className='text-muted firstparavalue'>Full-face respirators, covering the entire face and incorporating eye protection, find application in scenarios where exposure extends beyond the respiratory system. Fit testing is a critical aspect, ensuring a tight seal for maximum efficacy, particularly important for N95 respirators and tight-fitting facepieces.A robust respiratory protection program involves thorough training and education, imparting knowledge on proper usage, maintenance, and storage of respirators.
                            </p> : null}
                            { show === "vcp9" ? <p className='text-muted firstparavalue'>The monsoon season also brings an increased risk of waterborne diseases. Taking preventive measures, such as avoiding stagnant water, maintaining personal hygiene, and seeking appropriate vaccinations, is essential for safeguarding health during this period.In worksites, especially in construction and outdoor settings, additional safety precautions are paramount. 
                            </p> : null}
                           
                            
                            
                            </div>

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Body suits, such as coveralls and boilersuits, offer comprehensive coverage from head to toe, ensuring overall protection in various work environments.</p>: null}
                                    {show === "vcp2"?<p>Reinforced toes (steel or composite) for protection against impact and compression.Ideal for industries where heavy objects or machinery pose a risk to the feet.</p>: null}
                                    {show === "vcp3"?<p>Specifically crafted to safeguard against cuts and abrasions in industries dealing with sharp objects or machinery, ensuring worker safety in high-risk environments.</p>: null}
                                    {show === "vcp4"?<p>Safety helmets are designed to offer comprehensive protection for the head, safeguarding against falling objects, impacts, and electrical hazards in industries such as construction and manufacturing.</p>: null}
                                    {show === "vcp5"?<p>Safety goggles are designed to provide a secure barrier against impact and airborne particles, offering crucial eye protection in environments where there's a risk of projectiles or debris.</p>: null}
                                    {show === "vcp6"?<p>Safety belts serve as a personal restraint system, preventing workers from reaching areas where the risk of falling is present. They are essential for tasks conducted at heights where a fall could occur.</p>: null}
                                    {show === "vcp7"?<p> Mesh reflective jackets feature a breathable design, incorporating mesh panels that enhance ventilation. This is particularly beneficial in warmer climates or during strenuous activities, ensuring comfort for the wearer.</p>: null}
                                    {show === "vcp8"?<p>Disposable respirators are designed with specific Particle Filtration Efficiency (PFE) ratings, indicating their effectiveness in filtering out airborne particles. This feature ensures protection against dust, allergens, and other particulate matter.</p>: null}
                                    {show === "vcp9"?<p>Utilize raincoats, shirts, and pants made from water-resistant materials to stay dry and comfortable during heavy rainfall.</p>: null}
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Specifically designed suits with flame-resistant materials protect against the intense heat generated during electric arc flashes, crucial for occupations with electrical hazards.</p>: null}
                                    {show === "vcp2"?<p>Waterproof and protective against liquids and chemicals.Commonly used in agricultural, industrial, or wet environments.</p>: null}
                                    {show === "vcp3"?<p>Lightweight and breathable, cotton gloves are ideal for general tasks, providing comfort and maintaining hygiene standards in diverse environments.</p>: null}
                                    {show === "vcp4"?<p>Equipped with an adjustable suspension system, safety helmets ensure a secure and comfortable fit for various head sizes, enhancing wearer comfort and safety.</p>: null}
                                    {show === "vcp5"?<p>Featuring a full seal around the eyes, safety goggles ensure comprehensive protection, minimizing the risk of irritants, liquids, or particles entering from the sides, top, or bottom.</p>: null}
                                    {show === "vcp6"?<p>Equipped with body support and attachment points, safety belts allow workers to secure themselves to a stable structure, minimizing the potential for falls and providing a measure of safety while working at elevated positions.</p>: null}
                                    {show === "vcp7"?<p> These jackets are often lightweight and flexible, allowing for ease of movement. The combination of mesh material and flexibility ensures that workers can perform their tasks comfortably without compromising safety.</p>: null}
                                    {show === "vcp8"?<p>Many disposable respirators fall under the N95 classification, signifying their ability to filter out at least 95% of airborne particles. This makes them particularly valuable in healthcare settings and industries where protection against infectious agents is paramount.</p>: null}
                                    {show === "vcp9"?<p>Opt for full coats that cover both the upper and lower body, providing extensive protection against adverse weather conditions.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Specialized body suits are constructed with materials resistant to liquid and chemical penetration, providing a barrier against hazardous substances in industries like chemical manufacturing or laboratories.</p>: null}
                                    {show === "vcp2"?<p>Suited for professional environments where style and comfort are prioritized.While not designed for specific hazards, they contribute to a polished appearance.</p>: null}
                                    {show === "vcp3"?<p>Recognized for their durability, leather gloves are robust defenders against rough surfaces, offering effective protection from abrasions and punctures in heavy-duty work.</p>: null}
                                    {show === "vcp4"?<p>Helmet refills, including replacement components and inner linings, contribute to the maintenance of safety helmets. They ensure the integrity of the helmet over time, enhancing comfort and protection.</p>: null}
                                    {show === "vcp5"?<p>Many safety goggles come equipped with an anti-fog coating, maintaining clear vision in conditions where temperature variations may cause fogging, ensuring uninterrupted visibility for the wearer.</p>: null}
                                    {show === "vcp6"?<p>Safety nets are passive fall arrest systems, providing a physical barrier beneath elevated work areas. They serve as a safety net, catching workers in the event of a fall and minimizing the risk of injuries.</p>: null}
                                    {show === "vcp7"?<p>Fabric reflective jackets are designed with weather-resistant properties, providing protection against rain and other adverse weather conditions. This ensures that workers remain dry and comfortable even in inclement weather.</p>: null}
                                    {show === "vcp8"?<p>Being disposable, these respirators offer the convenience of single-use, eliminating the need for cleaning or maintenance. This is especially practical in situations where regular decontamination may be challenging or impractical.
</p>: null}
                                    {show === "vcp9"?<p>Wear gumboots, with their waterproof design and extended height, to safeguard feet from water and potential contaminants, particularly in worksites or waterlogged areas..</p>: null}
                                  
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Suits made from flame-resistant materials serve as protective layers in environments with fire hazards, such as welding or firefighting.</p>: null}
                                    {show === "vcp2"?<p>Safety shoes and gumboots designed for prolonged wear with features like arch support.Aim to reduce fatigue and promote overall foot well-being.</p>: null}
                                    {show === "vcp3"?<p>Engineered for chemical resistance, rubber gloves serve a crucial role in protecting against corrosive substances, making them essential in environments where such exposures are prevalent.</p>: null}
                                    {show === "vcp4"?<p>Earmuffs are designed to reduce exposure to harmful noise levels, providing protection against hearing damage in noisy environments such as construction sites or industrial settings.</p>: null}
                                    {show === "vcp5"?<p>Face shields offer extensive coverage, protecting the entire face from splashes, sparks, and potentially hazardous materials, making them essential in settings where there is a risk of facial exposure.</p>: null}
                                    {show === "vcp6"?<p>An anchorage line serves as a secure attachment point, allowing workers to connect their fall protection equipment, such as safety belts or harnesses. It plays a crucial role in ensuring a reliable anchor for fall arrest systems.</p>: null}
                                    {show === "vcp7"?<p> Reflective vest belts typically come with adjustable features, allowing for a customizable fit for various body sizes. This ensures that the reflective elements are positioned optimally for visibility while accommodating different wearers.</p>: null}
                                    {show === "vcp8"?<p>Disposable respirators often feature a comfortable fit and user-friendly design. They are lightweight, easy to don and doff, and provide a secure seal, ensuring wearer comfort during extended periods of use.
</p>: null}
                                    {show === "vcp9"?<p>Prioritize footwear with anti-skid soles to enhance traction on wet surfaces, minimizing the risk of slips and falls.</p>: null}
                                  
                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Beyond their protective features, body suits are known for their versatility, allowing ease of movement. They eliminate the need for coordinating separate garments, promoting efficiency in various work settings.</p>: null}
                                    {show === "vcp2"?<p>Manufactured in compliance with industry safety standards.Undergo testing to ensure protection, durability, and overall safety for workers in hazardous environments.</p>: null}
                                    {show === "vcp3"?<p>Tailored for electrical hazard protection, electrical gloves play a vital role in preventing electric shock, ensuring the safety of workers handling live wires or electrical equipment.</p>: null}
                                    {show === "vcp4"?<p>Earplugs, being compact and easily insertable into the ear canal, offer a convenient solution for hearing protection, especially in situations where earmuffs might be impractical or uncomfortable.</p>: null}
                                    {show === "vcp5"?<p>Face shields are often designed to be compatible with other personal protective equipment (PPE), such as safety goggles or respirators, ensuring a comprehensive and layered approach to facial protection in diverse work environments.</p>: null}
                                    {show === "vcp6"?<p>Fall arrestors are devices designed to rapidly decelerate a fall, reducing the impact force on the worker. They are a key component of fall arrest systems, enhancing safety by minimizing the consequences of a fall.</p>: null}
                                    {show === "vcp7"?<p>High visibility jackets, incorporating reflective elements, offer a versatile design suitable for a range of industries. They are commonly used in construction, traffic management, and emergency services, providing visibility and safety in various work environments.</p>: null}
                                    {show === "vcp8"?<p>Disposable respirators find widespread applicability across various industries, from construction and manufacturing to healthcare. Their versatility makes them a convenient choice for temporary or infrequent exposure to airborne hazards.</p>: null}
                                    {show === "vcp9"?<p>Incorporate visibility-enhancing features, such as reflective strips on raincoats, full coats, or gumboots, to ensure individuals remain easily noticeable in low-light or rainy conditions.</p>: null}
                                   
                                    </span>
                            </div>
                            {/*  6 points  */}
                         
                            
                            {/* 7 points  */}
                            
                            {/* 8 points  */}
                           
                        </div>
    </div>
  )
}

export default Cs