import React from 'react'
import '../css/CnBanner.css'
import { BsArrowRight } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';

const CnBanner = ({windowSize,title,image}) => {
    const {id} = useParams()
    const images = {
        backgroundImage: `url(${image})`,
    }
   
    return(
        // <div className="cnBanner-head" >
        //     <p className="cnBanner-cnt">{title}</p>
        <>
           
            
            <div className='cnbanner-slider-head'>
            
             <div className='slider'>
                {
                    id === "Fillezy" ? 
                    <img className="fillezybanner" src={image} alt={title}/>
                    :
                    <img className="sliderImg" src={image} alt={title}/>
                }
                
             </div> 
             {
             
             windowSize[0] >= 1506 && 
            <div className='cnBanner-2'>
                <div className='cnBanner-3'>
                    <ul>
                        <Link to='/'><li>Home</li></Link>
                        <Link to='/about-us'><li>About Us</li></Link>
                        <Link to='/services'><li>Products</li></Link>
                        <Link to='/career'><li>Career</li></Link>
                        <Link to='/contacts'><li>Contacts</li></Link>
                        <Link to='/blogs'><li>Blogs</li></Link>
                        <Link to='/Downloadcenter'><li>Download Center</li></Link>
                        {/* <li className='cb-btn'><button>Appointment</button></li> */}
                    </ul> 
                   <Link to="/contacts"><button>Join Us <BsArrowRight className='cb-icons'/></button></Link>
                </div>
                {/* <div className='cnBanner-4'>
                    <button>Appointment</button>
                </div> */}
             </div>
            }

            <div className="cnBanner-cnt">
                {title}
            </div>
             </div>
        
         
                </>
    //   </div>
    )
}

export default CnBanner