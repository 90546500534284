import React from 'react';
import "../css/SubCategory.css";
import { Link,useParams } from 'react-router-dom';

function SubCategory() {
    const {id}=useParams();

    const VCI = [
        {
            id:1,
            title:"VCI 2D Bags",
            para:"VCI 2D bags are used for the packaging of small components which are further put into pallets or boxes.",
        },
        {
            id:2,
            title:"VCI 3D Carton Liners",
            para:"Corrugations contain high level of humidity.VCI 3D Carton Liners are used to protect steel components from effects of moisture.",
        },
        {
            id:3,
            title:"VCI 3D Pallet Liners",
            para:"VCI 2D bags are used for the packaging of small components which are further put into pallets or boxes",
        },
        {
            id:4,
            title:"VCI Paper",
            para:"RUSTX manufactures Super Laminated VCI Paperswhich have exceptional strength, tear resistance as well as puncture resistance.",
        },
        {
            id:5,
            title:"VCI Shrink Tape",
            para:"Rust-X manufactures a high-quality Shrink tape for a wide range of applications, including electrical insulation, sealing, and bundling.",
        },
        {
            id:6,
            title:"VCI Ventilation Grill",
            para:"Rust-X shrink packaging ventilation grill is a high-quality product designed to improve airflow and prevent overheating.",
        },
        {
            id:7,
            title:"VCI Zip Lock Bags",
            para:"CI Zip Lock Bags are used to pack components which need removal and resealing.",
        },
        {
            id:8,
            title:"VCI Sheets and Rolls",
            para:"VCI sheets and rolls are available in various sizes and can be used for wrapping large or small components or equipments.",
        },
        {
            id:9,
            title:"VCI Lamina Shrink Films",
            para:"RustX VCI Lamina Films are manufactured using state of the art SMP Technology for speedy moisture passivation.",
        },
        {
            id:10,
            title:"VCI Strength Fabric",
            para:"RustX Strength Fabric is a woven fabric made on circular knitting machines from  specialized high strength HDPE resin with dosages of UV protection.",
        },
        {
            id:11,
            title:"VCI Barrier Foils",
            para:"Aluminium barrier foils are a perfect combinations to reduce WVTR and OTR penetration inside the packaging.",
        },
        {
            id:12,
            title:"VCI Bubble Wrap",
            para:"VCI bubble wraps are used where impact protection such as dents, scratches are required on the parts during shipment and storage.",
        },
        {
            id:13,
            title:"VCI Holo and MLP FILMS",
            para:"VCI Holographic films have are used in aftermarket spares.A great way to protect parts from counterfeit.",
        },
        {
            id:14,
            title:"VCI Power Stretch Films",
            para:"Rust-X VCI Power Stretch Films are manufactured using state of the art SMP Technology for speedy moisture  passivation.",
        },
        {
            id:15,
            title:"Surface Protection Films",
            para:"Surface Protection Film or Pro-tape  is a cost effective solution to protect your floors, windows & doors,  glass for scratches.",
        },
        {
            id:16,
            title:"VCI Masking Stickers",
            para:"RUST-X VCI Masking sticker is the ultimate solution in the industry for corrosion prevention and scratch prevention.",
        },
        {
            id:17,
            title:"VCI Shrink Films",
            para:"Shrink films are made from high quality resins to cover almost anything. There is wide range of widths, lengths.",
        },
        {
            id:18,
            title:"VCI Metal Barrier Foils",
            para:"Metal barrier protection has a n aluminum coated polyester with VCI LDPE combination can be used in applications.",
        },
        {
            id:19,
            title:"VCI Shower Cap",
            para:"VCI Shower Caps has its unique elastic bottom. The elastic band provides a secure fit over totes or odd-shaped.",
        },
        {
            id:20,
            title:"VCI Tuffpaulin",
            para:"A tarpaulin is a material which has been designed to be treated to be waterproof or water resistant.",
        },
    ]
    const Cleaners = [
        {
            id:1,
            title:"Aqueous Cleaners",
            para:"Aquaflush is a water based cleaning agent based on organic compounds which effectively solubilises oils, etc",
        },
        {
            id:2,
            title:"Solvent Cleaners",
            para:"RUST-X Cleaning agents are order less and solvent based washing systems have various advantages owing to the fact.",
        },
        {
            id:3,
            title:"Vibrox Cleaner",
            para:"VIBROX is a special grade additive used for the vibratory machines and provides excellent washing.",
        }];

        const CuttingOil= [
            {
                id:1,
                title:"Mineral ",
                para:"Water Soluble Coolants -WS50’s main function is to remove heat from the cutting tool, chip and work piece.",
            },
            {
                id:2,
                title:"Semi-Synthetic",
                para:"Sharp 3000,6000 and 9000 has been developed to supply the modern machine workshop with a single versatile products.",
            },
            {
                id:3,
                title:"Synthetic ",
                para:"AmpliFi 40000, 50000 and 70000 premium are an ester based cutting fluid having no mineral oil content .",
            }]

            const Coatings= [
                {
                    id:1,
                    title:"VCI Coatings ",
                    para:"RUST-X high performance coatings offer clients a range of acrylic, waxy, transparent and bitumen based hard coats.",
                },
                {
                    id:2,
                    title:"VCI Jelly Wax",
                    para:"RUST-X VCI Jelly Wax 164 is a viscous wax type mineral oil based product, which can be applied with a brush.",
                }]

                const Rustpreventiveoils= [
                    {
                        id:1,
                        title:"VCI Additives ",
                        para:"Deposition of water on metal surface results in high moisture content around the metal",
                    },
                    {
                        id:2,
                        title:"Metal Working Fluids",
                        para:"VCI Metal Working Fluids (MWF) is the name given to a range of oils and other liquids that are used to cool and/or lubricate metal workplaces.",
                    },
                    {
                        id:3,
                        title:"VCI Oils-Domestic",
                        para:"Domestic grade  VCI Rust Preventive Oils are well suited for Inland transportation with short to long term storage.",
                    },
                    {
                        id:4,
                        title:" Preventive Oils – Export ",
                        para:"Rust-X offers a complete line of rust preventive oils to prevent rust on exposed metals and unpainted metal parts. ",
                    }];

                    const Vciemitters= [
                        {
                            id:1,
                            title:"19000 Desiccants",
                            para:"VCI Desiccants 19000 are DMF free, ROHS and REACH approved desiccants that help reduce the humidity inside the package.",
                        },
                        {
                            id:2,
                            title:"4200 Emitters Sachets",
                            para:"VCI Emitters 4200 are a mixture of high and low speed concentrates of Vapor Corrosion Inhibitors powders.",
                        },
                        {
                            id:3,
                            title:"4231 Tablets",
                            para:"Rust-X VCI Tablets Thanks to the small sizes, they can be easily positioned in the most hidden and difficult to protect points.",
                        },
                        {
                            id:4,
                            title:" 4500 Dual Action Pouch",
                            para:"VCI 4500 is the most advanced version which is the dual action pouch is made to avoid confusions.",
                        },
                        {
                            id:5,
                            title:"777 Chips",
                            para:"RustX VCI Chips are impregnated with the SMP VCI quality of rust preventives and give excellent cushion and scratch.",
                        },
                        {
                            id:6,
                            title:"  Foam 798",
                            para:"VCI Foams are flat PU foams containing VCI chemicals. These chemicals sublimate and deposit on metal surfaces forming.",
                        },
                        {
                            id:7,
                            title:"Emitter 5 and 11",
                            para:"Emitter 5 and 11 are in the form of plastic boxes that can installed in electrical cabinets and form a leak proof system.",
                        },
                        {
                            id:8,
                            title:"VCI Powder 4201",
                            para:"Corrosion Protection for boilers, pressure vessels, heat exchangers and other process plant equipment is a difficult task.",
                        }
                    ]
                    const Steelcoil= [
                        {
                            id:1,
                            title:"protectors & Protex",
                            para:"Rust-X Manufactures OD protectors, ID rings and Protex rolls that are pseudo embossed from a 0.5 mm sheet. ",
                        },
                        {
                            id:2,
                            title:"Crepe Paper",
                            para:"RUST-X manufactures VCI Crepe Papers which have exceptional strength, tear resistance.",
                        },
                        {
                            id:3,
                            title:"HDPE Papers",
                            para:"VCI HDPE Papers are manufactured by combining various combinations of raffia and PE with VCI papers.",
                        },
                        {
                            id:4,
                            title:" Power Stretch",
                            para:"VCI power stretch films are ideal to wrap large machinery, coils or parts that cannot be packed in bags.",
                        },
                        {
                            id:5,
                            title:"Yotex",
                            para:"Yotex is a product made from high-density polyethylene and combined with cross-link structure for high strength polymers.",
                        },
                        {
                            id:6,
                            title:"  Scrim Paper",
                            para:"Rust-X manufactures Super Laminated VCI Scrim paper that have exceptional bi directional strength.",
                        },
                        {
                            id:7,
                            title:" Scrim Tight Film",
                            para:"The reinforced fibers prevent tear propagation in the event of a puncture or shear damage.",
                        },
                        {
                            id:8,
                            title:"Strength Fabric",
                            para:"It is laminated on both sides with Poly Ethylene or Propylene and impregnated with VCI additives.",
                        }
                    ]
                    const Vcisprays= [
                        {
                            id:1,
                            title:"Conformal Coating",
                            para:"RUST-X Electrical and Electronic Contact Cleaner and Conformal coatings are ideal for cleaning.",
                        },
                        {
                            id:2,
                            title:"RUST-X 40",
                            para:"RUST-X 40 is a multipurpose spray and an excellent Rust Preventive and degreaser for mild.",
                        },
                        {
                            id:3,
                            title:"RUST-X Anti Spatter",
                            para:"RUST-X Anti Spatter Spray is a non-silicone, non-flammable release preparation that prevents adhesion of spatter .",
                        },
                        {
                            id:4,
                            title:" RUST-X Clear Coat",
                            para:"RUST-X Clear Coat Spray is a high gloss clear fast drying, flexible, protective, plastic etc.,",
                        },
                        {
                            id:5,
                            title:"RUST-X Cold Zinc",
                            para:"Cold Zinc Galvanizing Spray is a Zinc rich, cold galvanizing coating that provides long term corrosion protection.",
                        },
                        {
                            id:6,
                            title:"  RUST-X Degreaser",
                            para:"The aerosol has deep foaming penetrating action and a powerful stream that removes oil, dirt and grime without engine disassembly.",
                        },
                        {
                            id:7,
                            title:" RUST-X Liquid Grease",
                            para:"RUST-X Liquid Grease Spray , is premium white lithium grease. It is fortified with Polytetrafluoroethane and are designed.",
                        },
                        {
                            id:8,
                            title:" Under Body Coat",
                            para:"Under Body Coat Spray leaves a resilient, heavy-duty waxy film for long term corrosion protection.",
                        },
                        {
                            id:9,
                            title:" Silicone Sprays",
                            para:"Silicone Sprays provides a protective, waterproof and low friction coating to multiple surfaces.",
                        },
                        {
                            id:10,
                            title:"VCI Ammo-Guard",
                            para:"Ammo-guard 22 is a Multi-Metal Volatile Corrosion Inhibiting product used mainly for enhancing shelf-life of arms and ammunition.",
                        }
                    ]

                    const Desiccants= [
                        {
                            id:1,
                            title:"Zorbit Clay ",
                            para:"ZORBIT Clay contains natural activated clay based desiccants which work by evaporation and neutralization of moisture.",
                        },
                        {
                            id:2,
                            title:"Zorbit Gel",
                            para:"Zorbit Gel Desiccants are made from combination of special starch that are highly hygroscopic and absorb up to 350% its own weight. ",
                        }]

                        const Rustromvers= [
                            {
                                id:1,
                                title:"Rust Converters ",
                                para:"Rust Converter is a powerful oxidation converter designed for use on various steels, iron, cast iron and copper etc.,",
                            },
                            {
                                id:2,
                                title:"Rust Remover",
                                para:"Rust-X Neutra-Safe Rust Remover is a powerful oxidation remover designed for use on various steels, iron,cast iron etc., ",
                            }]
    // ind saftey
    const Bodysuits = [
        {
            id:1,
            title:"BODY SUITS",
            para:"These suits are made from different materials based on the specific risks and requirements of the tasks.",
        },
        {
            id:2,
            title:"FOOT PROTECTION",
            para:"Foot protection is an integral part of a comprehensive personal protective equipment (PPE) program. ",
        },
        {
            id:3,
            title:"HAND PROTECTION",
            para:"Hand protection is an integral component of a comprehensive personal protective equipment (PPE) program. ",
        },
        {
            id:4,
            title:"HEAD AND EAR",
            para:"VIBROX is a special grade additive used for the vibratory machines and provides excellent washing.",
        },
        {
            id:5,
            title:"EYE AND FACE ",
            para:"Eye and face protection are fundamental aspects of ensuring the safety and well-being of workers. ",
        },
        {
            id:6,
            title:"FALL PROTECTION",
            para:"Fall protection is a shared responsibility that involves proper planning, training, and ongoing maintenance.",
        },
        {
            id:7,
            title:"SAFETY JACKETS",
            para:" Safety jackets a crucial role in ensuring the safety of workers, especially in situations where visibility.",
        },
        {
            id:8,
            title:"RESPIRATORY ",
            para:"Respiratory protection is essential for safeguarding the health and well-being of workers in various industries.",
        },
        {
            id:9,
            title:"Mansoon Safety",
            para:"Monsoon safety is a crucial consideration, especially in regions where heavy rainfall, storms, and flooding. ",
        },
    ];
    const roadsaf = [
        {
            id:1,
            title:"TRAFFIC SAFETY",
            para:"Traffic safety equipment plays a crucial role in maintaining order and preventing accidents on roads and highways",
        },
        {
            id:2,
            title:"PARKING SAFETY",
            para:"Parking safety is essential to ensure the well-being of drivers, pedestrians, and the security of vehicles.",
        },
        {
            id:3,
            title:"LIGHTS",
            para:" Traffic lights should undergo regular inspections to ensure proper functioning of bulbs, lenses, and signal heads. ",
        },
        {
            id:4,
            title:"TAPES",
            para:"Traffic tapes are valuable tools for enhancing traffic management and safety on roadways and parking areas.",
        },
    ];
    const ff= [
        {
            id:1,
            title:"FIRE EXTINGUISHER",
            para:"Fire extinguishers are crucial devices designed to control and extinguish small fires.",
        },
        {
            id:2,
            title:"FIRE HYDRANT SYSTEM",
            para:"Fire hydrant systems are essential assets in urban and industrial firefighting infrastructure. ",
        },
     
    ];
    const weld= [
        {
            id:1,
            title:"WELDING SAFETY",
            para:"Welding is a vital but inherently hazardous activity, involving intense heat, bright light,etc., ",
        }
    ];
    const exprod= [
        {
            id:1,
            title:"MEDICAL PURPOSE",
            para:"Tailoring medical treatment to individual characteristics, such as genetics, lifestyle, and environment.",
        },
        {
            id:2,
            title:"HARDWARE",
            para:" Preventing accidental machinery activation during maintenance by electronically controlling access.",
        },
        {
            id:3,
            title:"GENERAL",
            para:"General safety products are essential components of a comprehensive safety strategy.",
        },
        {
            id:4,
            title:"SAFETY SIGNS ",
            para:"Safety signs and signals are crucial components of an effective safety communication system.",
        },
        {
            id:5,
            title:"SAFETY NET",
            para:"Safety nets are a fundamental component of fall protection systems, designed to mitigate the risks associated.",
        },
     
    ];
    const marine= [
        {
            id:1,
            title:"LIFE JACKET",
            para:"Provides the highest buoyancy, turning most unconscious wearers face-up in the water.",
        },
        {
            id:2,
            title:"Life Buoy",
            para:"Inspection should include checking for any signs of damage, wear, or deterioration of materials.",
        }
     
    ];
    return (
        <div className='container mt-5 mp-5'>

          {id=="vcipackaging" &&
           <><div className='about1'>
           <div className='smallsss1'></div>
          <h4 className='aboutline_head1'>Vci Packaging</h4>
             </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Rust-X application engineers can recommend and implement the best corrosion management system from our
                    large selection of stock products or create a cost-effective custom solution.</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>Rust-X is one of the leading VCI Film
                         manufacturer & supplier in India and worldwide. We are expert in VCI technology.
                           Integrated combinations of VCI Packaging protection systems, such as VCI corrosion protection
                        with acid-gas scavenger and anti-static (ESD) are available to provide the best result.
                        </p></div>
                </div>
                </>
           }

{id=="welding" &&
           <><div className='about1'>
           <div className='smallsss1'></div>
          <h4 className='aboutline_head1'>Vci Packaging</h4>
             </div>
              <div className='biod biode'>
                 
                    <h2 className='pill'>Welding is both a skill and a science that bridges creativity
                    and precision. From the construction of massive structures .</h2>
                    <div className='text-muted pol'><p>Welding remains an indispensable process that shapes
                         the world we live in. As technology advances,
                         welding techniques continue to evolve, ensuring its relevance in diverse industries.
                        </p></div>
                </div>
                </>
           }
               {id=="cleaners" &&
                <><div className='about1'>
                <div className='smallsss1'></div>
               <h4 className='aboutline_head1'>Cleaners</h4>
                  </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Cleanliness of parts improve their corrosion protection as well as performance during use. Automotive and Aerospace
                    companies follow Millipore testing to establish dirt content on the parts.</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>RUST-X produces two kinds of cleaning agents Solvent Based  CL Series ideally recommended for parts that have to be stored for long periods or exported.Solvent Based – CL Series ideally recommended for parts that have to be stored for long periods or exported.
                    Water Based Aquaflush washing agents recommended before assembly,
Vibrox Cleaning Agents for Vibro finishing process
                        </p></div>
                </div>
                </>
           }
               {id=="marinesafety" &&
                <><div className='about1'>
                <div className='smallsss1'></div>
               <h4 className='aboutline_head1'>Marine Safety</h4>
                  </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Marine safety products are indispensable for creating a secure
                     maritime environment, where the challenges of
                     the open sea are met with preparedness and vigilance.</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>Safety at sea is paramount,
                        and marine safety products are essential for ensuring
                        the well-being of maritime professionals and passengers.
                        Explore the diverse range of marine safety products that
                         contribute to safe navigation and emergency response on the water.
                        </p></div>
                </div>
                </>
           }


{id=="firefighting" &&
                <><div className='about1'>
                <div className='smallsss1'></div>
               <h4 className='aboutline_head1'>Fire Fighting</h4>
                  </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Their multifaceted roles encompass firefighting, rescue operations,
                        medical response, and community education, etc.,    </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>Firefighters, with their dedication, training, and commitment to public safety,
                     are essential contributors to the resilience and well-being of communities.
                        </p></div>
                </div>
                </>
           }

          {id=="cuttingoil" &&
           <><div className='about1'>
           <div className='smallsss1'></div>
          <h4 className='aboutline_head1'>Cutting Oil</h4>
             </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Water Soluble Coolants -WS50’s main function is to remove heat from
                    the cutting tool, chip and work piece.
                    They are often manufactured by hydrocracking.</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>The cutting oils are manufactured from highly refined
                         Group 2 base oils that are low in Sulphur content. Group II base oils are defined as being
                         more than 90 percent saturates,
                         less than 0.03 percent sulfur and with a viscosity index of 80 to 120.
                         </p>
                </div>
                </div>
                </>
           }
           {id=="desiccants" &&
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Rust-X VCI Desiccants are commonly used during shipping or storage of products. Can be used for ferrous and  
                     non-ferrous products to prevent saturation, moisture and rusting of metals within the container</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>Rust-X ZORBIT contains natural activated clay based desiccants which work by evaporation and neutralization of moisture. Desiccant
                             is a drying agent used to lower the moisture content of air inside a closed space, such as Moisture Barrier Bag.
                             Zorbit Desiccant is a drying agent used to lower the moisture content of air inside a closed space, such as Moisture Barrier Bag.
                         </p>
                </div>
                </div>
           }
           {id=="rustremovers" &&
            <><div className='about1'>
            <div className='smallsss1'></div>
           <h4 className='aboutline_head1'>Rust Removers</h4>
              </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Neutracoat Rust Converter is a
                    powerful oxidation converter designed for use on various steels,
                    iron, cast iron and copper, metals & aluminum.
</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>The biggest advantage is that the components do not get rusty again after removing
                            from solution which is case in Acidic Rust removers
                            and forms black inactive layer on the metal surface which can then be painted for longer life.
                         </p>
                </div>
                </div>
                </>
           }
           
     
{id=="coatings" &&
 <><div className='about1'>
 <div className='smallsss1'></div>
<h4 className='aboutline_head1'>Coatings</h4>
   </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Rust-X high performance coatings offer clients
                     a range of acrylic, waxy, transparent and bitumen based hard coats.
                    </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>These provides high salt spray life and longevity for metal protection.
                    they are environmentally friendly and free from heavy, metals.
                         </p>
                </div>
                </div>
                </>
           }
           {id=="excul-prod" &&
 <><div className='about1'>
 <div className='smallsss1'></div>
<h4 className='aboutline_head1'>Exclusive-Products</h4>
   </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Embrace a new era of safety with exclusive safety
                    products that redefine the very essence of protection.
                    </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>In the realm of safety, exclusive products redefine the standards by
                            combining cutting-edge technology, superior materials, and innovative design.
                         </p>
                </div>
                </div>
                </>
           }
{id=="rustpreventiveoils" &&
     <><div className='about1'>
     <div className='smallsss1'></div>
    <h4 className='aboutline_head1'>Rust Prevent Oils </h4>
       </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Rust-X high performance coatings offer clients
                     a range of acrylic, waxy, transparent and bitumen based hard coats.
                    </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>These provides high salt spray life and longevity for metal protection.
                    they are environmentally friendly and free from heavy, metals.
                         </p>
                </div>
                </div>
                </>
           }

{id=="vciemitters" &&
 <><div className='about1'>
 <div className='smallsss1'></div>
<h4 className='aboutline_head1'>Vci Emitters</h4>
   </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Rust-X emitter is something that gives off, or sends out, a substance. Vapor corrosion inhibitors are a type of compound used to protect ferrous and non-ferrous metals from rust and corrosion.</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>When we combine the two, the result is a VCI Emitter — a device designed to protect enclosed metal and metal parts from rust. VCI emitters use a range of carriers, such as polyether
                        foam or chipboard, to host the VCI for use in a various rust-prevention application
                        </p></div>
                </div>
                </>
           }

{id=="propre" && <><div className='about1'>
                   <div className='smallsss1'></div>
                  <h4 className='aboutline_head1'>Personal Protective equipments </h4>
                     </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Safety equipment in industries plays a crucial role in protecting
                    workers from potential hazards and ensuring a secure working environment.</h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>The type of safety equipment required varies depending on the nature
                        of the industry and the specific risks involved.
                        re are some common types of safety equipment used in industries:
                        </p></div>
                </div>
                </>
           }
           {id=="road&parking" && <><div className='about1'>
                   <div className='smallsss1'></div>
                  <h4 className='aboutline_head1'>Road and Parking</h4>
                     </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500"> Road and traffic safety are paramount to preventing accidents, injuries, and fatalities.
                    </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500"><p>Overall, a comprehensive approach involving infrastructure improvements,
                         effective law enforcement, and public education is crucial for creating safer roads and reducing accidents.
                        </p></div>
                </div>
                </>
           }

{id=="steelcoil" &&
 <><div className='about1'>
 <div className='smallsss1'></div>
<h4 className='aboutline_head1'>Steel Coils  </h4>
   </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Steel coils are typically packaged and transported in a way that ensures their protection and ease of handling during storage, shipping, and handling.
                    Steel coils are often secured with steel strapping or banding.  </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>The packaging method can vary based on the type of steel,
                        its dimensions, transportation mode, and specific customer requirements.
                        Additionally, these packaging methods aim to protect the steel coils from corrosion,
                        physical damage, and other potential hazards during transit and storage.
                        </p></div>
                </div>
                </>
           }

{id=="vcisprays" &&
 <><div className='about1'>
 <div className='smallsss1'></div>
<h4 className='aboutline_head1'>Vci Sprays </h4>
   </div>
              <div className='biod biode'>
                 
                    <h2 className='pill' data-aos="fade-right" data-aos-duration="1500">Rust-X offers sprayable protection with Vapor Corrosion Inhibitors (VCIs) to metals shipped or stored in enclosed packaging.
                    VCI spray is an ideal rust preventer for closed systems like fuel tanks, storage tanks, gear housings etc.,  </h2>
                    <div className='text-muted pol' data-aos="fade-left" data-aos-duration="1500">
                        <p>The product can be applied by spraying onto the areas, which have to be cleaned or lubricated. Works well for steel,
                            aluminum, iron, brass, copper, zinc, nickel, chromium and other multi-metal systems.Heavy-duty temporary coating remover, Will not affect painted surfaces,
                            Works well for steel, aluminum, iron, brass, copper, zinc, nickel, chromium and other multimetal systems.
                        </p></div>
                </div>
                </>
           }
                {id=="vcipackaging" &&  
            <div className='subcategory1'>
            {
                VCI.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airport" :val.id===2 ? "airport1" :val.id===3 ? "airport2" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/VCI Packaging/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
                        </div>
               }
                     {id=="cleaners" &&  
            <div className='subcategory1'>
            {
                Cleaners.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airports1" :val.id===2 ? "airports2" :val.id===3 ? "airports3" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Cleaners/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
   
           
                        </div>
               }

            {id=="cuttingoil" &&  
            <div className='subcategory1'>
            {
                CuttingOil.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airporting1" :val.id===2 ? "airporting2" :val.id===3 ? "airporting3" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Cutting Oils/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
   
           
                        </div>
               }
               {id=="vciemitters" &&  
            <div className='subcategory1'>
            {
                Vciemitters.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "air1" :val.id===2 ? "air2" :val.id===3 ? "air3" :val.id===4 ? "air4" :
                                                            val.id===5 ? "air5" : val.id===6 ? "air6" : val.id===7 ? "air7" :val.id===8 ? "air8" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/VCI Emitters/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
   
           
                        </div>
               }

       {id=="coatings" &&  
            <div className='subcategory1'>
            {
                Coatings.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airportings1" :val.id===2 ? "airportings2" :val.id===3 ? "airporting3" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Coatings/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
   
           
                        </div>
               }
                {id=="rustpreventiveoils" &&  
                <div className='subcategory1'>
              {
                Rustpreventiveoils.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airpo1" :val.id===2 ? "airpo2" :val.id===3 ? "airpo3" :val.id===4 ? "airpo4" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Rust Preventive Oils/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
   
           
                        </div>
               }
                {id=="steelcoil" &&  
                <div className='subcategory1'>
              {
                Steelcoil.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airp1" :val.id===2 ? "airp2" :val.id===3 ? "airp3" :val.id===4 ? "airp4" :
                                                            val.id===5 ? "airp5" : val.id===6 ? "airp6" : val.id===7 ? "airp7" :val.id===8 ? "airp8" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Steel Coil Packaging/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }
               {id=="vcisprays" &&  
                <div className='subcategory1'>
              {
                Vcisprays.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "airp01" :val.id===2 ? "airp01" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/VCI Sprays/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }

{id=="desiccants" &&  
                <div className='subcategory1'>
              {
                Desiccants.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "ai01" :val.id===2 ? "ai02" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Desiccants/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }

{id=="rustremovers" &&  
                <div className='subcategory1'>
              {
                Rustromvers.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "ai1" :val.id===2 ? "ai2" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Remover & Converter/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }


{/* Ind safety */}
{id=="propre" &&  
                <div className='subcategory1'>
              {
                Bodysuits.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "porta1" :val.id===2 ? "porta2" :val.id===3 ? "porta3" :val.id===4 ? "porta4" :
                                                            val.id===5 ? "porta5" : val.id===6 ? "porta6" : val.id===7 ? "porta7" :val.id===8 ? "porta8" :
                                                            val.id===9 ? "porta9" : val.id===10 ? "porta11" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/IndustrialSafety/propre/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }
{id=="road&parking" &&  
                <div className='subcategory1'>
              {
                roadsaf.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>            
                                <div className={`subcategory2 ${val.id===1 ? "safety1" :val.id===2 ? "safety2" :val.id===3 ? "safety3" :val.id===4 ? "safety4" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/IndustrialSafety/road&parking/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }
{id=="firefighting" &&  
                <div className='subcategory1'>
              {
                ff.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "public" :val.id===2 ? "public1" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/IndustrialSafety/firefighting/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }
{id=="welding" &&  
                <div className='subcategory1'>
              {
                weld.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "aero" :val.id===2 ? "ai02" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/IndustrialSafety/welding/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }
{id=="excul-prod" &&  
                <div className='subcategory1'>
              {
                exprod.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "excul" :val.id===2 ? "excul1" :val.id===3 ? "excul2" :val.id===4 ? "excul3" :
                                                            val.id===5 ? "excul4" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/IndustrialSafety/excul-prod/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

                        </div>
               }
{id=="marinesafety" &&  
                <div className='subcategory1'>
              {
                marine.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                           
                                <div className={`subcategory2 ${val.id===1 ? "ida" :val.id===2 ? "ida1" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/IndustrialSafety/marinesafety/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
                        </div>
               }




        </div>
    )
}
export default SubCategory;