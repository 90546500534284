import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { AiOutlineSearch } from "react-icons/ai";
import { BsBasket,BsArrowRight } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsTwitter} from "react-icons/bs";
import { FaFacebookF} from "react-icons/fa";
import { AiFillInstagram} from "react-icons/ai";
import { IoLogoWhatsapp} from "react-icons/io";
import { AiFillMail } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import { BsFillTelephoneFill } from 'react-icons/bs';


import '../css/Navbar.css'
import { Link } from 'react-router-dom';

const Navbar = ({windowSize,color,setColor}) => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    // const [color, setColor] = useState(false)
    // const changeColor = () => {
    //     if(window.scrollY >= 90){
    //         setColor(true)
    //     }else{
    //         setColor(false)
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', changeColor)

    //     return () => window.removeEventListener('scroll',changeColor)
    // })
    const [opennav, setOpenNav] = useState(false)

    return (
      <div className="all-head ">
        <div className={`navheader ${color ? "head-bg" : ""}`}>
            <nav className='container navbar'>
             
                    <img src="/images/LOGO (1).png" alt='logo' />
             
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} className='iothead-icon' />)
                        : (<FaBars size={30} className='iothead-icon' />)}

                </div>
                <ul className={`${click ? "nav-menu activee" : "nav-menu"} ${color ? "nav-color" : "nav-white"}`}>
                    <li className='mb-img'>
                      <img src="/images/LOGO (1).png" alt='logo' />
                    </li>
                    
                    <li className='nav-item nav-hover'>
                        <Link to='/'>Home</Link>
                    </li>
                    <li className='nav-item nav-hover'>
                        <Link to='/about-us'>About Us</Link>
                    </li>
                    <li className='nav-item nav-hover'>
                        <Link to='/services'>Products</Link>
                    </li>
                    <li className='nav-item nav-hover'>
                        <Link to='/career'>Career</Link>
                    </li>   
                    <li className='nav-item nav-hover'>
                        <Link to='/contacts'>Contact</Link>
                    </li>
                    <li className='nav-item nav-hover'>
                        <Link to='/blogs'>Blog</Link>
                    </li>
                    <li className='nav-item nav-hover'>
                        <Link to='/Downloadcenter'>Download Center</Link>
                    </li>
                    { windowSize[0] >= 1024 &&
                    <li className='nav-item'>
                        <a href='#demo' onClick={() => setOpenNav(!opennav)}><FaBars /></a>
                    </li>
                }
                </ul>
            </nav>

        {/* <div className={`navbar ${isOpen ? 'active' : ''} ${sticky ? "sticky" : ""}`}>
          
          <div className="logo">
                <img src='https://demo.casethemes.net/consultio-digital-marketing/wp-content/uploads/2021/02/logo-dark.png' alt="Logo" width='300px'/>
          </div>
          <div className="navbar-toggle" onClick={toggleNavbar}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <ul className="navbar-links">
            <li><img src='https://demo.casethemes.net/consultio-digital-marketing/wp-content/uploads/2021/02/logo-dark.png' alt="Logo" width='300px'/></li>
            <li><a href="#">Demo</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Projects</a></li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">blog</a></li>
            <li><a href="#"><AiOutlineSearch /></a></li>
            <li><a href="#"><BsBasket /></a></li>
            <li><a href="#">< RxHamburgerMenu /></a></li>
          
          </ul>
        </div> */}
        </div>
        
        <div className={opennav ? "on-bg aaa" : "on-bg"}>
            <div className={opennav ? "opennav aa" : "opennav"}>
                <div>
                    <FaTimes onClick={() => setOpenNav(!opennav)} style={{float:"right",fontSize:"30px"}}/>
                </div>
                <img src="/images/LOGO (1).png" alt='logo' width="180px"/>
                {/* <img src='https://demo.casethemes.net/consultio-digital-marketing/wp-content/uploads/2021/02/logo-dark.png' width="200px"/> */}
                <p className='nav-p'>
                    Edgecut specializes in a wide range of technological services, from software development and web design to data analytics and artificial intelligence.
                </p>
                <div className='on-1'>
                    <BsFillTelephoneFill className="on-icon"/>
                    <div className='on-2'>
                        <span> +91 9876543210</span>
                        <span> +91 9876543210</span>
                    </div>
                </div>
                <div className='on-1'>
                <AiFillMail className="on-icon"/>
                    <div className='on-2'>
                        <span>EdgecutTools@gmail.com</span>
                        <span>EdgecutTools@gmail.com</span>
                    </div>
                </div>
                <div className='on-1'>
                <FaLocationDot className="on-icon"/>
                    <div className='on-2'>
                        <span>Hari complex opp.prozone mall,</span>
                        <span>Saravanampatti, Coimbatore</span>
                    </div>
                </div>
                <button
                data-aos="fade-up"
                data-aos-duration="2500"
                className="test-btns"
              >
                <span className="test-sc"></span>
                <span className="iot-text">
                  Contact
                  <BsArrowRight className="btns-icons1" />
                </span>
                </button>
                <div>
                    <FaFacebookF className="on-sicons"/>
                    <BsTwitter className="on-sicons"/>
                    <IoLogoWhatsapp className="on-sicons"/>
                    <AiFillInstagram className="on-sicons"/>
                </div>
            </div>
        </div>
      </div>
    )
}
export default Navbar