import React from 'react'
import { IoLocationSharp } from 'react-icons/io5';
import { IoMdMail } from 'react-icons/io';
import { BsFillTelephoneFill } from 'react-icons/bs';
import '../css/EcContact.css'
import axios from 'axios';
import Alert from "react-bootstrap/Alert";
import { useState } from 'react';

const EcContact = () => {
    const [values, setValues] = useState({
        fname: "",
        lname: "",
        email:"",
        phno:"",
        subject: "",
        service: "",
        message: ""
      });

      const handleInput = (e) => {
        setValues((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      };

      const [errors, setErrors] = useState("");
      const [show, setShow] = useState(false);
      const [showMsg, setShowMsg] = useState(false);
      
    const handleSubmit = (e) => {
        e.preventDefault();
    
          axios
            .post("https://infygain.in/api/contact", values)
            .then((res) => {
                if(res.data.info){
                    setValues({
                        fname: "",
                        lname: "",
                        email:"",
                        phno:"",
                        subject: "",
                        service: "",
                        message: ""
                      });
                      document.querySelector(".form").reset()
                      setErrors(res.data.info);
                      setShowMsg(true);
                }
                else{
                    setErrors(res.data.err);
                    setShow(true);
                    
                }
              
            })
            .catch((err) => {
              console.log(err);
              setShow(true);
              setErrors("Internal Server Error");
            });
      };

   
      function alertBox() {
        if (show) {
          return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
              {errors}
            </Alert>
          );
        }
      }
      function msgBox() {
        if (showMsg) {
          return (
            <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
              {errors}
            </Alert>
          );
        }
      }


  return (
    <>
        <div className='ec-contact-head'>
            <div className='ec-contact1'>
                <div className='container ec-contact2-head'>
                    <div className='ec-contact2'   data-aos="fade-right" data-aos-duration="1000">
                        <span className='ec-ul'></span>
                        <p>Get in touch for any kind of help and informations</p>
                    </div>
                    <div className='ec-contact3' data-aos="fade-left" data-aos-duration="1000">
                        <span className='ec-hcont'>
                        We’re glad to discuss your organisation’s situation. So please contact us 
                        via the details below, or enter your request.
                        </span>
                        
                    </div>
                </div>


                <div className='container ec-main-head'  >
                    <div className='ec-main1' data-aos="fade-right" data-aos-duration="1500">
                        <div className='ec-det-head'>
                            <IoLocationSharp className='ec-det-icons'/>
                            <div className='ec-det1'>
                                <span>Our head office address:</span>
                                <p>Hari complex opp.prozone mall, Saravanampatti, Coimbatore</p>
                            </div>
                        </div>

                        <div className='ec-det-head'>
                            <BsFillTelephoneFill className='ec-det-icons'/>
                            <div className='ec-det1'>
                                <span>Call for help:</span>
                                <p>+91 9876543210</p>
                            </div>
                        </div>

                        <div className='ec-det-head'>
                            <IoMdMail className='ec-det-icons'/>
                            <div className='ec-det1'>
                                <span>Mail us for information:</span>
                                <p>EdgecutTools@gmail.com</p>
                            </div>
                        </div>
                    </div>

                    <form className='form ec-form-head' method='POST' onSubmit={handleSubmit} data-aos="fade-left" data-aos-duration="1500">
                        {alertBox()}
                        {msgBox()}
                        <div className='ec-form1'>
                            <input type="text" placeholder='First Name*' name='fname' required onChange={handleInput}/>
                            <input  type="text" placeholder='Last Name*' name='lname' required onChange={handleInput}/>
                        </div>
                        <div className='ec-form1'>
                            <input type="email" placeholder='Email*' name='email' required onChange={handleInput}/>
                            <input  type="text" placeholder='Phone Number*' maxLength="10" name='phno' required onChange={handleInput}/>
                        </div>
                        <div className='ec-form1'>
                            <input type="text" placeholder='Subject*' name='subject' required onChange={handleInput}/>
                            {/* <input  type="text" placeholder='Last Name*'/> */}
                            <select name='services' required onChange={handleInput}>
                                <option>Others</option>
                                <option>Industrial Cutting</option>
                                <option>Industrial Safety Products</option>
                                <option>Gas Pipeline projects</option>
                                <option>Lubricants Packing Products</option>
                                <option>Bio Degradable Products</option>
                                <option>Industrial LPG Solutions</option>
                                <option>Textile Machinery Spares</option>
                                <option>Office Essential</option>
                            </select>
                        </div>
                        <div className='ec-txtarea'>
                            <textarea  placeholder='Message*' rows="4" name='message' required onChange={handleInput}/>
                            <button>Send Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.7811496063073!2d76.99272817447631!3d11.05502825399422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba857c33d84afff%3A0x768c706018754ac8!2sEdgecut%20Tools%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1697709958517!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </>
  )
}

export default EcContact