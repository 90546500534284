import React from 'react'
import '../css/SnHeader.css'
import { AiFillMail } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import { BsFillTelephoneFill } from 'react-icons/bs';

const SnHeader = () => {
  return (
    <div className='bn-head'>
        <div className=' bn-header'>
            <img src="/images/LOGO (1).png" alt='logo' />
            {/* <img src='https://demo.casethemes.net/consultio-digital-marketing/wp-content/uploads/2021/02/logo-dark.png' /> */}
            <div className='bn-header1'>
                <div className='bn-header2'>
                    <BsFillTelephoneFill className='bn-icons'/>
                    <div className='bn-header3 bn-header4'>
                        <span className='bn-span1'>Call us. +91 9876543210</span>
                        <span className='bn-span2'>(Monday-Saturday)</span>
                    </div>
                </div>
                <div className='bn-header2'>
                    <AiFillMail  className='bn-icons'/>
                    <div className='bn-header3 bn-header4'>
                        <span className='bn-span1'>Mail us for help:</span>
                        <span className='bn-span2'>EdgecutTools@gmail.com</span>
                    </div>
                </div>
                <div className='bn-header2'>
                    <FaLocationDot  className='bn-icons'/>
                    <div className='bn-header3 bn-header5'>
                        <span className='bn-span1'>Hari complex opp.prozone mall,</span>
                        <span className='bn-span2'>Saravanampatti, Coimbatore</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SnHeader