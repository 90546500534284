import React from 'react'
// import { saveAs } from 'file-saver';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import '../css/ProductSlider.css';
import '../css/Productdetails.css';
import { FaLocationDot } from 'react-icons/fa6';
import { BiSolidDownload  } from 'react-icons/bi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { AiFillMail } from 'react-icons/ai';import { Link } from 'react-router-dom';
;


const ProductSlider = ({pid,cat,id}) => {

  // pdf download fn
//   const downloadPdf = () => {
//     // Path to your PDF file
//     const pdfFilePath = '/Brochure/Infy Brochure Final_compressed.pdf';

//     // Fetch the PDF file as a blob
//     fetch(pdfFilePath)
//       .then(response => response.blob())
//       .then(blob => {
//         saveAs(blob, 'Infygain-Brochure.pdf'); 
//       })
//       .catch(error => {
//         console.error('Error downloading PDF:', error);
//       });
//   };
const VCI = [
    {
        id:1,
        title:"VCI 2D Bags",
        para:"VCI 2D bags are used for the packaging of small components which are further put into pallets or boxes.",
    },
    {
        id:2,
        title:"VCI 3D Carton Liners",
        para:"Corrugations contain high level of humidity.VCI 3D Carton Liners are used to protect steel components from effects of moisture.",
    },
    {
        id:3,
        title:"VCI 3D Pallet Liners",
        para:"VCI 2D bags are used for the packaging of small components which are further put into pallets or boxes",
    },
    {
        id:4,
        title:"VCI Paper",
        para:"RUSTX manufactures Super Laminated VCI Paperswhich have exceptional strength, tear resistance as well as puncture resistance.",
    },
    {
        id:5,
        title:"VCI Shrink Tape",
        para:"Rust-X manufactures a high-quality Shrink tape for a wide range of applications, including electrical insulation, sealing, and bundling.",
    },
    {
        id:6,
        title:"VCI Ventilation Grill",
        para:"Rust-X shrink packaging ventilation grill is a high-quality product designed to improve airflow and prevent overheating.",
    },
    {
        id:7,
        title:"VCI Zip Lock Bags",
        para:"CI Zip Lock Bags are used to pack components which need removal and resealing.",
    },
    {
        id:8,
        title:"VCI Sheets and Rolls",
        para:"VCI sheets and rolls are available in various sizes and can be used for wrapping large or small components or equipments.",
    },
    {
        id:9,
        title:"VCI Lamina Shrink Films",
        para:"RustX VCI Lamina Films are manufactured using state of the art SMP Technology for speedy moisture passivation.",
    },
    {
        id:10,
        title:"VCI Strength Fabric",
        para:"RustX Strength Fabric is a woven fabric made on circular knitting machines from  specialized high strength HDPE resin with dosages of UV protection.",
    },
    {
        id:11,
        title:"VCI Barrier Foils",
        para:"Aluminium barrier foils are a perfect combinations to reduce WVTR and OTR penetration inside the packaging.",
    },
    {
        id:12,
        title:"VCI Bubble Wrap",
        para:"VCI bubble wraps are used where impact protection such as dents, scratches are required on the parts during shipment and storage.",
    },
    {
        id:13,
        title:"VCI Holo and MLP FILMS",
        para:"VCI Holographic films have are used in aftermarket spares.A great way to protect parts from counterfeit.",
    },
    {
        id:14,
        title:"VCI Power Stretch Films",
        para:"Rust-X VCI Power Stretch Films are manufactured using state of the art SMP Technology for speedy moisture  passivation.",
    },
    {
        id:15,
        title:"Surface Protection Films",
        para:"Surface Protection Film or Pro-tape  is a cost effective solution to protect your floors, windows & doors,  glass for scratches.",
    },
    {
        id:16,
        title:"VCI Masking Stickers",
        para:"RUST-X VCI Masking sticker is the ultimate solution in the industry for corrosion prevention and scratch prevention.",
    },
    {
        id:17,
        title:"VCI Shrink Films",
        para:"Shrink films are made from high quality resins to cover almost anything. There is wide range of widths, lengths.",
    },
    {
        id:18,
        title:"VCI Metal Barrier Foils",
        para:"Metal barrier protection has a n aluminum coated polyester with VCI LDPE combination can be used in applications.",
    },
    {
        id:19,
        title:"VCI Shower Cap",
        para:"VCI Shower Caps has its unique elastic bottom. The elastic band provides a secure fit over totes or odd-shaped.",
    },
    {
        id:20,
        title:"VCI Tuffpaulin",
        para:"A tarpaulin is a material which has been designed to be treated to be waterproof or water resistant.",
    },
]
const Cleaners = [
    {
        id:1,
        title:"Aqueous Cleaners",
        para:"Aquaflush is a water based cleaning agent based on organic compounds which effectively solubilises oils, etc",
    },
    {
        id:2,
        title:"Solvent Cleaners",
        para:"RUST-X Cleaning agents are order less and solvent based washing systems have various advantages owing to the fact.",
    },
    {
        id:3,
        title:"Vibrox Cleaner",
        para:"VIBROX is a special grade additive used for the vibratory machines and provides excellent washing.",
    }];
    const CuttingOil= [
        {
            id:1,
            title:"Mineral ",
            para:"Water Soluble Coolants -WS50’s main function is to remove heat from the cutting tool, chip and work piece.",
        },
        {
            id:2,
            title:"Semi-Synthetic",
            para:"Sharp 3000,6000 and 9000 has been developed to supply the modern machine workshop with a single versatile products.",
        },
        {
            id:3,
            title:"Synthetic ",
            para:"AmpliFi 40000, 50000 and 70000 premium are an ester based cutting fluid having no mineral oil content .",
        }]

        const Coatings= [
            {
                id:1,
                title:"VCI Coatings ",
                para:"RUST-X high performance coatings offer clients a range of acrylic, waxy, transparent and bitumen based hard coats.",
            },
            {
                id:2,
                title:"VCI Jelly Wax",
                para:"RUST-X VCI Jelly Wax 164 is a viscous wax type mineral oil based product, which can be applied with a brush.",
            }]

            const Rustpreventiveoils= [
                {
                    id:1,
                    title:"VCI Additives ",
                    para:"Deposition of water on metal surface results in high moisture content around the metal",
                },
                {
                    id:2,
                    title:"Metal Working Fluids",
                    para:"VCI Metal Working Fluids (MWF) is the name given to a range of oils and other liquids that are used to cool and/or lubricate metal workplaces.",
                },
                {
                    id:3,
                    title:"VCI Oils-Domestic",
                    para:"Domestic grade  VCI Rust Preventive Oils are well suited for Inland transportation with short to long term storage.",
                },
                {
                    id:4,
                    title:" Preventive Oils – Export ",
                    para:"Rust-X offers a complete line of rust preventive oils to prevent rust on exposed metals and unpainted metal parts. ",
                }];

                const Vciemitters= [
                    {
                        id:1,
                        title:"19000 Desiccants",
                        para:"VCI Desiccants 19000 are DMF free, ROHS and REACH approved desiccants that help reduce the humidity inside the package.",
                    },
                    {
                        id:2,
                        title:"4200 Emitters Sachets",
                        para:"VCI Emitters 4200 are a mixture of high and low speed concentrates of Vapor Corrosion Inhibitors powders.",
                    },
                    {
                        id:3,
                        title:"4231 Tablets",
                        para:"Rust-X VCI Tablets Thanks to the small sizes, they can be easily positioned in the most hidden and difficult to protect points.",
                    },
                    {
                        id:4,
                        title:" 4500 Dual Action Pouch",
                        para:"VCI 4500 is the most advanced version which is the dual action pouch is made to avoid confusions.",
                    },
                    {
                        id:5,
                        title:"777 Chips",
                        para:"RustX VCI Chips are impregnated with the SMP VCI quality of rust preventives and give excellent cushion and scratch.",
                    },
                    {
                        id:6,
                        title:"  Foam 798",
                        para:"VCI Foams are flat PU foams containing VCI chemicals. These chemicals sublimate and deposit on metal surfaces forming.",
                    },
                    {
                        id:7,
                        title:"Emitter 5 and 11",
                        para:"Emitter 5 and 11 are in the form of plastic boxes that can installed in electrical cabinets and form a leak proof system.",
                    },
                    {
                        id:8,
                        title:"VCI Powder 4201",
                        para:"Corrosion Protection for boilers, pressure vessels, heat exchangers and other process plant equipment is a difficult task.",
                    }
                ]
                const Steelcoil= [
                    {
                        id:1,
                        title:"protectors & Protex",
                        para:"Rust-X Manufactures OD protectors, ID rings and Protex rolls that are pseudo embossed from a 0.5 mm sheet. ",
                    },
                    {
                        id:2,
                        title:"Crepe Paper",
                        para:"RUST-X manufactures VCI Crepe Papers which have exceptional strength, tear resistance.",
                    },
                    {
                        id:3,
                        title:"HDPE Papers",
                        para:"VCI HDPE Papers are manufactured by combining various combinations of raffia and PE with VCI papers.",
                    },
                    {
                        id:4,
                        title:" Power Stretch",
                        para:"VCI power stretch films are ideal to wrap large machinery, coils or parts that cannot be packed in bags.",
                    },
                    {
                        id:5,
                        title:"Yotex",
                        para:"Yotex is a product made from high-density polyethylene and combined with cross-link structure for high strength polymers.",
                    },
                    {
                        id:6,
                        title:"  Scrim Paper",
                        para:"Rust-X manufactures Super Laminated VCI Scrim paper that have exceptional bi directional strength.",
                    },
                    {
                        id:7,
                        title:" Scrim Tight Film",
                        para:"The reinforced fibers prevent tear propagation in the event of a puncture or shear damage.",
                    },
                    {
                        id:8,
                        title:"Strength Fabric",
                        para:"It is laminated on both sides with Poly Ethylene or Propylene and impregnated with VCI additives.",
                    }
                ]
                const Vcisprays= [
                    {
                        id:1,
                        title:"Conformal Coating",
                        para:"RUST-X Electrical and Electronic Contact Cleaner and Conformal coatings are ideal for cleaning.",
                    },
                    {
                        id:2,
                        title:"RUST-X 40",
                        para:"RUST-X 40 is a multipurpose spray and an excellent Rust Preventive and degreaser for mild.",
                    },
                    {
                        id:3,
                        title:"RUST-X Anti Spatter",
                        para:"RUST-X Anti Spatter Spray is a non-silicone, non-flammable release preparation that prevents adhesion of spatter .",
                    },
                    {
                        id:4,
                        title:" RUST-X Clear Coat",
                        para:"RUST-X Clear Coat Spray is a high gloss clear fast drying, flexible, protective, plastic etc.,",
                    },
                    {
                        id:5,
                        title:"RUST-X Cold Zinc",
                        para:"Cold Zinc Galvanizing Spray is a Zinc rich, cold galvanizing coating that provides long term corrosion protection.",
                    },
                    {
                        id:6,
                        title:"  RUST-X Degreaser",
                        para:"The aerosol has deep foaming penetrating action and a powerful stream that removes oil, dirt and grime without engine disassembly.",
                    },
                    {
                        id:7,
                        title:" RUST-X Liquid Grease",
                        para:"RUST-X Liquid Grease Spray , is premium white lithium grease. It is fortified with Polytetrafluoroethane and are designed.",
                    },
                    {
                        id:8,
                        title:" Under Body Coat",
                        para:"Under Body Coat Spray leaves a resilient, heavy-duty waxy film for long term corrosion protection.",
                    },
                    {
                        id:9,
                        title:" Silicone Sprays",
                        para:"Silicone Sprays provides a protective, waterproof and low friction coating to multiple surfaces.",
                    },
                    {
                        id:10,
                        title:"VCI Ammo-Guard",
                        para:"Ammo-guard 22 is a Multi-Metal Volatile Corrosion Inhibiting product used mainly for enhancing shelf-life of arms and ammunition.",
                    }
                ]

                const Desiccants= [
                    {
                        id:1,
                        title:"Zorbit Clay ",
                        para:"ZORBIT Clay contains natural activated clay based desiccants which work by evaporation and neutralization of moisture.",
                    },
                    {
                        id:2,
                        title:"Zorbit Gel",
                        para:"Zorbit Gel Desiccants are made from combination of special starch that are highly hygroscopic and absorb up to 350% its own weight. ",
                    }]

                    const Rustromvers= [
                        {
                            id:1,
                            title:"Rust Converters ",
                            para:"Rust Converter is a powerful oxidation converter designed for use on various steels, iron, cast iron and copper etc.,",
                        },
                        {
                            id:2,
                            title:"Rust Remover",
                            para:"Rust-X Neutra-Safe Rust Remover is a powerful oxidation remover designed for use on various steels, iron,cast iron etc., ",
                        }]

  return (
    <div className='container servicepage-head'>
         { pid === "Bio-Degradable" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> Bio Degradable Products</span>
                </div>
            <div className='service-slider'>
            {/* <Slider className='serv-sliders'  {...settings}> */}
            <div className='biots'>
                        <img src="/images/bio1.webp" className='iml image-fluid' />
                        <div className='plo'>
                        </div>
                        <div className='testplo'>
                            <h3 className='biops'>Food packaging</h3>
                            <Link to="/products/Bio-Degradable/1" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                        
                    </div>
                    <div className='biots'>
                        <img src="/images/bio2.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                            <h3 className='biops'>Lamination</h3>
                            <Link to="/products/Bio-Degradable/2" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio4.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Agriculture</h3>
                            <Link to="/products/Bio-Degradable/3" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio3.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Shopping Carry Bags</h3>
                            <Link to="/products/Bio-Degradable/4" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio5.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Shrink, Strech Films </h3>
                            <Link to="/products/Bio-Degradable/5" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio6.webp" className='iml image-fluid' />
                        <div className='plo'>
                            
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Paper Like Films</h3>
                            <Link to="/products/Bio-Degradable/6" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio7.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Bottles, Containers</h3>
                            <Link to="/products/Bio-Degradable/7" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio8.webp" className='iml image-fluid' />
                    
                        <div className='plo'>
                           
                               
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Cutlery</h3>
                                <Link to="/products/Bio-Degradable/8" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/bio9.webp" className='iml image-fluid' />
                        <div className='plo'>
                          
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Garbage Bags</h3>
                            <Link to="/products/Bio-Degradable/9" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
            {/* </Slider> */}
            </div>
         </>
        }
         { pid === "IndustrialCutting" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span>Industrial Cutting Products</span>
                </div>
            <div className='service-slider'>
            {/* <Slider className='serv-sliders'  {...settings}> */}
            <div className="biots">
                  <img src="/images/solid.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Solid Drill</h3>
                    <Link to="/products/IndustrialCutting/1" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/solidcut.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Solid Milling Cutters</h3>
                    <Link to="/products/IndustrialCutting/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/taps1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Taps</h3>
                    <Link to="/products/IndustrialCutting/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/turning1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Turning </h3>
                    <Link to="/products/IndustrialCutting/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/milling1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Milling</h3>
                    <Link to="/products/IndustrialCutting/5" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/therad1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Threading</h3>
                    <Link to="/products/IndustrialCutting/6" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/holmak.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Hole Making</h3>
                    <Link to="/products/IndustrialCutting/7" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/toolsys1.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Tooling System</h3>
                    <Link to="/products/IndustrialCutting/8" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
            {/* </Slider> */}
            </div>
         </>
        }
         { pid === "GasPipeline" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> GasPipeline Products</span>
                </div>
            <div className='service-slider'>
            {/* <Slider className='serv-sliders'  {...settings}> */}
            <div className='biots'>
                        <img src="/images/dp.webp" className='iml image-fluid' />
                        <div className='plo'>
                            
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Domestic Pipeline</h3>
                            <Link to="/products/GasPipeline/1" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/cp.webp" className='iml image-fluid' />
                        <div className='plo'>
                          
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Commercial Pipeline</h3>
                            <Link to="/products/GasPipeline/2" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/lot.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>LOT Pipeline Systems</h3>
                            <Link to="/products/GasPipeline/3" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/mlp.webp" className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Medical/Laboratory </h3>
                            <Link to="/products/GasPipeline/4" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        <img src="/images/gfp.webp " className='iml image-fluid' />
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>Gas Fuel Conversion </h3>
                            <Link to="/products/GasPipeline/5" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
            {/* </Slider> */}
            </div>
         </>
        }
         { pid === "OfficeEssential" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> Office Essential Products</span>
                </div>
            <div className='service-slider'>
            {/* <Slider className='serv-sliders'  {...settings}> */}
            <div className="biots">
                  <img src="/images/thermalpaper.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Thermal Paper Roll</h3>
                    <Link to="/products/OfficeEssential/1" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/normal paper.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Normal Paper Roll</h3>
                    <Link to="/products/OfficeEssential/2" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/corrugated-paper-roll.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Carrugated Paper Roll</h3>
                    <Link to="/products/OfficeEssential/3" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/ply-corrugated-roll.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Carrugated Ply Roll</h3>
                    <Link to="/products/OfficeEssential/4" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/office.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">Office Stationery's</h3>
                    <Link to="/products/OfficeEssential/5" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
                <div className="biots">
                  <img src="/images/blank-white-paper.webp" className="iml image-fluid" />
                  <div className="plo"></div>
                  <div className="testplo">
                    <h3 className="biops">A4 Bundles</h3>
                    <Link to="/products/OfficeEssential/6" target="_blank">
                      <button className="butts btn1">+</button>
                    </Link>
                  </div>
                </div>
            {/* </Slider> */}
            </div>
         </>
        }
         { pid === "Industriallpg" &&
         <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> Industrial LPG Products</span>
                </div>
            <div className='service-slider'>
            {/* <Slider className='serv-sliders'  {...settings}> */}
            <div className='biots'>
                <div className='lpgimg'>
                        <img src="/images/lpg1.webp" className='imllpg ' />
                        </div>
                        <div className='plo'>

                        </div>
                        <div className='testplo'>
                            <h3 className='biops'>Commercial Cylinder​</h3>
                            <Link to="/products/Industriallpg/1" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                    <div className='lpgimg'>
                        <img src="/images/lpg2.webp" className='imllpg  image-fluid' />
                        </div>
                        <div className='plo'>
                          
                        </div>
                        <div className='testplo'>
                            <h3 className='biops'>BMCG</h3>
                            <Link to="/products/Industriallpg/2" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                        
                             <img src="/images/lpg3.webp" className='iml  image-fluid' />
                        
                      
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>HIPPO</h3>
                            <Link to="/products/Industriallpg/3" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                    <div className='biots'>
                    <div className='lpgimg'>
                        <img src="/images/lpg4.webp" className='imllpg  image-fluid' />
                        </div>
                        <div className='plo'>
                           
                        </div>
                        <div className='testplo'>
                        <h3 className='biops'>5KG FTL</h3>
                            <Link to="/products/Industriallpg/4" target='_blank'><button className='butts btn1'>+</button></Link>
                        </div>
                    </div>
                 
            {/* </Slider> */}
            </div>
         </>
        }
        {
            cat === "VCI Packaging" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>VCI Packaging</span>
            </div>
            <div className='subcategory1'>
            {
                VCI.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airport" :val.id===2 ? "airport1" :val.id===3 ? "airport2" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/VCIPackaging/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "Cleaners" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Cleaners</span>
            </div>
            <div className='subcategory1'>
            {
                Cleaners.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airports1" :val.id===2 ? "airports2" :val.id===3 ? "airports3" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Cleaners/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "Cutting Oils" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Cutting</span>
            </div>
            <div className='subcategory1'>
            {
                CuttingOil.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airporting1" :val.id===2 ? "airporting2" :val.id===3 ? "airporting3" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Cutting/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "VCI Emitters" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Vci emitters</span>
            </div>
            <div className='subcategory1'>
            {
                Vciemitters.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "air1" :val.id===2 ? "air2" :val.id===3 ? "air3" :val.id===4 ? "air4" :
                                                            val.id===5 ? "air5" : val.id===6 ? "air6" : val.id===7 ? "air7" :val.id===8 ? "air8" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Vciemitters/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "Coatings" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Coatings</span>
            </div>
            <div className='subcategory1'>
            {
                Coatings.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airportings1" :val.id===2 ? "airportings2" :val.id===3 ? "airporting3" :val.id===4 ? "airport3" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Coating/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "Rust Preventive Oils" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Rustpreventiveoils</span>
            </div>
            <div className='subcategory1'>
            {
                Rustpreventiveoils.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airpo1" :val.id===2 ? "airpo2" :val.id===3 ? "airpo3" :val.id===4 ? "airpo4" :
                                                            val.id===5 ? "airport4" : val.id===6 ? "airport5" : val.id===7 ? "airport6" :val.id===8 ? "airport7" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/RustPreventiveOils/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "Steel Coil Packaging" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Steelcoil</span>
            </div>
            <div className='subcategory1'>
            {
                Steelcoil.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airp1" :val.id===2 ? "airp2" :val.id===3 ? "airp3" :val.id===4 ? "airp4" :
                                                            val.id===5 ? "airp5" : val.id===6 ? "airp6" : val.id===7 ? "airp7" :val.id===8 ? "airp8" :
                                                            val.id===9 ? "airport8" : val.id===10 ? "airport9" : val.id===11 ? "airport10": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Steelcoil/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "VCI Sprays" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Vcisprays</span>
            </div>
            <div className='subcategory1'>
            {
                Vcisprays.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "airp01" :val.id===2 ? "airp01" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/VciSpray/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }
            </div>
            </>
        }
        {
            cat === "Desiccants" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Desiccants</span>
            </div>
            <div className='subcategory1'>
            {
                Desiccants.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ai01" :val.id===2 ? "ai02" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Desiccants/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

            </div>
            </>
        }
        {
            cat === "Remover & Converter" &&
            <>
            <div className='prdtline-heading'>
                <div className='prdtunder-line'></div>
                <span>Rustromvers</span>
            </div>
            <div className='subcategory1'>
            {
                Rustromvers.map((val,ind) => (
                    <>
                        <div className='subcategoryhead'>
                            
                                <div className={`subcategory2 ${val.id===1 ? "ai1" :val.id===2 ? "ai2" :val.id===3 ? "airp01" :val.id===4 ? "airp01" :
                                                            val.id===5 ? "airp01" : val.id===6 ? "airp01" : val.id===7 ? "airp01" :val.id===8 ? "airp01" :
                                                            val.id===9 ? "airp02" : val.id===10 ? "airp03" : val.id===11 ? "airp01": val.id===12 ? "airport11" :
                                                            val.id===13 ? "airport12" : val.id===14 ? "airport13" : val.id===15 ? "airport14": val.id===16 ? "airport15" :
                                                            val.id===17 ? "airport16" : val.id===18 ? "airport17" : val.id===19 ? "airport18": val.id===20 ? "airport19" :""}`}>

                                </div>
                                <div className='subcategory3'>
                                    <h4>{val.title}</h4>
                                    <div className='pilot'>
                                        <p>{val.para}</p>
                                            <Link target='_blank' to={`/products/LubricantsPacking/Rust-X/Remover&Converter/${val.id}`}><button className='btn btn-md warrier'>Read More &gt;&gt;</button></Link>
                                    </div>
                                </div>
                        </div>
                    </>
                    ))
                }

            </div>
            </>
        }

        {/* Fillezy */}
        {
            id === "Fillezy" &&
            <>
            <div className='prdtline-heading'>
                    <div className='prdtunder-line'></div>
                    <span> Fillezy Products</span>
                </div>
                 <div className='ghj'>
                 <div className='biots'>
                <img src="/images/fillezy1.webp" className='iml image-fluid' />
                <div className='plo'>
                   
                </div>
                <div className='testplo'>
                <h3 className='biops'>Bio Aer</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/1"><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy2.webp" className='iml image-fluid' />
                <div className='plo'>
                  
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillexy Air Bubbles</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/2"><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy3.webp" className='iml image-fluid' />
                <div className='plo'>
                  
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillezy Air Cushion</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/3" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy4.webp" className='iml image-fluid' />
                <div className='plo'>
                   
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillezy Air Quilts</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/4" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy5.webp" className='iml image-fluid' />
                <div className='plo'>
                    
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillezy Air Tubes</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/5" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy6.webp" className='iml image-fluid' />
                <div className='plo'>
                   
                </div>
                <div className='testplo'>
                <h3 className='biops'> Double Cushion</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/6"><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy7.webp" className='iml image-fluid' />
                <div className='plo'>
                    
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillezy Handling</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/7" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy8.webp" className='iml image-fluid' />
            
                <div className='plo'>
                   
                       
                  
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillezy Hexa Paper</h3>
                        <Link to="/products/LubricantsPacking/Fillezy/8"><button className='butts btn1'>+</button></Link>
                </div>
            </div>
            <div className='biots'>
            <img src="/images/fillezy9.webp" className='iml image-fluid' />
                <div className='plo'>
                  
                </div>
                <div className='testplo'>
                <h3 className='biops'>Fillezy Rapid </h3>
                    <Link to="/products/LubricantsPacking/Fillezy/9" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>

            <div className='biots'>
            <img src="/images/fillezy10.webp" className='iml image-fluid' />
                <div className='plo'>
                    
                </div>
                <div className='testplo'>
                <h3 className='biops'>Paper Aer</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/10" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>

            <div className='biots'>
            <img src="/images/fillezy11.webp" className='iml image-fluid' />
                <div className='plo'>
                   
                </div>
                <div className='testplo'>
                <h3 className='biops'>Paper-Smart Machine</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/11"><button className='butts btn1'>+</button></Link>
                </div>
            </div>

            <div className='biots'>
            <img src="/images/fillezy12.webp" className='iml image-fluid' />
                <div className='plo'>

                </div>
                <div className='testplo'>
                <h3 className='biops'>Paper-Smart Pads</h3>
                    <Link to="/products/LubricantsPacking/Fillezy/12" ><button className='butts btn1'>+</button></Link>
                </div>
            </div>
        </div>
            </>
        }
    </div>
  )
}

export default ProductSlider