import React,{useState,useEffect} from 'react';
import '../css/AboutBackground.css';
import { BiBadge } from 'react-icons/bi';


function AboutBackground(){

    const [para1,setPara1]=useState(true);
    const [para2,setPara2]=useState(false);
    const [para3,setPara3]=useState(false);

    const Clickonme=()=>{
        setPara1(true);
        setPara2(false);
        setPara3(false);
    }

    const Clickonme1=()=>{
       
        setPara1(false);
        setPara2(true);
        setPara3(false);
    }

    const Clickonme2=()=>{
        setPara1(false);
        setPara2(false);
        setPara3(true);
    }
    return(
        <div>
            {/* <div className='fullimageview'>
                <div className='container'>
                    <div className='parentviewing'>
                        <div className='left_col_value'>

                        </div>
                         <div className='right_col_value'>
                            <div className='zero'>
                            <div className='first'>
                            <p> Like What We Offer</p>
                            </div>
                            <div className='second'>
                            <h1>DO BUSINESS<br></br> WITH US!</h1>
                            </div>
                            <div className='third'>
                            <p>In order to acquire our services please kindly send us your inquiry of interest.
                                 We shall revert back immediately</p>
                           </div>
                           <div className='puffy'>
                           <button className='puffy1'>Contact us</button>
                           </div>
                           </div>
                         </div>
                    </div>

                </div>

            </div> */}
            <div className='downgirl mt-5' >
            <div className='container'>
                <div className='col-md-8'>
                <div className='inview'>
                    <div className='gridcontroller'>
                    <div className='parajick'>
                    <p className='paranick'  onClick={Clickonme}>Our Vision</p>
                    <p className='paranick' onClick={Clickonme1}>Our Misssion</p>
                    <p className='paranick' onClick={Clickonme2}>Quality</p>
                    </div>
                    <div className='coding'>
                    <div className={para1? 'codeview' : 'code'} onClick={Clickonme}></div>
                    <div className={para2?'codeview' :'code1' }onClick={Clickonme1}></div>
                    <div className={para3?'codeview' :'code2' }onClick={Clickonme2}></div>
                    </div>
                    <div className='parapart'>

                    {para1? <p className='ourpara1'> Setting industry standards by delivering the best service and upholding superior integrity in all customer interactions.</p> : null}
                        {para1? <p className='ourpara2'>Creating a nurturing work environment where employees reach their fullest potential, enabling exceptional customer service</p> : null}
                          

                             {para2? <p className='ourpara1'> Dedicated to delivering superior products and services that blend performance and value, setting industry standards.</p> : null}
                        {para2? <p className='ourpara2'>Building enduring customer relationships through exceptional service, understanding, and a commitment to exceeding expectations</p> : null}
                             

                             {para3? <p className='ourpara1'>Supplying products that consistently meet customer expectations, ensuring their satisfaction and trust</p> : null}
                        {para3? <p className='ourpara2'>Dedication to enhancing our business through continual refinement of Quality Management Systems, fostering a culture of ongoing improvement</p> : null}

                             </div>
                    </div>
                </div>
                </div>
                <div className='col-md-4'>

                </div>
                </div>
            </div>

        </div>
    )
}

export default AboutBackground;