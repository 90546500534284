import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../../css/Productdetails.css';

const Weld = ({id,cid,cat}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "welding"&&cid==='1'){
            setShow("vcp1")
          }
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "WELDING SAFETY"   
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/welding.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Welding safety is paramount in industrial settings, and a comprehensive approach involves prioritizing protection for the face, body, and hands of welders. Welders utilize specialized welding helmets with auto-darkening filters, ensuring the shielding of their faces and eyes from intense light and sparks. Additional face protection is provided by face shields, guarding against flying debris and burns. Flame-resistant clothing, including welding jackets or coveralls, forms a crucial component of body protection, shielding welders from sparks, molten metal, and potential flash fires</p> : 
                                null}
                               
                            </div>
                        </div>

                        < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>Hand protection is ensured through the use of welding gloves made from durable, flame-resistant materials, safeguarding against heat, sparks, and sharp edges. In conjunction with respiratory protection and steel-toed boots for foot safety, this holistic approach to personal protective equipment creates a secure working environment, mitigating the risks associated with welding operations and promoting the well-being of the workforce.
                            </p> : null}

                            </div>

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Welders must use proper welding helmets with auto-darkening filters to protect their face and eyes from intense light, sparks, and infrared radiation generated during welding. Face shields provide additional protection against flying debris and burns.</p>: null}

                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Welders should wear flame-resistant clothing, such as welding jackets or coveralls, to safeguard their bodies from sparks, molten metal, and potential flash fires. This clothing helps prevent burns and provides an additional layer of protection.</p>: null}
                                    
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Welding gloves are essential to shield the hands from heat, sparks, and sharp edges. These gloves, made of durable and flame-resistant materials, protect against burns and injuries during welding operation</p>: null}
                                   
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>In environments with inadequate ventilation or when welding certain materials, respiratory protection becomes crucial. Welders should use appropriate respirators to prevent inhalation of harmful fumes and particulates.</p>: null}
                                   
                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Welders should wear steel-toed boots to protect their feet from falling objects, heavy equipment, and potential hazards in the welding environment. These boots provide stability and prevent injuries caused by impact or compression.</p>: null}

                                    </span>
                            </div>
                          
                        </div>
    </div>
  )
}

export default Weld