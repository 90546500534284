import React, { useEffect, useState } from "react";
import AOS from "aos";
import { GiNetworkBars } from "react-icons/gi";
import { BiSolidCctv } from "react-icons/bi";
import { BsArrowRight, BsLaptopFill, BsProjectorFill } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { BsBasket } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "../css/Banner.css";
import Navbar from "./Navbar";
import { BsTwitter} from "react-icons/bs";
import { FaFacebookF} from "react-icons/fa";
import { AiFillInstagram} from "react-icons/ai";
import { IoLogoWhatsapp} from "react-icons/io";
import { Link } from "react-router-dom";
import { FaHelmetSafety } from "react-icons/fa6";


const Banner = ({windowSize,color,setColor}) => {
  const [isOpen, setIsOpen] = useState(false);
  // services icons style 
  let iconStyles = { color: "#37a7fd", fontSize: "1.5em" };
  const toggleNavbar = () => {
      setIsOpen(!isOpen);
  };
  // banner slider usestate
  const [currSlide, setCurrSlide] = useState(0);

  const images = [
    
    // "/images/banneer2.webp",
    "/images/newbanner2.webp",
    // "/images/samplebanner.webp",
    "/images/test-banner.webp",
  ];

  useEffect(() => {
    AOS.init();
    // console.log(currSlide)
    const interval = setInterval(() => {
      setCurrSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 8000);

    return () => clearInterval(interval);
  },[currSlide]);

  // nextslide
  const goToSlide = (slideIndex) => {
    setCurrSlide(slideIndex);
  };

  // banner slider bg image style
  const backgroundImage = {
    backgroundImage: `url(${images[currSlide]})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "70%",
    // backgroundAttachment: "fixed",
  };

  return (
    <div className="iot-ban-and-serv">
     {/* { windowSize[0] <= 940 && */}
      <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
     {/* } */}
      {/* banner */}
      <div className="banner">
      {/* { windowSize[0] >= 940 &&
                  <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
                } */}
        {currSlide === 0 && (
          <div className="iot-bgimg" style={backgroundImage}>
             {/* Navbar */}
               {/* { width >= 940 &&
                  <Navbar width={width}/>
                } */}

            <div className="banner-content1 container">
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ color: "#ffffff" }}
              >
                Welcome to <span style={{ color: "#37a7fd", fontWeight: 900 }}>Edgecut</span>
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1200"
                style={{ color: "#37a7fd", fontWeight: 900 }}
              >
                {/* EdgeCut Tools */}
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1400"
                style={{ color: "#ffffff", fontWeight: 900 }}
              >
                
              </p>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="banner-content2 container"
            >
              <p style={{ color: "#ffffff" }}>
                We've been lucky to collaborate with
              </p>
              <p style={{ color: "#37a7fd" }}>a long list of customers</p>
              
              <button
                data-aos="fade-up"
                data-aos-duration="2500"
                className="test-btns"
              >
                <span className="test-sc"></span>
                <span className="iot-text">
                  About Us
                  <BsArrowRight className="btns-icons1" />
                </span>
              </button>
            </div>
            {/* <img
              className="iot-ani-img"
              src="/boxblue.png"
            />
            <img
              className="iot-ani1-img"
              src="/boxblue2.png"
            /> */}

            <div className="ani-con" style={{}}>
                <Link to="/contacts"><span style={{color:"#fff"}}>Contact us</span></Link>
                <p style={{width:"5px",backgroundColor:"#37a7fd",height:"50px",marginTop:"10px"}}></p>
            </div>

            <div className="si-con" style={{}}>
              
              
              <span style={{color:"#313131"}}>
                <BsTwitter className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <FaFacebookF className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <AiFillInstagram className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <IoLogoWhatsapp className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
              </span>
              <p style={{width:"5px",backgroundColor:"#313131",height:"50px",marginTop:"10px"}}></p>
            </div>

            <img className="img-ring" src="/images/ringpic.png" />

            <img
              className="iot-ani-img"
              src="/boxblue.png"
            />
            <img
              className="iot-ani1-img"
              src="/boxblue2.png"
            />
          </div>
        )}
        {currSlide === 1 && (
          <div className="iot-bgimg" style={backgroundImage}>
            {/* Navbar */}
            {/* { width >= 940 &&
                  <Navbar width={width}/>
                } */}
            <div className="banner-content1 container">
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ color: "#ffffff" }}
              >
              <span  style={{ color: "#37a7fd", fontWeight: 900 }}> Delivering </span> Complete
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1200"
                style={{ color: "#ffffff" }}
              >
                 Industrial <span  style={{ color: "#37a7fd", fontWeight: 900 }}>Solutions</span>
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1400"
                style={{ color: "#ffffff", fontWeight: 900 }}
              >
               
              </p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="banner-content2 container"
            >
              <p style={{ color: "#ffffff" }}>
                We've been lucky to collaborate with
              </p>
              <p style={{ color: "#37a7fd" }}>a long list of customers</p>
              <button
                data-aos="fade-up"
                data-aos-duration="2500"
                className="test-btns"
              >
                <span className="test-sc"></span>
                <span className="iot-text">
                  About Us
                  <BsArrowRight className="btns-icons1" />
                </span>
              </button>
            </div>


            <img
              className="iot-ani-img"
              src="/boxblue.png"
            />
            <img
              className="iot-ani1-img"
              src="/boxblue2.png"
            />

        
          
               <div className="ani-con" style={{}}>
               <Link to="/contacts"><span style={{color:"#fff"}}>Contact us</span></Link>
                <p style={{width:"5px",backgroundColor:"#37a7fd",height:"50px",marginTop:"10px"}}></p>
            </div>

            <div className="si-con" style={{}}>
              
              
              <span style={{color:"#313131"}}>
              <BsTwitter className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <FaFacebookF className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <AiFillInstagram className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <IoLogoWhatsapp className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
              </span>
              <p style={{width:"5px",backgroundColor:"#313131",height:"50px",marginTop:"10px"}}></p>
            </div>

            <img className="img-ring" src="/images/ringpic.png" />

          </div>
        )}
        {currSlide === 2 && (
          <div className="iot-bgimg" style={backgroundImage}>
           {/* Navbar */}
           {/* { width >= 940 &&
                  <Navbar width={width}/>
                } */}
            <div className="banner-content1 container">
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ color: "#ffffff" }}
              >
                You have the{" "}
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1200"
                style={{ color: "#37a7fd", fontWeight: 900 }}
              >
                Need, We have
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="1400"
                style={{ color: "#ffffff", fontWeight: 900 }}
              >
                the SOLUTION
              </p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="banner-content2 container"
            >
              <p style={{ color: "#ffffff" }}>
                We've been lucky to collaborate with
              </p>
              <p style={{ color: "#37a7fd" }}>a long list of customers</p>
              <button
                data-aos="fade-up"
                data-aos-duration="2500"
                className="test-btns"
              >
                <span className="test-sc"></span>
                <span className="iot-text">
                  About Us
                  <BsArrowRight className="btns-icons1" />
                </span>
              </button>
            </div>
            

            <img
              className="iot-ani-img"
              src="/boxblue.png"
            />
            <img
              className="iot-ani1-img"
              src="/boxblue2.png"
            />

         
               <div className="ani-con" style={{}}>
                <span style={{color:"#fff"}}>Contact us</span>
                <p style={{width:"5px",backgroundColor:"#37a7fd",height:"50px",marginTop:"10px"}}></p>
            </div>

            <div className="si-con" style={{}}>
              
              
              <span style={{color:"#313131"}}>
                <BsTwitter className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <FaFacebookF className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <BsTwitter className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
                <BsTwitter className="si-con1"  style={{rotate:"95deg",margin:"10px 0px",fontSize:"20px"}}/>
              </span>
              <p style={{width:"5px",backgroundColor:"#313131",height:"50px",marginTop:"10px"}}></p>
            </div>

            <img className="img-ring" src="/images/ringpic.png" />

          </div>
        )}
      </div>

      {/* dot container */}
      <div className="dots-container">
        {images.map((_, index) => (
          <React.Fragment key={index}> 
            {index === 0 && <div className="line" key={index+1}/>}
            <span
              key={index+2}
              className={currSlide === index ? "dots active" : "dots"}
              onClick={() => goToSlide(index)}
            ></span>
            {index < images.length + 1 - 1 && (
              <span key={`line-${index}`} className="line"></span>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* services */}

      <div className="container heading">
        <div className="containerss container ">
          <div className="bg1">
            <div className="head">
              <div className="sub-head">
                <h1 className="icon">
                  <FaHelmetSafety  style={iconStyles} />
                </h1>
                <h1 className="title">
                  Industrial Safety
                  <br />
                   Products
                </h1>
              </div>

              <div className="number">01</div>
            </div>
          </div>

          <div className="overlay1">
            <div className="texts">
              <h4>
                {" "}
                Industrial Safety <br />  Products{" "}
              </h4>
              <div className="texts-line" />
              <p>
               
Industrial safety products are essential for protecting workers and maintaining secure work environments in various industries.
              </p>
            </div>

            {/* arrow */}
            <div className="overlay-arrow">
              <Link to='/Product_Category/IndustrialSafety'><BsArrowRight style={{ fontSize: "22px", marginTop: "10px" }} /></Link>
            </div>
          </div>
        </div>

        <div className="containerss container ">
          <div className="bg2">
            <div className="head">
              <div className="sub-head">
                <h1 className="icon">
                  {/* <MdOutlineSecurity style={iconStyles} /> */}
                  <img src="/images/gas-icon.png" style={iconStyles} height="60px"/>
                </h1>
                <h1 className="title">
                  Gas Pipeline
                  <br />
                   Projects
                </h1>
              </div>

              <div className="number">02</div>
            </div>
          </div>

          <div className="overlay1">
            <div className="texts">
              <h4>
                {" "}
                Gas Pipeline
                <br />
                 Projects
              </h4>
              <div className="texts-line" />
              <p>
              Expert in comprehensive gas pipeline projects, prioritizing excellence in Commercial and LPG installations across diverse sectors.
              </p>
            </div>
            {/* arrow */}
            <div className="overlay-arrow">
            <Link to='/Product_Category/gas-pipeline'><BsArrowRight style={{ fontSize: "22px", marginTop: "10px" }} /></Link>
            </div>
          </div>
        </div>

        <div className="containerss container">
          <div className="bg3">
            <div className="head">
              <div className="sub-head">
                <h1 className="icon">
                  {/* <BsProjectorFill style={iconStyles} /> */}
                  <img src="/images/bio-energy.png" style={iconStyles} height="60px"/>
                </h1>
                <h1 className="title">
                Bio-Degradable <br />  Products
                </h1>
              </div>

              <div className="number">03</div>
            </div>
          </div>

          <div className="overlay1 ">
            <div className="texts">
              <h4>
                {" "}
                Bio-Degradable <br /> Products
              </h4>
              <div className="texts-line" />
              <p>
              Dr. Bio Polymers: Eco-friendly, compostable resins for film and blow-moulding, efficiently degrading within 3-6 months in landfills.{" "}
              </p>
            </div>
            {/* arrow */}
            <div className="overlay-arrow">
            <Link to='/Product_Category/bio-degradable'><BsArrowRight style={{ fontSize: "22px", marginTop: "10px" }} /></Link>
            </div>
          </div>
        </div>

        <div className="containerss container">
          <div className="bg4">
            <div className="head">
              <div className="sub-head">
                <h1 className="icon">
                  {/* <BsLaptopFill style={iconStyles} /> */}
                  <img src="/images/lub-icon.png" style={iconStyles} height="60px"/>
                </h1>
                <h1 className="title">
                Lubricants Packing<br />  Products
                </h1>
              </div>

              <div className="number">04</div>
            </div>
          </div>

          <div className="overlay1">
            <div className="texts">
              <h4>
              Lubricants Packing<br /> Products
              </h4>
              <div className="texts-line" />
              <p>
              Lubricant products incorporating Rust-X VCI for corrosion protection and Fillezy solutions ensuring damage prevention across industries.
              </p>
            </div>
            {/* arrow */}
            <div className="overlay-arrow">
            <Link to='/Product_Category/lubricants'><BsArrowRight style={{ fontSize: "22px", marginTop: "10px" }} /></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
