import React, { useEffect, useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { AiOutlineSearch } from "react-icons/ai";
import {BsFillTelephoneFill, BsBasket,BsArrowRight } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsTwitter} from "react-icons/bs";
import { FaFacebookF} from "react-icons/fa";
import { AiFillInstagram} from "react-icons/ai";
import { IoLogoWhatsapp} from "react-icons/io";
import { AiFillMail } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import '../css/Nav.css'
import { Link } from 'react-router-dom';

const Nav = ({width}) => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [color, setColor] = useState(false)
    const changeColor = () => {
        if(window.scrollY >= 90){
            setColor(true)
        }else{
            setColor(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeColor)

        return () => window.removeEventListener('scroll',changeColor)
    })
    const [opennav, setOpenNav] = useState(false)

    return (
      <div className="cn-all-head">
        <div className={`cn-navheader ${color ? "cn-head-bg" : ""}`}>
            <nav className='cn-navbar'>
                <a href='/' className='cn-logo'>
                    <img src="/images/LOGO (1).png" alt='logo' />
                    {/* <img src="https://demo.casethemes.net/consultio-digital-marketing/wp-content/uploads/2021/02/logo-dark.png" alt='logo' /> */}
                </a>
                <div className='cn-hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} className='cn-iothead-icon' />)
                        : (<FaBars size={30} className='cn-iothead-icon' />)}

                </div>
                <ul className={`${click ? "cn-nav-menu cn-active" : "cn-nav-menu"} ${color ? "cn-nav-color" : "cn-nav-white"}`}>
                    <li className='cn-mb-img'>
                    <img src="/images/LOGO (1).png" alt='logo' />
                      {/* <img src="https://demo.casethemes.net/consultio-digital-marketing/wp-content/uploads/2021/02/logo-dark.png" alt='logo' /> */}
                    </li>
                    
                    <li className='cn-nav-item'>
                        <Link to='/'>Home</Link>
                    </li>
                    <li className='cn-nav-item'>
                        <Link to='/about-us'>About Us</Link>
                    </li>
                    <li className='cn-nav-item'>
                        <Link to='/services'>Products</Link>
                    </li>
                    <li className='cn-nav-item'>
                        <Link to='/career'>Career</Link>
                    </li>   
                    <li className='cn-nav-item'>
                        <Link to='/contacts'>Contact</Link>
                    </li>
                    <li className='cn-nav-item'>
                        <Link to='/blogs'>Blog</Link>
                    </li>
                    <li className='cn-nav-item'>
                        <Link to='/Downloadcenter'>Download Center</Link>
                    </li>
                <div>
                <div className='na-header2'>
                    <BsFillTelephoneFill className='na-icons'/>
                    <div className='na-header3 na-header4'>
                        <span className='na-span1'>Call us. +91 9876543210</span>
                        <span className='na-span2'>(Mon-Sat)</span>
                    </div>
                </div>
                <div className='na-header2'>
                    <AiFillMail className='na-icons'/>
                    <div className='na-header3 na-header4'>
                        <span className='na-span1'>Mail us for help:</span>
                        <span className='na-span2'>EdgecutTools@gmail.com</span>
                    </div>
                </div>
                <div className='na-header2'>
                    <FaLocationDot className='na-icons'/>
                    <div className='na-header3 na-header4'>
                        <span className='na-span1'>Hari complex opp.prozone mall,</span>
                        <span className='na-span2'>Saravanampatti, Coimbatore</span>
                    </div>
                </div>
                    <button
                 
                    className="test-btns"
                >
                    <span className="test-sc"></span>
                    <Link to='/contacts'>
                    <span className="iot-text">
                        Join Us
                    <BsArrowRight className="btns-icons1" />
                    </span>
                    </Link>
                </button>
                </div>
                </ul>
            </nav>  
        </div>
        
    
      </div>
    )
}
export default Nav
