import React, { useEffect } from 'react'
import '../css/Productdetails.css';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';

const CommonBtns = ({pid,id,setLoad}) => {
    const {cat} = useParams()
    const [openCategory, setOpenCategory] = useState(null);
    const [openCategory1, setOpenCategory1] = useState(null);
    const categories = [
        {
          id: 1,
          name: 'Bio Degradable Products',
          brands: ['Food Packaging', 'Lamination', 'Agriculture', 'Shopping Carry Bags',
           'Shrink, Strech Films', 'Paper Like Films', 'Bottles, Containers','Cutlery', 'Garbage Bags'],
          paths :['1','2','3','4','5','6','7','8','9']
        },
        {
          id: 2,
          name: 'Gas Pipeline projects',
          brands: ['Domestic Pipeline', 'Commercial Pipeline', 'LOT Pipeline Systems', 'Medical Pipeline', 'Gas Fuel Conversion'],
          paths :['1','2','3','4','5']
        },
        {
          id: 3,
          name: 'Industrial Cutting',
          brands: ['Solid Drill', 'Solid Milling Cut', 'Taps', 'Turning','Milling','Threading','Hole Making','Tooling System'],
          paths :['1','2','3','4','5','6','7','8']
        },
        {
          id: 4,
          name: 'Industrial Safety Products',
          brands: ['Personal Protective equipments', 'Road & Parking Safety', 'Fire Fighting', 'Welding Products', 'Exculsive Products', 'Marine Safety'],
          paths :['propre','road&parking','firefighting','welding','excul-prod','marinesafety']
        },
        {
          id: 5,
          name: 'Lubricants Packing Products',
          brands: ['Rust-X','Fillezy'],
          paths :["Rust-X",'Fillezy'],
          subPaths:{
            paths:["Cleaners",'Cutting Oils',"Coatings","VCI Packaging",'Rust Preventive Oils',"VCI Emitters","Steel Coil Packaging",'VCI Sprays',"Desiccants",'VCI Masterbatch',"Remover & Converter","Data Loggers"]
        }
        },
        {
          id: 6,
          name: 'Industrial LPG Solutions',
          brands: ['Commercial', 'BMCG', 'HIPPO', '5KG FTL',],
          paths :['1','2','3','4',]
        },
        // {
        //   id: 7,
        //   name: 'Textile Machinery Spares',
        //   brands: ['Brand A', 'Brand B', 'Brand C', 'Brand D', 'Brand E', 'Brand F', 'Brand G', 'Brand H'],
        //   paths :['foodPacking','2','3','4','5','6','7','8']
        // },
        {
          id: 8,
          name: 'Office Essential',
          brands: ['Thermal Paper Roll', 'Normal Paper Roll', 'Carrugated Paper Roll', 'Carrugated Ply Roll', `Office Stationarys's`, 'A4 Bundles',],
          paths :['1','2','3','4','5','6',]
        }
    ]

    const toggleCategory = (categoryId) => {
        setOpenCategory(openCategory === categoryId ? null : categoryId);
      };
    const toggleCategory1 = (categoryId) => {
        setOpenCategory1(openCategory1 === categoryId ? null : categoryId);
      };

      useEffect(() => {
        if(pid === "Bio-Degradable"){
            setOpenCategory(1)
        }
        if(pid === "GasPipeline"){
            setOpenCategory(2)
        }
        if(pid === "IndustrialCutting"){
            setOpenCategory(3)
        }
        if(pid === "IndustrialSafety"){
            setOpenCategory(4)
        }
        if(pid === "LubricantsPacking"){
            setOpenCategory(5)
           
        }
        if(pid === "LubricantsPacking" && id==="Rust-X"){
            setOpenCategory1("Rust-X")
        }
        if(pid === "Industriallpg"){
            setOpenCategory(6)
        }
        if(pid === "OfficeEssential"){
            setOpenCategory(8)
        }
      
    },[])

  return (
   <>
        <div className='rightboxheadcol'>
            {categories.map((category,index) => (
                <div>
                    <button onClick={() => toggleCategory(category.id)} className={`btn-categ ${ pid==="Bio-Degradable" && index===0 ? 'categ-active' : 
                                                                                    pid==="GasPipeline" && index===1 ? 'categ-active': pid==="LubricantsPacking" && index===4 ? 'categ-active' :
                                                                                    pid==="IndustrialSafety" && index===3 ? 'categ-active': pid==="Industriallpg" && index===5 ? 'categ-active':
                                                                                    pid==="OfficeEssential" && index===6 ? 'categ-active':pid==="IndustrialCutting"&& index===2 ? "categ-active":''}`} >
                            <span>
                                {category.name}
                            </span>   
                            <span>
                                <IoIosArrowForward />
                            </span> 
                    </button>
                

                    {openCategory === category.id && (
                        <span className='btn-categ1'>
                        <ul>
                            {
                                index === 0 &&
                                <>
                                    {category.brands.map((brand, index) => (
                                    <Link 
                                    className={pid==="Bio-Degradable"  && `${id === category.paths[index] ? "prdt-active" : ""}`} to={`/products/Bio-Degradable/${category.paths[index]}`} key={index}><span><li key={index}>{brand}</li>  <IoIosArrowForward /></span></Link>
                                    ))}
                                </>
                            }
                            {
                                index === 1&&
                                <>
                                    {category.brands.map((brand, index) => (
                                    <Link 
                                    className={pid==="GasPipeline"  && `${id === category.paths[index] ? "prdt-active" : ""}`} to={`/products/GasPipeline/${category.paths[index]}`} key={index}><span><li key={index}>{brand}</li>  <IoIosArrowForward /></span></Link>
                                    ))}
                                </>
                            }
                            {
                                index === 2&&
                                <>
                                    {category.brands.map((brand, index) => (
                                    <Link 
                                    className={pid==="IndustrialCutting"  && `${id === category.paths[index] ? "prdt-active" : ""}`}
                                    to={`/products/IndustrialCutting/${category.paths[index]}`} key={index}><span><li key={index}>{brand}</li>  <IoIosArrowForward /></span></Link>
                                    ))}
                                </>
                            }
                            {
                                index === 3&&
                                <>
                                    {category.brands.map((brand, index) => (
                                    <Link 
                                    className={pid==="IndustrialSafety"  && `${id === category.paths[index] ? "prdt-active" : ""}`}
                                    to={`/products/IndustrialSafety/${category.paths[index]}/1`} key={index}><span><li key={index}>{brand}</li>  <IoIosArrowForward /></span></Link>
                                    ))}
                                </>
                            }
                               {
                                index === 4 && (
                                <>
                                    {category.brands.map((brand, brandIndex) => (
                                        <div key={brandIndex}>
                                            <span className={`${id === brand ? 'prdt-active' : ''}`} onClick={brandIndex === 0 ? () => toggleCategory1(brand) : undefined}>
                                                <Link className={`${id === brand ? 'prdt-active' : ''}`} to={brandIndex === 1 ? "/products/LubricantsPacking/Fillezy/1" : ""}>
                                                    {brand}
                                                </Link>
                                                <IoIosArrowForward />
                                            </span>
                                            { openCategory1 === brand && (
                                                <span className='nested-dropdown'>
                                                    <ul>
                                                        {/* Render the specific paths related to the selected brand */}
                                                        {brand === 'Rust-X' && (
                                                            category.subPaths.paths.map((subPath, subIndex) => (
                                                                <Link
                                                                    to={`/products/LubricantsPacking/Rust-X/${subPath}/1`}
                                                                    className={`${cat === subPath ? 'prdt-active' : ''}`}
                                                                    key={subIndex}
                                                                >
                                                                    <li>{subPath}</li>
                                                                </Link>
                                                            ))
                                                        )}
                                                    </ul>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </>
                                    )
                                }

                            {
                                index === 5&&
                                <>
                                    {category.brands.map((brand, index) => (
                                    <Link to={`/products/Industriallpg/${category.paths[index]}`} 
                                    className={pid==="Industriallpg"  && `${id === category.paths[index] ? "prdt-active" : ""}`} 
                                    key={index}><span><li key={index}>{brand}</li>  <IoIosArrowForward /></span></Link>
                                    ))}
                                </>
                            }
                            {
                                index === 6&&
                                <>
                                    {category.brands.map((brand, index) => (
                                    <Link 
                                    className={pid==="OfficeEssential"  && `${id === category.paths[index] ? "prdt-active" : ""}`} 
                                    to={`/products/OfficeEssential/${category.paths[index]}`} key={index}><span><li key={index}>{brand}</li>  <IoIosArrowForward /></span></Link>
                                    ))}
                                </>
                            }
                        </ul>
                        </span>
                    )}
                </div>
            ))}
        </div>
   </>
  )
}

export default CommonBtns