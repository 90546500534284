import React from 'react'
import { BsTwitter} from "react-icons/bs";
import { FaFacebookF} from "react-icons/fa";
import { AiFillInstagram} from "react-icons/ai";
import { IoLogoWhatsapp} from "react-icons/io";
import '../css/Welcome.css'

const Welcome = () => {
    const iconStyle = {
        backgroundColor:"#37a7fd",
        color:"#fff",
        borderRadius:"50%",
        margin:"0px 10px",
        padding:"5px",
        fontSize:"28px",
    } 
  return (
    <div className='c-welcome1' >
        <span style={{color:"#fff"}}>Welcome to our company<span style={{color:"#37a7fd"}}>&nbsp; EdgeCut Tools!</span></span>
        <span>
        <FaFacebookF className='cw-icons' style={iconStyle} />
        <BsTwitter className='cw-icons' style={iconStyle}/>
        <IoLogoWhatsapp className='cw-icons' style={iconStyle}/>
        <AiFillInstagram  className='cw-icons' style={iconStyle}/>
        </span>
    </div>
  )
}

export default Welcome