import React, { useEffect, useState } from 'react'
import Navbar from '../Home/Navbar'
import Footer from '../Home/Footer'
import Welcome from '../Common/Welcome'
import SnHeader from '../Common/SnHeader'
import Nav from '../Common/Nav'
import CnBanner from '../Common/CnBanner'
import About_us from '../About/About_us'
import AbtPricing from '../About/AbtPricing'
import AboutBackground from '../About/AboutBackground'
import Expert1 from '../Home/Expert1'



const AboutPage = ({windowSize,color,setColor}) => {
  const [show, setShow] = useState(false)
  const showed = () => {
      if(window.scrollY >= 150){
        setShow(true)
      }else{
        setShow(false)
      }
  }

  useEffect(() => {
      window.addEventListener('scroll', showed)

      return () => window.removeEventListener('scroll',showed)
  })

  const image = '/images/Ec-banner.jpg'
  return (  
    <>  
    {windowSize[0] > 1200 && show &&
      <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
    }
    {windowSize[0] > 1200 && 
          <Welcome />
        }
     
      {windowSize[0] > 1390 && 
       <SnHeader /> 
    }
    {windowSize[0] < 1390 && 
      <Nav />
    }    
    <CnBanner windowSize={windowSize} title="About Us" image={image}/>
      <About_us />
      <AboutBackground />
     
      <AbtPricing />
      <Expert1/>
      <Footer color={color}/>
    </>
  )
}

export default AboutPage