import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Cleaners = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }

    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "Aqueous Cleaners"  : show === "vcp2" ? "Solvent Cleaners"  : 
                               show === "vcp3" ? "Vibrox Cleaner"  
                               :""}

                               

                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/cleaners.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/cleanrs1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/cleaners3.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'> 
                                Embrace the natural brilliance of water-based cleaning! Aqueous cleaners redefine cleanliness by harnessing the pure, cleansing power of water. Gentle yet effective, these eco-friendly solutions effortlessly remove grime, grease, and contaminants from a variety of surfaces, ensuring a pristine finish without harmful chemicals. From industrial applications to everyday household cleaning, our versatile range of aqueous cleaners is designed to meet your diverse needs. Experience a new level of clean with Aqueous Cleaners – the environmentally conscious choice for a sparkling, spotless world. Discover the refreshing difference of water-based cleaning today!</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Experience the Swift Efficacy of Solvent Cleaners: Designed for effortless cleaning, solvent cleaners swiftly dissolve tough stains, grease, and grime from various surfaces. Ideal for industrial, commercial, and household applications, these potent solutions leave surfaces spotless and residue-free. Discover the convenience of swift, efficient cleaning with our range of Solvent Cleaners. Achieve superior results with minimal effort</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>VIBROX is a special grade additive used for the vibratory machines and provides excellent washing, degreasing, deburring and cleaning properties to provide shiny parts along with superior corrosion protection properties during in-process short term storage. The cleaning agents used are bio-degradable & the corrosion preventive agents ensure corrosion free storage. The chemicals present in Vibrox lower the surface tension and prevent re adherence of particles to the components. The biocidal properties prevent development of bacterial or fungal smells in the operational facility.</p> : 
                                null}
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Aqueous cleaners are environmentally friendly as they are biodegradable and do not contain harmful solvents or chemicals, reducing their impact on ecosystems.</p>: null}
                                    {show === "vcp2"?<p> Solvent cleaners are highly effective at dissolving and removing tough grease, oil, adhesive residues, and other stubborn contaminants from various surfaces.</p>: null}
                                    {show === "vcp3"?<p>Vibrox is a specialized grade additive designed for vibratory machines, enhancing their cleaning, degreasing, deburring, and washing capabilities.</p>: null}
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>These cleaners are generally non-toxic and safe for human health, minimizing the risks associated with exposure, especially in comparison to solvent-based cleaners.</p>: null}
                                    {show === "vcp2"?<p>Solvent cleaners evaporate quickly, leaving surfaces dry and residue-free, making them suitable for applications where moisture is a concern, such as electronic components.</p>: null}
                                    {show === "vcp3"?<p> Vibrox provides excellent cleaning properties, ensuring parts come out shiny and free from contaminants. It effectively removes dirt, grease, and debris from components.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Aqueous cleaners can be formulated for a wide range of applications, including degreasing, metal cleaning, parts washing, and surface preparation, making them versatile for various industries.</p>: null}
                                    {show === "vcp2"?<p>Solvent cleaners can be formulated for specific applications, including degreasing machinery, cleaning electronic components, and preparing surfaces for painting or bonding</p>: null}
                                    {show === "vcp3"?<p> In addition to cleaning, Vibrox offers superior corrosion protection properties, making it ideal for short-term in-process storage. It prevents corrosion, ensuring the components remain in optimal condition.</p>: null}
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Aqueous cleaners are effective in removing grease, oil, dirt, and contaminants from surfaces, making them valuable in industrial settings where thorough cleaning is essential.</p>: null}
                                    {show === "vcp2"?<p>They are often used in precision cleaning applications where thorough removal of contaminants without leaving any residue is crucial, such as in aerospace and automotive industries.</p>: null}
                                    {show === "vcp3"?<p>The cleaning agents used in Vibrox are biodegradable, making it an environmentally friendly choice. It promotes green practices by utilizing eco-friendly cleaning solutions.</p>: null}
                                  
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Some aqueous cleaners contain rust inhibitors that help prevent corrosion on metal surfaces, enhancing the longevity of equipment and machinery.</p>: null}
                                    {show === "vcp2"?<p>Solvent cleaners can be suitable for cleaning delicate or sensitive surfaces like electronic circuits, where water-based cleaners might cause damage.</p>: null}
                                    {show === "vcp3"?<p>Vibrox contains chemicals that lower the surface tension, preventing particles from re-adhering to the components. This feature ensures a thorough and effective cleaning process.</p>: null}
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Because they are water-based, aqueous cleaners are easier to dispose of and typically do not require special handling or disposal methods, reducing disposal costs and environmental impact.</p>: null}
                                    {show === "vcp2"?<p>Many solvent cleaners are flammable and require careful handling and storage to prevent accidents. Safety precautions, including proper ventilation, are necessary when using solvent-based cleaners.</p>: null}
                                    {show === "vcp3"?<p>Vibrox possesses biocidal properties, preventing the development of bacterial or fungal odors in operational facilities. It maintains a clean and hygienic environment.</p>: null}

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Aqueous cleaners can be used in ultrasonic and high-pressure spray cleaning systems, which are energy-efficient and provide thorough cleaning even in complex geometries.</p>: null}
                                    {show === "vcp2"?<p>Solvent cleaners can contain volatile organic compounds (VOCs) that contribute to air pollution. Environmentally friendly, low-VOC or VOC-free solvent alternatives are available to mitigate environmental impact.</p>: null}
                                    {show === "vcp3"?<p>Vibrox is particularly effective in degreasing operations, removing oils and greases from parts. This is crucial in industries where components need to be free from any oily residues.</p>: null}
                                  
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Many aqueous cleaners comply with industry standards and regulations, ensuring that they meet specific quality and safety criteria, making them suitable for use in regulated environments.</p>: null}
                                    {show === "vcp2"?<p>Due to environmental and health concerns, regulations regarding the use and disposal of solvent cleaners vary by region. Compliance with local regulations is crucial to ensure safe usage and proper disposal.</p>: null}
                                    {show === "vcp3"?<p>By providing superior cleaning, degreasing, and corrosion protection properties, Vibrox enhances operational efficiency, ensuring components are cleaned effectively and stored safely during short-term storage periods.</p>: null}
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Cleaners