import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const VciSpray = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "Rust-X"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "Rust-X"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "Rust-X"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "Rust-X"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "Rust-X"&&cid==='8'){
            setShow("vcp8")
          }
        if(id === "Rust-X"&&cid==='9'){
            setShow("vcp9")
          }
        if(id === "Rust-X"&&cid==='10'){
            setShow("vcp10")
          }
      
       
        
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "Conformal Coating"  : show === "vcp2" ? "RUST-X 40"  : 
                               show === "vcp3" ? "RUST-X Anti Spatter"  : show === "vcp4" ? "RUST-X Clear Coat"  :
                               show === "vcp5" ? "RUST-X Cold Zinc"  : show === "vcp6" ? "RUST-X Degreaser"  :
                               show === "vcp7" ? "RUST-X Liquid Grease"  : show === "vcp8" ? "Under Body Coat"  :
                               show === "vcp9" ? "Silicone Sprays"  : show === "vcp10" ? "VCI Ammo-Guard"  
                            
                               :""}

                              

                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp5" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp6" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp7" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp8" ?
                                <>
                                    <img src='/images/vcispray1.webp' alt='dummy'/> 
                                </>:
                               show === "vcp9" ?
                                <>
                                    <img src='/images/vcispray2.webp' alt='dummy'/> 
                                </>:
                               show === "vcp10" ?
                                <>
                                    <img src='/images/vcispray3.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>RUST-X Electrical and Electronic Contact Cleaner and Conformal coatings are ideal for cleaning , maintaining and servicing electrical and electronic components, switches and circuit boards. It does not contain any lubricants. It is composed of a special cleaning fluid exhibiting low surface tension, high solvency, relatively rapid evaporation characteristics and deep penetrating properties.Conformal coating is a protective coating applied to electronic circuit boards and other sensitive components to protect them from environmental factors such as moisture, dust, chemicals, and temperature extremes. </p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>RUST-X 40 is a multipurpose spray and an excellent Rust Preventive and degreaser for mild, heavy and waxy coatings removal. RustX products have longer corrosion prevention ability owing to its registered and patented VCI technology. Rust-X oils prevent corrosion of the metallic components in the most damaging environment by providing sea worthy corrosion prevention from saline atmosphere as well as during long term storage of parts. RUST-X 40 spray contains a thin film type rust preventive oil, which can act as an excellent degreaser, provides lubrication during use but dries to an invisible film after 2-4 minutes of application</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>RUST-X Anti Spatter Spray is a non-silicone, non-flammable release preparation that prevents adhesion of spatter on welding tools and parts. It also has a nozzle which prevents accumulation of spatter on nozzles & tips of welding torches.RUST-X Anti Spatter is a specialized product used in welding processes to prevent weld spatter from adhering to metal surfaces.RUST-X Anti Spatter or any similar product, it's important to follow the manufacturer's instructions for proper application and safety guidelines to ensure effective spatter prevention and a safe working environment during welding operations.</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>RUST-X Clear Coat Spray is a high gloss clear fast drying, flexible, protective, and insulating coating designed to protect metal, plastic, wood, concrete, glass, and other substrates from atmospheric corrosion, dust, and other common contaminants. Clearcoat is also transparent paint that goes over your base coat and protects it from damage and UV rays. It also gives a glossy finish that deepens the base color quality and lets you easily buff out small scratches.RUST-X Clear Coat offers a clear and protective solution to prevent corrosion on metal surfaces while preserving their natural appearance.</p> : 
                                null}
                                {show === "vcp5"? <p className='text-muted firstparavalue'>Cold Zinc Galvanizing Spray is a Zinc rich, cold galvanizing coating that provides long term corrosion protection comparable to hot-dip galvanization and also due to sacrificial anodization.RUST-X Cold Zinc is a specialized coating designed to provide corrosion protection to metal surfaces, especially in cold or harsh environments.RUST-X Cold Zinc offers a cost-effective and efficient solution for protecting metal surfaces in cold environments or areas prone to corrosion. Proper surface preparation and application techniques are crucial to maximize the durability and effectiveness of the cold zinc coating.</p> : 
                                null}
                                {show === "vcp6"? <p className='text-muted firstparavalue'>RUST-X Degreaser spray makes equipment run smoother, last longer and look new. The aerosol has deep foaming penetrating action and a powerful stream that removes oil, dirt and grime without engine disassembly.RUST-X Degreaser is a specialized cleaning solution designed to remove grease, oil, dirt, grime, and other contaminants from metal surfaces. RUST-X Degreaser is an effective solution for maintaining clean and grease-free metal surfaces, essential for various industrial, automotive, and maintenance applications. Proper application methods and adherence to safety guidelines are important for achieving optimal cleaning results.</p> : 
                                null}
                                {show === "vcp7"? <p className='text-muted firstparavalue'>RUST-X Liquid Grease Spray , is premium white lithium grease. It is fortified with Polytetrafluoroethane and are designed to reduce friction between load bearing moving metal surfaces and also between metal and plastic surfaces.RUST-X Liquid Grease is a reliable solution for lubricating and protecting metal components in various industrial and automotive applications. Following the manufacturer's guidelines for application and maintenance is essential to ensure optimal performance and longevity of lubricated parts.</p> : 
                                null}
                                {show === "vcp8"? <p className='text-muted firstparavalue'>Under Body Coat Spray leaves a resilient, heavy-duty waxy film for long term corrosion protection of all types of components and equipment particularly those operating in outdoor environments including automotive applications.Underbody coating is a crucial step in vehicle maintenance, especially in regions prone to road salt usage during winter months. It not only preserves the vehicle's structural integrity but also enhances its overall longevity and resale value. Regular maintenance and reapplication, as needed, are essential to ensure ongoing protection
</p> : 
                                null}
                                {show === "vcp9"? <p className='text-muted firstparavalue'>Silicone Sprays provides a protective, waterproof and low friction coating to multiple surfaces. Our quick dry formula cures into a clear, stain resistant hard film that protects your surfaces from the elements. Safe to use on metal, rubber, vinyl and plastic without a messy residue.Silicone sprays come in aerosol cans with a nozzle for easy and precise application. They can be sprayed on various materials, including metal, plastic, rubber, and fabric.Use silicone sprays in well-ventilated areas. Avoid overspraying and ensure proper application to achieve the desired results without causing damage to surfaces or materials.</p> : 
                                null}
                                {show === "vcp10"? <p className='text-muted firstparavalue'>VCI Ammo-Guard is a specialized product designed to protect firearms, ammunition, and other metal equipment from rust, corrosion, and damage during storage and transit.Ammo-guard 22 is a Multi-Metal Volatile Corrosion Inhibiting product used mainly for enhancing shelf-life of arms and ammunition. The Ammo-guard 22 card releases vapors of corrosion inhibitor molecules that form an invisible & passive layer on metal that resists corrosion.  Arms and ammunition are made of multiple kinds of metals such as copper, brass, steel, aluminum, zinc and chrome platings etc. that can corrode overtime.</p> : 
                                null}
                                
                               
                               

                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp4" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp5" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp6" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp7" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp8" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp9" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp10" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                       
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>Conformal coatings provide a protective layer that prevents damage to electronic components from moisture, dust, chemicals, and other contaminants. This protection is crucial for ensuring the reliability and longevity of electronic devices, especially in harsh environments.</p>: null}
                                    {show === "vcp2"?<p>RUST-X 40 is specifically formulated as a corrosion inhibitor. It is designed to prevent the formation of rust on metal surfaces, even in challenging environments.</p>: null}
                                    {show === "vcp3"?<p>RUST-X Anti Spatter is designed to prevent weld spatter, which is molten metal expelled during welding, from sticking to surrounding metal surfaces. This helps in maintaining clean and smooth welds.</p>: null}
                                    {show === "vcp4"?<p>RUST-X Clear Coat provides a clear protective layer on metal surfaces, preventing them from exposure to moisture, chemicals, and environmental elements that can cause corrosion and rust</p>: null}
                                    {show === "vcp5"?<p>RUST-X Cold Zinc is a cold galvanizing compound, which means it offers similar protection to hot-dip galvanizing but can be applied at ambient temperatures. It provides sacrificial protection to metal surfaces, preventing rust and corrosion.</p>: null}
                                    {show === "vcp6"?<p>RUST-X Degreaser is formulated to effectively dissolve and remove grease, oil, lubricants, and other hydrocarbon-based contaminants from metal surfaces. It provides thorough cleaning to prepare surfaces for further treatments or coatings.</p>: null}
                                    {show === "vcp7"?<p> RUST-X Liquid Grease is formulated to lubricate moving parts and reduce friction between metal surfaces. It creates a protective film that prevents wear and tear, extending the lifespan of mechanical components.</p>: null}
                                    {show === "vcp8"?<p>Underbody coating acts as a barrier against rust and corrosion. It shields the vehicle's undercarriage, which is highly susceptible to damage from road salt, water, and other corrosive elements.</p>: null}
                                    {show === "vcp9"?<p> Silicone sprays are excellent lubricants, providing smooth and friction-free movement for various objects, including hinges, locks, slides, and gears. They are often used in automotive, household, and industrial applications.</p>: null}
                                    {show === "vcp10"?<p>VCI Ammo-Guard utilizes advanced VCI technology to release vapor-phase corrosion inhibitors. These inhibitors form a protective molecular layer on metal surfaces, preventing corrosion and rust formation.</p>: null}
                                   

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Conformal coatings are commonly used in industries such as aerospace, automotive, telecommunications, military, and consumer electronics. They are applied to circuit boards, microchips, sensors, and other electronic assemblies. </p>: null}
                                    {show === "vcp2"?<p>When applied to metal surfaces, RUST-X 40 creates a protective barrier that inhibits the corrosive reactions that lead to rust formation. It prevents the metal from coming into contact with moisture and other corrosive elements.</p>: null}
                                    {show === "vcp3"?<p> It is typically applied to surfaces near the welding area, such as workpieces, fixtures, and welding equipment, to create a protective barrier that prevents spatter adhesion.</p>: null}
                                    {show === "vcp4"?<p> If necessary, RUST-X Clear Coat can be removed from the metal surface for re-coating or other purposes, allowing for flexibility in maintenance and refinishing.</p>: null}
                                    {show === "vcp5"?<p>This coating contains a high concentration of zinc particles, which serve as the sacrificial anode. Zinc sacrificially corrodes in place of the underlying metal, providing excellent corrosion resistance.</p>: null}
                                    {show === "vcp6"?<p>It can be used on various metal substrates, including steel, aluminum, and other alloys. It is commonly used in industrial settings, automotive maintenance, machinery cleaning, and other applications where degreasing is required.</p>: null}
                                    {show === "vcp7"?<p>It can be used on various metal parts and equipment, including bearings, hinges, gears, slides, chains, and other moving components in machinery, vehicles, and industrial equipment.</p>: null}
                                    {show === "vcp8"?<p> It seals gaps and crevices in the undercarriage, preventing moisture and dirt from infiltrating. Additionally, underbody coating provides some degree of insulation, reducing road noise and enhancing overall comfort.</p>: null}
                                    {show === "vcp9"?<p>Silicone sprays create a water-resistant barrier on surfaces, making them ideal for protecting electronics, tools, and outdoor equipment from moisture-related damage. They are commonly used on ignition systems and electrical connections.</p>: null}
                                    {show === "vcp10"?<p>VCI Ammo-Guard is specifically formulated to safeguard firearms, ammunition, gun barrels, and metal components from rust and corrosion. It is ideal for long-term storage of firearms and military-grade equipment.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>There are several types of conformal coatings available, including acrylic, silicone, urethane, and epoxy coatings. Each type has specific properties, making them suitable for different applications and environmental conditions..</p>: null}
                                    {show === "vcp2"?<p> RUST-X 40 can be applied to various metal surfaces, including steel, iron, and other ferrous alloys. It is commonly used in industrial, manufacturing, and storage settings to protect metal components during transit, storage, and shipment.</p>: null}
                                    {show === "vcp3"?<p>By using RUST-X Anti Spatter, welders can easily remove spatter after the welding process without damaging the underlying metal surfaces. This simplifies the post-welding cleanup process.</p>: null}
                                    {show === "vcp4"?<p>Unlike traditional coatings, RUST-X Clear Coat dries to a transparent finish, allowing the natural appearance of the metal to show through. It does not alter the color or aesthetics of the metal surface.</p>: null}
                                    {show === "vcp5"?<p>RUST-X Cold Zinc creates a protective layer on the metal surface, acting as a barrier against moisture, salt, chemicals, and other corrosive elements. It is particularly effective in marine and industrial environments.</p>: null}
                                    {show === "vcp6"?<p> RUST-X Degreaser is powerful enough to remove stubborn stains and residues, making it ideal for cleaning heavily soiled metal surfaces and equipment.</p>: null}
                                    {show === "vcp7"?<p>RUST-X Liquid Grease has high lubricity, ensuring smooth movement of mechanical parts. It helps in reducing heat generated by friction, enhancing the efficiency and performance of machinery.</p>: null}
                                    {show === "vcp8"?<p>Underbody coatings offer protection against abrasion caused by gravel, debris, and rough terrain. This is especially important for off-road vehicles and those used in harsh environments.</p>: null}
                                    {show === "vcp9"?<p>By repelling moisture, silicone sprays help prevent corrosion on metal surfaces. They are applied to metal parts susceptible to rust, such as bicycle chains and fishing reels.</p>: null}
                                    {show === "vcp10"?<p> The VCI protection provided by Ammo-Guard is long-lasting, ensuring firearms and ammunition remain in optimal condition even during extended storage periods. It eliminates the need for frequent cleaning and oiling.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Conformal coatings can be applied using various methods, including spraying, dipping, brushing, and selective coating. The choice of method depends on the size and complexity of the electronic components being coated.</p>: null}
                                    {show === "vcp2"?<p>RUST-X 40 can be applied through methods such as spraying, brushing, or dipping. The application method can be chosen based on the specific requirements of the metal components being protected.</p>: null}
                                    {show === "vcp3"?<p>RUST-X Anti Spatter is often formulated to be non-flammable, ensuring safety during welding operations. It does not pose a fire hazard in high-temperature welding environments.</p>: null}
                                    {show === "vcp4"?<p>RUST-X Clear Coat is versatile and can be applied to various metal substrates, including steel, aluminum, and other alloys. It can be used in diverse applications, such as automotive, marine, industrial, and household settings.</p>: null}
                                    {show === "vcp5"?<p>It can be applied to various metal substrates, including steel, iron, and other ferrous alloys. It is commonly used on structural steel, bridges, pipelines, fences, and other metal structures exposed to harsh weather conditions.</p>: null}
                                    {show === "vcp6"?<p> Before applying coatings, paints, or protective treatments, it is essential to have a clean and grease-free surface. RUST-X Degreaser prepares metal surfaces by removing contaminants, ensuring better adhesion of subsequent coatings.</p>: null}
                                    {show === "vcp7"?<p> This grease is designed to resist water and moisture, making it suitable for applications where equipment is exposed to damp or humid environments. It protects metal surfaces from corrosion caused by moisture.</p>: null}
                                    {show === "vcp8"?<p>In addition to reducing road noise, underbody coating can enhance sound insulation within the vehicle, providing a quieter ride for occupants.</p>: null}
                                    {show === "vcp9"?<p>Silicone sprays provide a non-stick coating on surfaces, making them useful in various applications, including cooking utensils, molds, and woodworking tools. The non-stick properties make cleaning easier and reduce friction.</p>: null}
                                    {show === "vcp10"?<p> VCI Ammo-Guard is environmentally friendly and safe to use. It does not contain harmful chemicals and does not leave residue on firearms or ammunition.</p>: null}
                                   
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Conformal coatings have excellent dielectric properties, meaning they can insulate electronic components, preventing electrical short circuits and enhancing the performance and safety of electronic devices.</p>: null}
                                    {show === "vcp2"?<p>Once applied, RUST-X 40 provides long-lasting protection against rust. Its protective coating remains effective over extended periods, ensuring that metal items remain corrosion-free.</p>: null}
                                    {show === "vcp3"?<p>Many formulations of RUST-X Anti Spatter are eco-friendly and free from harmful chemicals. They are safe to use and do not release harmful pollutants into the environment.</p>: null}
                                    {show === "vcp4"?<p>It can be applied using methods such as spraying, brushing, or dipping, depending on the specific requirements of the metal surface and the application environment.</p>: null}
                                    {show === "vcp5"?<p>RUST-X Cold Zinc can be used as a primer coat for additional paint systems or as a standalone topcoat. It enhances the adhesion of subsequent paint layers and provides an extra layer of protection.</p>: null}
                                    {show === "vcp6"?<p>The degreaser is designed for efficient and rapid cleaning. It helps save time and effort in cleaning tasks, making it a valuable product in industrial and commercial cleaning operations.</p>: null}
                                    {show === "vcp7"?<p>RUST-X Liquid Grease maintains its lubricating properties across a wide range of temperatures. It remains effective even in high-temperature environments, ensuring consistent lubrication in various operating conditions.</p>: null}
                                    {show === "vcp8"?<p>Underbody coatings come in various formulations, including rubber-based, asphalt-based, and wax-based coatings. Each type offers specific advantages in terms of durability, flexibility, and protection.</p>: null}
                                    {show === "vcp9"?<p>Silicone sprays can rejuvenate and protect rubber and plastic parts, preventing them from drying out, cracking, or sticking together. They are commonly used on seals, gaskets, and automotive hoses.</p>: null}
                                    {show === "vcp10"?<p>VCI Ammo-Guard can be used for various firearms, including rifles, handguns, shotguns, and ammunition of different calibers. It is suitable for military, law enforcement, and civilian applications.</p>: null}
                                   
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> Some conformal coatings have thermal management properties, dissipating heat from electronic components. This is particularly important for devices that generate significant heat during operation.</p>: null}
                                    {show === "vcp2"?<p>RUST-X 40 has water-displacing properties, which means it helps displace moisture from the metal surfaces. This feature is crucial for preventing rust formation, especially in humid or damp environments.</p>: null}
                                    {show === "vcp3"?<p>RUST-X Anti Spatter products are available in various forms, including sprays, gels, and pastes. The choice of form depends on the specific application requirements and the preference of the user.</p>: null}
                                    {show === "vcp4"?<p>RUST-X Clear Coat forms a durable and long-lasting protective barrier on the metal. It provides resistance against scratches, abrasions, and environmental wear, ensuring prolonged protection.</p>: null}
                                    {show === "vcp5"?<p>RUST-X Cold Zinc is easy to apply using methods such as brushing, spraying, or rolling. It adheres well to metal surfaces, ensuring uniform coverage and protection.</p>: null}
                                    {show === "vcp6"?<p>RUST-X Degreaser is often formulated to be user-friendly, meaning it is easy to apply, rinse off, and does not leave residue behind. It facilitates hassle-free cleaning processes.</p>: null}
                                    {show === "vcp7"?<p>The grease contains additives that provide anti-wear protection, reducing the damage caused by friction and prolonging the life of mechanical parts.</p>: null}
                                    {show === "vcp8"?<p> Underbody coating is typically applied using specialized spray equipment. It adheres to the metal surface, forming a durable and protective layer.</p>: null}
                                    {show === "vcp9"?<p> Silicone sprays have high-temperature resistance, making them suitable for applications exposed to heat, such as oven doors, barbecue grills, and industrial equipment.</p>: null}
                                    {show === "vcp10"?<p>Besides corrosion protection, VCI Ammo-Guard helps prevent the formation of fingerprints and other marks on metal surfaces. This is especially important for firearms used in professional or tactical settings.</p>: null}
                                   
                                    
                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> UV-curable conformal coatings offer rapid curing times when exposed to ultraviolet (UV) light. This quick curing process increases efficiency in manufacturing and reduces production time.</p>: null}
                                    {show === "vcp2"?<p>When the metal components are ready to be used, RUST-X 40 can be easily removed, allowing for the clean and smooth operation of machinery and equipment without the need for additional cleaning processes.</p>: null}
                                    {show === "vcp3"?<p>RUST-X Anti Spatter is compatible with different welding processes, including MIG (Metal Inert Gas) welding, TIG (Tungsten Inert Gas) welding, and stick welding. It can be used in various welding applications across different industries.</p>: null}
                                    {show === "vcp4"?<p>Some formulations of RUST-X Clear Coat are designed to be UV-resistant. This means they can withstand exposure to ultraviolet (UV) rays from the sun without yellowing or degrading, making them suitable for outdoor applications.</p>: null}
                                    {show === "vcp5"?<p> It typically dries quickly, allowing for efficient application and minimizing downtime. Quick drying is particularly advantageous for applications where rapid turnaround is essential.</p>: null}
                                    {show === "vcp6"?<p> While powerful in removing contaminants, RUST-X Degreaser is formulated to be safe for most metal surfaces. It cleans without causing damage or corrosion to the underlying metal.</p>: null}
                                    {show === "vcp7"?<p> In addition to lubrication, RUST-X Liquid Grease forms a protective barrier that shields metal surfaces from corrosion. It helps prevent rust and corrosion, especially in harsh environmental conditions.</p>: null}
                                    {show === "vcp8"?<p>Certain areas of the vehicle's undercarriage, such as frame rails, suspension components, and exposed metal surfaces, are particularly vulnerable to corrosion. Underbody coating provides targeted protection to these areas.</p>: null}
                                    {show === "vcp9"?<p>Silicone sprays have excellent dielectric properties, making them ideal for insulating and protecting electrical components and connections from moisture and contaminants.</p>: null}
                                    {show === "vcp10"?<p>VCI Ammo-Guard is effective in combating the effects of humidity, making it suitable for storage in different climates and environments, including humid conditions that are conducive to rust formation.</p>: null}
                                   
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Conformal coatings are designed to be repairable. When necessary, they can be removed, allowing for repairs or modifications to be made to the electronic assemblies.</p>: null}
                                    {show === "vcp2"?<p>RUST-X 40 is often formulated to be environmentally friendly, free from harmful chemicals or substances that could harm the environment. This makes it a safe and responsible choice for corrosion prevention.</p>: null}
                                    {show === "vcp3"?<p>By preventing weld spatter from adhering to metal surfaces, RUST-X Anti Spatter helps reduce the time and effort required for post-welding cleanup. This makes it a cost-effective solution for welders and fabricators.</p>: null}
                                    {show === "vcp4"?<p> RUST-X Clear Coat is often formulated for quick drying, allowing for efficient application and reducing the downtime of the coated objects.</p>: null}
                                    {show === "vcp5"?<p> RUST-X Cold Zinc can be used for repair and touch-up purposes. It can be applied to damaged or corroded areas to restore the protective coating and prevent further corrosion.</p>: null}
                                    {show === "vcp6"?<p> Many formulations of RUST-X Degreaser are designed to be environmentally friendly, containing biodegradable ingredients and minimal or no harmful chemicals. They are safe for users and eco-friendly.</p>: null}
                                    {show === "vcp7"?<p>RUST-X Liquid Grease is easy to apply using methods such as brushing, spraying, or direct application from a container. It adheres well to metal surfaces, ensuring thorough coverage and effective lubrication.</p>: null}
                                    {show === "vcp8"?<p>Over time, underbody coatings can wear off due to friction and impact. Regular inspections are essential to identify areas where the coating has deteriorated and reapply it as necessary.</p>: null}
                                    {show === "vcp9"?<p> Silicone sprays can be used to protect surfaces, including painted metal, from water damage and environmental contaminants. They create a thin, protective layer that repels water and prevents staining.</p>: null}
                                    {show === "vcp10"?<p>VCI Ammo-Guard products are designed for user convenience. They are easy to insert, do not interfere with the operation of firearms, and provide hassle-free, continuous protection.</p>: null}
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default VciSpray