import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/Expert.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiPlus } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";


function Expert1() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();

  }, []);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 828,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 666,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 649,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      

    ]
  };
  return (
    <div>
      <div>
        <div className='value'>
          <div className='content'>
            <div className='clinicrow'>
            <div className='smallss'></div>
            <p>Team member</p>
            </div>
            <div className='chief'>
            <h1> Our expert team <span> is<br></br>{""} the best ever.</span></h1>
            </div>
          </div>
          <div className='sliding'>
            <Slider {...settings} className='slick'>
              <div>
                <img className="photocopy" src="/images/slick1.jpg" alt="our teams" />
              </div>
              <div>
                <img className="photocopy" src="/images/slick2.jpg" alt="our teams" />
              </div>
              <div>
                <img className="photocopy" src="/images/slick3.jpg" alt="our teams" />
              </div>
              <div>
                <img className="photocopy" src="/images/slick4.jpg" alt="our teams" />
              </div>
              <div>
                <img className="photocopy" src="/images/slick4.jpg" alt="our teams" />
                {/* <p>Suresh<br></br>
                  Executive Officer</p>
                <div className={change4 ? 'button1' : 'butting'} >
                  <BiPlus className='iconing' onClick={clickme4} />
                </div>
                <div className='icongrouping'>
                  {change4 ? (
                    <>
                         <GrFacebookOption className='blackwhite'/>
                      <AiOutlineTwitter className='blackwhite' />
                    </>
                  ) : null}
                </div> */}
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Expert1;