import React, { useEffect, useState } from 'react'
import CnBanner from '../Common/CnBanner'
import Career from '../Career/Career'
import SnHeader from '../Common/SnHeader'
import Welcome from '../Common/Welcome'
import Nav from '../Common/Nav'
import Navbar from '../Home/Navbar'
import Footer from '../Home/Footer'

const CareerPage = ({windowSize,color,setColor}) => {
    const [show, setShow] = useState(false)
    const showed = () => {
        if(window.scrollY >= 150){
          setShow(true)
        }else{
          setShow(false)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', showed)
  
        return () => window.removeEventListener('scroll',showed)
    })
    // const image = '/images/Ec-banner.jpg'
    const image = '/images/careerbanner.webp'
  return (
    <>
          {windowSize[0] > 1200 && show &&
            <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
            }
        {windowSize[0] > 1200 && 
          <Welcome />
        }
     
      {windowSize[0] > 1390 && 
       <SnHeader /> 
      }
     {windowSize[0] < 1390 && 
      <Nav />
     }    
    <CnBanner windowSize={windowSize} title="Career" image={image}/>

    <Career />

    <Footer color={color}/>
    </>
  )
}

export default CareerPage