import React, { useEffect, useState } from "react";
import "../css/Productdetails.css";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import CommonBtns from "./CommonBtns";
import LoadPage from "../Pages/LoadPage";

function IndLpg({ setLoad }) {
  const [openCategory, setOpenCategory] = useState(null);

  const { id, pid } = useParams();
  const [indlpg, setIndLpg] = useState("");

  useEffect(() => {
    if (id === "1") {
        setIndLpg("cc")
    }
    if (id === "2") {
        setIndLpg("bmcg")
    }
    if (id === "3") {
        setIndLpg("hippo")
    }
    if (id === "4") {
        setIndLpg("ftl")
    }
  }, [id]);



  const handleLoad = () => {
    setLoad(true);

    // setTimeout(() => {
    //     setShowLoader(false);
    //   }, 1000);
  };

  const [isLoading, setIsLoading] = useState(false);


  return (
    <>
      <div className="container">
        <div className={isLoading ? "opaque" : "opaque1"}>
          <div className="row overall">
            <div className="col-md-4">
              <CommonBtns id={id} pid={pid} setLoad={setLoad} />
            </div>
            <div className="col-md-8">
              <div className="firstlineflex">
                <div className="yellowbreak"></div>
                <div className="firstheading">
                  <h1>
                    {indlpg === "cc"
                      ? "Commercial Cylinder​"
                      : indlpg === "bmcg"
                      ? "BMCG"
                      : indlpg === "hippo"
                      ? "HIPPO"
                      : indlpg === "ftl"
                      ? "5KG FTL"
                      : ""}
                  </h1>
                </div>
              </div>
              <div className="row paraoutdoorlpg">
                <div className="col-md-4 col-sm-12">
                  <div className="lpgdetialimg">

                  {indlpg === "cc" ? (
                    <>
                      <img src="/images/lpg1.webp" alt="dummy" />
                      {/* <button className='prdtBtn'>Buy now</button> */}
                    </>
                  ) : indlpg === "bmcg" ? (
                    <img src="/images/lpg2.webp" alt="dummy" />
                  ) : indlpg === "hippo" ? (
                    <img className="lpgimg3" src="/images/lpg3.webp" alt="dummy" />
                  ) : indlpg === "ftl" ? (
                    <img src="/images/lpg4.webp" alt="dummy" />
                  ) 
                   : null}

                  </div>
                </div>
                <div className="col-md-8 col-sm-12">
                  {indlpg === "cc" ? (
                    <p className="text-muted firstparavalue">
                     Vapour Off-Take LPG installations excel in delivering a steady and reliable supply of gas, making them ideal for commercial and industrial settings with lower flow rate requirements. On the other hand, Liquid Off-Take LPG installations stand out as the safer and more efficient choice when dealing with high flow rates. Utilizing advanced vaporizer technology, these installations ensure heightened safety measures and cater seamlessly to the needs of industries requiring substantial volumes of LPG for their processes. Available in versatile package options of 19kg, 35kg, and 47.5kg, these solutions offer flexibility and precision to meet diverse operational demands, empowering businesses with tailored and efficient LPG installations
                    </p>
                  ) : null}
                  {indlpg === "bmcg" ? (
                    <p className="text-muted firstparavalue">
                     The BMCG represents a groundbreaking advancement in hydrocarbon industrial gas, integrating an innovative additive that elevates flame temperature and delivers impeccable precision at a remarkably low cost compared to conventional options. This cutting-edge gas finds extensive applications in cutting, heating, brazing, galvanizing, soldering, and coating processes. Notably, Bharat Metal Cutting Gas prioritizes safety and user-friendliness, being non-toxic and devoid of backfire tendencies, ensuring a secure operational environment. Available in convenient 19kg and 35kg packages, it’s poised to redefine metalworking efficiency and economy
                    </p>
                  ) : null}
                  {indlpg === "hippo" ? (
                    <p className="text-muted firstparavalue">
                      Streamline your heavy manufacturing operations efficiently with the 422Kg HIPPO installation. Perfect for spaces facing constraints, these cylinders offer a compact yet high-efficiency solution. Experience the convenience of a space-saving design without compromising on performance. With these conventional cylinders, there's no worry about sweating, and they provide accurate content gauging while minimizing LPG residue and the need for multiple joints during installation. Embrace the efficiency of a seamlessly integrated solution designed specifically for heavy manufacturing needs
                    </p>
                  ) : null}
                  {indlpg === "ftl" ? (
                    <p className="text-muted firstparavalue">
                     Experience unmatched flexibility with the Free Trade LPG - 5 Kg FTL. This innovative scheme offers the freedom to procure Bharatgas Mini cylinders at your convenience in select cities. Upon the first sale, the cost includes equipment charges (DPR plus cylinder), prevailing Non-domestic 5 Kg cylinder price, and administrative fees. For subsequent refills, only the cost of the LPG product is payable. Embrace the liberty to purchase cylinders as needed, providing a hassle-free and flexible approach to fulfilling your LPG requirements.
                    </p>
                  ) : null}
                  
                </div>
              </div>


              <div className="downsession">
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Ideal for commercial and industrial setups needing controlled, lower flow rates of LPG, ensuring efficiency in various applications.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       BMCG introduces an innovative additive, elevating flame temperature. This enhancement ensures superior cutting precision and delivers a fine cut finish.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                      Tailored for heavy manufacturing with limited space, the 422Kg HIPPO gas installation offers a compact design, maximizing operational efficiency within confined areas.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                     Enjoy the freedom to purchase Bharatgas Mini cylinders (5 kg) at your convenience, catering to your specific needs in select cities.
                      </p>
                    ) : null}

                   
                  </span>
                </div>
                {/* 2 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Offers enhanced safety and effectiveness for applications demanding higher LPG flow rates. Utilizes a vaporizer for efficient operations.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                        Offers a cost-effective solution compared to traditional DA BMCG, reducing operational expenses while maintaining high-quality results in cutting, heating, brazing, galvanizing, soldering, and coating applications.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                         Despite its compact size, these conventional cylinders maintain high efficiency levels, ensuring consistent and reliable performance for heavy manufacturing processes.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      At the first sale, cover the expenses for equipment (DPR plus cylinder), prevailing Non-domestic 5 Kg cylinder price, and administrative charges.
                      </p>
                    ) : null}

                  </span>
                </div>
                {/* 3 point */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Liquid Off-Take installations prioritize safety, providing a reliable solution for high-flow LPG requirements, surpassing conventional VOT cylinders.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       Widely applicable across multiple industrial processes, including cutting, heating, brazing, galvanizing, soldering, and coating, providing a versatile solution for various metalworking needs.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                     HIPPO gas cylinders prevent the issue of cylinder sweating, maintaining a controlled and dry environment within manufacturing facilities.


                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                   For subsequent refills, pay only for the cost of the product, simplifying the purchase process and reducing additional charges.
                      </p>
                    ) : null}

                  
                  </span>
                </div>
                {/*  4 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                        Suited for a wide range of industries – from manufacturing to hospitality – catering to different flow rate needs with varying package sizes.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                       Bharat Metal Cutting Gas prioritizes safety and operator comfort. The NXT GEN variant ensures complete operator-friendly usage, minimizing risks associated with backfires and ensuring a secure working environment.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                        Provides accurate content gauging mechanisms, allowing for precise monitoring and optimized utilization of gas, minimizing waste and enhancing cost-effectiveness.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                       Operates under the Free Trade LPG scheme, offering users the liberty to buy cylinders without strict subscription or commitment requirements.
                      </p>
                    ) : null}

                  </span>
                </div>
                {/* 5 points */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Available in diverse package sizes: 19Kg, 35Kg, and 47.5Kg, ensuring flexibility to meet specific demands of different commercial and industrial processes.
                      </p>
                    ) : null}
                    {indlpg === "bmcg" ? (
                      <p>
                        Ensures a safe working environment as it is non-toxic, complying with safety standards and regulations. This feature contributes to a healthier workplace for operators and employees.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                        Ensures minimal residue after usage, optimizing the gas consumption and reducing the need for frequent cylinder replacements or refills.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                        Available in select cities, the 5 Kg FTL cylinder scheme ensures accessibility and ease for households and small businesses.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/*  6 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Engineered with robust materials and cutting-edge technology, ensuring durability and consistent performance in demanding environments.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                        Available in convenient package sizes of 19Kg and 35Kg, facilitating easy handling and ensuring a consistent and uninterrupted supply for industrial operations.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                     Streamlines installation processes by minimizing joints, ensuring a safer and more straightforward setup, reducing the potential for leaks and enhancing safety measures.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p> 
                        Designed as Bharatgas Mini cylinders, the 5 Kg FTL offers a compact and manageable size, ideal for smaller households or businesses with limited space.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/* 7 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Designed to optimize LPG utilization, ensuring efficient operations in commercial and industrial applications, enhancing productivity.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Maintains a consistent flame quality and operational efficiency throughout usage, ensuring reliability and high performance in various metalworking applications.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                      Allows heavy manufacturing units to make the most of their available space by utilizing these compact and efficient gas installations without compromising on productivity.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Over time, investing in a well-designed medical/laboratory pipeline system can be cost-effective compared to the recurring expenses of purchasing and replacing gas cylinders, especially in facilities with high gas consumption.
                      </p>
                    ) : null}

                
                  </span>
                </div>
                {/* 8 points  */}
                <div className="downparasession">
                  <img src="/download.svg" className="image-fluid iconroller" />
                  <span className="clinicpara">
                    {indlpg === "cc" ? (
                      <p>
                       Trusted and currently used across multiple commercial and industrial applications, endorsed by users for its performance and safety.
                      </p>
                    ) : null}

                    {indlpg === "bmcg" ? (
                      <p>
                      Endorsed by industry experts and widely adopted across metalworking sectors, proving its efficacy and reliability in demanding industrial environments.
                      </p>
                    ) : null}

                    {indlpg === "hippo" ? (
                      <p>
                       Endorsed by various heavy manufacturing sectors, HIPPO gas installations are trusted for their reliability, efficiency, and ability to meet the unique demands of space-constrained manufacturing environments.
                      </p>
                    ) : null}

                    {indlpg === "ftl" ? (
                      <p>
                      Provides consumers with greater control over their LPG usage, allowing them to purchase cylinders as needed, offering flexibility in managing their energy needs.
                      </p>
                    ) : null}

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndLpg;
