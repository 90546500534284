import React, { useState } from 'react';
import '../css/OurMission.css';
import { MdOutlineEmojiFlags } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdOutlineHandyman } from "react-icons/md";
import ProgressBar from "@ramonak/react-progress-bar";
import { BsFillPlayFill } from "react-icons/bs";
import ScrollTrigger from "react-scroll-trigger";


function OurMission() {
    const [first,setFirst]=useState(true);
    const [second,setSecond]=useState(false);
    const [third,setThird]=useState(false);
    const [bar, setBar] = useState(true);    


    const Clickingme1=()=>{
      setFirst(true);
      setSecond(false);
      setThird(false);
    }
    const Clickingme2=()=>{
        setFirst(false);
        setSecond(true);
        setThird(false);
      }
      const Clickingme3=()=>{
        setFirst(false);
        setSecond(false);
        setThird(true);
      }
    return (
      <>
      <ScrollTrigger onEnter={() => setBar(true)} onExit={() => setBar(false)}>

        <div className='container'>
            <div className='row overallourmission'>
                <div className='overcolmission col-md-7 col-lg-7'>
                    <div className='row'>
                        <div className='innerleftbox col-md-6 col-lg-6'>
                        <div className='blackboxes'>
                        <div className='firstbend'onClick={Clickingme1}>
                        <div className='iconblocking'>
                            <MdOutlineEmojiFlags />
                        </div>
                        <p className='letters'> Our Mission</p>
                    </div>
                    <div className='firstbend' onClick={Clickingme2}>
                    <div className='iconblocking'>
                            <FaEye />
                        </div>
                        <p className='letters'>Our Vision</p>

                    </div>
                    <div className='firstbend' onClick={Clickingme3}>
                    <div className='iconblocking'>
                            <MdOutlineHandyman />
                        </div>
                        <p className='letters'>Quality</p>

                    </div>
                    </div>
                    
                        </div>
                        {/* rightside column */}
                        <div className='overallrightside col-md-6 col-lg-6'>
                        {first?<p className='hiddenparagraph'> To provide the highest quality
                         of products and services that combine performance with value 
                        pricing while establishing a long-standing successful relationship with our customers.
                        </p> : null}
                       {second?<p className='hiddenparagraph'> To provide work environment 
                       where our employees can meet their fullest potential to serve our customers
                        in setting the Industry standards by offering the best 
                       service and maintaining a superior level of integrity in interactions with our customers.
                        </p>: null}
                       {third ? <p className='hiddenparagraph'> We are committed to supply of quality 
                       products that meets customer expectations
                        and improve our business by the way of Continual 
                        Improvement in Quality Management Systems.
                        </p> : null}

                        </div>
                    </div>
                    <div className='row valueedit'>
                    <div className='col-md-6'>
                    <div className='bottomquote'>
                    <h1>Essential business <span className='om-span'>skills.</span></h1>
                    </div>
                    </div>
                    <div className='col-md-6 probarside'>
                    <p className='edgeclassvalue'>Inventory Support</p>
                    {bar && (
                      <ProgressBar
                        className="pbar"
                        completed={100}
                        bgColor="#000000"
                        labelColor="#37a7fd"
                        transitionDuration="2s"
                        animateOnRender
                        maxCompleted={98}
                        customLabel="100%"
                        style={{ height: '10px' }}
                      />
                    )}
                     <p className='edgeclassvalue'>Onsite Training</p>
                    {bar && (
                      <ProgressBar
                        className="pbar"
                        completed={100}
                        bgColor="#000000"
                        labelColor="#37a7fd"
                        transitionDuration="2s"
                        animateOnRender
                        maxCompleted={98}
                        customLabel="100%"
                        style={{ height: '10px' }}
                      />
                    )}
                     <p className='edgeclassvalue'>Application Support </p>
                      {bar && (
                      <ProgressBar
                        className="pbar"
                        completed={100}
                        bgColor="#000000"
                        labelColor="#37a7fd"
                        transitionDuration="2s"
                        animateOnRender
                        maxCompleted={98}
                        customLabel="98%"
                        style={{ height: '10px' }}
                      />
                      )}
                    </div>
                   
                    </div>
                   
                </div>
                
                {/* empty right side */}
                <div className='col-md-5 col-lg-5 rightvalueside'>
                    <div className='chinaicon'>
                        <BsFillPlayFill className='iconnumber'/>
                    </div>
                </div>
            </div>
        </div>
      </ScrollTrigger>
      </>
    )
}

export default OurMission;