import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Vci = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "Rust-X"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "Rust-X"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "Rust-X"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "Rust-X"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "Rust-X"&&cid==='8'){
            setShow("vcp8")
          }
        if(id === "Rust-X"&&cid==='9'){
            setShow("vcp9")
          }
        if(id === "Rust-X"&&cid==='10'){
            setShow("vcp10")
          }
        if(id === "Rust-X"&&cid==='11'){
            setShow("vcp11")
          }
        if(id === "Rust-X"&&cid==='12'){
            setShow("vcp12")
          }
        if(id === "Rust-X"&&cid==='13'){
            setShow("vcp13")
          }
        if(id === "Rust-X"&&cid==='14'){
            setShow("vcp14")
          }
        if(id === "Rust-X"&&cid==='15'){
            setShow("vcp15")
          }
        if(id === "Rust-X"&&cid==='16'){
            setShow("vcp16")
          }
        if(id === "Rust-X"&&cid==='17'){
            setShow("vcp17")
          }
        if(id === "Rust-X"&&cid==='18'){
            setShow("vcp18")
          }
        if(id === "Rust-X"&&cid==='19'){
            setShow("vcp19")
          }
        if(id === "Rust-X"&&cid==='20'){
            setShow("vcp20")
          }
    
       
        
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "VCI 2D Bags"  : show === "vcp2" ? "VCI 3D Carton Liners"  : 
                               show === "vcp3" ? "VCI 3D Pallet Liners"  : show === "vcp4" ? "VCI Paper"  :
                               show === "vcp5" ? "VCI Shrink Tape"  : show === "vcp6" ? "VCI Ventilation Grill"  :
                               show === "vcp7" ? "VCI Zip Lock Bags"  : show === "vcp8" ? "VCI Sheets and Rolls"  :
                               show === "vcp9" ? "VCI Lamina Shrink Films"  : show === "vcp10" ? "VCI Strength Fabric"  :
                               show === "vcp11" ? "VCI Barrier Foils"  : show === "vcp12" ? "VCI Bubble Wrap"  :
                               show === "vcp13" ? "VCI Holo and MLP FILMS"  : show === "vcp14" ? "VCI Power Stretch Films"  :
                               show === "vcp15" ? "Surface Protection Films"  : show === "vcp16" ? "VCI Masking Stickers"  :
                               show === "vcp17" ? "VCI Shrink Films"  : show === "vcp18" ? "VCI Metal Barrier Foils"  :
                               show === "vcp19" ? "VCI Shower Cap"  : show === "vcp20" ? "VCI Tuffpaulin"  
                               :""}

                               {
                                show === "ba1" ? "Bio Aer" :""
                               }

                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/rustx1.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/rust2.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/rustx3.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/rustx4.webp' alt='dummy'/> 
                                </>:
                               show === "vcp5" ?
                                <>
                                    <img src='/images/rustx5.webp' alt='dummy'/> 
                                </>:
                               show === "vcp6" ?
                                <>
                                    <img src='/images/rustx6.webp' alt='dummy'/> 
                                </>:
                               show === "vcp7" ?
                                <>
                                    <img src='/images/rustx7.webp' alt='dummy'/> 
                                </>:
                               show === "vcp8" ?
                                <>
                                    <img src='/images/rustx8.webp' alt='dummy'/> 
                                </>:
                               show === "vcp9" ?
                                <>
                                    <img src='/images/rustx9.webp' alt='dummy'/> 
                                </>:
                               show === "vcp10" ?
                                <>
                                    <img src='/images/rustx10.webp' alt='dummy'/> 
                                </>:
                               show === "vcp11" ?
                                <>
                                    <img src='/images/rustx11.webp' alt='dummy'/> 
                                </>:
                               show === "vcp12" ?
                                <>
                                    <img src='/images/rustx12.webp' alt='dummy'/> 
                                </>:
                               show === "vcp13" ?
                                <>
                                    <img src='/images/rustx13.webp' alt='dummy'/> 
                                </>:
                               show === "vcp14" ?
                                <>
                                    <img src='/images/rustx14.webp' alt='dummy'/> 
                                </>:
                               show === "vcp15" ?
                                <>
                                    <img src='/images/rustx15.webp' alt='dummy'/> 
                                </>:
                               show === "vcp16" ?
                                <>
                                    <img src='/images/rustx16.webp' alt='dummy'/> 
                                </>:
                               show === "vcp17" ?
                                <>
                                    <img src='/images/rustx17.webp' alt='dummy'/> 
                                </>:
                               show === "vcp18" ?
                                <>
                                    <img src='/images/rustx18.webp' alt='dummy'/> 
                                </>:
                               show === "vcp19" ?
                                <>
                                    <img src='/images/rustx19.webp' alt='dummy'/> 
                                </>:
                               show === "vcp20" ?
                                <>
                                    <img src='/images/rustx20.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>VCI 2D bags are used for the packaging of small components which are further put into pallets or boxes. 2D bags are loaded with SMP technology VCI chemicals to prevent corrosion and abrasion whilst giving complete transparency and view ability of the product.
                                Volatile Corrosion Inhibitor 2D bags, are specialized packaging solutions designed to protect metal components and parts from corrosion during storage and transportation. These bags utilize Volatile Corrosion Inhibitors (VCIs) to create a protective atmosphere around metal items, preventing corrosion and rust formation.</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>VCI 3D carton liners, also known as Volatile Corrosion Inhibitor 3D carton liners, are specialized packaging solutions designed to protect metal parts and components from corrosion during storage and transportation. These carton liners utilize Volatile Corrosion Inhibitors (VCIs) to create a protective atmosphere around metal items, preventing corrosion and rust formation. 
                                Corrugations contain high level of humidity. VCI 3D Carton Liners are used to protect steel components from effects of moisture. These are available as per the size of the carton in Gusseted (703G), 5 sided extended height (703 XH) or 6 sided bag with a flap (703FL)</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>VCI 3D pallet liners, or Volatile Corrosion Inhibitor 3D pallet liners, are specialized packaging solutions designed to protect metal products stored on pallets from corrosion during storage and transportation. These liners utilize Volatile Corrosion Inhibitors (VCIs) to create a protective atmosphere around metal items, preventing corrosion and rust formation
                                It is important to seal the packaging to attain an inert atmosphere with the Pallet box and restrict the influx of moist air. 3D Pallet Liners are used to form an air-tight atmosphere and prevent corrosion due to saline sea humidity entering the pallets. These are available as per the size of the carton in a Gusseted (704 G), 5 sided extended height (704 XH) or 6 sided bag with a flap (704 FL).</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>Volatile Corrosion Inhibitor paper, is a specialized packaging material designed to protect metal components and parts from corrosion during storage and transportation. It is impregnated with Volatile Corrosion Inhibitors (VCIs), which vaporize and form a protective layer on metal surfaces, preventing rust and corrosion.
                                RUSTX manufactures Super Laminated VCI Papers which have exceptional strength, tear resistance as well as puncture resistance. VCI Paper, or Vapor Corrosion Inhibitor paper, is a kraft packaging paper manufactured with added VCI chemistry. VCI molecules protect metals from corrosion. Volatile Chemicals coated on Natural Kraft Paper can provide protection of up to 10 years in the packaged condition when is synergy with Rust-X oils.</p> : 
                                null}
                                {show === "vcp5"? <p className='text-muted firstparavalue'>Volatile Corrosion Inhibitor shrink tape, is a specialized packaging material designed to protect metal products from corrosion during storage and transportation. This tape combines the protective properties of Volatile Corrosion Inhibitors (VCIs) with the shrink film technology, offering comprehensive protection for metal surfaces. 
                                Rust-X manufactures a high-quality  Shrink tape for a wide range of applications, including electrical insulation, sealing, and bundling. Made from durable materials, including polyolefin, PVC, and fluoropolymer, our shrink tape is resistant to abrasion, moisture, and chemicals, making it ideal for use in harsh environments. When applied to an object and heated, our shrink tape shrinks tightly around it, creating a secure and protective seal. </p> : 
                                null}
                                {show === "vcp6"? <p className='text-muted firstparavalue'>Volatile Corrosion Inhibitor ventilation grills, are specialized components designed to integrate Volatile Corrosion Inhibitor (VCI) technology into ventilation systems. These grills are engineered to release VCI molecules into the air, creating a protective atmosphere that prevents corrosion on metal surfaces within an enclosed space.
                                Rust-X shrink packaging ventilation grill is a high-quality product designed to improve airflow and prevent overheating in shrink-wrapped packages. Made from durable materials, including high-density polyethylene (HDPE), our ventilation grill is designed to withstand the rigors of packaging and shipping.</p> : 
                                null}
                                {show === "vcp7"? <p className='text-muted firstparavalue'>Volatile Corrosion Inhibitor zip lock bags, are specialized packaging solutions designed to protect metal components and parts from corrosion during storage and transportation. These bags are equipped with Volatile Corrosion Inhibitors (VCIs) that release protective molecules, forming a rust-preventative layer around metal surfaces. VCI Zip Lock Bags are used to pack components which need removal and resealing. These are also used where multiple products are packed in a bag and then need to be used one by one over a longer time period.</p> : 
                                null}
                                {show === "vcp8"? <p className='text-muted firstparavalue'>Volatile Corrosion Inhibitor sheets and rolls, are specialized packaging materials designed to protect metal components and parts from corrosion during storage and transportation. These sheets and rolls are impregnated with Volatile Corrosion Inhibitors (VCIs), which release protective molecules, creating a rust-preventative layer around metal surfaces.VCI sheets and rolls are available in various sizes and can be used for wrapping large or small components or equipments and for covering corrugation/ honeycomb layers. </p> : 
                                null}
                                {show === "vcp9"? <p className='text-muted firstparavalue'> Volatile Corrosion Inhibitor lamina shrink films, are specialized packaging materials designed to protect metal products from corrosion during storage and transportation. These films combine the protective properties of Volatile Corrosion Inhibitors (VCIs) with shrink film technology, creating a robust barrier against rust and corrosion. 
                                RustX VCI Lamina Films are manufactured using state of the art SMP Technology for speedy moisture passivation and provides long term corrosion protection to the packaged equipment. It is made by adding a special layer VCI in the co extrusion structure of the film manufactured.</p> : 
                                null}
                                {show === "vcp10"? <p className='text-muted firstparavalue'>VCI strength fabric, or Volatile Corrosion Inhibitor strength fabric, is a specialized material engineered to provide both mechanical strength and corrosion protection to metal components. 
                                RustX Strength Fabric is a woven fabric made on circular knitting machines from specialized high strength HDPE resin with dosages of UV protection and optical brighteners made from Virgin polymers The fabric is woven on automatic lines to ensure adequate strength is achieved by a Warp and Weft structure of the material. Strength fabric by its name is symbolic to not just the advantage of High Strength but also excellent moisture penetrations properties and oxygen transmissions</p> : 
                                null}
                                {show === "vcp11"? <p className='text-muted firstparavalue'>VCI barrier foils, or Volatile Corrosion Inhibitor barrier foils, are advanced packaging materials designed to protect metal components and products from corrosion during storage and transit. These foils provide a high level of protection by incorporating Volatile Corrosion Inhibitors (VCIs) into multilayer barrier films.
                                Aluminium barrier foils are a perfect combinations to reduce WVTR and OTR penetration inside the packaging and provide enhanced shelf life to the products. They can be vacuumed , heat sealed and stored for longer periods without the risk of losing the original condition of the product was initially packaged. 3 dimensional covers for machines, Pallet liners as well as can be supplied in 2 Dimensional bags or sheet for packaging of parts. </p> : 
                                null}
                                {show === "vcp12"? <p className='text-muted firstparavalue'>VCI bubble wrap, or Volatile Corrosion Inhibitor bubble wrap, is a specialized packaging material designed to protect metal components and products from corrosion during storage and transportation. It combines the cushioning properties of bubble wrap with the corrosion protection of Volatile Corrosion Inhibitors (VCIs). 
                                VCI bubble wraps are used where impact protection such as dents, scratches are required on the parts during shipment and storage. These can be provided in the form or sheet or in custom sized bags.</p> : 
                                null}
                                {show === "vcp13"? <p className='text-muted firstparavalue'>VCI Holo Films and MLP Films are specialized packaging materials designed to provide advanced corrosion protection for metal components during storage and transportation. VCI Holographic films have are used in aftermarket spares. A great way to protect parts from counterfeit and brand your product accompanied with VCI.
                                This combination of holographic features and VCI technology offers a practical solution for safeguarding aftermarket spares from counterfeiting while ensuring the longevity and quality of the parts through corrosion protection.</p> : 
                                null}
                                {show === "vcp14"? <p className='text-muted firstparavalue'>VCI Power Stretch Films are specialized packaging materials designed to protect metal components and products from corrosion during storage and transportation. They combine the stretching properties of power stretch films with the corrosion protection of Volatile Corrosion Inhibitors (VCIs)
                                VCI Stretch films are suitable for ferrous and non-ferrous metals and inhibit the onset of corrosion, tarnishing etc. for up to 5 years when used with a complete system designed by Rust-X. Rust-X VCI Stretch Films are made from special proprietary additives that increase the strength between the molecular chain thereby providing a film with very high tear strength, tensile strength and dart impact.</p> : 
                                null}
                                {show === "vcp15"? <p className='text-muted firstparavalue'>Surface protection films, also known as protective films or masking films, are temporary protective coatings applied to various surfaces to safeguard them from scratches, abrasions, dirt, and other types of damage during manufacturing, transportation, storage, or installation processes. 
                                We can also design and manufacture protective film for any surface if the customer provides sample of that substrate. PROTAPE is used to protect surfaces which are highly prone to corrosion & need protection without the application of physical coatings, paints or oils but require long term corrosion protection in covered or open conditions.</p> : 
                                null}
                                {show === "vcp16"? <p className='text-muted firstparavalue'>VCI masking stickers are specialized adhesive labels infused with Volatile Corrosion Inhibitors (VCIs) used to protect metal components during various industrial processes. he product has a polymer white layer which is made from a co-extrusion process that has inside black film and further is co-joined with a black release base. The adhesives used are stain free release that leave no residue on the surface of metal on which it is pasted. The film is impregnated with VCI additives during manufacturing process to enable corrosion prevention throughout the application life of product. The product has great UV protection for upto 2-5 years and don’t disintegrate so easily.</p> : 
                                null}
                                {show === "vcp17"? <p className='text-muted firstparavalue'>VCI shrink films, or Volatile Corrosion Inhibitor shrink films, are specialized packaging materials designed to protect metal components and products from corrosion during storage and transportation. These films combine the shrink properties of traditional shrink films with the corrosion protection of Volatile Corrosion Inhibitors (VCIs).
                                Shrink films are made from high quality resins to cover almost anything. There is wide range of widths, lengths and mil thicknesses and colors. All our shrink wraps contains maximum UV inhibitors to last as long as 10-15 years in open. Shrink films can be heat shrunk using a hot air electric gun, gas gun or flame gun which are also available with us</p> : 
                                null}
                                {show === "vcp18"? <p className='text-muted firstparavalue'>VCI metal barrier foils, or Volatile Corrosion Inhibitor metal barrier foils, are advanced packaging materials designed to protect metal components and products from corrosion during storage and transportation. These foils combine the barrier properties of metal foils with the corrosion protection of Volatile Corrosion Inhibitors (VCIs). Metal barrier protection has a n aluminum coated polyester with VCI LDPE combination can be used in applications where high level of protection is required where VCI can protect parts in highly demanding areas. </p> : 
                                null}
                                {show === "vcp19"? <p className='text-muted firstparavalue'>VCI shower caps are specialized protective covers infused with Volatile Corrosion Inhibitors (VCIs) designed to safeguard metal components and equipment from corrosion during various processes. VCI Shower Caps has its unique elastic bottom. The elastic band provides a secure fit over totes or odd-shaped or protruding parts such as hub assemblies. Tote Covers prevent corrosion by diffusing Vapor Corrosion Inhibitor (VCI) chemistry. The covers also act as a barrier of protection against dirt, dust, smoke and other contaminants. The covers are typically made of High-Density Rust-x VCI Film, but the Tote Covers can be made from almost any Rust-X film product as required by the application.</p> : 
                                null}
                                {show === "vcp20"? <p className='text-muted firstparavalue'>VCI Tuffpaulin is a specialized protective covering made from durable materials and infused with Volatile Corrosion Inhibitors (VCIs). It is designed to protect metal components, equipment, and machinery from corrosion during storage, transportation, and outdoor exposure.Tuffpaulin is a special type of tarpaulin. A tarpaulin is a material which has been designed to be treated to be waterproof or water resistant. Tarpaulins are used in a large range of applications for everything from providing shelter from inclement weather to protecting flooring from paint drips.</p> : 
                                null}
                               
                               

                            </div>
                        </div>

                        {/* < div className='paraoutdoor1 '>
                            { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp4" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp5" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp6" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp7" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp8" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp9" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp10" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp11" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp12" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp13" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp14" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp15" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp16" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp17" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp18" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp19" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            { show === "vcp20" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                    It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                            </p> : null}
                            
                            
                            
                            </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>VCI 2D bags provide effective corrosion protection for metal components. They release volatile corrosion inhibitors that form a protective layer on metal surfaces, inhibiting the corrosion process.</p>: null}
                                    {show === "vcp2"?<p>VCI 3D carton liners provide effective corrosion protection for metal parts and components. They release volatile corrosion inhibitors that form a protective layer on metal surfaces, inhibiting the corrosion process.</p>: null}
                                    {show === "vcp3"?<p>VCI 3D pallet liners provide effective corrosion protection for metal products placed on pallets. They release volatile corrosion inhibitors that form a protective layer on metal surfaces, inhibiting the corrosion process.</p>: null}
                                    {show === "vcp4"?<p>VCI paper provides effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. It prevents corrosion by creating a molecular barrier on the metal surface.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tape provides effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. It prevents corrosion by creating a molecular barrier on the metal surface, inhibiting rust and corrosion.</p>: null}
                                    {show === "vcp6"?<p>VCI ventilation grills release volatile corrosion inhibitors into the surrounding air, forming a protective layer on metal surfaces. This prevents corrosion and rusting on metal items within the ventilated area.</p>: null}
                                    {show === "vcp7"?<p> VCI zip lock bags provide effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. They prevent corrosion by creating a molecular barrier on the metal surface, inhibiting rust and corrosion.</p>: null}
                                    {show === "vcp8"?<p>VCI sheets and rolls offer effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. They prevent corrosion by creating a molecular barrier on the metal surface, inhibiting rust and corrosion.</p>: null}
                                    {show === "vcp9"?<p>VCI lamina shrink films provide effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. They prevent corrosion by releasing Volatile Corrosion Inhibitors that form a protective layer on metal surfaces, inhibiting rust and corrosion.</p>: null}
                                    {show === "vcp10"?<p>VCI strength fabric combines the mechanical strength of industrial fabric with the corrosion protection of Volatile Corrosion Inhibitors (VCIs). It offers robust protection against both physical damage and corrosion.</p>: null}
                                    {show === "vcp11"?<p>VCI barrier foils protect metal items, including steel, iron, brass, copper, and aluminum, from corrosion. The incorporated VCIs form a protective layer on metal surfaces, preventing rust and corrosion even in harsh environmental conditions.</p>: null}
                                    {show === "vcp12"?<p>VCI bubble wrap provides effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. It prevents corrosion by releasing Volatile Corrosion Inhibitors, forming a protective layer on metal surfaces.</p>: null}
                                    {show === "vcp13"?<p>VCI Holo Films and MLP Films are embedded with Volatile Corrosion Inhibitors (VCIs) that vaporize and form a protective layer on metal surfaces. This layer inhibits corrosion, preventing rust and other forms of metal degradation.</p>: null}
                                    {show === "vcp14"?<p>VCI Power Stretch Films provide effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. They prevent corrosion by releasing Volatile Corrosion Inhibitors, forming a protective layer on metal surfaces.</p>: null}
                                    {show === "vcp15"?<p> Surface protection films act as a protective barrier, shielding surfaces such as glass, plastic, metal, painted finishes, and electronics from scratches, dings, and other potential damage.</p>: null}
                                    {show === "vcp16"?<p>VCI masking stickers release Volatile Corrosion Inhibitors that form a protective layer on metal surfaces. They prevent corrosion by inhibiting the oxidation process, ensuring that the metal components remain rust-free.</p>: null}
                                    {show === "vcp17"?<p>VCI shrink films provide effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. They prevent corrosion by releasing Volatile Corrosion Inhibitors, forming a protective layer on metal surfaces.</p>: null}
                                    {show === "vcp18"?<p>VCI metal barrier foils provide effective corrosion protection for various metal items, including steel, iron, brass, copper, and aluminum. They prevent corrosion by releasing Volatile Corrosion Inhibitors, forming a protective layer on metal surfaces.</p>: null}
                                    {show === "vcp19"?<p>VCI shower caps release Volatile Corrosion Inhibitors, forming a protective layer on metal surfaces. This inhibits the corrosion process, ensuring the metal components remain rust-free during storage or transit.</p>: null}
                                    {show === "vcp20"?<p>VCI Tuffpaulin releases Volatile Corrosion Inhibitors, creating a protective layer on metal surfaces. This inhibits the corrosion process, ensuring that the covered metal items remain rust-free.</p>: null}

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> VCI 2D bags are flat and typically come in various sizes to accommodate different metal items. They are designed to lay flat, making them ideal for packaging flat or smaller metal parts.</p>: null}
                                    {show === "vcp2"?<p>Unlike flat bags, VCI 3D carton liners are designed to fit into cartons or crates, offering protection to metal items regardless of their shape or size. The liners conform to the three-dimensional space within the container.</p>: null}
                                    {show === "vcp3"?<p>Unlike flat sheets, VCI 3D pallet liners are designed to fit the dimensions of pallets, offering protection to metal items regardless of their shape or size. The liners conform to the three-dimensional space within the pallet.</p>: null}
                                    {show === "vcp4"?<p>VCI paper is infused with Volatile Corrosion Inhibitors, which vaporize and form an invisible protective layer around the metal. This layer inhibits the electrochemical reactions responsible for corrosion.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tape is infused with Volatile Corrosion Inhibitors, which vaporize and form an invisible protective layer around the metal. This layer prevents corrosion on both the metal surfaces in direct contact with the tape and in the surrounding enclosed space.</p>: null}
                                    {show === "vcp6"?<p>VCI grills are designed to seamlessly integrate into existing ventilation systems. They can be installed in various environments, such as warehouses, storage facilities, and manufacturing plants, where metal items are stored or processed.</p>: null}
                                    {show === "vcp7"?<p>VCI zip lock bags are impregnated with Volatile Corrosion Inhibitors. When the bag is sealed, these inhibitors vaporize, creating a protective atmosphere within the bag that prevents corrosion on metal items placed inside.</p>: null}
                                    {show === "vcp8"?<p> VCI sheets and rolls are infused with Volatile Corrosion Inhibitors. When in close proximity to metal items, these inhibitors vaporize, forming a protective atmosphere around the metal surfaces, preventing corrosion.</p>: null}
                                    {show === "vcp9"?<p>VCI lamina shrink films incorporate shrink film properties. When heat is applied, the film shrinks tightly around the metal components, providing a secure and protective covering. This tight seal minimizes moisture and air exposure.</p>: null}
                                    {show === "vcp10"?<p>The fabric is infused with Volatile Corrosion Inhibitors, which release protective molecules, forming a rust-preventative layer on metal surfaces. This inhibits corrosion, ensuring the metal components remain rust-free.</p>: null}
                                    {show === "vcp11"?<p>VCI barrier foils consist of multiple layers, including metalized films, barrier layers, and VCI-infused layers. This complex structure provides an effective barrier against moisture, oxygen, and other corrosive elements.</p>: null}
                                    {show === "vcp12"?<p>Cushioning Properties Like traditional bubble wrap, VCI bubble wrap features air-filled bubbles that provide cushioning and shock absorption. It protects metal items from physical damage, including scratches, dents, and impacts, during handling and transit.</p>: null}
                                    {show === "vcp13"?<p>VCI Holo Films feature holographic patterns on their surface, enhancing visual appeal. MLP Films, on the other hand, have multiple layers that contribute to increased strength and barrier properties. Both films offer corrosion protection while catering to specific aesthetic and structural requirements.</p>: null}
                                    {show === "vcp14"?<p>Power stretch films have excellent stretching capabilities, allowing them to conform tightly to the shape of the items being wrapped. This ensures a secure and snug fit, providing maximum protection against moisture and contaminants.</p>: null}
                                    {show === "vcp15"?<p>These films are designed for temporary use. They are applied when the surface is at risk of damage and can be easily removed without leaving residue once the protected surface is no longer vulnerable.</p>: null}
                                    {show === "vcp16"?<p>These stickers are designed for temporary use. They are applied directly onto metal surfaces, protecting specific areas from corrosion, paint, or other treatments during processes like painting, coating, or storage.</p>: null}
                                    {show === "vcp17"?<p>VCI shrink films have the ability to shrink tightly around the metal components when heat is applied. This creates a secure and protective covering, minimizing moisture and air exposure, which are common causes of corrosion.</p>: null}
                                    {show === "vcp18"?<p>These foils have a metal layer, such as aluminum, which acts as an excellent barrier against moisture, gases, and other corrosive agents. This metal layer provides an additional level of protection to the enclosed metal components.</p>: null}
                                    {show === "vcp19"?<p> VCI shower caps are available in various sizes and designs to fit specific metal components. They are tailored to cover intricate shapes, threads, and other vulnerable areas, providing comprehensive protection.</p>: null}
                                    {show === "vcp20"?<p>VCI Tuffpaulin is made from high-quality, durable materials that provide resistance against tears, abrasions, and harsh weather conditions. This makes it suitable for outdoor applications and challenging environments.</p>: null}


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> These bags are suitable for a wide range of metal materials, including steel, aluminum, brass, and copper. They are commonly used in the automotive, aerospace, electronics, and manufacturing industries.</p>: null}
                                    {show === "vcp2"?<p>These carton liners are suitable for a wide range of metal materials, including steel, aluminum, brass, and copper. They are commonly used in the automotive, aerospace, electronics, and manufacturing industries.</p>: null}
                                    {show === "vcp3"?<p>These pallet liners are suitable for various metal materials, including steel, aluminum, brass, and copper. They are commonly used in industries such as manufacturing, automotive, electronics, and aerospace to protect metal products during transportation and storage.</p>: null}
                                    {show === "vcp4"?<p>VCI paper offers both contact and vapor phase protection. The VCIs prevent corrosion on the metal surfaces in direct contact with the paper, as well as in the surrounding enclosed space.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tape incorporates shrink film properties. When heat is applied, the tape shrinks tightly around the metal components, providing a secure and protective covering. This tight seal minimizes moisture and air exposure.</p>: null}
                                    {show === "vcp6"?<p>VCI ventilation grills provide passive corrosion protection without the need for direct contact with metal components. The released VCI molecules circulate through the air, protecting metal surfaces in the vicinity of the ventilation system.</p>: null}
                                    {show === "vcp7"?<p>The zip lock feature allows easy and secure sealing of the bag. It provides a resealable closure, enabling users to access the contents multiple times without compromising the effectiveness of the VCI protection.</p>: null}
                                    {show === "vcp8"?<p> VCI sheets are available in various sizes, while rolls offer the flexibility to cut custom lengths. They can be wrapped around individual items, interleaved between components, or used to cover large surfaces, providing comprehensive protection.</p>: null}
                                    {show === "vcp9"?<p>These shrink films are suitable for a wide range of metal products, such as machinery, equipment, automotive parts, and large industrial components. They are ideal for protecting metal items during long-term storage, overseas shipping, or outdoor storage.</p>: null}
                                    {show === "vcp10"?<p>VCI strength fabric is designed to be sturdy and durable, providing mechanical protection against scratches, abrasions, and impacts during handling, transportation, and storage.</p>: null}
                                    {show === "vcp11"?<p> These foils are available in various configurations, allowing customization based on the specific needs of the metal components. Different barrier properties and VCI concentrations can be tailored to match the requirements of different products.</p>: null}
                                    {show === "vcp12"?<p>The bubble wrap is impregnated with Volatile Corrosion Inhibitors, which vaporize and create a protective atmosphere around the metal components. This inhibits the corrosion process, ensuring the metal items remain rust-free.</p>: null}
                                    {show === "vcp13"?<p>These films can be customized in terms of holographic designs, colors, sizes, and VCI concentrations. Customization allows businesses to align the packaging with branding needs while ensuring optimal corrosion protection for different metal components.</p>: null}
                                    {show === "vcp14"?<p> The films are impregnated with Volatile Corrosion Inhibitors, which vaporize and create a protective atmosphere around the metal components. This inhibits the corrosion process, ensuring the metal items remain rust-free.</p>: null}
                                    {show === "vcp15"?<p>Surface protection films are available in various materials, including polyethylene, polypropylene, and polyester. The choice of material depends on the type of surface being protected and the level of protection required.</p>: null}
                                    {show === "vcp16"?<p>VCI masking stickers come with an adhesive backing that ensures they adhere securely to metal surfaces. The adhesive is specially formulated to provide strong bonding during the application period without leaving residue upon removal.</p>: null}
                                    {show === "vcp17"?<p>The films are infused with Volatile Corrosion Inhibitors that vaporize and create a protective atmosphere around the metal components. This inhibits the corrosion process, ensuring the metal items remain rust-free.</p>: null}
                                    {show === "vcp18"?<p> The foils are infused with Volatile Corrosion Inhibitors that vaporize and create a protective atmosphere around the metal components. This inhibits the corrosion process, ensuring the metal items remain rust-free.</p>: null}
                                    {show === "vcp19"?<p>The shower caps are easy to apply and remove, allowing for quick installation and removal without the need for specialized tools or equipment. They can be simply placed over the metal components, ensuring a snug fit.</p>: null}
                                    {show === "vcp20"?<p> VCI Tuffpaulin is waterproof, protecting the metal components from moisture and rain. It is also UV resistant, safeguarding the covered items from the damaging effects of sunlight and prolonged exposure to outdoor elements.</p>: null}
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI 2D bags are easy to use; metal components are placed inside the bag, and the bag is sealed, creating a sealed environment where the VCI molecules can protect the metal items from corrosion.</p>: null}
                                    {show === "vcp2"?<p>VCI 3D carton liners are easy to install; they are placed inside cartons or crates, creating a sealed environment where the VCI molecules can protect the metal items from corrosion. The liners conform to the shape of the items inside the container.</p>: null}
                                    {show === "vcp3"?<p>VCI 3D pallet liners are easy to install; they are placed on the pallets, creating a sealed environment where the VCI molecules can protect the metal items from corrosion. The liners conform to the shape of the items placed on the pallet.</p>: null}
                                    {show === "vcp4"?<p>VCI paper is available in various forms, including sheets, rolls, bags, and pouches. It can be easily wrapped around metal parts or used as interleaving material between components. The simplicity of use makes it popular in industries like automotive, aerospace, and manufacturing.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tape is easy to apply using a heat gun or shrink wrapping equipment. Once heated, it conforms to the shape of the metal items, creating a snug and protective seal. It can be used for various shapes and sizes of metal products.</p>: null}
                                    {show === "vcp6"?<p>Once installed, VCI grills continuously release protective VCI molecules as part of the ventilation air, ensuring constant corrosion protection for enclosed metal items..</p>: null}
                                    {show === "vcp7"?<p> Many VCI zip lock bags are made from transparent materials, allowing users to identify the contents without opening the bag. This transparency is useful for inventory management and quick visual inspection.</p>: null}
                                    {show === "vcp8"?<p> VCI sheets and rolls find application in industries such as manufacturing, automotive, electronics, and aerospace. They are used for protecting machinery, tools, automotive parts, electronics, and other metal components during storage and shipment.</p>: null}
                                    {show === "vcp9"?<p>VCI lamina shrink films are easy to install using heat shrink equipment. Once heated, the film conforms to the shape of the metal items, creating a snug and protective seal. Proper installation ensures maximum corrosion protection.</p>: null}
                                    {show === "vcp10"?<p>VCI molecules within the film vaporize and create a protective atmosphere around the metal components, preventing corrosion on both the surfaces in direct contact with the film and in the surrounding enclosed space.</p>: null}
                                    {show === "vcp11"?<p>VCI barrier foils offer excellent barrier properties against moisture, gases, and UV light. They create a hermetic seal that protects metal items from the damaging effects of humidity and external contaminants.</p>: null}
                                    {show === "vcp12"?<p> VCI bubble wrap is suitable for a wide range of metal products, such as automotive parts, machinery, electronics, and delicate components. It is ideal for protecting metal items during shipping, storage, and export.</p>: null}
                                    {show === "vcp13"?<p>VCI Holo and MLP Films are versatile and find applications in various industries, including automotive, electronics, aerospace, and jewelry. They are used for protecting metal parts, electronic components, ornaments, and other valuable items during transit and storage.</p>: null}
                                    {show === "vcp14"?<p>VCI Power Stretch Films are suitable for a wide range of metal products, such as machinery, equipment, automotive parts, and industrial components. They are ideal for protecting metal items during shipping, storage, and export.</p>: null}
                                    {show === "vcp15"?<p>Films come in different thicknesses, ranging from thin and flexible films suitable for delicate surfaces to thicker, more robust films for heavy-duty protection.</p>: null}
                                    {show === "vcp16"?<p>VCI masking stickers are easy to apply and remove. They are precisely cut to fit specific areas on metal components, allowing for accurate placement. When the protection is no longer required, the stickers can be peeled off cleanly, leaving the underlying surface undamaged.</p>: null}
                                    {show === "vcp17"?<p>VCI shrink films are suitable for a wide range of metal products, such as machinery, equipment, automotive parts, and industrial components. They are ideal for protecting metal items during shipping, storage, and export.</p>: null}
                                    {show === "vcp18"?<p>VCI metal barrier foils can be customized in terms of size, thickness, and VCI concentration to meet specific application requirements. Customization ensures proper coverage and protection for different types of metal components.</p>: null}
                                    {show === "vcp19"?<p> VCI shower caps do not require adhesives for attachment. They rely on their elastic properties to remain securely in place, preventing residue and adhesive-related damage to the metal surfaces.</p>: null}
                                    {show === "vcp20"?<p>VCI Tuffpaulin is available in various sizes and can be customized to fit specific equipment or machinery. Custom sizing ensures complete coverage and protection for different types of metal components.</p>: null}
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Volatile Corrosion Inhibitors vaporize within the bag, forming a protective layer on the metal surfaces. This layer prevents moisture and contaminants from reaching the metal, ensuring long-term corrosion protection.</p>: null}
                                    {show === "vcp2"?<p> Volatile Corrosion Inhibitors vaporize within the carton liner, forming a protective layer on the metal surfaces. This layer prevents moisture and contaminants from reaching the metal, ensuring long-term corrosion protection.</p>: null}
                                    {show === "vcp3"?<p>Volatile Corrosion Inhibitors vaporize within the pallet liner, forming a protective layer on the metal surfaces. This layer prevents moisture and contaminants from reaching the metal, ensuring long-term corrosion protection.</p>: null}
                                    {show === "vcp4"?<p>VCI paper is used to protect a wide range of metal items, such as machinery, automotive parts, electrical components, tools, and equipment, during storage, shipping, and export.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tape is commonly used in the packaging of large metal equipment, machinery, and industrial products. It is ideal for protecting metal components during long-term storage, overseas shipping, or outdoor storage.</p>: null}
                                    {show === "vcp6"?<p>VCI ventilation grills are easy to install in ventilation ducts or openings. They can be retrofitted into existing systems or incorporated into new construction projects, offering flexibility in installation.</p>: null}
                                    {show === "vcp7"?<p>VCI zip lock bags are available in various sizes to accommodate different metal components. They can be used for small parts, tools, fasteners, and other metal items that need protection from corrosion.</p>: null}
                                    {show === "vcp8"?<p>VCI sheets and rolls are easy to handle. They can be cut and shaped to fit specific items or surfaces. The flexibility of these materials allows for effortless wrapping and coverage of metal products.</p>: null}
                                    {show === "vcp9"?<p>Many VCI lamina shrink films are transparent, allowing for easy visual inspection of the wrapped items without the need for unwrapping. This transparency is beneficial for inventory management and quality checks.</p>: null}
                                    {show === "vcp10"?<p>VCI strength fabric can be customized in terms of size, thickness, and VCI concentration to meet specific application requirements. Customization ensures optimal protection for different types of metal components.</p>: null}
                                    {show === "vcp11"?<p>VCI barrier foils are widely used in industries such as automotive, aerospace, electronics, and military. They protect metal parts, machinery, electronics, and sensitive equipment during storage, shipping, and export.</p>: null}
                                    {show === "vcp12"?<p>VCI bubble wrap is easy to handle and can be cut and shaped to fit specific items or surfaces. It is available in various widths and lengths, offering flexibility in packaging different sizes of metal components.</p>: null}
                                    {show === "vcp13"?<p>MLP Films are designed with multiple layers, enhancing their barrier properties against moisture, oxygen, and other corrosive agents. This high barrier ensures that the enclosed metal components remain protected from environmental factors.</p>: null}
                                    {show === "vcp14"?<p>Power stretch films are known for their high tensile strength and puncture resistance. This makes them durable and capable of withstanding the rigors of transportation and handling.</p>: null}
                                    {show === "vcp15"?<p>Surface protection films have adhesive backing that adheres securely to the surface. The adhesive is designed to provide a strong bond during protection but allows for clean and easy removal without damaging the surface.</p>: null}
                                    {show === "vcp16"?<p>These stickers are versatile and can be customized in terms of size and shape to match the specific requirements of different metal components. They are widely used in industries such as automotive, aerospace, and manufacturing, where precise protection during various processes is essential.</p>: null}
                                    {show === "vcp17"?<p> VCI shrink films are easy to apply using heat shrink equipment. Once heated, the film conforms tightly to the shape of the metal items, creating a snug and protective seal. Proper installation ensures maximum corrosion protection.</p>: null}
                                    {show === "vcp18"?<p>VCI metal barrier foils are widely used in industries such as aerospace, automotive, electronics, and military. They protect metal parts, machinery, electronics, and sensitive equipment during storage, shipping, and export.</p>: null}
                                    {show === "vcp19"?<p> Many VCI shower caps are transparent, allowing for easy visual inspection of the covered components without the need to remove the caps. This transparency aids in quality checks and inventory management.</p>: null}
                                    {show === "vcp20"?<p>VCI Tuffpaulin is easy to install and secure in place. It can be tied down or fastened using ropes, straps, or hooks, ensuring a snug fit around the metal items, preventing exposure to corrosive agents.</p>: null}
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI 2D bags offer long-term protection, making them suitable for both short-term storage and extended periods of transportation. They are especially useful for items that need to be stored for months or even years.</p>: null}
                                    {show === "vcp2"?<p>VCI 3D carton liners offer long-term protection, making them suitable for both short-term storage and extended periods of transportation. They are especially useful for items that need to be stored or shipped for months or even years.</p>: null}
                                    {show === "vcp3"?<p>VCI 3D pallet liners offer long-term protection, making them suitable for both short-term storage and extended periods of transportation. They are especially useful for metal products that need to be stored or shipped for months or even years.</p>: null}
                                    {show === "vcp4"?<p>VCI paper offers long-term protection, making it suitable for both short-term storage and extended periods of transportation. It is especially useful for items that might be in storage for months or even years.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tape requires heat activation to shrink and tightly conform to the wrapped items. The heat process ensures a secure fit and maximizes the protective seal.</p>: null}
                                    {show === "vcp6"?<p>VCI ventilation grills are suitable for a wide range of industries, including automotive, aerospace, manufacturing, and storage. They are ideal for environments where metal items are susceptible to corrosion due to exposure to humidity or harsh atmospheric conditions.</p>: null}
                                    {show === "vcp7"?<p>VCI zip lock bags are user-friendly. Metal components are placed inside the bag, and the bag is sealed using the zip lock feature, creating a sealed environment where the VCI molecules can protect the items from corrosion.</p>: null}
                                    {show === "vcp8"?<p>Some VCI sheets and rolls are transparent, allowing visual inspection without unwrapping. Additionally, they can be custom printed with labels, logos, or instructions, enhancing brand visibility and providing valuable information.</p>: null}
                                    {show === "vcp9"?<p>VCI lamina shrink films are available in various widths and thicknesses to accommodate different sizes of metal components. Custom sizing options ensure proper coverage and protection for specific applications.</p>: null}
                                    {show === "vcp10"?<p>Despite its strength, the fabric is flexible and easy to handle, allowing it to conform to the shape of the items being protected. This flexibility ensures efficient wrapping and coverage of metal products..</p>: null}
                                    {show === "vcp11"?<p>The foils can be heat-sealed to create a secure, airtight packaging for metal items. Heat sealing ensures that the foil forms a tight barrier around the products, preventing the ingress of moisture and other corrosive agents.</p>: null}
                                    {show === "vcp12"?<p>Some VCI bubble wraps are transparent, allowing for easy visual inspection of the wrapped items without the need for unwrapping. Additionally, they can be custom printed with labels, logos, or handling instructions.</p>: null}
                                    {show === "vcp13"?<p>VCI Holo Films, with their holographic patterns, add visual appeal to the packaging. This makes them suitable for premium and retail products, enhancing the overall presentation of the packaged items.</p>: null}
                                    {show === "vcp14"?<p> VCI Power Stretch Films are easy to apply using power stretch wrapping machines. They can be stretched and wrapped around metal components quickly and efficiently, saving time and labor costs.</p>: null}
                                    {show === "vcp15"?<p>Many surface protection films offer UV resistance, preventing the covered surface from discoloration or damage due to prolonged exposure to sunlight.</p>: null}
                                    {show === "vcp16"?<p>VCI masking stickers can be color-coded or labeled with specific instructions, allowing for easy identification of protected areas. This visual identification ensures that workers follow the correct procedures during industrial processes.</p>: null}
                                    {show === "vcp17"?<p>Many VCI shrink films are transparent, allowing for easy visual inspection of the wrapped items without the need for unwrapping. This transparency is beneficial for inventory management and quality checks.</p>: null}
                                    {show === "vcp18"?<p> The foils can be heat-sealed to create a secure, airtight packaging for metal items. Heat sealing ensures that the foil forms a tight barrier around the products, preventing the ingress of moisture and other corrosive agents.</p>: null}
                                    {show === "vcp19"?<p>VCI shower caps find applications in various industries, including automotive, aerospace, electronics, and manufacturing. They are used to protect metal parts, bearings, screws, and delicate components during production, storage, or shipping.</p>: null}
                                    {show === "vcp20"?<p>Some VCI Tuffpaulin products feature ventilation options, allowing air circulation while providing protection. Proper ventilation is crucial to prevent condensation and moisture buildup, which can lead to corrosion.</p>: null}
                                    
                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI 2D bags are available in various sizes and can be customized to fit specific metal components. Custom sizing ensures a snug fit, maximizing the efficiency of the corrosion protection</p>: null}
                                    {show === "vcp2"?<p>VCI 3D carton liners can be customized to fit specific carton or crate sizes and shapes. Custom sizing ensures a snug fit, maximizing the efficiency of the corrosion protection and minimizing wasted space.</p>: null}
                                    {show === "vcp3"?<p>VCI 3D pallet liners are often environmentally friendly and can be recycled after use, promoting sustainable packaging practices in various industries.</p>: null}
                                    {show === "vcp4"?<p>VCI paper is often biodegradable and recyclable, promoting environmentally friendly packaging practices. Manufacturers increasingly focus on eco-friendly formulations to minimize environmental impact.</p>: null}
                                    {show === "vcp5"?<p>Some VCI shrink tapes are transparent, allowing for easy visual inspection of the wrapped items without the need for unwrapping. This transparency is beneficial for inventory management and quality checks.</p>: null}
                                    {show === "vcp6"?<p> VCI ventilation grills provide long-term corrosion protection, making them suitable for both short-term and extended storage periods. They help preserve the integrity of metal components over time.</p>: null}
                                    {show === "vcp7"?<p> VCI zip lock bags offer long-term protection, making them suitable for both short-term storage and extended periods of transportation. They are especially useful for items that need to be stored or shipped for months or even years.</p>: null}
                                    {show === "vcp8"?<p> VCI sheets and rolls offer long-term protection, making them suitable for both short-term storage and extended periods of transportation. They help prevent corrosion during overseas shipping, warehousing, or outdoor storage.</p>: null}
                                    {show === "vcp9"?<p>VCI lamina shrink films offer long-term corrosion protection, making them suitable for both short-term and extended storage periods. They help preserve the integrity of metal components over time, even in challenging environmental conditions.</p>: null}
                                    {show === "vcp10"?<p>VCI strength fabric offers long-term corrosion protection, making it suitable for both short-term storage and extended periods of transportation. It helps preserve the integrity of metal components over time.</p>: null}
                                    {show === "vcp11"?<p>VCI barrier foils provide long-term corrosion protection, making them suitable for extended storage periods and international shipments. They ensure that metal components arrive at their destination in optimal condition.</p>: null}
                                    {show === "vcp12"?<p>VCI bubble wrap provides long-term corrosion protection, making it suitable for both short-term and extended storage periods. It ensures that metal components remain in optimal condition even when stored for extended periods..</p>: null}
                                    {show === "vcp13"?<p>Both types of films are easy to seal using heat-sealing techniques. Proper sealing ensures an airtight enclosure, maximizing the effectiveness of the VCI protection.</p>: null}
                                    {show === "vcp14"?<p>Many VCI Power Stretch Films are transparent, allowing for easy visual inspection of the wrapped items without the need for unwrapping. This transparency is beneficial for inventory management and quality checks.</p>: null}
                                    {show === "vcp15"?<p> Common applications include protecting appliances, electronic devices, automotive parts, furniture, glass surfaces, and painted finishes. They are widely used in construction, manufacturing, transportation, and renovation projects.</p>: null}
                                    {show === "vcp16"?<p>Many VCI masking stickers are heat-resistant, making them suitable for applications where components undergo high-temperature processes such as welding or powder coating.</p>: null}
                                    {show === "vcp17"?<p>VCI shrink films provide long-term corrosion protection, making them suitable for both short-term and extended storage periods. They help preserve the integrity of metal components over time, even in challenging environmental conditions.</p>: null}
                                    {show === "vcp18"?<p>Many VCI metal barrier foils are transparent, allowing for easy visual inspection of the wrapped items without the need for unwrapping. This transparency is beneficial for inventory management and quality checks.</p>: null}
                                    {show === "vcp19"?<p>Some VCI shower caps are heat-resistant, making them suitable for applications where components undergo high-temperature processes such as welding, painting, or powder coating.</p>: null}
                                    {show === "vcp20"?<p>VCI Tuffpaulin offers long-term corrosion protection, making it suitable for extended outdoor storage periods. It ensures that the covered metal components remain in optimal condition, even in harsh weather conditions.</p>: null}
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI 2D bags are often environmentally friendly and can be recycled after use, promoting sustainable packaging practices in various industries.</p>: null}
                                    {show === "vcp2"?<p>VCI 3D carton liners are often environmentally friendly and can be recycled after use, promoting sustainable packaging practices in various industries.</p>: null}
                                    {show === "vcp3"?<p>VCI 3D pallet liners can be customized to fit specific pallet sizes and shapes. Custom sizing ensures a snug fit, maximizing the efficiency of the corrosion protection and minimizing wasted space on the pallet.</p>: null}
                                    {show === "vcp4"?<p> VCI paper can be customized in terms of size, thickness, and VCI concentration to meet specific packaging requirements. Customization ensures optimal protection for various metal components.</p>: null}
                                    {show === "vcp5"?<p>VCI shrink tapes are available in various widths and thicknesses to accommodate different sizes of metal components. Custom sizing options ensure proper coverage and protection for specific applications.</p>: null}
                                    {show === "vcp6"?<p>VCI ventilation grills can be customized based on the specific ventilation system requirements, including size, airflow capacity, and VCI concentration. Customization ensures optimal performance for different applications.</p>: null}
                                    {show === "vcp7"?<p>Some VCI zip lock bags can be custom printed with labels, logos, or handling instructions, allowing businesses to personalize the packaging and enhance brand visibility..</p>: null}
                                    {show === "vcp8"?<p>Many VCI sheets and rolls are biodegradable and recyclable, promoting eco-friendly packaging practices while providing excellent corrosion protection.</p>: null}
                                    {show === "vcp9"?<p> VCI molecules within the film vaporize and create a protective atmosphere around the metal components, preventing corrosion on both the surfaces in direct contact with the film and in the surrounding enclosed space.</p>: null}
                                    {show === "vcp10"?<p>Many VCI strength fabrics are environmentally friendly and can be recycled after use, promoting sustainable packaging practices and reducing environmental impact.</p>: null}
                                    {show === "vcp11"?<p> Many VCI barrier foils are recyclable and contribute to eco-friendly packaging practices. They reduce the need for excessive oil-based rust preventatives and other harmful chemicals, promoting sustainable packaging solutions.</p>: null}
                                    {show === "vcp12"?<p>Many VCI bubble wraps are recyclable and contribute to eco-friendly packaging practices. They reduce the need for excessive oil-based rust preventatives and other harmful chemicals, promoting sustainable packaging solutions.</p>: null}
                                    {show === "vcp13"?<p>VCI Holo and MLP Films offer long-term corrosion protection, making them suitable for extended storage periods and international shipments. They help preserve the integrity and quality of metal components over time.</p>: null}
                                    {show === "vcp14"?<p>VCI Power Stretch Films provide long-term corrosion protection, making them suitable for both short-term and extended storage periods. They help preserve the integrity of metal components over time, even in challenging environmental conditions.</p>: null}
                                    {show === "vcp15"?<p>When the protected surface is no longer at risk, surface protection films can be peeled off easily and cleanly, leaving the original surface undamaged and ready for use.</p>: null}
                                    {show === "vcp16"?<p>VCI masking stickers offer a cost-effective solution to protect specific areas of metal components. By preventing corrosion and damage during various processes, they reduce the need for rework, repair, or replacement, saving both time and money for manufacturers.</p>: null}
                                    {show === "vcp17"?<p> Many VCI shrink films are recyclable and contribute to eco-friendly packaging practices. They reduce the need for excessive oil-based rust preventatives and other harmful chemicals, promoting sustainable packaging solutions.</p>: null}
                                    {show === "vcp18"?<p>VCI metal barrier foils provide long-term corrosion protection, making them suitable for extended storage periods and international shipments. They help preserve the integrity of metal components over time, even in challenging environmental conditions.</p>: null}
                                    {show === "vcp19"?<p>VCI shower caps offer long-term corrosion protection, making them ideal for both short-term and extended storage periods. They help preserve the integrity and quality of metal components over time, ensuring they reach the end-users in optimal condition.</p>: null}
                                    {show === "vcp20"?<p> VCI Tuffpaulin is widely used in industries such as construction, agriculture, marine, and military. It protects metal equipment, machinery, vehicles, and structures during construction projects, outdoor storage, and transportation.</p>: null}
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Vci