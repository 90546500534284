import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Vm = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "VCI Masterbatch"     
                               :""}
                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/rustflex10.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Rust-X patented VCI Masterbatch3100 PC is an anti-corrosion master batch designed for both ferrous and most of the non-ferrous metals.The VCI (Volatile Corrosion Inhibitor) additives are partly based on chemicals with migration ability and vapor pressure to release the active anticorrosion molecules from the film and out in the environmental air to protect the metal packed from corrosion.Extrusion of polyethylene film with VCI MB-3100 PC will produce a finished product that combines the properties of polyethylene resin and the VCI which will solve the corrosion problems.</p> : 
                                null}
                                
                                
                            </div>
                        </div>

                       {/* < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                       
                        
                      
                        
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p> VCI Masterbatch contains Volatile Corrosion Inhibitors (VCIs) that vaporize and form a protective molecular layer on metal surfaces. This layer inhibits corrosion and rust formation on metal components that come into contact with the plastic product.</p>: null}
                                   
                                    
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Masterbatch is incorporated into plastic materials during the manufacturing process. It is typically mixed with the plastic resin before molding or extrusion. This ensures that the VCI properties are evenly distributed throughout the plastic product.</p>: null}
                                   
                                    
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Masterbatch can be used with various types of plastics, including polyethylene (PE), polypropylene (PP), polyvinyl chloride (PVC), and other plastic resins. The choice of plastic depends on the specific application requirements.</p>: null}
                                   
                                    
                    
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Masterbatch is designed to be compatible with different plastic processing methods, such as injection molding, extrusion, and blow molding. It does not significantly impact the mechanical or physical properties of the plastic.</p>: null}
                                   
                                    
                                  
                                    
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Masterbatch provides long-term corrosion protection for metal parts enclosed within plastic products. This is especially valuable for products used in storage, shipping, or packaging applications where metal components are susceptible to corrosion during transit or storage.</p>: null}
                                   
                                    
                                 
                                    
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Masterbatch is versatile and can be used in various plastic products, including packaging materials, industrial containers, wraps, films, and other plastic items used in industries such as automotive, electronics, and aerospace.</p>: null}
                                   
                                    

                                    

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>Many VCI Masterbatch formulations are eco-friendly and do not contain harmful substances. They comply with environmental regulations and are safe for use in various applications.</p>: null}
                                   
                                    
                                  
                                    
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI Masterbatch can be customized based on the specific plastic resin and the level of corrosion protection required. Different formulations are available to cater to different metals and environmental conditions.</p>: null}
                                   
                                    
                                   
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Vm