import About from "./Home/About";
import { useEffect, useState } from "react";
import FindWindowSize from "./Hooks/FindWIndowSize";
import { Route, Routes } from "react-router";
import Home from "./Pages/Home";
import AboutPage from "./Pages/AboutPage";
import About_us from './About/About_us'
import ServicePage from "./Pages/ServicePage";
import ContactPage from "./Pages/ContactPage";
import BlogPage from "./Pages/BlogPage";
import BlogDetails from "./Pages/BlogDetails";
import CareerPage from "./Pages/CareerPage";
import Login from "./Admin/Login";
import DashBoard from "./Admin/DashBoard";
import Category from "./Admin/Category";
import AddCategory from "./Admin/AddCategory";
import EditBlog from "./Admin/EditBlog";
import Blog from "./Admin/Blog";
import EditCategory from "./Admin/EditCategory";
import AddBlog from "./Admin/AddBlog";
import Product_Category from "./Service/Product_Category";
import Biodegradable from "./Service/Biodegradable"
import Careers from "./Admin/Careers";
import AddCareer from "./Admin/AddCareer";
import EditCareers from "./Admin/EditCareers";
import Candidate from "./Admin/Candidate";
import ProductDetail from "./Service/ProductDetail";
import Product from "./Service/Products";
import Gp from "./Service/Gp";
import Expert1 from "./Home/Expert1";
import SubCategories from "./Service/SubCategories";
import Downloadcenter from "./Pages/Downloadcenter";
import Product1 from "./Service/Product1";
import Four from "./Pagenotfound";



function App() {
    const [load, setLoad] = useState(false)
  const [color, setColor] = useState(false)
  const changeColor = () => {
      if(window.scrollY >= 90){
          setColor(true)
      }else{
          setColor(false)
      }
  }

  useEffect(() => {
      window.addEventListener('scroll', changeColor)

      return () => window.removeEventListener('scroll',changeColor)
  })

  const windowSize = FindWindowSize()


  return (
      <Routes>
        <Route path="/" element={<Home windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/about-us" element={<AboutPage windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/services" element={<ServicePage windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/contacts" element={<ContactPage windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/blogs" element={<BlogPage windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/Downloadcenter" element={<Downloadcenter windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/blogdetails/:id" element={<BlogDetails windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/career" element={<CareerPage windowSize={windowSize} color={color} setColor={setColor}/>}/>
        <Route path="/admin" element={<Login />} />
        <Route path="/DashBoard" element={<DashBoard />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/AddCategory" element={<AddCategory />} />
        <Route path="/editblog/:id" element={<EditBlog />} />
        <Route path="/editcategory/:id" element={<EditCategory />} />
        <Route path="/adminblog" element={<Blog />} />
        <Route path="/addblog" element={<AddBlog />} />

        <Route path="/Product_Category/:id" element={<Product_Category windowSize={windowSize} color={color} setColor={setColor}/>} />
        <Route path="/Product_Category/:id/:sub" element={<Product_Category windowSize={windowSize} color={color} setColor={setColor}/>} />
        <Route path="/Biodegradable" element={<Biodegradable />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/AddCareer" element={<AddCareer />} />
        <Route path="/edit-career/:id" element={<EditCareers />} />
        
        <Route path="/products/:pid/:id" element={<Product windowSize={windowSize} color={color} setColor={setColor} load={load} setLoad={setLoad}/>} />
        <Route path="/products/:pid/:id/:cat/:cid/" element={<Product1 windowSize={windowSize} color={color} setColor={setColor} load={load} setLoad={setLoad}/>} />
        <Route path="/products/:pid/:id/:cid/" element={<Product1 windowSize={windowSize} color={color} setColor={setColor} load={load} setLoad={setLoad}/>} />
        <Route path="/products/:pid/:id" element={<Gp windowSize={windowSize} color={color} setColor={setColor} load={load} setLoad={setLoad}/>} />

        <Route path="/candidate" element={<Candidate />} />
        <Route path="/SubCategories/:id" element={<SubCategories  windowSize={windowSize} color={color} setColor={setColor} load={load} setLoad={setLoad}/>}/>
        <Route path="*" element={<Four />} />
      </Routes>
  );
}
export default App;
