import React, { useEffect, useState } from 'react';
import '../css/Productdetails.css';
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Spinner from 'react-bootstrap/Spinner';
import { Link, useParams } from 'react-router-dom';
import CommonBtns from './CommonBtns';
import Vci from './Rustx/Vci';
import Cleaners from './Rustx/Cleaners';
import Cutting from './Rustx/Cutting';
import Coating from './Rustx/Coating';
import Rustp from './Rustx/Rustp';
import Streetc from './Rustx/Streetc';
import VciSpray from './Rustx/VciSpray';
import Desiccants from './Rustx/Desiccants';
import Rc from './Rustx/Rc';
import Vm from './Rustx/Vm';
import Dl from './Rustx/Dl';
import VciEmt from './Rustx/VciEmt';
import ProductSlider from './ProductSlider';


function Lp({setLoad}) {
    const [openCategory, setOpenCategory] = useState(null);
    const {id,pid,cid,cat} = useParams()
    const [show , setShow] = useState("")

  
    
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='4'){
            setShow("vcp4")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='5'){
            setShow("vcp5")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='6'){
            setShow("vcp6")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='7'){
            setShow("vcp7")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='8'){
            setShow("vcp8")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='9'){
            setShow("vcp9")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='10'){
            setShow("vcp10")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='11'){
            setShow("vcp11")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='12'){
            setShow("vcp12")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='13'){
            setShow("vcp5")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='14'){
            setShow("vcp6")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='15'){
            setShow("vcp7")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='16'){
            setShow("vcp8")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='17'){
            setShow("vcp9")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='18'){
            setShow("vcp10")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='19'){
            setShow("vcp11")
          }
        if(id === "Rust-X"&&cat==="VCIPackaging"&&cid==='20'){
            setShow("vcp12")
          }
    
       
        
    },[id,cid])

    const handleLoad = () => {
        setLoad(true); 

        // setTimeout(() => {
        //     setShowLoader(false);
        //   }, 1000);
    }

    const [isLoading, setIsLoading] = useState(false);

    return (
    <>
            
            <div className='container'>
                <div className={isLoading ? "opaque" :  "opaque1"}>
                <div className='row overall'>
                    <div className='col-md-4' >
                    <CommonBtns id={id} pid={pid} setLoad={setLoad}/>
                    </div> 
                    
                    {
                        cat === "VCI Packaging" &&
                        <>
                            <Vci id={id} cid={cid}/>
  
                        </>
                    }
                    {
                        cat === "Cleaners" &&
                        <>
                            <Cleaners id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Cutting Oils" &&
                        <>
                            <Cutting id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Coatings" &&
                        <>
                            <Coating id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Rust Preventive Oils" &&
                        <>
                            <Rustp id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Steel Coil Packaging" &&
                        <>
                            <Streetc id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "VCI Sprays" &&
                        <>
                            <VciSpray id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Desiccants" &&
                        <>
                            <Desiccants id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Remover & Converter" &&
                        <>
                            <Rc id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "VCI Masterbatch" &&
                        <>
                            <Vm id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "Data Loggers" &&
                        <>
                            <Dl id={id} cid={cid}/>
                        </>
                    }
                    {
                        cat === "VCI Emitters" &&
                        <>
                            <VciEmt id={id} cid={cid}/>
                        </>
                    }

                </div>
                </div>
            </div>
    

    </>
      
    )
}

export default Lp;