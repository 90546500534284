import React, { useEffect, useState } from 'react'
import {BsFillPersonFill} from 'react-icons/bs'
import {AiFillCalendar} from 'react-icons/ai'
import '../css/EcBlogs.css'
import { Link } from 'react-router-dom'
import axios from 'axios'

const EcBlog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get("https://infygain.in/api/blogdata").then((res) => {
      setBlogs(res.data);
    });
  }, []);

  function dateSlice(id){
    const rawDate = blogs[id].date;
    const blogDate = rawDate.slice(0, 10);
    return blogDate;
     }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = blogs.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const maxVisiblePages = 3; // Maximum visible page numbers
    const pages = [];

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i}>
          <button
            className={currentPage === i ? "active" : ""}
            onClick={() => handleClick(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pages;
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
    <div className='container ec-blogs-head'>
      {/* {
        blogContent.map((blog) => (
          <div className='ec-blog-slide '>
              
          <img className='ec-bl-image'  src={blog.img}/> 
          <div className=' ec-blog-overtext' >
              
              <div className='ec-bl-col1'>
                <p><AiFillCalendar className='ec-bl-icons'/> &nbsp; {blog.date} &nbsp;</p>
                <p><BsFillPersonFill className='ec-bl-icons'/> &nbsp; {blog.name}</p>
              </div>
              <p className='ec-bl-over-para1'>{blog.title}</p>

              <div className='ec-blog-overlay-text'>
                <p className='ec-bl-overlay-para1'>{blog.title}</p>
                <p className='ec-bl-overlay-para2'>Read More &gt;&gt;</p>
            </div>
        </div>
      </div>
        ))
      } */}

        {currentItems.map((value, index)=>(
                // <div className='blog-slide ' >
              
                //         <img className='bl-image'  src='/images/image1.webp'/>
                //         <div className=' blog-overtext' >
                      
                //           <div key={value.id}>
                //             <div className='bl-col1' >
                //               <p><AiFillCalendar className='bl-icons'/> &nbsp; {dateSlice(index)} &nbsp;</p>
                //               <p><BsFillPersonFill className='bl-icons'/> &nbsp; Rinnah</p>
                //             </div>
                //             <p className='bl-over-para1'>{value.title}</p>
                //             <div className='blog-overlay-text'>
                //               <p className='bl-overlay-para1'>Reason to explian fast business builder</p>
                //               <p className='bl-overlay-para2'>Read More &gt;&gt;</p>
                //           </div>
                //           </div>
                        
                //       </div>
                    
                //     </div>

                <div className='ec-blog-slide '>
                              
                <Link to='/blogdetails'> <img className='ec-bl-image'  src='/images/image1.webp'/> </Link>
                  <div className=' ec-blog-overtext' >
                      
                      <div className='ec-bl-col1'>
                        <p><AiFillCalendar className='ec-bl-icons'/> &nbsp; {dateSlice(index)} &nbsp;</p>
                        <p><BsFillPersonFill className='ec-bl-icons'/> &nbsp; Rinnah</p>
                      </div>
                      <p className='ec-bl-over-para1'>{value.title}</p>

                      <div className='ec-blog-overlay-text '>
                        <p className='ec-bl-overlay-para1'>Reason to explian fast business builder</p>
                        <Link to={`/blogdetails/${value.id}`}><p className='ec-bl-overlay-para2'>Read More &gt;&gt;</p></Link>
                    
                    </div>
                </div>
                </div>

              
              ))}
      </div>
      {/* Pagination */}
      {totalPages > 1 && (
              <ul className="pagination">
                <li>
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>
                </li>
                {renderPageNumbers()}
                <li>
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            )}
    
          
    </>
  )
}

export default EcBlog