import React,{useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/OurServices.css';
import Product_Category from "./Product_Category"
import { TfiAnnouncement } from "react-icons/tfi";
import { TfiLayoutMediaCenterAlt } from "react-icons/tfi";
import { RiGlobalFill } from "react-icons/ri";
import { SiWebtrees } from "react-icons/si";
import { RiLightbulbFlashLine } from "react-icons/ri";
import { GiProgression } from "react-icons/gi";
import { MdNetworkWifi3Bar } from "react-icons/md";
import { GiCctvCamera } from "react-icons/gi";
import { LuLaptop2 } from "react-icons/lu";
import { Link } from 'react-router-dom';
import { FaCut } from "react-icons/fa";
import { AiOutlineSafety } from "react-icons/ai";
import { GiPipes } from "react-icons/gi";
import { BiSolidPackage } from "react-icons/bi";
import { GrCubes } from "react-icons/gr";
import { GiThreeLeaves } from "react-icons/gi";
import { BiGasPump } from "react-icons/bi";
import { BsFillBuildingsFill } from "react-icons/bs";




import AOS from "aos";
import "aos/dist/aos.css";
// import { Hidden } from '@mui/material';


function OurServices() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div className='firsthead'>
           <div className='container'>
            {/* left side start  */}
           <div className=' Ourservice_leftside'>
                    <div className=' header1'>
                        <div className='abouts'>
                            <div className='small_line'></div>
                            <h4 className='aboutline_head'>Service's</h4>
                        </div>
                        <p className='service-head'>
                        Explore industrial tools, safety products, and eco-friendly lubricants. Prioritize cost-effectiveness and productivity across various sectors
                            {/* Discover a world of possibilities with our comprehensive 
                            range of services tailored to meet your needs. */}
                            </p>
                    </div>
               
                    <div className=' header2 text-muted'>
                        <p>
                            Explore comprehensive training ensuring your workforce stays updated with cutting-edge technology. Our dedicated engineering team ensures top-notch product quality and consistent customer support for optimized operations.
                            {/* Join us on a journey of digital excellence.
                         Let's shape your vision into reality and take your online presence 
                         and technology infrastructure to new heights. */}
                         </p>
                    </div>
            </div>
            {/* leftside end */}


            {/* rows 1 */}
            <div className="container rightsidegrid">
           

       
                {/* first row */}
                
                <div className='firstrow' data-aos="zoom-in-right">
                    <div className='divline'></div>
                    <FaCut className='icons'/>
                    <h3>
                   Industrial <br></br>Cutting
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'>Utilizing a high-powered laser beam, 
                    this method is known for its precision and efficiency.</p>
                        {/* <div className='iconbox'> */}
                        <FaCut className='icon2'/>
                        {/* </div> */}
                           {/* {Hidden} */}
                           <div className='hiddenrowsa'>
                           <div className='pros'>
                           <div className='divline'></div>
                   <h2 className='edgehead'>Industrial Cutting</h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/industrialcutting" >
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                        </div>
                        {/* dummy */}
                        <div className='firstrow' data-aos="zoom-in-right">
                    <div className='divline'></div>
                    <AiOutlineSafety className='icons'/>
                    <h3>
                    Industrial Safety Products
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'>Protect the head from falling objects, electrical hazards, 
                    and other potential impacts.</p>
                        {/* <div className='iconbox'> */}
                        <AiOutlineSafety className='icon2'/>
                        {/* </div> */}
                           {/* {Hidden} */}
                           <div className='hiddenrowsb'>
                           <div className='pros'>
                           <div className='divline'></div>
                   <h2 className='edgehead'> Industrial Safety Products </h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/IndustrialSafety" >
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                        </div>
                        {/* dummy */}
                        <div className='firstrow' data-aos="zoom-in-right">
                    <div className='divline'></div>
                    <GiPipes className='icons'/>
                    <h3>
                     Gas Pipeline <br></br>projects
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'>We are specialised in gas pipeline services.
                     We are engaged in LPG (Liquefied Petroleum Gas) Gas Pipeline Installation.</p>
                        {/* <div className='iconbox'> */}
                        <GiPipes className='icon2'/>
                        {/* </div> */}
                           {/* {Hidden} */}
                           <div className='hiddenrowsc'>
                           <div className='pros'>
                           <div className='divline'></div>
                   <h2 className='edgehead'>  Gas Pipeline projects </h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/gas-pipeline">
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                        </div>
                        {/* dummy */}
                        <div className='firstrow' data-aos="zoom-in-right">
                    <div className='divline'></div>
                    <BiSolidPackage className='icons'/>
                    <h3>
                     Lubricants Packing Products
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'> Lubricants intended for medium-scale
                     use are packaged in metal or plastic pails or cans.</p>
                        {/* <div className='iconbox'> */}
                        <BiSolidPackage className='icon2'/>
                        {/* </div> */}
                           {/* {Hidden} */}
                           <div className='hiddenrowsd'>
                           <div className='pros'>
                           <div className='divline'></div>
                   <h2 className='edgehead'>  Lubricants Packing Products </h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/lubricants">
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                        </div>
                        {/* dummy */}
                        <div className='firstrow' data-aos="zoom-in-left">
                    <div className='divline'></div>
                    <GiThreeLeaves className='icons'/>
                    <h3>
                    Bio-Degradable Products
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'>Dr Bio is India’s first approved bio polymer manufacturer, 
                    focusing on compostable polymers, packaging, cutlery, and straws.</p>
                        {/* <div className='iconbox'> */}
                        <GiThreeLeaves className='icon2'/>
                        {/* </div> */}
                           {/* {Hidden} */}
                           <div className='hiddenrowse'>
                           <div className='pros'>
                           <div className='divline'></div>
                   <h2 className='edgehead'> Bio Degradable Products </h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/bio-degradable">
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                        </div>
                {/* second row */}
                <div className='firstrow' data-aos="zoom-in-left">
                <div className='divline'></div>
                <BiGasPump className='icons'/>
                    <h3>
                 Industrial<br></br> LPG Solutions
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'> LBG is a renewable energy source derived from organic waste. Its production 
                    helps in reducing greenhouse gas emissions by utilizing waste materials.
                </p>
                    <BiGasPump className='icon2'/>
                       {/* {Hidden} */}
                       <div className='hiddenrows4'>
                       <div className='pros'>
                       <div className='divline'></div>
                   <h2 className='edgehead'>Industrial LPG Solutions </h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/industriallpg" >
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                    {/* {hidden row} */}
                
                </div>
                {/* third row */}
                <div className='firstrow' data-aos="zoom-in-left">
                <div className='divline'></div>
                <BsFillBuildingsFill className='icons'/>
                    <h3>
                   Textile Machinery Spares
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'>"Textile machinery spares" refers to the components 
                    or parts that are used in textile machinery for repair, replacement, or maintenance purposes. 
            </p>
                    <BsFillBuildingsFill className='icon2'/>
                       {/* {Hidden} */}
                       <div className='hiddenrows5'>
                       <div className='pros'>
                       <div className='divline'></div>
                   <h2 className='edgehead'>Textile Machinery Spares</h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/textilemachinery">
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                </div>
                {/* fourth row */}
                <div className='firstrow' data-aos="zoom-in-left">
                <div className='divline'></div>
                <LuLaptop2 className='icons'/>
                    <h3>
                    Office Essential
                    </h3>
                    <div className='divideline'></div>
                    <p className='param'>"Official essential" typically refers to items, documents, 
                    or tools that are necessary or required for official or formal purposes.  
            </p>
                    <LuLaptop2 className='icon2'/>
                       {/* {Hidden} */}
                       <div className='hiddenrows6'>
                       <div className='pros'>
                       <div className='divline'></div>
                   <h2 className='edgehead'> Office Essential</h2>
                   <div className='buttongroup'>
                   <Link to="/Product_Category/OfficeEssential">
                    <button className='butt btn1'>+</button></Link>
                   </div>
                   </div>
                    </div>
                </div>
                {/* row completed */}
            </div>
            </div>
        </div>
    )
}

export default OurServices;