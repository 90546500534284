import React, { useState } from 'react';
import "../css/Certificates.css";
import { saveAs } from 'file-saver';
import { Document, Page } from 'react-pdf';



const Certificates = () => {
  const [numPages, setNumPages] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // const handleDownload = () => {
  //   const pdfURL="https://rustx.net/wp-content/uploads/2019/02/TradeMark-RUSTX-INDIA-4.pdf";
  //   saveAs(pdfURL, 'Certificate.pdf');
  // };

  const handleDownload=()=>{
  setNumPages(true);
  }

 

  return (
    <div className='container mt-5 mb-5'>
      <div className="download" >
        <h1>Download Certificates</h1>
        </div>
        <div className='download2'>
          <div className='downloadchild'  data-aos="fade-right" data-aos-duration="1500">
          <h1 className='download8 mt-3'>Download Certificate</h1>
          <h4 className='mt-3'>TraderMark Class-3</h4>
          <h4 className='mt-3'>Rust-x </h4>
          <h4 className='mt-3'>Rust Without Rust</h4>
          <button onClick={handleDownload} className='download9 mt-3'>
          <a  href="/pdf/TradeMark-RUSTX-INDIA-4.pdf" download>
           Download the Certificates
          </a>
          </button>
         
          </div>
          <div className='downloadchild'  data-aos="fade-up" data-aos-duration="1500">
          <h1 className='download8 mt-3'>Download Certificate</h1>
          <h4 className='mt-3'>TraderMark Class-3</h4>
          <h4 className='mt-3'>Rust-x </h4>
          <h4 className='mt-3'>Rust Without Rust</h4>
          <button onClick={handleDownload} className='download9 mt-3'>
          <a  href="/pdf/TradeMark-RUSTX-INDIA-4.pdf" download>
           Download the Certificates
          </a>
          </button>
         
          </div>
          <div className='downloadchild'  data-aos="fade-left" data-aos-duration="1500">
          <h1 className='download8 mt-3'>Download Certificate</h1>
          <h4 className='mt-3'>TraderMark Class-3</h4>
          <h4 className='mt-3'>Rust-x </h4>
          <h4 className='mt-3'>Rust Without Rust</h4>
          <button onClick={handleDownload} className='download9 mt-3'>
          <a  href="/pdf/TradeMark-RUSTX-INDIA-4.pdf" download>
           Download the Certificates
          </a>
          </button>
         
          </div>

        </div>
    </div>
  );
};

export default Certificates;
