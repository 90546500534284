import React from 'react'
import { TfiAnnouncement } from "react-icons/tfi";
import '../css/AbtPricing.css'

const AbtPricing = () => {
  return (
    <div className='container abtprice-head'>

        {/* <div className='container abt-ch'>
            <div className='abt-pheads'>
                <div className='abt-ph'>
                    <span className='abt-ul'></span>
                    <span className='abt-pcnt'>Our service Aera's</span>
                </div>
                <p className='abt-cnt'>We're ready to share our <br /> advice and experience.</p>
            </div>

            <div className='abt-cont2'>
                <span>The main difference between Statistic and Statistics is that the Statistic is a single measure of some attribute of a sample and Statistics is a study of the collection.</span>
            </div>
        </div> */}

        <div className='sa-head'>
            <p>Our Service Area's</p>
        </div>

        <div className='container AbtPricing-head'>
            <div className='AbtPricing1' data-aos="zoom-in-right" data-aos-duration="1000">
                <img src='/images/Am.webp' className='sev-area-icon'/>
                {/* <TfiAnnouncement className='AbtPricing-icon'/> */}
                <p className="AbtPricing2">Automotive</p>
                <p className="AbtPricing3">India is one of the world's fastest growing economic systems. This is particularly applicable to the automobile and automotive industry.
                </p>
            </div>
            <div className='AbtPricing1' data-aos="zoom-in-up" data-aos-duration="1000">
            <img src='/images/as.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">Aerospace</p>
                <p  className="AbtPricing3">Lightweight construction is growing inexorably, especially in the aerospace industry. Now offers tools for machining panels.
                </p>
            </div>
            <div className='AbtPricing1' data-aos="zoom-in-up" data-aos-duration="1000">
            <img src='/images/worker.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">General Machining</p>
                <p  className="AbtPricing3">Better machines and higher pay: For industry professionals attending the China International Machine Tool & Tools Exhibition 2014 in Beijing.
                </p>
            </div>
            <div className='AbtPricing1' data-aos="zoom-in-left" data-aos-duration="1000">
            <img src='/images/et.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">Energy Technology</p>
                <p  className="AbtPricing3">According to the German federal government’s GreenTech Atlas, green technology will be one of the growth drivers of the future
                </p>
            </div>
            <div className='AbtPricing1' data-aos="zoom-in-right" data-aos-duration="1000">
            <img src='/images/mold.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">Mold and Die</p>
                <p  className="AbtPricing3">A discussion with Hanjo Gissler, product manager for milling at LMT Tools, illustrates the rapid development in mold and die making.
                </p>
            </div>
            <div className='AbtPricing1' data-aos="zoom-in-up" data-aos-duration="1000">
            <img src='/images/pe.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">Project Engineering</p>
                <p  className="AbtPricing3">Reliable performance, reproducible results, lower storage costs and higher cutting speed - during tapping users.
                </p>
            </div>  
            <div className='AbtPricing1' data-aos="zoom-in-up" data-aos-duration="1000">
            <img src='/images/ret.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">Restaurants</p>
                <p  className="AbtPricing3">Indulge in a symphony of flavors crafted with passion and served with care at our restaurant, where every dish tells a unique story.
                </p>
            </div>  
            <div className='AbtPricing1' data-aos="zoom-in-left" data-aos-duration="1000">
            <img src='/images/cc.webp' className='sev-area-icon'/>
                <p className="AbtPricing2">Civil Construction</p>
                <p  className="AbtPricing3">We build futures by blending expertise, innovation, and dedication, creating landmarks that stand as testaments to progress and quality.
                </p>
            </div>  
        </div>
    </div>
  )
}

export default AbtPricing