import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../../css/Productdetails.css';

const Rustp = ({id,cid}) => {
    const [show , setShow] = useState("")
    useEffect(() => {
        //VCIPackaging
        if(id === "Rust-X"&&cid==='1'){
            setShow("vcp1")
          }
        if(id === "Rust-X"&&cid==='2'){
            setShow("vcp2")
          }
        if(id === "Rust-X"&&cid==='3'){
            setShow("vcp3")
          }
        if(id === "Rust-X"&&cid==='4'){
            setShow("vcp4")
          }

    },[id,cid])
  return (
    <div className='col-md-8'>
                        <div className='firstlineflex'>
                            <div className='yellowbreak'></div>
                            <div className='firstheading'>
                                <h1>
                               { show === "vcp1" ? "VCI Additives"  : show === "vcp2" ? "Metal Working Fluids"  : 
                               show === "vcp3" ? "VCI Oils-Domestic"  : show === "vcp4" ? "Preventive Oils – Export"  
                               :""}

                               

                                </h1>
                            </div>
                        </div>
                        <div className='row paraoutdoor'>
                            <div className='col-md-4 col-sm-12'>
                            {
                               show === "vcp1" ?
                                <>
                                    <img src='/images/rustpreventoils.webp' alt='dummy'/> 
                                    {/* <button className='prdtBtn'>Buy now</button> */}
                                </>:
                               show === "vcp2" ?
                                <>
                                    <img src='/images/rustpreventoils2.webp' alt='dummy'/> 
                                </>:
                               show === "vcp3" ?
                                <>
                                    <img src='/images/rustpreventoils3.webp' alt='dummy'/> 
                                </>:
                               show === "vcp4" ?
                                <>
                                    <img src='/images/rustpreventoils4.webp' alt='dummy'/> 
                                </>:
                                null
                               }
                            </div>
                            <div  className='col-md-8 col-sm-12'>
                                {show === "vcp1"? <p className='text-muted firstparavalue'>Unrivaled Corrosion Defense, Enhanced Performance! Our VCI (Vapor Corrosion Inhibitor) additives are engineered to redefine metal preservation. These specialized formulations seamlessly integrate into various lubricants, oils, and coatings, creating a powerful shield against corrosion and rust. Designed for industrial applications, our VCI additives ensure long-lasting protection for your valuable assets, enhancing their durability and reliability. Experience the difference of advanced corrosion defense technology. Choose VCI Additives to safeguard your investments, ensuring optimal performance and longevity. Unleash the potential of your equipment with our cutting-edge VCI solutions</p> : 
                                null}
                                {show === "vcp2"? <p className='text-muted firstparavalue'>Unleashing Performance, Enhancing Efficiency! Our metal working fluids are meticulously crafted to meet the demands of modern industries. Engineered for exceptional cooling, lubrication, and corrosion protection, these fluids empower your machining processes with unmatched efficiency and precision. Whether you're shaping, cutting, or grinding, our specialized formulations ensure smooth operations, prolong tool life, and enhance surface quality. Trust in our expertise to deliver top-tier metal working fluids that redefine industry standards. Elevate your productivity, enhance your results, and experience the seamless synergy between technology and craftsmanship with our cutting-edge solutions.</p> : 
                                null}
                                {show === "vcp3"? <p className='text-muted firstparavalue'>Reliable Protection, Domestic Excellence: Introducing VCI Oils for Your Precious Metals! Our VCI (Vapor Corrosion Inhibitor) Oils offer superior protection for your domestic metal assets. Specially formulated to prevent corrosion and rust, these oils create an invisible shield, ensuring your metals remain pristine even in challenging environments. Perfect for domestic applications, our VCI oils provide a robust defense against moisture and atmospheric elements, preserving the integrity of your metals. Trust in our domestic expertise to safeguard your investments. Choose VCI Oils for unparalleled corrosion protection and lasting peace of mind in every application.</p> : 
                                null}
                                {show === "vcp4"? <p className='text-muted firstparavalue'>Unveiling Our Preventive Oils for International Markets! Our Preventive Oils, tailored for export, redefine the standards of metal preservation worldwide. Crafted with precision and expertise, these oils form a resilient barrier against corrosion and rust, ensuring your metal products remain flawless during transit and storage. Engineered for international shipping, our Preventive Oils provide unmatched protection against harsh climates and long journeys, guaranteeing your exports reach their destination in optimal condition. Trust in our export-grade solutions to safeguard your products on the global stage. Elevate your exports with Preventive Oils – where excellence meets international standards</p> : 
                                null}
                            </div>
                        </div>
{/* 
                       < div className='paraoutdoor1 '>
                        { show === "vcp1" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp2" ? <p className='text-muted firstparavalue'>Dr1 Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                        { show === "vcp3" ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                        </p> : null}
                      
                        {show === "vcp4"? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.</p> : 
                                null}
                        
                        
                        </div> */}

                        {/* <div className='paraoutdoor1'>
                        {fp ? <p className='text-muted firstparavalue'>Dr Bio Polymers have the same functionalities, appearance and feel as conventional polymers, they are durable to protect food Bio-Degradable and keep them fresh so that customers can see their content. A package provides protection, tampering resistance, and special physical, chemical, or biological needs
                                It may bear printed information that can have nutrition facts label and other information about food being offered for sale. In addition, they can be revalued as compost, despite containing organic residues. It does not matter if the film or packaging is contaminated with food, as it can be composted.
                                
                                </p> : null}
                        </div> */}
                {/*  1 line */}
                        {/* <div className='downsession'>
                            {fp &&
                                <div >
                                
                                    <div className='downparasession'>
                                        <img src="/download.svg" alt='p' className='image-fluid iconroller'/> 
                                        <p>Packaging extends shelf life, reducing food waste, and ensuring freshness for consumers.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p> Acts as a protective barrier, preventing contamination and maintaining food hygiene and safety standards.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Provides essential details such as ingredients, nutritional facts, and expiry dates for informed consumer choices.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Enables easy handling, storage, and on-the-go consumption, aligning with modern, fast-paced lifestyles.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/>  <p>Focuses on eco-friendly materials and recycling practices, minimizing the environmental impact of packaging waste.</p>
                                </div>
                                <div className='downparasession'>
                                    <TbSquareRoundedCheckFilled className='tick'/>   <p>Integrates technology for features like freshness indicators and interactive elements, enhancing user experience and product quality.</p>
                                    </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Adheres to strict standards and regulations, ensuring food safety and accurate information on packaging labels.</p>
                                </div>
                                    <div className='downparasession'>
                                        <TbSquareRoundedCheckFilled className='tick'/> <p>Promotes efficient design and recyclable materials, contributing to reducing overall environmental waste and encouraging responsible consumption.</p>
                                    </div>
                                        
                                </div>
                            }
                                
                        
                        </div> */}

                        <div className='downsession'>

                            <div className='downparasession'>
                                <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                    {show === "vcp1"?<p>VCI additives are designed to prevent corrosion on metal surfaces by releasing volatile corrosion inhibitors into the surrounding environment. These inhibitors protect metals from oxidation and rust</p>: null}
                                    {show === "vcp2"?<p>MWFs dissipate heat generated during metalworking processes, preventing overheating of the cutting tool and workpiece. Proper cooling is essential to maintain dimensional accuracy and prevent thermal damage to the material being processed.</p>: null}
                                    {show === "vcp3"?<p> Domestic VCI oils provide excellent corrosion protection for metal surfaces. They release volatile corrosion inhibitors, creating a protective layer on the metal, preventing corrosion and rust.</p>: null}
                                    {show === "vcp4"?<p>Preventive oils for export are specifically engineered to prevent corrosion on metal surfaces. They create a protective layer, inhibiting rust and corrosion caused by moisture, humidity, and other environmental factors during international shipping and storage.</p>: null}
                                    

                                  
                                    </span>
                                </div>
                                {/* 2 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI additives work through vapor-phase corrosion inhibition, meaning they release protective vapors that form a molecular layer on metal surfaces. This layer inhibits the electrochemical reactions responsible for corrosion</p>: null}
                                    {show === "vcp2"?<p> MWFs provide lubrication between the cutting tool and the workpiece, reducing friction and wear. Lubrication is crucial for extending the tool life and ensuring a smooth machining process.</p>: null}
                                    {show === "vcp3"?<p>VCI oils can be applied to various metals, including steel, aluminum, brass, and copper. They are suitable for protecting a wide range of metal components, equipment, and machinery.</p>: null}
                                    {show === "vcp4"?<p>These oils typically contain VCIs, which release protective vapors that form a molecular layer on metal surfaces. This layer inhibits the electrochemical reactions responsible for corrosion, ensuring comprehensive protection.</p>: null}
                                   


                                   
                                    </span>
                            </div>
                            {/* 3 point */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> VCI additives can be incorporated into various materials, including oils, greases, plastics, papers, and films. This versatility allows them to be applied to a wide range of metal products and components.</p>: null}
                                    {show === "vcp2"?<p>MWFs facilitate the efficient removal of metal chips or swarf from the cutting area, preventing them from clogging the tool or interfering with the machining process.</p>: null}
                                    {show === "vcp3"?<p>These oils are easy to apply, either through spraying, brushing, or dipping. They adhere well to metal surfaces, ensuring uniform coverage and protection.</p>: null}
                                    {show === "vcp4"?<p>Export preventive oils are formulated to meet international standards and regulations, making them suitable for use in various countries and regions worldwide. They are designed to withstand diverse climates and environmental conditions.</p>: null}
                                    
                                    
                                    </span>
                            </div>
                            {/*  4 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI additives are easy to apply during the manufacturing process. They can be mixed into coatings, oils, or packaging materials, ensuring uniform distribution on metal surfaces.</p>: null}
                                    {show === "vcp2"?<p>Metalworking fluids contain additives that protect metal surfaces from rust and corrosion. This protection is particularly important when machining ferrous metals.</p>: null}
                                    {show === "vcp3"?<p>VCI oils offer long-term protection, making them ideal for both short-term storage and long-term preservation of metal parts and components..</p>: null}
                                  
                                    {show === "vcp4"?<p>These oils can be applied to a wide range of metals, including ferrous and non-ferrous metals, ensuring comprehensive protection for various metal components, machinery, and equipment.</p>: null}
                                    

                                   </span>
                            </div>
                            {/* 5 points */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI additives provide long-term corrosion protection, making them suitable for both short-term storage and long-term preservation of metal parts, components, and equipment.</p>: null}
                                    {show === "vcp2"?<p> Properly selected and applied MWFs contribute to achieving a superior surface finish on the workpiece. The lubrication they provide ensures a clean, smooth cut, reducing the likelihood of surface imperfections.</p>: null}
                                    {show === "vcp3"?<p> VCI oils contain vapor-phase inhibitors that create a protective atmosphere around metal surfaces. These inhibitors prevent corrosion by interrupting the electrochemical reactions responsible for metal degradation.</p>: null}
                                 
                                    {show === "vcp4"?<p>Preventive oils are user-friendly and easy to apply using methods such as spraying, brushing, or dipping. They adhere well to metal surfaces, ensuring complete coverage for effective protection.</p>: null}
                                    
                                    </span>
                            </div>
                            {/*  6 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p>VCI additives are effective on various metals, including steel, aluminum, copper, and brass. They can protect different types of metals stored or transported together.</p>: null}
                                    {show === "vcp2"?<p>MWFs come in various types, including straight oils (neat oils), water-soluble oils, semi-synthetic fluids, and synthetic fluids. Each type has specific advantages and is suitable for different machining operations and materials.</p>: null}
                                    {show === "vcp3"?<p>Despite their strong protection, VCI oils are designed to be easily removable. The protective layer can be cleaned off metal surfaces when the parts are ready for use, leaving no residue behind.</p>: null}

                                    {show === "vcp4"?<p>Export preventive oils provide long-term preservation, making them ideal for products that might be in transit or storage for extended periods, including during international shipping or warehousing.</p>: null}

                                  
                                    </span>
                            </div>
                            {/* 7 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> VCI additives are often non-toxic and environmentally friendly. They do not contain harmful chemicals, making them safe for both users and the environment.</p>: null}
                                    {show === "vcp2"?<p>Many modern metalworking fluids are formulated to be biodegradable, reducing their environmental impact. Biodegradable MWFs break down naturally over time, promoting eco-friendly machining practices.</p>: null}
                                    {show === "vcp3"?<p>Domestic VCI oils are formulated according to regional standards and regulations. They are customized to meet the specific needs and requirements of industries within a particular country or region.  </p>: null}
                                  
                                    {show === "vcp4"?<p>While providing durable protection, these oils are designed to be easily removable. The protective layer can be cleaned off metal surfaces when the products are ready for use, leaving the metal clean and free of residue</p>: null}
                                   

                                    
                                    </span>
                            </div>
                            {/* 8 points  */}
                            <div className='downparasession'>
                            <img src="/download.svg" className='image-fluid iconroller'/>
                                <span className='clinicpara'>
                                {show === "vcp1"?<p> While VCI additives offer long-lasting protection, they are designed to be removable. The protective layer can be easily removed by cleaning or washing the metal surface, leaving it clean and ready for use without residue.</p>: null}
                                    {show === "vcp2"?<p>Proper handling and disposal of metalworking fluids are essential for the health and safety of workers. Exposure to certain MWFs, especially those containing additives or contaminants, can pose health risks, so following safety guidelines and using appropriate protective equipment is crucial.</p>: null}
                                    {show === "vcp3"?<p>Domestic VCI oils are often cost-effective solutions for corrosion prevention. They provide robust protection without the need for expensive coatings or complicated application processes.</p>: null}
                                    {show === "vcp4"?<p>Manufacturers often offer custom formulations tailored to specific export requirements, ensuring that the preventive oils meet the unique needs of the products</p>: null}
                                    </span>
                            </div>
                        </div>
    </div>
  )
}

export default Rustp