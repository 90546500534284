import React from 'react'
import Vci from '../Rustx/Vci'
import { useParams } from 'react-router-dom'
import CommonBtns from '../CommonBtns'
import Cs from './ppe/Cs'
import { useState } from 'react'
import Rps from './ppe/Rps'
import Ff from './ppe/Ff'
import Weld from './ppe/Weld'
import ExProd from './ppe/ExProd'
import Ms from './ppe/Ms'
import SafetyProd from './SafetyProd'

const Ppe = ({setLoad}) => {
    const {id,pid,cid,cat} = useParams()
    const [isLoading, setIsLoading] = useState(false);

    console.log("1",id)
  return (
    <>
            
    <div className='container'>
        <div className={isLoading ? "opaque" :  "opaque1"}>
        <div className='row overall'>
            <div className='col-md-4' >
            <CommonBtns id={id} pid={pid} setLoad={setLoad}/>
            </div> 
            
            {
                id === "propre" &&
                <>
                    <Cs id={id} cid={cid} />
                </>
            }
            {
                id === "road&parking" &&
                <>
                    <Rps id={id} cid={cid} />
                </>
            }
            {
                id === "firefighting" &&
                <>
                    <Ff id={id} cid={cid} />
                </>
            }
            {
                id === "welding" &&
                <>
                    <Weld id={id} cid={cid} />
                </>
            }
            {
                id === "excul-prod" &&
                <>
                    <ExProd id={id} cid={cid} />
                </>
            }
            {
                id === "marinesafety" &&
                <>
                    <Ms id={id} cid={cid} />
                </>
            }

           <SafetyProd id={id} cid={cid}/>
           
        </div>
        </div>
    </div>


</>
  )
}

export default Ppe