import React, { useEffect, useState } from 'react'
import Navbar from '../Home/Navbar'
import Welcome from '../Common/Welcome'
import SnHeader from '../Common/SnHeader'
import Nav from '../Common/Nav'
import Footer from '../Home/Footer'
import CnBanner from '../Common/CnBanner'
import Blogs from '../Home/Blogs'
import EcBlog from '../Blogs/EcBlog'
import Certificates from "../Pages/Certificates"

const Downloadcenter = ({windowSize,color,setColor}) => {
    const [show, setShow] = useState(false)
    const showed = () => {
        if(window.scrollY >= 150){
          setShow(true)
        }else{
          setShow(false)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', showed)
  
        return () => window.removeEventListener('scroll',showed)
    })
    const image = '/images/downloadbanner.webp'

  return (
    <>
        {windowSize[0] > 1200 && show &&
            <Navbar windowSize={windowSize} color={color} setColor={setColor}/>
        }
        {windowSize[0] > 1200 && 
          <Welcome />
        }
     
      {windowSize[0] > 1390 && 
       <SnHeader /> 
      }
     {windowSize[0] < 1390 && 
      <Nav />
     }    
    <CnBanner windowSize={windowSize} title="Download Center" image={image}/>

    <Certificates/>

     <Footer color={color}/>
    </>
  )
}

export default Downloadcenter;